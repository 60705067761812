import Button from "@mui/material/Button/Button";
import styled from "@mui/material/styles/styled";

const StyledButton = styled(Button)({
  textTransform: "none",
  borderRadius: "30px",
  fontWeight: "700",
  height: "6vh",
  paddingInline: "1vw",
});

const ButtonComponent = ({
  label,
  type,
  disabled,
  onClick,
  fullWidth,
  backgroundColor,
  color,
  size,
  height,
  borderRadius,
  fontSize,
}) => {
  return (
    <StyledButton
      variant="contained"
      disabled={disabled}
      disableElevation
      disableRipple
      disableFocusRipple
      disableTouchRipple
      type={type}
      onClick={onClick}
      size={size ?? "large"}
      fullWidth={fullWidth}
      style={{
        backgroundColor: backgroundColor ?? "white",
        color: color ?? "#858585",
        height: height ?? "6vh",
        borderRadius: borderRadius ?? "30px",
        fontSize: fontSize ?? "1.46vh",
        lineHeight: "normal",
      }}
    >
      {label}
    </StyledButton>
  );
};

export default ButtonComponent;
