import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import green from "@mui/material/colors/green";

import {
  BodyComponent,
  ButtonComponent,
  FiltersComponent,
  TableComponent,
} from "../../../../components";
import useAdminPaymentsController from "./admin-payments.controller";
import ReceiptForPaymentsDialogComponent from "./components/receipt-for-payments-dialog.component";
import { Format } from "../../../../utils";

const AdminPaymentsPage = () => {
  const {
    payments,
    campaigns,
    users,
    onSearch,
    onPayUser,
    situationOptions,
    onSelectedPayments,
    isValidSelectedPaymentsToPay,
    onConfirmPayUser,
    handleChangePage,
    page,
    count,
    rowsPerPage,
  } = useAdminPaymentsController();

  return (
    <BodyComponent>
      <ReceiptForPaymentsDialogComponent onConfirmPayUser={onConfirmPayUser} />
      <FiltersComponent
        userOptions={users}
        campaignOptions={campaigns}
        onSearch={onSearch}
        situationOptions={situationOptions}
        actions={
          isValidSelectedPaymentsToPay()
            ? [
                <ButtonComponent
                  label="DAR BAIXA"
                  backgroundColor="#774E98"
                  color="white"
                  fullWidth
                  height="5vh"
                  onClick={onPayUser}
                />,
              ]
            : null
        }
      />
      <TableComponent
        isSelected
        onSelected={onSelectedPayments}
        columns={[
          { label: "PRODUTO", acessor: "campaign", width: "10vw" },
          {
            label: "USUÁRIO",
            acessor: "user.name",
            width: "12vw",
            formatText: (row) => row["user"]["name"],
          },
          { label: "DATA", acessor: "eventDate", width: "7vw" },
          {
            label: "GANHO DA RIZOMA",
            acessor: "commission",
            width: "12vw",
            formatText: (row) => Format.toCurrency(row["commission"]),
          },
          {
            label: "GANHO DO USUÁRIO",
            acessor: "payment",
            width: "12vw",
            formatText: (row) => Format.toCurrency(row["payment"]),
          },
          {
            label: "PAGO",
            acessor: "paidOut",
            width: "4vw",
            formatWidget: (row) => {
              if (row["paidOut"]) {
                return <CheckCircleIcon style={{ color: green[500] }} />;
              }
            },
          },
        ]}
        rows={payments}
        pagination={{
          page: page,
          count: count,
          rowsPerPage: rowsPerPage,
          handleChangePage: handleChangePage,
        }}
      />
      <div style={{ height: "60vh" }}></div>
    </BodyComponent>
  );
};

export default AdminPaymentsPage;
