import React from "react";
import CssBaseline from "@mui/material/CssBaseline/CssBaseline";
import createTheme from "@mui/material/styles/createTheme";
import ThemeProvider from "@mui/material/styles/ThemeProvider";
import { red } from "@mui/material/colors";
import { ptBR } from "@mui/material/locale";

import theme from "../../theme";
import { useAuth } from "./auth.provider";

const WhiteLabelContext = React.createContext({
  theme: null,
  hasCustomTheme: false,
  preferencias: {
    logoUrl: null,
  },
});

export const useWhiteLabel = () => React.useContext(WhiteLabelContext);

const publicRoutes = [
  "/",
  "/entrar",
  "/nova-senha",
  "/criar-conta",
  "/anunciar",
  "/criar-conta/sucesso",
];

export const WhiteLabelProvider = ({ children }) => {
  const { user } = useAuth();
  const [currentTheme, setCurrentTheme] = React.useState(theme);
  const [hasCustomTheme, setHasCustomTheme] = React.useState(false);
  const [preferencias, setPreferencias] = React.useState(null);

  React.useEffect(() => {
    if (!publicRoutes.includes(window.location.pathname)) {
      if (!!user && user.role != "ADMINISTRADOR" && !!user.theme) {
        const newTheme = createTheme(
          {
            typography: {
              fontFamily: "Open Sans, sans-serif",
            },
            palette: {
              primary: {
                main: user.theme.corPrimaria,
              },
              secondary: {
                main: user.theme.corSecundaria,
              },
              error: {
                main: red.A400,
              },
              background: {
                default: "#F9F6F8",
              },
            },
          },
          ptBR
        );

        setCurrentTheme(newTheme);
        setHasCustomTheme(true);
        setPreferencias({ logoUrl: user.theme.logoUrl });
      }
    }
  }, [user]);

  return (
    <WhiteLabelContext.Provider
      value={{
        hasCustomTheme,
        theme: currentTheme,
        preferencias: preferencias,
      }}
    >
      <ThemeProvider theme={currentTheme}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </WhiteLabelContext.Provider>
  );
};
