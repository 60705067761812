import React from "react";
import Typography from "@mui/material/Typography/Typography";
import makeStyles from "@mui/styles/makeStyles/makeStyles";
import html2canvas from "html2canvas";
import QRCode from "qrcode.react";

import {
  ButtonComponent,
  GenericDialogComponent,
} from "../../../../components";
import { useDialog, useResponsive } from "../../../../providers";
import BackgroundQrcodeImage from "../../../../../assets/imagens/background-qrcode.png";

const useStyles = makeStyles(() => ({
  content: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: "1vh",
    marginBottom: "2vh",
    overflow: "hidden",
  },
  divQrCode: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginBottom: "-6.2rem",
  },
  qrcode: {
    bottom: "14rem",
    position: "relative",
    borderRadius: "2px",
  },
  img: {
    height: "30rem",
  },
  text: {
    top: "8rem",
    position: "relative",
    borderRadius: "2px",
    zIndex: 1,
    color: "white",
    textAlign: "center",
    fontSize: "1.6rem",
    fontWeight: "600",
    width: "49%",
  },
  textMobile: {
    top: "20%",
    position: "relative",
    borderRadius: "2px",
    zIndex: 1,
    color: "white",
    textAlign: "center",
    fontSize: "1.3rem",
    fontWeight: "600",
    width: "60%",
  },
}));

const LinkQrCodeDialogComponent = () => {
  const classes = useStyles();
  const { isDesktop } = useResponsive();

  const { metadata: dados } = useDialog();

  const downloadQRCode = async () => {
    const qrCodeEl = document.getElementById("qrCodeEl");

    const canvas = document.createElement("canvas");
    canvas.width = 600;
    canvas.height = 1080;

    const context = canvas.getContext("2d");

    const scale = Math.min(
      canvas.width / qrCodeEl.offsetWidth,
      canvas.height / qrCodeEl.offsetHeight
    );

    context.scale(1.0, 1.0);

    html2canvas(qrCodeEl, {
      canvas: canvas,
      scale: scale,
    }).then(() => {
      const canvasDataURL = canvas.toDataURL("image/png", 1.0);

      let aEl = document.createElement("a");
      aEl.href = canvasDataURL;
      aEl.download = "QR_Code.png";

      document.body.appendChild(aEl);
      aEl.click();
      document.body.removeChild(aEl);
    });
  };
  //TODO: Imagem movimenta com scroll
  return (
    <GenericDialogComponent name="link-qr-code-dialog">
      <div className={classes.content}>
        <Typography
          style={{
            marginBottom: "6vh",
            fontWeight: "700",
            fontSize: "1.95vh",
            color: "#40215F",
          }}
        >
          LINK VIA QR-CODE
        </Typography>
        <div id="qrCodeEl" className={classes.divQrCode}>
          <span className={isDesktop ? classes.text : classes.textMobile}>
            {dados?.nome ?? ""}
          </span>
          <img
            src={BackgroundQrcodeImage}
            alt="Background"
            className={classes.img}
          />
          <QRCode
            id="qrCode"
            size={500}
            className={classes.qrcode}
            value={dados?.link ?? ""}
          ></QRCode>
        </div>

        <div
          style={{
            marginTop: isDesktop ? "8rem" : "8rem",
            position: "relative",
            bottom: "10rem",
          }}
        >
          <ButtonComponent
            label="BAIXAR"
            backgroundColor="#774E98"
            color="white"
            fullWidth
            height="5vh"
            onClick={downloadQRCode}
          />
        </div>
      </div>
    </GenericDialogComponent>
  );
};

export default LinkQrCodeDialogComponent;
