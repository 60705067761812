import Grid from "@mui/material/Grid/Grid";

import { BodyComponent, ButtonComponent } from "../../../../components";
import useAdminNotificationSendController from "./admin-notification-send.controller";
import SendSectionComponent from "./components/send-section.component";
import MessageSectionComponent from "./components/message-section.component";

const AdminNotificationSendPage = () => {
  const {
    control,
    sendTypeOptions,
    sendGroupOptions,
    notificationTypeOptions,
    onSubmit,
    withSendGroup,
    linkOptions,
    editImage,
    setErrorInMedia,
    onDeleteMedia,
    sendUsersOptions,
    isLoading,
    getValues,
  } = useAdminNotificationSendController();

  return (
    <BodyComponent>
      <Grid component="form" onSubmit={onSubmit} container>
        <Grid item xs={12} style={{ marginBottom: "4vh" }}>
          <SendSectionComponent
            control={control}
            sendTypeOptions={sendTypeOptions}
            sendGroupOptions={sendGroupOptions}
            sendUsersOptions={sendUsersOptions}
            notificationTypeOptions={notificationTypeOptions}
            withSendGroup={withSendGroup}
            isLoading={isLoading}
            getValues={getValues}
          />
        </Grid>

        <Grid item xs={12} style={{ marginBottom: "4vh" }}>
          <MessageSectionComponent
            control={control}
            linkOptions={linkOptions}
            editImage={editImage}
            setErrorInMedia={setErrorInMedia}
            onDeleteMedia={onDeleteMedia}
            isLoading={isLoading}
          />
        </Grid>

        <Grid item xs={12} style={{ marginBottom: "4vh" }}>
          <div style={{ display: "flex", justifyContent: "end" }}>
            <div style={{ marginLeft: "1vw" }}>
              <ButtonComponent
                label={
                  isLoading == false ? "ENVIAR NOTIFICAÇÃO" : "CARREGANDO..."
                }
                backgroundColor="#40215F"
                color="white"
                size="small"
                height="5vh"
                type="submit"
                disabled={isLoading}
              />
            </div>
          </div>
        </Grid>
      </Grid>
    </BodyComponent>
  );
};

export default AdminNotificationSendPage;
