import React from "react";

import {
  campaignService,
  userService,
  productionService,
  dashboardService,
} from "../../../../infrastructure/services";
import { useAlert, useAuth } from "../../../providers";
import { useIsMounted } from "../../../hooks";
import { Constants } from "../../../utils";
import moment from "moment";
import { useLoadingContext } from "../../../context/loading.context";
import dayjs from "dayjs";

const useDashboardController = () => {
  const { showError } = useAlert();
  const { user } = useAuth();
  const isMounted = useIsMounted();
  const [campaigns, setCampaigns] = React.useState([Constants.defaultSelect]);
  const [users, setUsers] = React.useState([Constants.defaultSelect]);
  const [dashboard, setDashboard] = React.useState(null);
  const { setLoadingState } = useLoadingContext();
  const [counters, setCounters] = React.useState();

  React.useEffect(() => {
    if (user != null) {
      setLoadingState(true);
      campaignService
        .getUserCampaigns()
        .then((response) => {
          if (isMounted.current) {
            setCampaigns([
              Constants.defaultSelect,
              ...response.map((item) => ({ id: item.id, label: item.name })),
            ]);
          }
        })
        .catch(showError)
        .finally(() => {
          setLoadingState(false);
        });

      setLoadingState(true);
      userService
        .getAffilietdToReports({ id: user.id })
        .then((response) => {
          if (isMounted.current) {
            setUsers([
              Constants.defaultSelect,
              ...response.map((item) => ({
                id: item.id,
                label: item.displayName,
              })),
            ]);
          }
        })
        .catch(showError)
        .finally(() => {
          setLoadingState(false);
        });

      if (user.isAdmin) {
        dashboardService
          .getCounters()
          .then((response) => {
            if (isMounted.current) {
              setCounters(response);
            }
          })
          .catch(showError)
          .finally(() => {
            setLoadingState(false);
          });
      }
    }
  }, [user]);

  const onSearch = ({ startDate, endDate, user, campaign, situation }) => {
    const query = {
      startDate: dayjs(startDate).format().substring(0, 10),
      endDate: dayjs(endDate).format().substring(0, 10),
      situacao:
        situation.label == "APROVADAS"
          ? "CONCLUÍDO"
          : situation.label == "PENDENTES"
          ? "AGUARDANDO"
          : "TODOS",
      page: 0,
      rowsPerPage: 100,
    };

    if (!!campaign && campaign.id != 0) {
      query.offerId = campaign.id;
    }

    if (!!user && user.id !== 0) {
      query.usuarioId = user.id;
    }
    setLoadingState(true);
    productionService
      .getDashboard(query)
      .then((response) => {
        setDashboard({
          ...response,
          chartCampaignVsConversion: makeConsersionChart(response),
        });
      })
      .catch(showError)
      .finally(() => {
        setLoadingState(false);
      });
  };

  const makeConsersionChart = (dashboard) => {
    const lines = [];
    const rows = Object.entries(
      dashboard.chartCampaignVsConversion.conversions
    );
    const campaigns = dashboard.chartCampaignVsConversion.campaigns;

    rows.forEach((row) => {
      const conversions = Object.keys(row[1]);

      const dataRow = Array.from({ length: campaigns.length }, () => 0);

      conversions.forEach((campaignName) => {
        const index = campaigns.indexOf(campaignName);
        dataRow[index] = row[1][campaignName];
      });

      lines.push([row[0], ...dataRow]);
    });

    const data = [["x", ...campaigns], ...lines];

    return data;
  };

  return { dashboard, campaigns, users, onSearch, counters };
};

export default useDashboardController;
