import FormControl from "@mui/material/FormControl/FormControl";
import FormHelperText from "@mui/material/FormHelperText/FormHelperText";
import InputLabel from "@mui/material/InputLabel/InputLabel";
import MenuItem from "@mui/material/MenuItem/MenuItem";
import Select from "@mui/material/Select/Select";
import styled from "@mui/material/styles/styled";
import { Controller } from "react-hook-form";

import ArrowToBottomIcon from "../../../assets/icones/arrow-to-bottom.svg";

const StyledSelect = styled(Select)({
  "& .MuiSelect-select": {
    color: "white",
    fontWeight: "600",
    fontSize: "1.75vh",
    backgroundColor: "inherit",
  },
});

const SelectGradientComponent = ({
  control,
  name,
  label,
  value,
  onChange,
  options,
  isValueObject = true,
}) => {
  const Field = ({ field, formState }) => {
    return (
      <FormControl
        variant="filled"
        style={{
          marginTop: "1.56vh",
          marginBottom: "0.78vh",
        }}
        fullWidth
      >
        <InputLabel
          style={{
            backgroundColor: "inherit",
            color: "rgba(255, 255, 255, 0.4)",
            textAlign: "center",
            fontWeight: "600",
            fontSize: "1.75vh",
          }}
        >
          {label}
        </InputLabel>
        <StyledSelect
          disableUnderline
          value={
            !!field
              ? isValueObject
                ? options.find((option) => option === field.value)
                : field.value
              : value
          }
          onChange={(e) => {
            if (!!field) {
              field.onChange(e.target.value);
            } else {
              onChange(e.target.name, e.target.value);
            }
          }}
          style={{
            backgroundColor: "inherit",
            color: "white",
            textAlign: "center",
            fontWeight: "600",
            borderRadius: "35px",
            borderColor: "#f0f4f8",
            border: "1px solid",
            fontSize: "1.75vh",
          }}
          error={!!field ? !!formState.errors[name] : false}
          label={label}
          IconComponent={(_props) => {
            const rotate = _props.className.toString().includes("iconOpen");
            return (
              <div
                style={{
                  position: "absolute",
                  cursor: "pointer",
                  pointerEvents: "none",
                  right: rotate ? 10 : 22,
                  height: "1.46vh",
                  width: "1.46vh",
                  transform: rotate ? "rotate(180deg)" : "none",
                }}
              >
                <img src={ArrowToBottomIcon} />
              </div>
            );
          }}
        >
          {options.map((opt, index) => (
            <MenuItem key={index} value={isValueObject ? opt : opt.id}>
              {opt.label}
            </MenuItem>
          ))}
        </StyledSelect>
        <FormHelperText error={!!field ? !!formState.errors[name] : false}>
          {!!field
            ? formState.errors[name]?.message == null
              ? formState.errors[name]?.id?.message
              : formState.errors[name]?.message
            : null}
        </FormHelperText>
      </FormControl>
    );
  };

  if (!!control) {
    return (
      <Controller
        name={name}
        control={control}
        render={({ field, formState }) => {
          return <Field field={field} formState={formState} />;
        }}
      />
    );
  }

  return <Field field={null} formState={null} />;
};

export default SelectGradientComponent;
