import React from "react";
import Grid from "@mui/material/Grid/Grid";
import Typography from "@mui/material/Typography/Typography";
import makeStyles from "@mui/styles/makeStyles/makeStyles";

import CardInfoComponent from "./card-info.component";
import { useResponsive } from "../../../../providers";

const useStyles = makeStyles(() => ({
  retangular: {
    position: "relative",
    width: "60vw",
    marginTop: "6vw",
    marginBottom: "13vw",
    height: "400px",
    backgroundColor: "#094E86",
    borderRadius: "20px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "20px",
    justifyContent: "space-around",
  },
  superior: {
    position: "absolute",
    top: "-25px",
    left: "50%",
    transform: "translateX(-50%)",
    width: "55px",
    height: "55px",
    borderRadius: "50%",
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    gap: "15vw",
    marginBottom: "20px",
  },
  inferior: {
    position: "absolute",
    top: "130px",
    left: "50%",
    transform: "translateX(-50%)",
    borderRadius: "50%",
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    marginBottom: "20px",
    flexDirection: "column",
  },
}));

const RectangleComponent = () => {
  const classes = useStyles();
  const { isDesktop } = useResponsive();

  return (
    <Grid container style={{ justifyContent: "center" }}>
      <Grid item>
        <div
          className={classes.retangular}
          style={{
            position: isDesktop ? "relative" : "inherit",
            width: isDesktop ? "60vw" : "100vw",
            height: isDesktop ? "40em" : "auto",
          }}
        >
          <div
            className={classes.superior}
            style={{
              flexDirection: isDesktop ? "row" : "column",
              position: isDesktop ? "absolute" : "inherit",
              gap: isDesktop ? "300%" : "5vw",
              height: "auto",
              width: isDesktop ? "55px" : "0",
            }}
          >
            <CardInfoComponent text="Treinamento e Mentoria Exclusivos" />
            <CardInfoComponent text="Trabalhe quando e onde quiser" />
            <CardInfoComponent text="Parceria com mais de 40 Instituições Financeiras" />
          </div>
          <div
            className={classes.inferior}
            style={{
              flexDirection: isDesktop ? "" : "column",
              position: isDesktop ? "absolute" : "inherit",
              width: isDesktop ? "0" : "10vw",
              transform: isDesktop ? "translateX(-50%)" : "inherit",
            }}
          >
            <Typography
              xs={isDesktop ? 1 : 12}
              style={{
                fontWeight: "700",
                fontSize: "18px",
                color: "white",
                margin: "1vw",
                width: "100vw",
              }}
              align="center"
            >
              CONHEÇA O GRUPO RIZOMA
            </Typography>
            <iframe
              xs={12}
              width={isDesktop ? "400" : "200"}
              height={isDesktop ? "400" : "200"}
              src="https://www.youtube.com/embed/S4444UUgxK0"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
            ></iframe>
          </div>
        </div>
      </Grid>
    </Grid>
  );
};

export default RectangleComponent;
