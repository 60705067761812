import Grid from "@mui/material/Grid/Grid";
import Typography from "@mui/material/Typography/Typography";
import Divider from "@mui/material/Divider/Divider";
import {
  InputFilterComponent,
  InputFormatComponent,
} from "../../../../components";

const ValuesSectionComponent = ({ control }) => {
  return (
    <div>
      <Typography
        style={{
          fontWeight: "600",
          fontSize: "1.75vh",
          color: "#5C5C5C",
        }}
      >
        VALORES DA PROPOSTA
      </Typography>
      <Divider style={{ marginBottom: "2vh" }} />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <InputFilterComponent
                name="bank"
                control={control}
                label="BANCO"
                placeholder="BANCO"
                disabled={true}
              />
            </Grid>
            <Grid item xs={4}>
              <InputFormatComponent
                name="parcelValue"
                control={control}
                label="VALOR DA PARCELA"
                placeholder="VALOR DA PARCELA"
                format="R$"
                disabled={true}
              />
            </Grid>
            <Grid item xs={4}>
              <InputFilterComponent
                name="parcelQuantity"
                control={control}
                label="QUANTIDADE DE PARCELAS"
                placeholder="QUANTIDADE DE PARCELAS"
                disabled={true}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default ValuesSectionComponent;
