import React from "react";

import { campaignService } from "../../../../infrastructure/services";
import { useAlert, useAuth, useDialog } from "../../../providers";
import { useIsMounted } from "../../../hooks";
import { useLoadingContext } from "../../../context/loading.context";

const useHomeController = () => {
  const { showError } = useAlert();
  const { user } = useAuth();
  const { closeDialog } = useDialog();
  const isMounted = useIsMounted();
  const [highlightCampaigns, setHighlightCampaigns] = React.useState([]);
  const [toHighlightCampaigns, setToHighlightCampaigns] = React.useState([]);
  const [campaigns, setCampaigns] = React.useState([]);
  const [allCampaigns, setAllCampaigns] = React.useState([]);
  const [campaignOptions, setCampaignOptions] = React.useState([]);
  const [featuredCampaigns, setFeaturedCampaigns] = React.useState([]);
  const { setLoadingState } = useLoadingContext();

  React.useEffect(() => {
    if (!!user) {
      setLoadingState(true);
      campaignService
        .getUserCampaigns()
        .then((campaignResponse) => {
          if (isMounted.current) {
            setLoadingState(true);
            campaignService
              .getHighlightCampaigns()
              .then((highlightResponse) => {
                setLoadingState(true);
                campaignService
                  .getFeaturedCampaigns()
                  .then((featuredResponse) => {
                    makeResponses({
                      highlightResponse: highlightResponse,
                      campaignResponse: campaignResponse,
                      featuredResponse: featuredResponse,
                    });
                  })
                  .catch(showError)
                  .finally(() => {
                    setLoadingState(false);
                  });
              })
              .catch(showError)
              .finally(() => {
                setLoadingState(false);
              });
          }
        })
        .catch(showError)
        .finally(() => {
          setLoadingState(false);
        });
    }
  }, [user]);

  const makeResponses = ({
    highlightResponse,
    campaignResponse,
    featuredResponse,
  }) => {
    setFeaturedCampaigns(featuredResponse);

    const highlightCampaignsAux = highlightResponse.map((id) => {
      return campaignResponse.find((produto) => produto.id == id);
    });

    setHighlightCampaigns(highlightCampaignsAux);

    setCampaigns(
      campaignResponse.filter(
        (c) =>
          !featuredCampaigns.map((item) => item.id).includes(c.id) &&
          !highlightCampaignsAux.map((item) => item.id).includes(c.id)
      ).sort((a, b) => b.createdAt - a.createdAt)
    );

    setAllCampaigns(campaignResponse);

    setCampaignOptions(
      campaignResponse.map((c) => ({
        id: c.id,
        label: c.name,
      }))
    );
  };

  const onSelectHighlightCampaigns = (campaignsToHightlight) => {
    setToHighlightCampaigns(campaignsToHightlight);
  };

  const isValidToSelectHighlightCampaigns = () => {
    return toHighlightCampaigns.length == 3;
  };

  const onChangeHightlightCampaigns = () => {
    setLoadingState(true);
    campaignService
      .changeHighlightCampaigns(toHighlightCampaigns.map((c) => c.id))
      .then(() => {
        closeDialog();
        setHighlightCampaigns(toHighlightCampaigns);
      })
      .catch(showError)
      .finally(() => {
        setLoadingState(false);
      });
  };

  const onChangeFeatureCampaign = (form) => {
    setLoadingState(true);
    campaignService
      .changeFeaturedCampaign(form)
      .then(() => {
        closeDialog();
        setFeaturedCampaigns(
          featuredCampaigns.map((f) => {
            if (f.id == form.idProdutoEmAmostra) {
              return {
                campaign: campaigns.find((c) => c.id == form.idProduto),
                title: form.titulo,
                describle: form.descricao,
                color: form.cor,
                isActive: true,
                id: parseInt(form.idProdutoEmAmostra),
              };
            }

            return f;
          })
        );
      })
      .catch(showError)
      .finally(() => {
        setLoadingState(false);
      });
  };

  return {
    featuredCampaigns,
    highlightCampaigns,
    campaigns,
    onSelectHighlightCampaigns,
    isValidToSelectHighlightCampaigns: isValidToSelectHighlightCampaigns,
    onChangeHightlightCampaigns,
    allCampaigns,
    campaignOptions,
    onChangeFeatureCampaign,
  };
};

export default useHomeController;
