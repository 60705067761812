import Grid from "@mui/material/Grid/Grid";
import Typography from "@mui/material/Typography/Typography";
import Card from "@mui/material/Card/Card";
import makeStyles from "@mui/styles/makeStyles/makeStyles";

import { BodyComponent, FiltersComponent } from "../../../components";
import useDashboardController from "./dashboard.controller";
import { useAuth, useResponsive } from "../../../providers";
import LinkCprChartComponent from "./components/link-cpr-chart.component";
import UserChartComponent from "./components/user-chart.component";
import InteractionChartComponent from "./components/interaction-chart.component";
import StatusChartComponent from "./components/status-chart.component";
import CompanyRankComponent from "./components/company-rank.component";
import UserRankComponent from "./components/user-rank.component";
import RegistersMapComponent from "./components/register-map.component";
import ConversionMapComponent from "./components/conversion-map.component";
import RentalChartComponent from "./components/rental-chart.component";
import ProposalsChartComponent from "./components/proposals-chart.component";

const useStyles = makeStyles(() => ({
  statusGrid: {
    justifyContent: "center",
    marginBottom: "4vh",
  },
  statusCard: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",

    height: "16.60vh",
    borderRadius: "10px",
  },
  statusCardLabel: {
    fontWeight: "700",
    fontSize: "1.46vh",
    color: "white",
    marginTop: "2vh",
    textAlign: "center",
  },
  statusCardValue: {
    fontWeight: "300",
    fontSize: "3.12vh",
    color: "white",
    marginTop: "3vh",
    textAlign: "center",
    lineHeight: "95%",
  },
  statusCardValue2: {
    fontWeight: "300",
    fontSize: "3.12vh",
    color: "white",
    textAlign: "center",
    lineHeight: "95%",
    marginTop: "1vh",
  },
}));

const DashboardPage = () => {
  const classes = useStyles();
  const { user } = useAuth();
  const { isDesktop } = useResponsive();
  const { dashboard, campaigns, users, onSearch, counters } =
    useDashboardController();

  return (
    <BodyComponent>
      <FiltersComponent
        autoSearch
        userOptions={users}
        campaignOptions={campaigns}
        onSearch={onSearch}
      />

      <div style={{ width: "71vw" }}>
        <Grid
          container
          spacing={isDesktop ? 4 : 2}
          className={classes.statusGrid}
        >
          <Grid item xs={isDesktop ? 3 : 6}>
            <CustomCardComponent
              backgroundColor="#774E98"
              label="CADASTROS CONCLUÍDOS"
              value1={dashboard?.completedRegistrations ?? 0}
            />
          </Grid>
          <Grid item xs={isDesktop ? 3 : 6}>
            <CustomCardComponent
              backgroundColor="#61C3BD"
              label="CADASTROS INICIADOS"
              value1={dashboard?.startedRegistrations ?? 0}
            />
          </Grid>
          <Grid item xs={isDesktop ? 3 : 6}>
            <CustomCardComponent
              backgroundColor="#E8DC32"
              label="TICKET MÉDIO"
              value1={dashboard?.averageTicket.quantity ?? 0}
              value2={`R$ ${dashboard?.averageTicket.value ?? "0,00"}`}
            />
          </Grid>
        </Grid>
      </div>

      {!!counters && !!user && user.isAdmin && (
        <div style={{ width: "71vw" }}>
          <Grid
            container
            spacing={isDesktop ? 4 : 2}
            className={classes.statusGrid}
          >
            <Grid item xs={isDesktop ? 3 : 6}>
              <CustomCardComponent
                backgroundColor="#40215F"
                label="USUÁRIOS ATIVOS"
                value1={parseFloat(counters?.usuariosAtivos ?? 0)}
              />
            </Grid>
            <Grid item xs={isDesktop ? 3 : 6}>
              <CustomCardComponent
                backgroundColor="#774E98"
                label="CADASTROS HOJE"
                value1={parseFloat(counters?.cadastrosHoje ?? 0)}
              />
            </Grid>
            <Grid item xs={isDesktop ? 3 : 6}>
              <CustomCardComponent
                backgroundColor="#61C3BD"
                label="CADASTROS QUE PRODUZIRAM"
                value1={parseFloat(counters?.cadastrosQueProduziram ?? 0)}
              />
            </Grid>
          </Grid>
        </div>
      )}

      {!!counters && !!user && user.isAdmin && (
        <CompanyRankComponent counters={counters} />
      )}

      {!!counters && !!user && user.isAdmin && (
        <UserRankComponent counters={counters} />
      )}

      {!!counters && !!user && user.isAdmin && (
        <RegistersMapComponent counters={counters} />
      )}

      {!!counters && !!user && user.isAdmin && (
        <ConversionMapComponent counters={counters} />
      )}

      <Grid container spacing={4} style={{ width: "71vw" }}>
        <Grid item xs={12}>
          {!!dashboard && !!user && user.isAdmin && <RentalChartComponent />}
        </Grid>
        <Grid item xs={12}>
          {!!dashboard && !!user && user.isAdmin && <ProposalsChartComponent />}
        </Grid>
        <Grid item xs={12}>
          {!!dashboard && !!user && user.isAdmin && (
            <InteractionChartComponent campaigns={campaigns} users={users} />
          )}
        </Grid>
        <Grid item xs={12}>
          {!!dashboard && !!user && user.isAdmin && (
            <StatusChartComponent users={users} />
          )}
        </Grid>
        <Grid item xs={12}>
          {!!dashboard && !!user && user.isAdmin && (
            <LinkCprChartComponent campaigns={campaigns} users={users} />
          )}
        </Grid>
        <Grid item xs={12}>
          {!!dashboard && !!user && user.isAdmin && <UserChartComponent />}
        </Grid>
      </Grid>
    </BodyComponent>
  );
};

const CustomCardComponent = ({ label, value1, value2, backgroundColor }) => {
  const classes = useStyles();
  const { isDesktop } = useResponsive();

  return (
    <Card
      elevation={0}
      className={classes.statusCard}
      style={{
        backgroundColor: backgroundColor,
        width: isDesktop ? "15.62vw" : "100%",
      }}
    >
      <Typography className={classes.statusCardLabel}>{label}</Typography>
      <Typography
        className={classes.statusCardValue}
        style={{ marginTop: !!value2 ? "2vh" : "3vh" }}
      >
        {value1}
      </Typography>
      {!!value2 && (
        <Typography className={classes.statusCardValue2}>{value2}</Typography>
      )}
    </Card>
  );
};

export default DashboardPage;
