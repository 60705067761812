import React from "react";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";

import GrupoRizomaReciboImage from "../../assets/imagens/grupo-rizoma-recibo.png";

const styles = StyleSheet.create({
  page: {
    padding: "40pt",
    fontFamily: "Helvetica",
    fontSize: "12pt",
  },
  headerContainer: {
    flexDirection: "row",
    alignItems: "center",
    marginBottom: "20pt",
    justifyContent: "space-between",
  },
  logo: {
    width: "100pt",
  },
  logo2: {
    width: "100pt",
  },
  column: {
    flex: 1,
  },
  title: {
    textAlign: "center",
    fontSize: "18pt",
    fontFamily: "Helvetica-Bold",
  },
  divider: {
    borderBottom: "1pt solid #000",
    marginBottom: "20pt",
  },
  section: {
    flexDirection: "row",
    marginBottom: 10,
  },

  label: {
    fontFamily: "Helvetica-Bold",
  },
  fieldValue: {
    marginLeft: "5pt",
  },
  sectionTitle: {
    fontSize: "14pt",
    fontFamily: "Helvetica-Bold",
    textDecoration: "underline",
    marginBottom: "10pt",
  },
  table: {
    display: "table",
    width: "auto",
    borderStyle: "solid",
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
  },
  tableRow: {
    margin: "auto",
    flexDirection: "row",
  },
  tableCol: {
    width: "35%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableColValue: {
    width: "15%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableHeader: {
    fontFamily: "Helvetica-Bold",
    margin: "auto",
    marginTop: 5,
    fontSize: 10,
  },
  tableCell: {
    margin: "auto",
    marginTop: 5,
    fontSize: 10,
  },
  calculoContainer: {
    flexDirection: "row",
    alignItems: "flex-end",
    justifyContent: "flex-end",
  },
  calculoTitulo: {
    textAlign: "center",
    fontFamily: "Helvetica-Bold",
    fontSize: "12pt",
    marginTop: 2,
  },
  calculoValor: {
    fontSize: "12pt",
    marginBottom: 0.5,
    marginLeft: 73,
  },
  dividerWithoutBorder: {
    borderBottom: "1pt solid #000",
  },
  assinatura: {
    marginTop: 10,
    alignItems: "center",
    justifyContent: "center",
    marginBottom: 10,
  },
  sectionDiscounts: {
    marginTop: 2,
  },
});

const ReceiptForPaymentsComponent = ({
  title,
  user,
  payments,
  values,
  hasDiscounts,
  discounts,
}) => {
  return (
    <Document title={title}>
      <Page size="A4" orientation="landscape" style={styles.page}>
        <View>
          <View style={styles.headerContainer}>
            <Image src={GrupoRizomaReciboImage} style={styles.logo} />
            <Text style={styles.title}>Pagamento de Comissões</Text>
            <Text style={styles.logo2}></Text>
          </View>

          <View style={styles.divider} />

          <View style={styles.section}>
            <View style={styles.column}>
              <View style={styles.section}>
                <Text style={styles.label}>CPF:</Text>
                <Text style={styles.fieldValue}>{user.cpf}</Text>
              </View>
              <View style={styles.section}>
                <Text style={styles.label}>E-mail:</Text>
                <Text style={styles.fieldValue}>{user.email}</Text>
              </View>
              <View style={styles.section}>
                <Text style={styles.label}>PIX:</Text>
                <Text style={styles.fieldValue}>{user.pix}</Text>
              </View>
              <View style={styles.section}>
                <Text style={styles.label}>Baixa:</Text>
                <Text style={styles.fieldValue}>{user.eventDate}</Text>
              </View>
            </View>

            <View style={styles.column}>
              <View style={styles.section}>
                <Text style={styles.label}>Titular:</Text>
                <Text style={styles.fieldValue}>{user.name}</Text>
              </View>
              <View style={styles.section}>
                <Text style={styles.label}>Banco:</Text>
                <Text style={styles.fieldValue}>{user.bank}</Text>
              </View>
              <View style={styles.section}>
                <Text style={styles.label}>Agência:</Text>
                <Text style={styles.fieldValue}>{user.bankAgency}</Text>
              </View>
              <View style={styles.section}>
                <Text style={styles.label}>Conta:</Text>
                <Text style={styles.fieldValue}>{user.bankAccount}</Text>
              </View>
              <View style={styles.section}>
                <Text style={styles.label}>Tipo:</Text>
                <Text style={styles.fieldValue}>{user.bankAccountType}</Text>
              </View>
            </View>
          </View>

          <View style={styles.divider} />

          <Text style={styles.sectionTitle}>Pagamentos</Text>

          {/* Seção: Pagamentos */}
          <View style={styles.table}>
            <View style={styles.tableRow}>
              <View style={styles.tableCol}>
                <Text style={styles.tableHeader}>Produto</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableHeader}>Data</Text>
              </View>
              <View style={styles.tableColValue}>
                <Text style={styles.tableHeader}>Comissão da Rizoma</Text>
              </View>
              <View style={styles.tableColValue}>
                <Text style={styles.tableHeader}>Comissão do Cliente</Text>
              </View>
            </View>
            {payments.map((payment, index) => {
              return (
                <View key={index} style={styles.tableRow}>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>{payment.campaign}</Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>{payment.eventDate}</Text>
                  </View>
                  <View style={styles.tableColValue}>
                    <Text style={styles.tableCell}>{payment.commission}</Text>
                  </View>
                  <View style={styles.tableColValue}>
                    <Text style={styles.tableCell}>{payment.payment}</Text>
                  </View>
                </View>
              );
            })}
          </View>

          <View style={styles.calculoContainer}>
            <Text style={styles.calculoTitulo}>Subtotal:</Text>
            <Text style={styles.calculoValor}>{values.commissionSubTotal}</Text>
            <Text style={styles.calculoValor}>{values.paymentSubTotal}</Text>
          </View>

          {hasDiscounts == true && (
            <View style={styles.sectionDiscounts}>
              <Text style={styles.sectionTitle}>Descontos</Text>

              {/* Seção: Descontos */}
              <View style={styles.table}>
                <View style={styles.tableRow}>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableHeader}>Descrição</Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableHeader}>Data</Text>
                  </View>
                  <View style={styles.tableColValue}>
                    <Text style={styles.tableHeader}>Referência</Text>
                  </View>
                  <View style={styles.tableColValue}>
                    <Text style={styles.tableHeader}>Valor</Text>
                  </View>
                </View>
                {discounts.map((discount, index) => {
                  return (
                    <View key={index} style={styles.tableRow}>
                      <View style={styles.tableCol}>
                        <Text style={styles.tableCell}>
                          {discount.describle}
                        </Text>
                      </View>
                      <View style={styles.tableCol}>
                        <Text style={styles.tableCell}>
                          {discount.eventDate}
                        </Text>
                      </View>
                      <View style={styles.tableColValue}>
                        <Text style={styles.tableCell}>
                          {discount.reference}
                        </Text>
                      </View>
                      <View style={styles.tableColValue}>
                        <Text style={styles.tableCell}>{discount.value}</Text>
                      </View>
                    </View>
                  );
                })}
              </View>

              <View style={styles.calculoContainer}>
                <Text style={styles.calculoTitulo}>Subtotal:</Text>
                <Text style={styles.calculoValor}></Text>
                <Text style={styles.calculoValor}>
                  {values.discountsSubTotal}
                </Text>
              </View>
            </View>
          )}

          <View style={styles.dividerWithoutBorder} />

          <View style={styles.calculoContainer}>
            <Text style={styles.calculoTitulo}>Total a pagar:</Text>
            <Text style={styles.calculoValor}>{values.paymentTotal}</Text>
          </View>

          <View style={styles.assinatura}>
            <Text>______________________________________</Text>
            <Text>RICHARD SALARI</Text>
            <Text>011.851.960-30</Text>
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default ReceiptForPaymentsComponent;
