import Menu from "@mui/material/Menu/Menu";
import withStyles from "@mui/styles/withStyles/withStyles";

const GenericMenuComponent = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
    borderRadius: "10px",
  },
})((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

export default GenericMenuComponent;
