import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { v4 as uuidv4 } from "uuid";
import { useEffect, useState } from "react";

import { useAlert, useAuth } from "../../../../providers";
import {
  mediaService,
  notificationService,
  userService,
} from "../../../../../infrastructure/services";
import { Constants, Format } from "../../../../utils";
import { useIsMounted } from "../../../../hooks";
import { useLoadingContext } from "../../../../context/loading.context";

const useAdminNotificationSendController = () => {
  const sendTypeOptions = Constants.getSendTypeOptionsList();

  const sendGroupOptions = ["USUÁRIOS", "ADMINISTRADORES", "ANUNCIANTES"];

  const notificationTypeOptions = ["WHATSAPP", "EMAIL", "SISTEMA"];

  const linkOptions = [
    { id: 1, label: "NENHUM", value: "/" },
    { id: 2, label: "PÁGINA HOME INTERNA", value: "/home" },
    { id: 3, label: "PERFIL DO USUÁRIO", value: "/perfil" },
    { id: 4, label: "PÁGINA DE AFILIADOS", value: "/afiliados" },
    { id: 5, label: "PÁGINA DE RELATÓRIOS", value: "/relatorios" },
    { id: 6, label: "PÁGINA DE DASHBOARD", value: "/dashboard" },
    { id: 7, label: "PÁGINA DE CAMPANHAS", value: "/campanhas" },
    { id: 8, label: "PÁGINA DA CAMPANHA", value: "/campanhas/[id]" },
    { id: 9, label: "PÁGINA DE FINANCEIRO", value: "/financeiro" },
    { id: 10, label: "PÁGINA DE AJUDA", value: "/duvidas" },
    { id: 11, label: "CADASTRO DE ANUNCIANTE", value: "/anunciar" },
    { id: 12, label: "PÁGINA DE LICENCIAMENTO", value: "/licenciamento" },

    { id: 13, label: "GESTÃO DE PAGAMENTOS", value: "/admin/pagamentos" },
    { id: 14, label: "GESTÃO DE CATEGORIAS", value: "/admin/categorias" },
    { id: 15, label: "GESTÃO DE CAMPANHAS", value: "/admin/campanhas" },
    {
      id: 16,
      label: "PÁGINA DE DETALHES DA CAMPANHA",
      value: "/admin/campanhas/formulario/[id]",
    },
    { id: 17, label: "GESTÃO DE USUÁRIOS", value: "/admin/usuarios" },
    {
      id: 18,
      label: "PÁGINA DE DETALHES DO USUÁRIO",
      value: "/admin/usuarios/formulario/[id]",
    },
    { id: 19, label: "GESTÃO DE ANUNCIANTES", value: "/admin/anunciantes" },
    {
      id: 20,
      label: "PÁGINA DE DETALHES ANUNCIANTE",
      value: "/admin/anunciantes/detalhe/[id]",
    },
    { id: 21, label: "GESTÃO DE PERFIS DE AFILIADOS", value: "/admin/perfis" },

    { id: 22, label: "GESTÃO DA PRODUÇÃO", value: "/anunciante/relatorios" },
  ];

  const schema = yup.object().shape({
    sendType: yup.string().required("Campo obrigatório"),

    startDate: yup
      .date()
      .optional()
      .nullable()
      .when("sendType", {
        is: (sendType) => sendType === Constants.DATA_CADASTRO,
        then: () => yup.date().required("Campo obrigatório"),
      }),

    endDate: yup
      .date()
      .optional()
      .nullable()
      .when("sendType", {
        is: (sendType) => sendType === Constants.DATA_CADASTRO,
        then: () =>
          yup
            .date()
            .required("Campo obrigatório")
            .when("startDate", (startDate, schema) =>
              schema.min(
                startDate,
                "A data final deve ser maior que a data inicial"
              )
            ),
      }),

    sendUsers: yup
      .array()
      .optional()
      .when("sendType", {
        is: (sendType) => sendType == 1,
        then: () =>
          yup
            .array()
            .required("Campo obrigatório")
            .min(1, "Adicione no mínimo um usuário"),
      }),

    sendGroup: yup
      .array()
      .optional()
      .when("sendType", {
        is: (sendType) => sendType == 2,
        then: () =>
          yup
            .array()
            .required("Campo obrigatório")
            .min(1, "Adicione no mínimo um grupo"),
      }),

    emailsExternos: yup
      .string()
      .optional()
      .when("sendType", {
        is: (sendType) => sendType == 4,
        then: () =>
          yup
            .string()
            .matches(
              /^[\s]*([^@\s]+@[^@\s]+\.[^@\s,]+)(,[^@\s]*@[^@\s]+\.[^@\s,]+)*[\s]*$/,
              "Insira uma lista válida de e-mails separados por vírgula"
            )
            .required("A lista de e-mails é obrigatória"),
      }),

    notificationType: yup
      .array()
      .required("Campo obrigatório")
      .min(1, "Adicione no mínimo um tipo de notificação"),
    title: yup.string().required("Campo obrigatório"),
    content: yup.string().required("Campo obrigatório"),
    media: yup.string().nullable().optional(),
    link: yup.string().optional(),
  });

  const { control, handleSubmit, watch, setValue, getValues } = useForm({
    defaultValues: {
      sendType: sendTypeOptions[0].id,
      startDate: null,
      endDate: null,
      sendGroup: [],
      sendUsers: [],
      notificationType: [],
      title: "",
      content: "",
      emailsExternos: "",
      media: null,
      link: linkOptions[0].id,
    },
    resolver: yupResolver(schema),
  });

  const { user } = useAuth();
  const isMounted = useIsMounted();
  const { showError, showSuccess } = useAlert();
  const [withSendGroup, setWithSendGroup] = useState(1);
  const [usersOptions, setUsersOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { setLoadingState } = useLoadingContext();

  useEffect(() => {
    if (!!user) {
      setIsLoading(true);
      setLoadingState(true);
      userService
        .getActiveUsers({ mode: "TODOS" })
        .then((response) => {
          if (isMounted.current) {
            setIsLoading(false);
            setUsersOptions(
              response.map((item) => ({
                id: item.id,
                label: item.displayName,
              }))
            );
          }
        })
        .catch(showRequestError)
        .finally(() => {
          setLoadingState(false);
        });
    }
  }, [user]);

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (name == "sendType") {
        setWithSendGroup(value.sendType);
        setValue("sendGroup", []);
        setValue("sendUsers", []);
      }
    });

    return () => subscription.unsubscribe();
  }, [watch]);

  const onSubmit = handleSubmit((data) => {
    var form = _makeForm(data);

    setIsLoading(true);

    setLoadingState(true);
    notificationService
      .sendNotification({ query: form })
      .then(() => {
        setIsLoading(false);
        showSuccess("Notificação enviada!");
      })
      .catch(showRequestError)
      .finally(() => {
        setLoadingState(false);
      });
  });

  const showRequestError = (error) => {
    setIsLoading(false);
    showError(error);
  };

  const _makeForm = (data) => {
    var form = {
      tipoEnvio: sendTypeOptions.filter(
        (option) => option.id == data.sendType
      )[0].value,
      grupoEnvio: data.sendGroup.length > 0 ? data.sendGroup.join(",") : null,
      usuariosEnvio:
        data.sendUsers.length > 0
          ? data.sendUsers.map((user) => user.id).join(",")
          : null,
      tipoNotificacao:
        data.notificationType.length > 0
          ? data.notificationType.join(",")
          : null,
      externo: data.emailsExternos,
      titulo: data.title,
      conteudo: data.content,
      startDate: data.startDate,
      endDate: data.endDate,
      media: !!data.media ? data.media.split("?")[0] : null,
      link:
        data.link != 1
          ? linkOptions.filter((option) => option.id == data.link)[0].value
          : null,
    };

    return form;
  };

  const editImage = ({ name, data, callback }) => {
    const formValue = getValues(name);

    var filename = uuidv4();
    if (!!formValue) {
      filename = Format.getFileNameFromURL(formValue);
    }

    setIsLoading(true);

    setLoadingState(true);
    mediaService
      .upload({ filename: filename, image: data })
      .then((imageUrl) => {
        setIsLoading(false);
        callback(imageUrl);
      })
      .catch(showRequestError)
      .finally(() => {
        setLoadingState(false);
      });
  };

  const setErrorInMedia = (error) => {
    setValue("media", null);
    setIsLoading(false);
    showError(error);
  };

  const onDeleteMedia = () => {
    setValue("media", null);
  };

  return {
    control,
    onSubmit,
    sendTypeOptions,
    sendGroupOptions,
    notificationTypeOptions,
    withSendGroup,
    linkOptions,
    editImage,
    setErrorInMedia,
    onDeleteMedia,
    sendUsersOptions: usersOptions,
    isLoading,
    getValues,
  };
};

export default useAdminNotificationSendController;
