import Typography from "@mui/material/Typography/Typography";
import IconButton from "@mui/material/IconButton/IconButton";
import LinkOutlinedIcon from "@mui/icons-material/LinkOutlined";

import AccordionComponent from "../accordion.component";
import { useResponsive } from "../../providers";

const HelpItemComponent = ({
  label,
  content,
  expanded,
  toggleExpanded,
  link,
}) => {
  const { isDesktop } = useResponsive();

  return (
    <AccordionComponent
      expanded={expanded}
      toggleExpanded={toggleExpanded}
      style={{ width: "100%", backgroundColor: "blue" }}
      summary={
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Typography
            style={{
              fontWeight: "600",
              fontSize: isDesktop ? "1.75vh" : "1.15vh",
              color: "#5C5C5C",
            }}
          >
            {label}
          </Typography>
          {window.location.pathname != "/" && (
            <IconButton href={link} rel="noopener noreferrer" color="inherit">
              <LinkOutlinedIcon />
            </IconButton>
          )}
        </div>
      }
      details={content.split("\\n").map((row, index) => {
        return (
          <Typography
            key={index}
            style={{
              marginLeft: "1vw",
              fontWeight: "400",
              fontSize: isDesktop ? "1.75vh" : "1.15vh",
              color: "#5C5C5C",
            }}
          >
            {row}
          </Typography>
        );
      })}
    />
  );
};

export default HelpItemComponent;
