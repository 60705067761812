import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { cityService, userService } from "../../../../infrastructure/services";
import { useAlert } from "../../../providers";
import { useEffect, useState } from "react";
import { Constants, Validate } from "../../../utils";
import { useLoadingContext } from "../../../context/loading.context";
import { useNavigate, useSearchParams } from "react-router-dom";

const useRegisterUserController = () => {
  const { showError, showInfo, showSuccess } = useAlert();
  const navigate = useNavigate();
  let [searchParams, setSearchParams] = useSearchParams();
  const { setLoadingState } = useLoadingContext();

  const minPassword = 6;
  const messagePasswordValidation = `Deve ter mais de ${minPassword} caracteres`;

  const schema = yup.object().shape({
    fullName: Validate.fullName,
    email: Validate.email,
    cpf: Validate.cpf,
    phone: Validate.phone,
    pix: Validate.pix,
    facebook: yup.string(),
    instagram: yup.string(),
    password: yup
      .string()
      .min(6, messagePasswordValidation)
      .required("Campo obrigatório"),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref("password"), null], "Senhas devem ser iguais")
      .min(6, messagePasswordValidation)
      .required("Campo obrigatório"),
    acceptedTerm: yup
      .boolean()
      .required("Campo obrigatório")
      .oneOf([true], "Deve aceitar o termo"),
    estado: yup.number().required("Campo obrigatório"),
    cidade: yup
      .number()
      .required("Campo obrigatório")
      .test("invalid", "Selecione uma das opções", (value) => {
        return value > 0;
      }),
  });

  const { control, handleSubmit, setValue, watch, getValues } = useForm({
    defaultValues: {
      fullName: "",
      email: "",
      cpf: "",
      phone: "",
      pix: "",
      facebook: "",
      instagram: "",
      password: "",
      confirmPassword: "",
      acceptedTerm: false,
      reCaptcha: "",
      token: "NAO-INFORMADO",
      estado: Constants.states[0].id,
      cidade: Constants.defaultSelectPlaceholder.id,
    },
    resolver: yupResolver(schema),
  });

  const [inviteToken, setInviteToken] = useState("NAO-INFORMADO");
  const [cities, setCities] = useState([Constants.defaultSelectPlaceholder]);

  useEffect(() => {
    if (searchParams.get("token")) {
      setLoadingState(true);
      userService
        .getInviteToken({ token: searchParams.get("token") })
        .then((resposne) => {
          setInviteToken(resposne);
        })
        .catch(showError)
        .finally(() => {
          setLoadingState(false);
        });
    }
    getCities({ state: Constants.states[0].label });
  }, [searchParams]);

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (name == "estado") {
        if (value.estado != null) {
          getCities({
            state: Constants.states.find((state) => state.id == value.estado)
              .label,
          });
        }
      }
    });

    return () => subscription.unsubscribe();
  }, [watch]);

  const getCities = ({ state }) => {
    setLoadingState(true);
    cityService
      .getCities({ state: state })
      .then((response) => {
        setCities([
          Constants.defaultSelectPlaceholder,
          ...response.map((item) => ({
            id: item.id,
            label: item.name,
          })),
        ]);
        setValue("cidade", Constants.defaultSelectPlaceholder.id);
      })
      .catch(showError)
      .finally(() => {
        setLoadingState(false);
      });
  };

  const onSubmit = handleSubmit((data) => {
    if (data.reCaptcha === "") {
      showInfo(
        "O uso do reCAPTCHA é obrigatório. Por favor, complete o desafio para continuar."
      );
    } else {
      var form = {
        tokenConvite: inviteToken,
        nome: data.fullName,
        cpf: data.cpf,
        celular: data.phone,
        email: data.email,
        pix: data.pix,
        novaSenha: data.password,
        confirmarSenha: data.confirmPassword,
        cidadeId: !!data.cidade
          ? data.cidade != 0
            ? data.cidade
            : null
          : null,
      };

      if (!!data.facebook) {
        form["facebook"] = data.facebook;
      }

      if (!!data.instagram) {
        form["instagram"] = data.instagram;
      }

      setLoadingState(true);
      userService
        .register(form)
        .then((res) => {
          if (res == "E-mail de reativação enviado") {
            showSuccess(res);
            navigate("/entrar");
          } else {
            showSuccess("Cadastro realizado com sucesso.");
            navigate("/criar-conta/sucesso");
          }
        })
        .catch(showError)
        .finally(() => {
          setLoadingState(false);
        });
    }
  });

  return {
    control,
    onSubmit,
    setValue,
    messagePasswordValidation,
    estados: Constants.states,
    cidades: cities,
  };
};

export default useRegisterUserController;
