import React from "react";
import Typography from "@mui/material/Typography/Typography";
import makeStyles from "@mui/styles/makeStyles/makeStyles";

import {
  ButtonComponent,
  GenericDialogComponent,
} from "../../../../components";
import { useDialog } from "../../../../providers";
import AnteciparComissaoImage from "../../../../../assets/imagens/antecipar-comissao.png";

const useStyles = makeStyles(() => ({
  content: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: "1vh",
    marginBottom: "2vh",
  },
}));

const AdvancePaymentDialogComponent = ({ onConfirmAdvancePayment }) => {
  const classes = useStyles();
  const { closeDialog } = useDialog();

  return (
    <GenericDialogComponent name="advance-payment-dialog">
      <div className={classes.content}>
        <Typography
          style={{
            marginBottom: "6vh",
            fontWeight: "700",
            fontSize: "1.95vh",
            color: "#40215F",
          }}
        >
          ANTECIPAR COMISSÃO
        </Typography>
        <img src={AnteciparComissaoImage} style={{ marginBottom: "6vh" }} />
        <Typography
          style={{
            color: "#5C5C5C",
            fontWeight: "700",
            fontSize: "1.46vh",
          }}
        >
          SERÁ COBRADO O VALOR DE R$ 20,00 NA ATECIPAÇÃO.
        </Typography>

        <div style={{ marginTop: "2vh" }}>
          <ButtonComponent
            label="CONFIRMAR"
            backgroundColor="#774E98"
            color="white"
            fullWidth
            height="5vh"
            onClick={() => {
              closeDialog();
              onConfirmAdvancePayment();
            }}
          />
        </div>
      </div>
    </GenericDialogComponent>
  );
};

export default AdvancePaymentDialogComponent;
