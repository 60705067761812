import { useEffect, useState } from "react";

import { useAlert, useAuth } from "../../../../providers";
import { useIsMounted } from "../../../../hooks";
import { advertiserService } from "../../../../../infrastructure/services";
import { useLoadingContext } from "../../../../context/loading.context";

const useAdminAdvertiserDetailController = ({ advertiserId }) => {
  const { showError } = useAlert();
  const { user } = useAuth();
  const isMounted = useIsMounted();

  const [advertiser, setAdvertiser] = useState(null);
  const { setLoadingState } = useLoadingContext();

  useEffect(() => {
    if (user != null) {
      setLoadingState(true);
      advertiserService
        .fetchAdvertisers()
        .then((advertisers) => {
          if (isMounted.current) {
            setAdvertiser(
              advertisers.filter((item) => item.id == advertiserId)[0]
            );
          }
        })
        .catch(showError)
        .finally(() => {
          setLoadingState(false);
        });
    }
  }, [user]);

  return { advertiser };
};

export default useAdminAdvertiserDetailController;
