import React, { useEffect, useState } from "react";
import FormControl from "@mui/material/FormControl/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel/FormControlLabel";
import FormGroup from "@mui/material/FormGroup/FormGroup";
import FormLabel from "@mui/material/FormLabel/FormLabel";
import Checkbox from "@mui/material/Checkbox/Checkbox";
import makeStyles from "@mui/styles/makeStyles/makeStyles";

import {
  ButtonComponent,
  GenericDialogComponent,
  InputWithButtonComponent,
} from "../../../../components";
import { useAlert, useAuth, useDialog } from "../../../../providers";
import { pixelService } from "../../../../../infrastructure/services";
import { useLoadingContext } from "../../../../context/loading.context";

const useStyles = makeStyles(() => ({
  content: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: "1vh",
    marginBottom: "2vh",
    overflow: "hidden",
  },
}));

const PixelSettingsDialogComponent = () => {
  const classes = useStyles();
  const { user } = useAuth();
  const [code, setCode] = useState("");
  const [codeVerified, setCodeVerified] = useState(false);
  const [codeError, setCodeError] = useState("");
  const [events, setEvents] = useState({
    pageView: false,
    addToCart: false,
    lead: false,
    contact: false,
  });
  const { showError, showSuccess } = useAlert();
  const { setLoadingState } = useLoadingContext();
  const [pixel, setPixel] = useState();
  const { pageView, addToCart, lead, contact } = events;

  const { metadata } = useDialog();

  useEffect(() => {
    if (metadata != null) {
      if (metadata.pixel != null) {
        setCodeVerified(true);
        setPixel(metadata.pixel);
        setCode(metadata.pixel.codigo);
        setEvents({
          addToCart: metadata.pixel.eventos.includes("addToCart"),
          pageView: metadata.pixel.eventos.includes("pageView"),
          lead: metadata.pixel.eventos.includes("lead"),
          contact: metadata.pixel.eventos.includes("contact"),
        });
      }
    }
  }, [metadata]);

  const handleChange = (event) => {
    setEvents({ ...events, [event.target.name]: event.target.checked });
  };

  function validarCodigoPixelMeta(codigo) {
    var regex = /^\d{15}$/; // 15 dígitos numéricos

    return regex.test(codigo);
  }

  function validar(valor) {
    if (validarCodigoPixelMeta(valor)) {
      setCodeError("");
      return true;
    } else {
      setCodeError("Código inválido!");
      return false;
    }
  }

  const metas = (metadata?.campaign?.metas ?? []).map((meta) =>
    meta.nome.split(" - ")[0].toLowerCase()
  );

  function salvar() {
    setCodeError("");

    var form = {
      afiliado: user.id,
      campanha: metadata.campaign.id,
      codigo: code,
      eventos: [],
    };

    if (events.addToCart == true) {
      form["eventos"].push("addToCart");
    }
    if (events.pageView == true) {
      form["eventos"].push("pageView");
    }
    if (events.lead == true) {
      form["eventos"].push("lead");
    }
    if (events.contact == true) {
      form["eventos"].push("contact");
    }

    if (form["eventos"].length == 0) {
      setCodeError("Selecione pelomenos 1 evento.");
    } else {
      setLoadingState(true);
      if (pixel != null) {
        pixelService
          .atualizarPixel({ ...form, id: pixel.id })
          .then((pixelAtualizado) => {
            setPixel(pixelAtualizado);
            showSuccess("Configuração do Facebook Pixel salva.");
          })
          .catch(showError)
          .finally(() => {
            setLoadingState(false);
          });
      } else {
        pixelService
          .criarPixel(form)
          .then((pixelCriado) => {
            setPixel(pixelCriado);
            showSuccess("Configuração do Facebook Pixel salva.");
          })
          .catch(showError)
          .finally(() => {
            setLoadingState(false);
          });
      }
    }
  }

  return (
    <GenericDialogComponent name="pixel-settings-dialog">
      <div className={classes.content}>
        <InputWithButtonComponent
          placeholder="CÓDIGO PIXEL DO FACEBOOK"
          color="#5C5C5C"
          isLight={true}
          value={code}
          onChange={(newCode) => {
            setCodeVerified(false);
            setCode(newCode);
            validar(newCode);
          }}
          disabled={false}
          onClick={() => {
            if (validar(code)) {
              setCodeVerified(true);
            } else {
              setCodeVerified(false);
            }
          }}
          buttonLabel="PRONTO"
          errorMessage={codeError}
        />

        <div
          style={{
            height: "20vh",
            display: "flex",
            justifyContent: "start",
            alignItems: "center",
            paddingTop: "2vh",
          }}
        >
          {codeVerified && (
            <FormControl component="fieldset">
              <FormLabel component="legend">Eventos</FormLabel>
              <FormGroup>
                {(metas.includes("cpl") || metas.includes("cpv")) && (
                  <FormControlLabel
                    label="Página Aberta"
                    control={
                      <Checkbox
                        color="primary"
                        checked={pageView}
                        onChange={handleChange}
                        name="pageView"
                      />
                    }
                  />
                )}
                {metas.includes("cpl") && (
                  <FormControlLabel
                    label="Formulário Preenchido"
                    control={
                      <Checkbox
                        color="primary"
                        checked={lead}
                        onChange={handleChange}
                        name="lead"
                      />
                    }
                  />
                )}
                {metas.includes("cpv") && (
                  <FormControlLabel
                    label="Produto adicionado ao carrinho"
                    control={
                      <Checkbox
                        color="primary"
                        checked={addToCart}
                        onChange={handleChange}
                        name="addToCart"
                      />
                    }
                  />
                )}
                {metas.includes("cpv") && (
                  <FormControlLabel
                    label="Entrar em contato para compra"
                    control={
                      <Checkbox
                        color="primary"
                        checked={contact}
                        onChange={handleChange}
                        name="contact"
                      />
                    }
                  />
                )}
              </FormGroup>
            </FormControl>
          )}
        </div>

        <ButtonComponent
          label="SALVAR"
          backgroundColor="#774E98"
          color="white"
          fullWidth
          height="5vh"
          disabled={!codeVerified}
          onClick={salvar}
        />
      </div>
    </GenericDialogComponent>
  );
};

export default PixelSettingsDialogComponent;
