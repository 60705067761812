import Paper from "@mui/material/Paper/Paper";
import Divider from "@mui/material/Divider/Divider";
import Typography from "@mui/material/Typography/Typography";
import makeStyles from "@mui/styles/makeStyles/makeStyles";
import { Chart } from "react-google-charts";
import { useEffect, useState } from "react";

const estados = [
  { uf: "AC", nome: "Acre" },
  { uf: "AL", nome: "Alagoas" },
  { uf: "AP", nome: "Amapá" },
  { uf: "AM", nome: "Amazonas" },
  { uf: "BA", nome: "Bahia" },
  { uf: "CE", nome: "Ceará" },
  { uf: "DF", nome: "Distrito Federal" },
  { uf: "ES", nome: "Espírito Santo" },
  { uf: "GO", nome: "Goiás" },
  { uf: "MA", nome: "Maranhão" },
  { uf: "MT", nome: "Mato Grosso" },
  { uf: "MS", nome: "Mato Grosso do Sul" },
  { uf: "MG", nome: "Minas Gerais" },
  { uf: "PA", nome: "Pará" },
  { uf: "PB", nome: "Paraíba" },
  { uf: "PR", nome: "Paraná" },
  { uf: "PE", nome: "Pernambuco" },
  { uf: "PI", nome: "Piauí" },
  { uf: "RJ", nome: "Rio de Janeiro" },
  { uf: "RN", nome: "Rio Grande do Norte" },
  { uf: "RS", nome: "Rio Grande do Sul" },
  { uf: "RO", nome: "Rondônia" },
  { uf: "RR", nome: "Roraima" },
  { uf: "SC", nome: "Santa Catarina" },
  { uf: "SP", nome: "São Paulo" },
  { uf: "SE", nome: "Sergipe" },
  { uf: "TO", nome: "Tocantins" },
];

const useStyles = makeStyles(() => ({
  card: {
    width: "100%",
    borderRadius: "10px",
    marginBottom: "4vh",
  },
  cardTitle: {
    marginInline: "4vh",
    marginTop: "4vh",
    marginBottom: "4vh",
    fontWeight: "700",
    fontSize: "1.75vh",
    color: "#5C5C5C",
  },
}));

const RegistersMapComponent = ({ counters }) => {
  const classes = useStyles();
  const [options, setOptions] = useState({});
  const [data, setData] = useState([]);

  useEffect(() => {
    if (!!counters) {
      makeOptions(counters);
    }
  }, [counters]);

  const makeOptions = (counters) => {
    setOptions({
      region: "BR",
      resolution: "provinces",
      colorAxis: { colors: ["#00853f", "black", "#e31b23"] },
      backgroundColor: "#81d4fa",
      datalessRegionColor: "#f8bbd0",
      defaultColor: "#f5f5f5",
    });

    setData([
      ["Estado", "Usuários Ativos"],
      ...counters.usuariosPorLocalizacao.map((user) => {
        const state = estados.filter((e) => e.uf == user.uf);
        return [state[0].nome, parseFloat(user.moradores)];
      }),
    ]);
  };

  return (
    <Paper variant="outlined" className={classes.card}>
      <Typography className={classes.cardTitle}>
        CADASTROS ATIVOS POR ESTADO
      </Typography>
      <Divider />
      <div style={{ marginBottom: "1vh", padding: "1vh" }}>
        {!!counters && (
          <Chart
            chartEvents={[
              {
                eventName: "select",
                callback: ({ chartWrapper }) => {
                  const chart = chartWrapper.getChart();
                  const selection = chart.getSelection();
                  if (selection.length === 0) return;
                },
              },
            ]}
            chartType="GeoChart"
            width="100%"
            height="45vh"
            options={options}
            data={data}
          />
        )}
      </div>
    </Paper>
  );
};

export default RegistersMapComponent;
