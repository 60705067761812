import React, { useState } from "react";
import { useForm } from "react-hook-form";

import { proposalService } from "../../../../infrastructure/services";
import { useAlert, useAuth } from "../../../providers";
import { useIsMounted } from "../../../hooks";
import { useLoadingContext } from "../../../context/loading.context";
import { useSearchParams } from "react-router-dom";

const useProposalDetailController = () => {
  let [searchParams, setSearchParams] = useSearchParams();
  const { showError } = useAlert();
  const isMounted = useIsMounted();
  const { user } = useAuth();
  const [withObservation, setWithObservation] = useState(false);
  const { setLoadingState } = useLoadingContext();

  const { control, reset } = useForm({
    defaultValues: {
      clientName: "",
      clientCpf: "",
      situation: "",
      value: "",
      affiliatedCommissionCurrency: "",
      campaign: "",
      proposalType: "",
      bank: "",
      parcelValue: "",
      parcelQuantity: "",
      observation: "",
    },
  });

  React.useEffect(() => {
    if (user != null) {
      if (!!searchParams.get("id")) {
        setLoadingState(true);
        proposalService
          .getProposalById({ id: searchParams.get("id"), withCommission: 1 })
          .then((response) => {
            if (isMounted.current) {
              reset({
                ...response,
                bank: response.bankName ?? "",
                campaign: response.campaignName,
                affiliatedCommissionCurrency: response.yourCommission,
              });
              setWithObservation(!!response.observation);
            }
          })
          .catch(showError)
          .finally(() => {
            setLoadingState(false);
          });
      }
    }
  }, [user]);

  return { control, withObservation };
};

export default useProposalDetailController;
