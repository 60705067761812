import Divider from "@mui/material/Divider/Divider";
import Grid from "@mui/material/Grid/Grid";
import Typography from "@mui/material/Typography/Typography";

import {
  AutocompleteMultipleComponent,
  SelectComponent,
} from "../../../../../components";
import { Constants } from "../../../../../utils";

const SendSectionComponent = ({
  control,
  sendTypeOptions,
  sendGroupOptions,
  notificationTypeOptions,
  withSendGroup,
  isLoading,
}) => {
  return (
    <div>
      <Typography
        style={{
          fontWeight: "600",
          fontSize: "1.75vh",
          color: "#5C5C5C",
        }}
      >
        ENVIO
      </Typography>
      <Divider style={{ marginBottom: "2vh" }} />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <SelectComponent
                name="sendType"
                control={control}
                label="TIPO DE ENVIO"
                acessor="label"
                options={sendTypeOptions}
                disabled={isLoading}
              />
            </Grid>
            <Grid item xs={8}>
              <AutocompleteMultipleComponent
                name="sendGroup"
                control={control}
                label="GRUPO DE ENVIO"
                withoutAcessor={true}
                options={sendGroupOptions}
                disabled={withSendGroup === Constants.POR_GRUPO || isLoading}
              />
            </Grid>
            <Grid item xs={12}>
              <AutocompleteMultipleComponent
                name="notificationType"
                control={control}
                label="TIPO DE NOTIFICAÇÃO"
                withoutAcessor={true}
                options={notificationTypeOptions}
                disabled={isLoading}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default SendSectionComponent;
