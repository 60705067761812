import React from "react";
import moment from "moment";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import {
  campaignService,
  proposalService,
} from "../../../../infrastructure/services";
import { useAlert, useAuth } from "../../../providers";
import { useIsMounted } from "../../../hooks";
import { Constants, Format, Validate } from "../../../utils";
import { useLoadingContext } from "../../../context/loading.context";
import dayjs from "dayjs";

const useProposalController = () => {
  const situations = [
    Constants.defaultSelect,
    { id: 1, label: "PENDENTE" },
    { id: 2, label: "APROVADO" },
    { id: 3, label: "REPROVADO" },
  ];
  const { showError } = useAlert();
  const { user } = useAuth();
  const isMounted = useIsMounted();
  const [campaigns, setCampaigns] = React.useState([Constants.defaultSelect]);
  const { setLoadingState } = useLoadingContext();

  const rowsPerPage = 10;
  const [proposals, setProposals] = React.useState([]);
  const [count, setCount] = React.useState(0);
  const [page, setPage] = React.useState(0);
  const [lastQuery, setLastQuery] = React.useState(null);
  const [counters, setCounters] = React.useState({
    pending: 0,
    approved: 0,
    disapproved: 0,
  });

  const schema = yup.object().shape(
    {
      clientName: yup
        .string()
        .optional()
        .when("clientName", {
          is: (value) => value?.length,
          then: (rule) => rule.min(3, "O nome deve ter no mínimo 3 caracteres"),
        }),
      clientCpf: yup
        .string()
        .optional()
        .when("clientCpf", {
          is: (value) => value?.length,
          then: (rule) => Validate.cpf,
        }),
      campaign: yup.object().optional(),
      situation: yup.object().optional(),
      proposalType: yup
        .string()
        .optional()
        .when("proposalType", {
          is: (value) => value?.length,
          then: (rule) =>
            rule.min(3, "O tipo de proposta deve ter no mínimo 3 caracteres"),
        }),
    },
    [
      ["clientName", "clientName"],
      ["clientCpf", "clientCpf"],
      ["proposalType", "proposalType"],
    ]
  );

  const { control, handleSubmit, getValues } = useForm({
    defaultValues: {
      clientName: "",
      clientCpf: "",
      campaign: Constants.defaultSelect,
      situation: Constants.defaultSelect,
      proposalType: "",
      startDate: "",
      endDate: "",
    },
    resolver: yupResolver(schema),
  });

  React.useEffect(() => {
    if (user != null) {
      setLoadingState(true);
      campaignService
        .getUserCampaigns()
        .then((response) => {
          if (isMounted.current) {
            setCampaigns([
              Constants.defaultSelect,
              ...response.map((item) => ({ id: item.id, label: item.name })),
            ]);
          }
        })
        .catch(showError)
        .finally(() => {
          setLoadingState(false);
        });
    }
  }, [user]);

  const onSubmit = handleSubmit((data) => {
    onSearch({ newPage: 0, ...data, isDownload: false });
  });

  const onDownloadSubmit = () => {
    onSearch({
      newPage: 0,
      ...getValues(),
      isDownload: true,
    });
  };

  const onSearch = ({
    newPage,
    clientName,
    clientCpf,
    campaign,
    situation,
    proposalType,
    startDate,
    endDate,
    isDownload,
  }) => {
    var query = {
      page: newPage ?? page,
      rowsPerPage: rowsPerPage,
      idAfiliado: user.id,
    };

    if (!!clientName) {
      query.clienteNome = clientName;
    }

    if (!!clientCpf) {
      query.clienteCpf = clientCpf;
    }

    if (campaign.id != 0) {
      query.idProduto = campaign.id;
    }

    if (situation.id != 0) {
      query.situacao = situation.label;
    }

    if (!!proposalType) {
      query.tipoProposta = proposalType;
    }

    if (!!startDate) {
      query.startDate = dayjs(startDate).format().substring(0, 10);
    }

    if (!!endDate) {
      query.endDate = dayjs(endDate).format().substring(0, 10);
    }

    if (isDownload == true) {
      query.baixar = 1;
    }

    if (newPage == 0) {
      setPage(0);
    }

    setLastQuery({
      clientName,
      clientCpf,
      campaign,
      situation,
      proposalType,
      startDate,
      endDate,
    });
    setLoadingState(true);
    proposalService
      .getProposals(query)
      .then((response) => {
        if (isDownload == true) {
          makeCsvData(response.data);
        } else {
          setProposals(response.data);
          setCount(response.count);
          setCounters(response.counters);
        }
      })
      .catch(showError)
      .finally(() => {
        setLoadingState(false);
      });
  };

  const makeCsvData = (data) => {
    if (data.length == 0) {
      return;
    }

    var csvRows = [];

    csvRows.push([
      "TIPO DE PROPOSTA",
      "CLIENTE",
      "CAMPANHA",
      "CATEGORIA",
      "SITUAÇÃO",
    ]);

    data.map((row) => {
      csvRows.push([
        row.proposalType,
        row.clientName,
        row.campaignName,
        row.campaignCategory,
        row.situation,
      ]);
    });

    const csvString = csvRows.join("\n");

    Format.downloadCsv(csvString, "propostas");
  };

  const handleChangePage = (newPage) => {
    setPage(newPage);
    onSearch({ newPage, ...lastQuery });
  };

  return {
    control,
    onSubmit,
    proposals,
    handleChangePage,
    page,
    count,
    rowsPerPage,
    campaignOptions: campaigns,
    situationOptions: situations,
    onDownloadSubmit: onDownloadSubmit,
    counters,
  };
};

export default useProposalController;
