import Divider from "@mui/material/Divider/Divider";
import Typography from "@mui/material/Typography/Typography";

import ImageVideoPdfListCardComponent from "../../../../../components/form/image-video-pdf-list-card.component";

const ImagesSectionComponent = ({
  images,
  addSupportImage,
  removeSupportImage,
  isLoading,
}) => {
  return (
    <div style={{ width: "100%" }}>
      <Typography
        style={{
          fontWeight: "600",
          fontSize: "1.75vh",
          color: "#5C5C5C",
        }}
      >
        IMAGENS DE APOIO
      </Typography>
      <Divider style={{ marginBottom: "2vh" }} />
      <ImageVideoPdfListCardComponent
        title="MATERIAIS PARA VENDA"
        mediaList={images}
        addMedia={addSupportImage}
        removeMedia={removeSupportImage}
        isDynamic={true}
        width="100%"
        disabled={isLoading}
      />
    </div>
  );
};

export default ImagesSectionComponent;
