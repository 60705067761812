import React from "react";

const DialogContext = React.createContext({
  isOpen: "",
  metadata: null,
  closeDialog: () => {},
  openDialog: (name, metadata) => {},
});

export const useDialog = () => React.useContext(DialogContext);

export const DialogProvider = ({ children }) => {
  const [isOpen, setIsOpen] = React.useState(null);
  const [metadata, setMetadata] = React.useState(null);

  const openDialog = (name, metadata) => {
    if (!!metadata) {
      setMetadata(metadata);
    } else {
      setMetadata(null);
    }
    setIsOpen(name);
  };

  const closeDialog = () => setIsOpen(null);

  return (
    <DialogContext.Provider
      value={{ closeDialog, openDialog, isOpen, metadata }}
    >
      {children}
    </DialogContext.Provider>
  );
};
