import FormControl from "@mui/material/FormControl/FormControl";
import FormHelperText from "@mui/material/FormHelperText/FormHelperText";
import InputLabel from "@mui/material/InputLabel/InputLabel";
import MenuItem from "@mui/material/MenuItem/MenuItem";
import Select from "@mui/material/Select/Select";
import makeStyles from "@mui/styles/makeStyles/makeStyles";
import { Controller } from "react-hook-form";

import { useResponsive } from "../../providers";
import ArrowToBottomIcon from "../../../assets/icones/arrow-to-bottom.svg";

const useStyles = makeStyles(() => ({
  select: {
    borderRadius: "10px",
    fontWeight: "700",
    color: "#858585",
    height: "5.5vh",
    textAlign: "start",
  },
  label: {
    fontWeight: "700",
    fontSize: "1.46vh",
    color: "#5C5C5C",
  },
  option: {
    fontWeight: "700",
    fontSize: "1.46vh",
    color: "#5C5C5C",
  },
}));

const SelectComponent = ({
  control,
  name,
  label,
  value,
  onChange,
  options,
  disabled,
}) => {
  const classes = useStyles();
  const { isDesktop } = useResponsive();

  const Field = ({ field, formState }) => {
    return (
      <FormControl variant="outlined" fullWidth disabled={disabled}>
        <InputLabel className={classes.label}>{label}</InputLabel>
        <Select
          className={classes.select}
          style={{ fontSize: isDesktop ? "1.46vh" : "1.26vh" }}
          value={!!field ? field.value : value}
          disabled={disabled}
          onChange={(e) => {
            if (!!field) {
              field.onChange(e.target.value);
            } else {
              onChange(e.target.name, e.target.value);
            }
          }}
          error={!!field ? !!formState.errors[name] : false}
          label={label}
          IconComponent={(_props) => {
            const rotate = _props.className.toString().includes("iconOpen");
            return (
              <div
                style={{
                  position: "absolute",
                  cursor: "pointer",
                  pointerEvents: "none",
                  right: isDesktop ? (rotate ? 10 : 22) : rotate ? 8 : 16,
                  height: "1.46vh",
                  width: "1.46vh",
                  transform: rotate ? "rotate(180deg)" : "none",
                }}
              >
                <img
                  src={ArrowToBottomIcon}
                  style={{ width: isDesktop ? null : "5vw" }}
                />
              </div>
            );
          }}
        >
          {options.map((opt, index) => (
            <MenuItem key={index} value={opt.id} className={classes.option}>
              {opt.label}
            </MenuItem>
          ))}
        </Select>
        <FormHelperText error={!!field ? !!formState.errors[name] : false}>
          {!!field ? formState.errors[name]?.message : null}
        </FormHelperText>
      </FormControl>
    );
  };

  if (!!control) {
    return (
      <Controller
        name={name}
        control={control}
        render={({ field, formState }) => (
          <Field field={field} formState={formState} />
        )}
      />
    );
  }

  return <Field field={null} formState={null} />;
};

export default SelectComponent;
