import React from "react";
import makeStyles from "@mui/styles/makeStyles/makeStyles";
import SwipeableViews from "react-swipeable-views";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    overflowX: "scroll",
    scrollSnapType: "x mandatory",
    "-webkit-overflow-scrolling": "touch",
    scrollBehavior: "smooth",
    padding: theme.spacing(1),
  },
  listItem: {
    flex: "0 0 auto",
    marginRight: theme.spacing(2),
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

const ListViewComponent = ({ itens }) => {
  const classes = useStyles();

  return (
    <SwipeableViews enableMouseEvents>
      <div className={classes.container}>
        {itens.map((item, index) => {
          return (
            <div key={index} className={classes.listItem}>
              {item}
            </div>
          );
        })}
      </div>
    </SwipeableViews>
  );
};

export default ListViewComponent;
