import Grid from "@mui/material/Grid/Grid";

import { useResponsive } from "../../../../providers";
import LucreMaisLightImage from "../../../../../assets/imagens/lucre-mais-light.png";
import LucreRendaExtraImage from "../../../../../assets/imagens/lucre-renda-extra.png";
import LucreRendaExtraMobileImage from "../../../../../assets/imagens/lucre-renda-extra-mobile.png";

const DescribleComponent = () => {
  const { isDesktop } = useResponsive();

  return (
    <Grid
      container
      style={{
        marginLeft: isDesktop ? "5vw" : "12vw",
        justifyContent: "start",
        paddingTop: "5vh",
      }}
    >
      <Grid item xs={12}>
        <img
          src={LucreMaisLightImage}
          style={{ width: "100%", maxWidth: isDesktop ? "12.01vw" : "33.88vw" }}
        />
      </Grid>
      <Grid
        item
        xs={12}
        style={{
          marginTop: isDesktop ? "22vh" : "12vh",
          marginBottom: "15vh",
          marginLeft: isDesktop ? "2vw" : 0,
        }}
      >
        <img
          src={isDesktop ? LucreRendaExtraImage : LucreRendaExtraMobileImage}
          style={
            isDesktop
              ? { width: "100%", maxWidth: "41vw" }
              : { maxWidth: "90vw" }
          }
        />
      </Grid>
    </Grid>
  );
};

export default DescribleComponent;
