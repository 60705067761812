import Dialog from "@mui/material/Dialog/Dialog";
import DialogContent from "@mui/material/DialogContent/DialogContent";
import DialogTitle from "@mui/material/DialogTitle/DialogTitle";
import IconButton from "@mui/material/IconButton/IconButton";
import useTheme from "@mui/styles/useTheme";
import CloseIcon from "@mui/icons-material/Close";

import { useDialog } from "../providers";
import { useIsDesktop } from "../hooks";

const GenericDialogComponent = ({
  children,
  name,
  maxWidth,
  size,
  isPublic = false,
  onClose,
  isClosed = true,
  backgroundColor,
}) => {
  const tema = useTheme();
  const { isOpen, closeDialog } = useDialog();
  const { isDesktop } = useIsDesktop();

  return (
    <Dialog
      onClose={() => {
        if (isClosed) {
          closeDialog();
          if (!!onClose) onClose();
        }
      }}
      open={isOpen == name}
      fullWidth
      maxWidth={!!size ? null : maxWidth ?? "sm"}
      hideBackdrop={backgroundColor}
      PaperProps={{
        elevation: !!backgroundColor ? 0 : 1,
        style: {
          background: isPublic
            ? "linear-gradient(292.46deg, #85539D, #6B2D87, #40215F)"
            : "",
          borderRadius: "10px",
          backgroundColor: backgroundColor,
          width: !!size && isDesktop ? size.width : null,
          height: !!size && isDesktop ? size.height : null,
        },
      }}
    >
      <DialogTitle>
        {isClosed && (
          <IconButton
            onClick={() => {
              closeDialog();
              if (!!onClose) onClose();
            }}
            sx={{
              position: "absolute",
              right: tema.spacing(1),
              top: tema.spacing(1),
              color: tema.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        )}
      </DialogTitle>
      <DialogContent>{children}</DialogContent>
    </Dialog>
  );
};

export default GenericDialogComponent;
