import { BodyComponent, TableComponent } from "../../../../components";
import useAdminUsersController from "./admin-users.controller";
import AdminUsersFilterComponent from "./components/admin-users-filter.component";
import { useResponsive } from "../../../../providers";
import { useNavigate } from "react-router-dom";

const AdminUsersPage = () => {
  const navigate = useNavigate();
  const { isDesktop } = useResponsive();
  const {
    users,
    page,
    count,
    rowsPerPage,
    handleChangePage,
    control,
    onSubmit,
    userTypes,
    profileTypes,
    baixarRelatorio,
  } = useAdminUsersController();

  return (
    <BodyComponent>
      <AdminUsersFilterComponent
        control={control}
        onSubmit={onSubmit}
        userTypes={userTypes}
        profileTypes={profileTypes}
        count={count}
        baixarRelatorio={baixarRelatorio}
      />
      <TableComponent
        columns={[
          {
            label: "NOME COMPLETO",
            acessor: "displayName",
            width: isDesktop ? "17vw" : "20vw",
          },
          {
            label: "CELULAR",
            acessor: "phone",
            width: "10vw",
            hidden: !isDesktop,
          },
          {
            label: "TIPO",
            acessor: "userType",
            width: isDesktop ? "13vw" : "15vw",
          },
          {
            label: "PERFIL",
            acessor: "profileType",
            width: isDesktop ? "13vw" : "15vw",
          },
          { label: "SITUAÇÃO", acessor: "situation", width: "9vw" },
          {
            label: "ÚLTIMO LOGIN",
            acessor: "lastLogin",
            width: "13vw",
            hidden: !isDesktop,
          },
          {
            label: "DATA CADASTRO",
            acessor: "dtCadastro",
            width: "13vw",
            hidden: !isDesktop,
          },
        ]}
        rows={users}
        onClick={(row) => navigate("/admin/usuarios/formulario/" + row.id)}
        pagination={{
          page: page,
          count: count,
          rowsPerPage: rowsPerPage,
          handleChangePage: handleChangePage,
        }}
      />
      <div style={{ height: "60vh" }}></div>
    </BodyComponent>
  );
};

export default AdminUsersPage;
