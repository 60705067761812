import { useState } from "react";
import Paper from "@mui/material/Paper/Paper";
import Divider from "@mui/material/Divider/Divider";
import Grid from "@mui/material/Grid/Grid";
import Typography from "@mui/material/Typography/Typography";
import makeStyles from "@mui/styles/makeStyles/makeStyles";

import { dashboardService } from "../../../../../infrastructure/services";
import { useAlert } from "../../../../providers";
import moment from "moment";
import FilterChartComponent from "./filter-chart.component";
import LineChartV2Component from "./line-chart-v2.component";
import dayjs from "dayjs";

const useStyles = makeStyles(() => ({
  card: {
    width: "100%",
    borderRadius: "10px",
    marginBottom: "4vh",
  },
  cardTitle: {
    marginInline: "4vh",
    marginTop: "4vh",
    marginBottom: "4vh",
    fontWeight: "700",
    fontSize: "1.75vh",
    color: "#5C5C5C",
  },
}));

const StatusChartComponent = ({ users }) => {
  const classes = useStyles();
  const { showError } = useAlert();
  const [reportVsStatus, setReportVsStatus] = useState(null);

  const fetchData = (query) => {
    dashboardService
      .getReportVsStatus(query)
      .then((response) => {
        let dataVariables = {};
        Object.keys(response).forEach((variableName) => {
          dataVariables[variableName] = Object.entries(
            response[variableName]
          ).map(([key, value]) => {
            return {
              key: dayjs(new Date(parseInt(key))).format("DD/MM/YYYY"),
              value: parseInt(value),
            };
          });
        });
        setReportVsStatus(dataVariables);
      })
      .catch(showError);
  };

  return (
    <Paper variant="outlined" className={classes.card}>
      <Grid container style={{ alignItems: "center", padding: "1vh" }}>
        <Grid item xs={3}>
          <Typography className={classes.cardTitle}>LEAD</Typography>
        </Grid>
        <FilterChartComponent
          status={[
            { id: "APROVADAS", label: "APROVADAS" },
            { id: "PENDENTES", label: "PENDENTES" },
            { id: "REPROVADAS", label: "REPROVADAS" },
          ]}
          users={users}
          onSearch={fetchData}
          data={reportVsStatus}
          downloadName={"lead_status"}
        />
      </Grid>
      <Divider />
      <div style={{ height: "34vh", marginBottom: "1vh", padding: "1vh" }}>
        {!!reportVsStatus && Object.keys(reportVsStatus).length > 0 && (
          <LineChartV2Component
            direction="xAxis"
            data={reportVsStatus}
            hasZoom={true}
          />
        )}
      </div>
    </Paper>
  );
};

export default StatusChartComponent;
