import Button from "@mui/material/Button/Button";
import Grid from "@mui/material/Grid/Grid";

import {
  AutocompleteComponent,
  ButtonComponent,
  DatapickerComponent,
} from "../../../../components";
import { useResponsive } from "../../../../providers";

const MESES = [
  "JANEIRO",
  "FEVEREIRO",
  "MARÇO",
  "ABRIL",
  "MAIO",
  "JUNHO",
  "JULHO",
  "AGOSTO",
  "SETEMBRO",
  "OUTUBRO",
  "NOVEMBRO",
  "DEZEMBRO",
];

const FinancialFilterComponent = ({
  control,
  campaigns,
  onDownloadSubmit,
  hasValueToAnticipate,
  onAdvancePayment,
  pendingInvoice,
  onSendInvoice,
}) => {
  const { isDesktop } = useResponsive();

  return (
    <div style={{ width: "71vw", marginTop: "4vh", marginBottom: "6vh" }}>
      <Grid container spacing={isDesktop ? 4 : 2}>
        <Grid item xs={isDesktop ? 6 : 12}>
          <Grid container spacing={isDesktop ? 4 : 2}>
            <Grid item xs={6}>
              <DatapickerComponent
                name="startDate"
                control={control}
                label="DATA INICIAL"
                readOnly={true}
              />
            </Grid>
            <Grid item xs={6}>
              <DatapickerComponent
                name="endDate"
                control={control}
                label="DATA FINAL"
                readOnly={true}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={isDesktop ? 6 : 12}>
          <AutocompleteComponent
            name="campaign"
            control={control}
            label="PRODUTO/SERVIÇO"
            acessorLabel="name"
            acessorValue="id"
            options={campaigns}
          />
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={isDesktop ? 4 : 2}>
            <Grid item xs={isDesktop ? 2 : 6}>
              <ButtonComponent
                label="DOWNLOAD"
                backgroundColor="#774E98"
                color="white"
                fullWidth
                height="5vh"
                onClick={onDownloadSubmit}
              />
            </Grid>
            {hasValueToAnticipate && (
              <Grid item xs={isDesktop ? 3 : 12}>
                <ButtonComponent
                  label="ANTECIPAR PAGAMENTO"
                  backgroundColor="#774E98"
                  color="white"
                  fullWidth
                  height="5vh"
                  onClick={onAdvancePayment}
                />
              </Grid>
            )}
            {pendingInvoice == true && (
              <Grid item xs={isDesktop ? 3 : 12}>
                <input
                  accept=".pdf, image/png, image/jpeg"
                  style={{ display: "none" }}
                  id="send-invoice"
                  type="file"
                  onChange={onSendInvoice}
                />
                <label htmlFor="send-invoice">
                  <Button
                    component="span"
                    variant="contained"
                    disableElevation
                    disableRipple
                    disableFocusRipple
                    disableTouchRipple
                    fullWidth={!isDesktop}
                    style={{
                      backgroundColor: "#774E98",
                      color: "white",
                      height: "5vh",
                      textTransform: "none",
                      borderRadius: "30px",
                      fontWeight: "700",
                      fontSize: "1.46vh",
                      paddingInline: "1vw",
                    }}
                  >
                    {"INCLUIR NOTA FISCAL DE " +
                      MESES[new Date().getMonth() - 1]}
                  </Button>
                </label>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default FinancialFilterComponent;
