import Grid from "@mui/material/Grid/Grid";
import green from "@mui/material/colors/green";
import red from "@mui/material/colors/red";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

import {
  BodyComponent,
  ButtonComponent,
  TableComponent,
} from "../../../../components";
import useAdminModalsController from "./admin-modals.controller";
import { useIsDesktop } from "../../../../hooks";
import { useNavigate } from "react-router-dom";

const AdminModalsPage = () => {
  const navigate = useNavigate();
  const { isDesktop } = useIsDesktop();
  const { modals } = useAdminModalsController();

  return (
    <BodyComponent>
      <Grid
        container
        spacing={4}
        style={{ marginBottom: "2vh", width: "71vw", justifyContent: "end" }}
      >
        <Grid item>
          <ButtonComponent
            label="CRIAR NOVO MODAL"
            backgroundColor="#40215F"
            color="white"
            size="small"
            height="4vh"
            onClick={() => navigate("/admin/modais/formulario")}
          />
        </Grid>
      </Grid>
      <TableComponent
        columns={[
          { label: "ORDEM", acessor: "order" },
          {
            label: "MEDIA",
            acessor: "bannerUrl",
            formatWidget: (row) => {
              if (row["bannerType"] == "imagem") {
                return (
                  <img
                    src={row["bannerUrl"]}
                    style={{ maxHeight: isDesktop ? "6vh" : "8vh" }}
                  />
                );
              }
              return row["bannerUrl"];
            },
          },
          { label: "LINK", acessor: "link" },
          {
            label: "ATIVO",
            acessor: "active",
            width: "5vw",
            formatWidget: (row) => {
              if (row["active"] == 1) {
                return <CheckCircleIcon style={{ color: green[500] }} />;
              }
              return <HighlightOffIcon style={{ color: red[500] }} />;
            },
          },
        ]}
        onClick={(row) => navigate("/admin/modais/formulario/" + row.id)}
        rows={modals}
      />
      <div style={{ height: "60vh" }}></div>
    </BodyComponent>
  );
};

export default AdminModalsPage;
