import Avatar from "@mui/material/Avatar/Avatar";
import Badge from "@mui/material/Badge/Badge";
import Divider from "@mui/material/Divider/Divider";
import Grid from "@mui/material/Grid/Grid";
import Typography from "@mui/material/Typography/Typography";
import Card from "@mui/material/Card/Card";
import makeStyles from "@mui/styles/makeStyles/makeStyles";
import styled from "@mui/styles/styled/styled";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import green from "@mui/material/colors/green";

import {
  BodyComponent,
  CurrencyChipComponent,
  TableComponent,
} from "../../../components";
import useFinancialController from "./financial.controller";
import AdvancePaymentDialogComponent from "./components/advance-payment-dialog.component";
import { useResponsive } from "../../../providers";
import FinancialFilterComponent from "./components/financial-filter.component";
import FinancialLightIcon from "../../../../assets/icones/financial-light.svg";
import PigIcon from "../../../../assets/icones/financial-light.svg";

const StyledBadge = styled(Badge)(() => ({
  "& .MuiBadge-badge": {
    right: "50%",
    color: "red",
  },
}));

const useStyles = makeStyles({
  card: {
    width: "34.37vw",
    height: "19.14vh",
    backgroundColor: "#40215F",
    borderRadius: "10px",
    textAlign: "center",
    paddingTop: "4vh",
  },
});

const FinancialPage = () => {
  const {
    control,
    campaigns,
    extract,
    amountReceivable,
    balance,
    pendingInvoice,
    onSendInvoice,
    onAdvancePayment,
    onConfirmAdvancePayment,
    hasValueToAnticipate,
    onDownloadSubmit,
    handleChangePage,
    page,
    count,
    rowsPerPage,
  } = useFinancialController();

  return (
    <BodyComponent>
      <AdvancePaymentDialogComponent
        onConfirmAdvancePayment={onConfirmAdvancePayment}
      />
      <div style={{ width: "71vw", marginTop: "4vh" }}>
        <Grid container spacing={5}>
          <Grid item xs={6}>
            <CurrencyCardComponent
              title="SEU SALDO"
              iconAsset={FinancialLightIcon}
              primaryColor="#40215F"
              secondaryColor="#774E98"
              value={balance.toLocaleString("pt-BR", {
                style: "currency",
                currency: "BRL",
              })}
            />
          </Grid>
          <Grid item xs={6}>
            <CurrencyCardComponent
              title="VALOR A RECEBER"
              iconAsset={PigIcon}
              primaryColor="#774E98"
              secondaryColor="#40215F"
              value={amountReceivable.toLocaleString("pt-BR", {
                style: "currency",
                currency: "BRL",
              })}
            />
          </Grid>
        </Grid>
      </div>

      <FinancialFilterComponent
        control={control}
        campaigns={campaigns}
        onDownloadSubmit={onDownloadSubmit}
        hasValueToAnticipate={hasValueToAnticipate}
        onAdvancePayment={onAdvancePayment}
        pendingInvoice={pendingInvoice}
        onSendInvoice={onSendInvoice}
      />

      <TableComponent
        columns={[
          { label: "PRODUTO", acessor: "campaign" },
          { label: "USUÁRIO", acessor: "affiliate" },
          { label: "DATA", acessor: "eventDate" },
          {
            label: "SEU GANHO",
            acessor: "payment",
            formatWidget: (row) => {
              return (
                <CurrencyChipComponent
                  value={row["payment"].toLocaleString("pt-BR", {
                    style: "currency",
                    currency: "BRL",
                  })}
                />
              );
            },
          },
          {
            label: "RECEBIDO",
            acessor: "received",
            formatWidget: (row) => {
              if (row["received"]) {
                return <CheckCircleIcon style={{ color: green[500] }} />;
              }
            },
          },
        ]}
        rows={extract}
        pagination={{
          page: page,
          count: count,
          rowsPerPage: rowsPerPage,
          handleChangePage: handleChangePage,
        }}
      />
      <div style={{ height: "60vh" }}></div>
    </BodyComponent>
  );
};

const CurrencyCardComponent = ({
  title,
  value,
  iconAsset,
  primaryColor,
  secondaryColor,
}) => {
  const classes = useStyles();
  const { isDesktop } = useResponsive();

  return (
    <StyledBadge
      overlap="rectangular"
      badgeContent={
        <Avatar
          style={{
            backgroundColor: secondaryColor,
            width: "5.42vh",
            height: "5.42vh",
          }}
        >
          <img src={iconAsset} />
        </Avatar>
      }
    >
      <Card
        elevation={0}
        className={classes.card}
        style={{ backgroundColor: primaryColor }}
      >
        <Typography
          style={{
            fontWeight: "700",
            fontSize: isDesktop ? "1.75vh" : "1.46vh",
            color: "white",
          }}
        >
          {title}
        </Typography>
        <Divider
          style={{
            backgroundColor: "white",
            marginTop: "2vh",
            marginBottom: "2vh",
          }}
        />
        <Typography
          style={{
            fontSize: isDesktop ? "3.12vh" : "2.82vh",
            color: "white",
          }}
        >
          {value}
        </Typography>
      </Card>
    </StyledBadge>
  );
};

export default FinancialPage;
