import React from "react";
import Grid from "@mui/material/Grid/Grid";
import Typography from "@mui/material/Typography/Typography";
import makeStyles from "@mui/styles/makeStyles/makeStyles";

import {
  GenericDialogComponent,
  InputFilterComponent,
} from "../../../../components";
import { useDialog } from "../../../../providers";

const useStyles = makeStyles(() => ({
  content: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: "1vh",
    marginBottom: "2vh",
  },
}));

const ProductionDetailDialogComponent = () => {
  const classes = useStyles();
  const { metadata: production } = useDialog();

  return (
    <GenericDialogComponent name="production-detail-in-reports-dialog">
      <div className={classes.content}>
        <Typography
          style={{
            marginBottom: "6vh",
            fontWeight: "700",
            fontSize: "1.95vh",
            color: "#40215F",
          }}
        >
          DETALHE DA PRODUÇÃO
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <InputFilterComponent
              placeholder="CAMPANHA"
              value={production?.campaign ?? ""}
              disabled
            />
          </Grid>
          <Grid item xs={12}>
            <InputFilterComponent
              placeholder="USUÁRIO"
              value={production?.affiliate ?? ""}
              disabled
            />
          </Grid>
          <Grid item xs={12}>
            <InputFilterComponent
              placeholder="OBJETIVO"
              value={production?.step ?? ""}
              disabled
            />
          </Grid>
          <Grid item xs={12}>
            <InputFilterComponent
              placeholder="DATA"
              value={production?.eventDate ?? ""}
              disabled
            />
          </Grid>
          {!!production && !!production.observation && (
            <Grid item xs={12}>
              <InputFilterComponent
                placeholder="OBSERVAÇÕES"
                value={production?.observation ?? ""}
                multiline={true}
                minRows={7}
                disabled
              />
            </Grid>
          )}
        </Grid>
      </div>
    </GenericDialogComponent>
  );
};

export default ProductionDetailDialogComponent;
