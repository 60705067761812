import {
  BodyComponent,
  CurrencyChipComponent,
  TableComponent,
} from "../../../components";
import { Constants } from "../../../utils";
import useAdvertiserReportsController from "./advertiser-reports.controller";
import FiltersComponent from "./component/filters.component";
import ProductionDetailDialogComponent from "./component/production-detail-dialog.component";
import ApproveProductionDialogComponent from "./component/approve-production-dialog.component";
import SendLinkDialogComponent from "./component/send-link-dialog.component";
import SetObservationDilaogComponent from "./component/set-observations-dialog.component";

const AdvertiserReportsPage = () => {
  const {
    onSearch,
    productions,
    selectedProduction,
    onSelectProduction,
    onChangeProductionSituation,
    onSendLink,
    onSetObservations,
    handleChangePage,
    page,
    count,
    rowsPerPage,
    counters,
    userOptions,
  } = useAdvertiserReportsController();

  return (
    <BodyComponent>
      <ApproveProductionDialogComponent
        production={selectedProduction}
        onChangeSituation={onChangeProductionSituation}
      />
      <ProductionDetailDialogComponent
        production={selectedProduction}
        onChangeSituation={onChangeProductionSituation}
      />
      <SendLinkDialogComponent onSendLink={onSendLink} />
      <SetObservationDilaogComponent onSetObservations={onSetObservations} />

      <FiltersComponent
        onSearch={onSearch}
        counters={counters}
        userOptions={userOptions}
      />
      <TableComponent
        onClick={onSelectProduction}
        columns={[
          { label: "PRODUTO", acessor: "campaign", width: "9vw" },
          {
            label: "ETAPA",
            acessor: "step",
            formatText: (row) => Constants.getProductionStep(row["step"]),
            width: "12vw",
          },
          {
            label: "USUÁRIO",
            acessor: "affiliate",
            width: "11vw",
            formatText: (row) => row["affiliate"]["name"],
          },
          { label: "DATA", acessor: "eventDate", width: "6vw" },
          { label: "STATUS", acessor: "situation", width: "7vw" },
          {
            label: "PAGAMENTO (R$)",
            acessor: "payment",
            width: "10vw",
            formatWidget: (row) => {
              if (row["payment"] != 0) {
                return (
                  <CurrencyChipComponent
                    value={row["payment"].toLocaleString("pt-BR", {
                      style: "currency",
                      currency: "BRL",
                    })}
                  />
                );
              }
            },
          },
        ]}
        rows={productions}
        pagination={{
          page: page,
          count: count,
          rowsPerPage: rowsPerPage,
          handleChangePage: handleChangePage,
        }}
      />
      <div style={{ height: "60vh" }}></div>
    </BodyComponent>
  );
};

export default AdvertiserReportsPage;
