import httpClient from "./axios.config";

class ProductionService {
  getReport = (query) => {
    return new Promise((resolve, reject) => {
      httpClient
        .get("/producao/buscarRelatorio", { params: query })
        .then((response) => {
          const productionsResponse = response.data.response.data;

          const productions = productionsResponse.map((production) => ({
            step: production.objetivo,
            campaign: production.campanha,
            affiliate: production.afiliadoNome,
            eventDate: production.data.substring(0, 10),
            payment: parseFloat(production.pagamento),
            observation: production.observacao,
          }));

          const counter = response.data.response.contadores;

          resolve({
            data: productions,
            count: parseInt(response.data.response.count),
            counters: {
              pending: counter.pendentes,
              approved: counter.aprovadas,
              disapproved: counter.reprovadas,
            },
          });
        })
        .catch((error) =>
          reject(
            error.response && error.response.data && error.response.data.message
              ? error.response.data.message
              : "Erro inesperado, tente novamente mais tarde."
          )
        );
    });
  };

  getDashboard = (query) => {
    return new Promise((resolve, reject) => {
      httpClient
        .get("/producao/buscarDashboard", { params: query })
        .then((response) => {
          const dashboard = response.data.response;

          resolve({
            completedRegistrations: dashboard.cadastrosConcluidos,
            startedRegistrations: dashboard.cadastrosIniciados,
            approvedProductions: {
              quantity: parseInt(dashboard.propostasAprovadasQuantidade),
              value: dashboard.propostasAprovadasValor.toLocaleString("pt-BR", {
                style: "currency",
                currency: "BRL",
              }),
            },
            averageTicket: {
              quantity: parseInt(dashboard.ticketMedioQuantidade),
              value: dashboard.ticketMedioValor.toLocaleString("pt-BR", {
                style: "currency",
                currency: "BRL",
              }),
            },
            chartCampaignVsConversion: {
              campaigns: dashboard.conversaoProduto.produtos,
              conversions: dashboard.conversaoProduto.conversoes,
            },
            chartMostLinkSolicitationCampaigns:
              dashboard.rankLinksSolicitadosPorProduto,
            chartMostLinkAttachedCampaigns:
              dashboard.rankLinksAnexadosPorProduto,
            chartMostSharedCampaigns: dashboard.rankProdutosCompartilhados,
          });
        })
        .catch((error) =>
          reject(
            error.response && error.response.data && error.response.data.message
              ? error.response.data.message
              : "Erro inesperado, tente novamente mais tarde."
          )
        );
    });
  };

  getInterReport = (query) => {
    return new Promise((resolve, reject) => {
      httpClient
        .get("/producao/buscarRelatorioInterno", { params: query })
        .then((response) => {
          const productionsResponse = response.data.response.data;

          const productions = productionsResponse.map((production) => ({
            id: production.id,
            campaign: production.campanha,
            step: production.objetivo,
            affiliate: {
              name: production.afiliadoNome,
              email: production.afiliadoEmail,
              phone: production.afiliadoTelefone,
            },
            eventDate: production.data.substring(0, 10),
            situation: production.status,
            metadata: production.metadata,
            payment: parseFloat(production.pagamento),
            commission: production.comissao,
            commissionType: production.tipoComissao == "1" ? "R$" : "%",
            linkCpr: production.linkCpr,
            observation: production.observacao,
          }));

          const counter = response.data.response.contadores;

          resolve({
            data: productions,
            count: parseInt(response.data.response.count),
            counters: {
              pending: counter.pendentes,
              approved: counter.aprovadas,
              disapproved: counter.reprovadas,
              visualized: counter.visualizadas,
            },
          });
        })
        .catch((error) =>
          reject(
            error.response && error.response.data && error.response.data.message
              ? error.response.data.message
              : "Erro inesperado, tente novamente mais tarde."
          )
        );
    });
  };

  changeProductionSituation = (form) => {
    return new Promise((resolve, reject) => {
      httpClient
        .put("/producao/alterarStatusProducaoInterna", form)
        .then((response) => {
          const json = response.data.response;
          resolve(json);
        })
        .catch((error) =>
          reject(
            error.response && error.response.data && error.response.data.message
              ? error.response.data.message
              : "Erro inesperado, tente novamente mais tarde."
          )
        );
    });
  };

  sendLinkCpr = (form) => {
    return new Promise((resolve, reject) => {
      httpClient
        .put("/producao/salvarProducaoInterna", form)
        .then((response) => {
          const json = response.data.response;
          resolve(json);
        })
        .catch((error) =>
          reject(
            error.response && error.response.data && error.response.data.message
              ? error.response.data.message
              : "Erro inesperado, tente novamente mais tarde."
          )
        );
    });
  };

  setObservations = (form) => {
    return new Promise((resolve, reject) => {
      httpClient
        .put("/producao/anexarObservacoes", form)
        .then((response) => {
          const json = response.data.response;
          resolve(json);
        })
        .catch((error) =>
          reject(
            error.response && error.response.data && error.response.data.message
              ? error.response.data.message
              : "Erro inesperado, tente novamente mais tarde."
          )
        );
    });
  };
}

export default new ProductionService();
