import Box from "@mui/material/Box/Box";
import TextField from "@mui/material/TextField/TextField";
import Typography from "@mui/material/Typography/Typography";
import makeStyles from "@mui/styles/makeStyles/makeStyles";
import styled from "@mui/material/styles/styled";
import { Controller } from "react-hook-form";
import React, { useState } from "react";

const StyledInput = styled("input")({
  "&:focus": {
    outline: "none",
  },
  boxSizing: "border-box",
  height: "100%",
  cursor: "pointer",
  opacity: 0,
  position: "absolute",
  zIndex: 1,
});

const StyledTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    color: "white",
    height: "7vh",
    fontWeight: "600",
    fontSize: "1.75vh",
    "& fieldset": {
      borderRadius: "35px",
      borderColor: "#f0f4f8",
    },
    "&:hover fieldset": {
      borderRadius: "35px",
      borderColor: "#f0f4f8",
    },
    "&.Mui-focused fieldset": {
      borderRadius: "35px",
      borderColor: "#d8dbdf",
    },
    "&.Mui-error fieldset": {
      borderRadius: "35px",
      borderColor: "red",
    },
    "&.Mui-disabled fieldset": {
      borderRadius: "35px",
      borderColor: "#F0F4F8",
    },
  },
});

const useStyles = makeStyles(() => ({
  container: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  content: {
    color: "white",
    opacity: "0.4",
    fontWeight: "600",
    fontSize: "1.75vh",
    marginTop: "-5.6vh",
    marginBottom: "3vh",
    zIndex: 0,
  },
  contentFile: {
    color: "white",
    opacity: "1",
    fontWeight: "600",
    fontSize: "1.75vh",
    marginTop: "-5.6vh",
    marginBottom: "3vh",
    zIndex: 0,
  },
}));

const SelectFileGradientComponent = ({
  name,
  label,
  placeholder,
  control,
  setValue,
  disabled,
  textHelp,
  onBase64Change,
}) => {
  const classes = useStyles();

  const [fileName, setFileName] = useState("");

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setFileName(file ? file.name : "");

    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64String = reader.result;
        onBase64Change(base64String);
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, formState }) => (
        <Box className={classes.container}>
          <StyledTextField
            variant="outlined"
            margin="normal"
            fullWidth
            label={label}
            placeholder={placeholder}
            error={!!formState.errors[name]}
            helperText={
              !!formState.errors[name]
                ? formState.errors[name]?.message
                : textHelp
            }
            FormHelperTextProps={{
              style: {
                color: !!formState.errors[name] ? "red" : "white",
              },
            }}
            style={{
              backgroundColor: "inherit",
              color: "white",
            }}
            InputProps={{
              inputComponent: StyledInput,
              inputProps: {
                type: "file",
                disabled: disabled || formState.isSubmitting,
                accept: ".jpg, .jpeg, .png",
                "aria-label": label,
                onChange: (event) => {
                  handleFileChange(event);
                  field.onChange(event);
                },
              },
            }}
          />
          <Typography
            className={fileName ? classes.contentFile : classes.content}
          >
            {fileName ? fileName : placeholder}
          </Typography>
        </Box>
      )}
    />
  );
};

export default SelectFileGradientComponent;
