import AppBar from "@mui/material/AppBar/AppBar";
import Grid from "@mui/material/Grid/Grid";
import Toolbar from "@mui/material/Toolbar/Toolbar";
import Typography from "@mui/material/Typography/Typography";

import { ButtonComponent } from "../../../../../components";
import { useResponsive } from "../../../../../providers";
import LucreMaisLightImage from "../../../../../../assets/imagens/lucre-mais-light.png";
import { useNavigate } from "react-router-dom";

const classes = {
  link: {
    fontSize: "1.46vh",
    fontWeight: "700",
    "&:hover": {
      cursor: "pointer",
    },
  },
};

const NavBarComponent = ({
  handleSectionClick,
  aboutRef,
  helpRef,
  campaignsRef,
}) => {
  const navigate = useNavigate();
  const { isDesktop } = useResponsive();

  return (
    <AppBar
      position="relative"
      elevation={0}
      color="transparent"
      style={{ paddingTop: isDesktop ? "5vh" : "0vh" }}
    >
      <Toolbar
        style={{
          paddingLeft: isDesktop ? "5vw" : "10vw",
          paddingRight: "5vw",
        }}
      >
        <Grid container>
          <Grid
            item
            xs={isDesktop ? 2 : 12}
            style={{ textAlign: "inherit", marginTop: isDesktop ? 0 : "3vh" }}
          >
            <img
              src={LucreMaisLightImage}
              style={{
                width: "100%",
                maxWidth: isDesktop ? "9.29vw" : "33.88vw",
              }}
            />
          </Grid>
          {isDesktop && (
            <Grid item xs={10}>
              <Grid
                container
                style={{ justifyContent: "end", alignItems: "center" }}
              >
                <Grid item style={{ marginRight: "3vw", color: "white" }}>
                  <Typography
                    sx={classes.link}
                    onClick={() => handleSectionClick(aboutRef)}
                  >
                    SOBRE
                  </Typography>
                </Grid>
                <Grid item style={{ marginRight: "3vw", color: "white" }}>
                  <Typography
                    sx={classes.link}
                    onClick={() => handleSectionClick(campaignsRef)}
                  >
                    CAMPANHAS
                  </Typography>
                </Grid>
                <Grid item style={{ marginRight: "3vw", color: "white" }}>
                  <Typography
                    sx={classes.link}
                    onClick={() => navigate("/anunciar")}
                  >
                    ANUNCIE SEU PRODUTO AQUI
                  </Typography>
                </Grid>
                <Grid item style={{ marginRight: "3vw", color: "white" }}>
                  <Typography
                    sx={classes.link}
                    onClick={() => handleSectionClick(helpRef)}
                  >
                    DÚVIDAS
                  </Typography>
                </Grid>
                <Grid item>
                  <ButtonComponent
                    label="ACESSAR SUA CONTA"
                    onClick={() => navigate("/entrar")}
                  />
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Toolbar>
    </AppBar>
  );
};

export default NavBarComponent;
