import Divider from "@mui/material/Divider/Divider";
import Grid from "@mui/material/Grid/Grid";
import Typography from "@mui/material/Typography/Typography";

import {
  InputFilterComponent,
  SelectComponent,
} from "../../../../../components";

const BasicSectionComponent = ({ control, isActiveOptions, isLoading }) => {
  return (
    <div>
      <Typography
        style={{
          fontWeight: "600",
          fontSize: "1.75vh",
          color: "#5C5C5C",
        }}
      >
        INFORMAÇÕES BÁSICAS
      </Typography>
      <Divider style={{ marginBottom: "2vh" }} />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={8}>
              <InputFilterComponent
                name="name"
                control={control}
                label="NOME"
                placeholder="NOME"
                disabled={true}
              />
            </Grid>
            <Grid item xs={4}>
              <SelectComponent
                name="isActive"
                control={control}
                label="NOTIFICAÇÃO ATIVA?"
                acessor="label"
                options={isActiveOptions}
                disabled={isLoading}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default BasicSectionComponent;
