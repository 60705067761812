import React from "react";
import Box from "@mui/material/Box/Box";
import Grid from "@mui/material/Grid/Grid";
import ReCAPTCHA from "react-google-recaptcha";

import { useResponsive } from "../../../../../providers";
import {
  ButtonGradientComponent,
  CheckBoxComponent,
  InputGradientComponent,
  LinkComponent,
} from "../../../../../components";
import SelectGradientComponent from "../../../../../components/form/select-gradient.component";
import useRegisterUserController from "./register-user.controller";

const FormComponent = () => {
  const _reCaptchaRef = React.createRef();
  const { isDesktop } = useResponsive();
  const {
    control,
    onSubmit,
    setValue,
    messagePasswordValidation,
    cidades,
    estados,
  } = useRegisterUserController();

  return (
    <Box
      component="form"
      onSubmit={onSubmit}
      sx={{
        display: "flex",
        justifyContent: "center",
        paddingTop: "3vh",
        paddingBottom: "2vh",
      }}
    >
      <Grid
        container
        sx={{
          maxWidth: isDesktop ? "32vw" : "100vw",
          paddingInline: isDesktop ? "0" : "4vw",
        }}
      >
        <Grid item xs={12}>
          <InputGradientComponent
            name="fullName"
            control={control}
            placeholder="NOME COMPLETO"
          />
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <InputGradientComponent
                name="email"
                control={control}
                placeholder="EMAIL"
              />
            </Grid>
            <Grid item xs={6}>
              <InputGradientComponent
                name="cpf"
                control={control}
                placeholder="CPF"
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={6}>
            <InputGradientComponent
              name="pix"
              control={control}
              placeholder="CHAVE DO PIX"
            />
          </Grid>
          <Grid item xs={6}>
            <InputGradientComponent
              name="phone"
              control={control}
              placeholder="CELULAR"
            />
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={6}>
            <SelectGradientComponent
              name="estado"
              control={control}
              label="ESTADO"
              options={estados}
              isValueObject={false}
            />
          </Grid>
          <Grid item xs={6}>
            <SelectGradientComponent
              name="cidade"
              control={control}
              label="CIDADE"
              options={cidades}
              isValueObject={false}
            />
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={6}>
            <InputGradientComponent
              name="facebook"
              control={control}
              placeholder="FACEBOOK"
            />
          </Grid>
          <Grid item xs={6}>
            <InputGradientComponent
              name="instagram"
              control={control}
              placeholder="INSTAGRAM"
            />
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={6}>
            <InputGradientComponent
              name="password"
              control={control}
              placeholder="SENHA"
              type="password"
              textHelp={messagePasswordValidation}
            />
          </Grid>
          <Grid item xs={6}>
            <InputGradientComponent
              name="confirmPassword"
              control={control}
              placeholder="CONFIRMAR SENHA"
              type="password"
              textHelp={messagePasswordValidation}
            />
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Box display="flex" flexDirection="column" alignItems="center">
              <LinkComponent
                onClick={() =>
                  window.open(
                    "https://contratosrizoma.us-southeast-1.linodeobjects.com/CONTRATO_DE_ADESAO_AFILIADOS.pdf",
                    "_blank"
                  )
                }
                label="TERMO DE ADESÃO E CONFIABILIDADE"
              />
              <CheckBoxComponent
                name="acceptedTerm"
                control={control}
                label="ACEITAR TERMO"
              />
            </Box>
          </Grid>
          <Grid item xs={6}>
            <div style={{ textAlign: "center" }}>
              <ReCAPTCHA
                style={{ display: "inline-block" }}
                theme="light"
                ref={_reCaptchaRef}
                sitekey={import.meta.env.VITE_RECAPTCHA_KEY}
                onChange={(token) => setValue("reCaptcha", token)}
              />
            </div>
          </Grid>
        </Grid>
        <Grid item xs={12} style={{ marginTop: "3vh" }}>
          <ButtonGradientComponent
            label="CRIE SUA CONTA"
            fullWidth
            type="submit"
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default FormComponent;
