import { useEffect, useState } from "react";

import { useAlert, useAuth } from "../../../../providers";
import { useIsMounted } from "../../../../hooks";
import {
  menuService,
  notificationService,
} from "../../../../../infrastructure/services";
import { useLoadingContext } from "../../../../context/loading.context";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

const useAdminNotificationsController = () => {
  const { showError, showSuccess } = useAlert();
  const { user } = useAuth();
  const isMounted = useIsMounted();
  const [menu, setMenu] = useState(null);

  const [preferences, setPreferences] = useState([]);
  const { setLoadingState } = useLoadingContext();

  const schemaLink = yup.object().shape({
    link: yup.string().required("Campo obrigatório"),
  });

  const {
    control: linkForm,
    handleSubmit: handleLinkSubmit,
    setValue: setValueLink,
    watch: watchLink,
    reset: resetLink,
  } = useForm({
    defaultValues: {
      id: "",
      link: "",
    },
    resolver: yupResolver(schemaLink),
  });

  useEffect(() => {
    if (user != null) {
      setLoadingState(true);
      notificationService
        .getPreferences()
        .then((response) => {
          if (isMounted.current) {
            setPreferences(response);
          }
        })
        .catch(showError)
        .finally(() => {
          setLoadingState(false);
        });
      setLoadingState(true);
      menuService
        .getMenu()
        .then((menu) => {
          setMenu(menu);
          setValueLink("link", menu.link);
          setValueLink("id", menu.id);
        })
        .catch(showError)
        .finally(() => {
          setLoadingState(false);
        });
    }
  }, [user]);

  const onSubmitLink = () => {
    handleLinkSubmit((data) => {
      setLoadingState(true);
      menuService
        .setMenu(data)
        .then((res) => {
          setLoadingState(false);
          showSuccess("Link alterado com sucesso");
        })
        .catch(showError)
        .finally(() => {
          setLoadingState(false);
        });
    })();
  };

  return { preferences, linkForm, onSubmitLink };
};

export default useAdminNotificationsController;
