import Grid from "@mui/material/Grid/Grid";

import { useResponsive } from "../../../../providers";
import CertificacaoAnepsImage from "../../../../../assets/imagens/certificacao-aneps.png";
import CertificacaoFebrabanImage from "../../../../../assets/imagens/certificacao-febraban.png";

const SectionFourComponent = () => {
  const { isDesktop } = useResponsive();

  return (
    <Grid
      container
      direction="column"
      style={{ alignItems: isDesktop ? "start" : "center" }}
    >
      <Grid item>
        <img
          src={CertificacaoAnepsImage}
          style={{ width: "100%", maxWidth: isDesktop ? "13.5vw" : "46.5vw" }}
        />
      </Grid>
      <Grid item>
        <img
          src={CertificacaoFebrabanImage}
          style={{ width: "100%", maxWidth: isDesktop ? "9.31vw" : "30.93vw" }}
        />
      </Grid>
    </Grid>
  );
};

export default SectionFourComponent;
