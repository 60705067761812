import React from "react";
import makeStyles from "@mui/styles/makeStyles/makeStyles";
import InputAdornment from "@mui/material/InputAdornment/InputAdornment";
import TextField from "@mui/material/TextField/TextField";
import Autocomplete from "@mui/material/Autocomplete";

import SearchIcon from "../../../assets/icones/search.svg";

const useStyles = makeStyles(() => ({
  root: {
    "& .MuiOutlinedInput-root": {
      borderRadius: "100px",
      backgroundColor: "#ffffff",
      "&:hover fieldset": {
        borderColor: "transparent",
      },
      "&.Mui-focused fieldset": {
        borderColor: "transparent",
      },
    },
    "& .MuiOutlinedInput-input::placeholder": {
      color: "#858585",
      fontSize: "1.46vh",
      fontWeight: "700",
    },
    "& .MuiOutlinedInput-input": {
      color: "#000000",
    },
    "& .MuiInputAdornment-root": {
      color: "#000000",
    },
  },
}));

const AutoCompleteGroupComponent = ({
  options,
  placeholder,
  fullWidth,
  onChange,
  valor,
}) => {
  const classes = useStyles();
  //TODO: Padding no topo
  return (
    <Autocomplete
      className={classes.root}
      options={options}
      groupBy={(option) => option.group}
      getOptionLabel={(option) => (option === "" ? "" : option.name)}
      value={valor}
      onChange={(e, v) => (onChange !== undefined ? onChange(v) : null)}
      popupIcon={<div></div>}
      renderInput={(params) => (
        <TextField
          {...params}
          fullWidth={fullWidth}
          style={{ width: !fullWidth ? "29.30vw" : null }}
          variant="outlined"
          placeholder={placeholder}
          size="small"
          margin="none"
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <InputAdornment position="start">
                <img src={SearchIcon} />
              </InputAdornment>
            ),
          }}
        />
      )}
    />
  );
};

export default AutoCompleteGroupComponent;
