import React from "react";
import Divider from "@mui/material/Divider/Divider";
import Grid from "@mui/material/Grid/Grid";
import Typography from "@mui/material/Typography/Typography";
import makeStyles from "@mui/styles/makeStyles/makeStyles";

import {
  ButtonComponent,
  GenericDialogComponent,
  InputFilterComponent,
} from "../../../../components";
import { useAlert, useDialog } from "../../../../providers";

const useStyles = makeStyles(() => ({
  content: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: "1vh",
    marginBottom: "2vh",
  },
}));

const ProductionDetailDialogComponent = ({ production, onChangeSituation }) => {
  const classes = useStyles();
  const { showInfo } = useAlert();
  const { openDialog } = useDialog();

  const handleCopyValue = (label, value) => {
    navigator.clipboard.writeText(value);
    showInfo(label + " copiado para a área de transferências!");
  };

  const hasMetadata = [
    "formulario_preenchido",
    "contato_para_compra",
    "cadastro_preenchido",
  ].includes(production?.step);

  const hasProductsMetadata = ["contato_para_compra"].includes(
    production?.step
  );

  const hasSelfRegistration = ["cadastro_preenchido"].includes(
    production?.step
  );

  const SectionTitle = ({ title }) => {
    return (
      <Grid item xs={12} style={{ marginTop: "1vh" }}>
        <Typography
          style={{
            fontWeight: "600",
            fontSize: "1.75vh",
            color: "#5C5C5C",
          }}
        >
          {title}
        </Typography>
        <Divider />
      </Grid>
    );
  };

  return (
    <GenericDialogComponent name="production-detail-dialog">
      <div className={classes.content}>
        <Typography
          style={{
            marginBottom: "6vh",
            fontWeight: "700",
            fontSize: "1.95vh",
            color: "#40215F",
          }}
        >
          DETALHE DA PRODUÇÃO
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <InputFilterComponent
                  placeholder="USUÁRIO"
                  value={production?.affiliate?.name ?? ""}
                  disabled
                />
              </Grid>
              <Grid item xs={6}>
                <InputFilterComponent
                  placeholder="EMAIL"
                  value={production?.affiliate?.email ?? ""}
                  disabled
                />
              </Grid>
              <Grid item xs={6}>
                <InputFilterComponent
                  placeholder="TELEFONE"
                  value={production?.affiliate?.phone ?? ""}
                  disabled
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <InputFilterComponent
              placeholder="OBJETIVO"
              value={production?.step ?? ""}
              disabled
            />
          </Grid>
          <Grid item xs={12}>
            <InputFilterComponent
              placeholder="DATA"
              value={production?.eventDate ?? ""}
              disabled
            />
          </Grid>
          {!!production && !!production.observation && (
            <Grid item xs={12}>
              <InputFilterComponent
                placeholder="OBSERVAÇÕES"
                value={production?.observation ?? ""}
                multiline={true}
                minRows={7}
                disabled
              />
            </Grid>
          )}

          {!!production && hasMetadata && <SectionTitle title="FORMULÁRIO" />}
          {!!production && hasMetadata && (
            <Grid item xs={12} style={{ marginTop: "1vh" }}>
              <Grid container spacing={2}>
                {Object.entries(JSON.parse(production.metadata)).map(
                  (field) => {
                    if (typeof field[1] === "string") {
                      const label =
                        field[0].charAt(0).toUpperCase() + field[0].slice(1);

                      return (
                        <Grid item key={field[0]} xs={6}>
                          <InputFilterComponent
                            placeholder={label}
                            value={field[1]}
                            disabled
                            onClick={() => handleCopyValue(label, field[1])}
                          />
                        </Grid>
                      );
                    }
                  }
                )}
              </Grid>
            </Grid>
          )}

          {!!production && hasSelfRegistration && <SectionTitle title="LINK" />}
          {!!production && hasSelfRegistration && (
            <Grid item xs={12} style={{ marginTop: "1vh" }}>
              <Grid item xs={12}>
                <InputFilterComponent
                  placeholder="Link do usuário"
                  value={production?.linkCpr ?? ""}
                  disabled
                  onClick={() =>
                    handleCopyValue("Link do usuário", production.linkCpr)
                  }
                />
              </Grid>
            </Grid>
          )}

          {!!production && hasProductsMetadata && (
            <SectionTitle title="PRODUTOS" />
          )}
          {!!production && hasProductsMetadata && (
            <Grid item xs={12} style={{ marginTop: "1vh" }}>
              {Object.entries(JSON.parse(production.metadata)).map((field) => {
                if (typeof field[1] === "object" && field[0] === "carrinho") {
                  return field[1].map((item) => {
                    return (
                      <Grid container spacing={1} key={item.produto}>
                        <Grid item xs={6}>
                          <InputFilterComponent
                            placeholder="Código do produto"
                            value={item.produto}
                            disabled
                            onClick={() =>
                              handleCopyValue("Código do produto", item.produto)
                            }
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <InputFilterComponent
                            placeholder="Quantidade"
                            value={item.quantidade}
                            disabled
                            onClick={() =>
                              handleCopyValue("Quantidade", item.quantidade)
                            }
                          />
                        </Grid>
                      </Grid>
                    );
                  });
                }
              })}
            </Grid>
          )}

          <Grid
            container
            spacing={2}
            style={{ justifyContent: "end", marginTop: "2vh" }}
          >
            {!!production && (
              <Grid item xs={3}>
                <ButtonComponent
                  label="OBSERVAÇÕES"
                  backgroundColor="#774E98"
                  color="white"
                  fullWidth
                  height="5vh"
                  onClick={() =>
                    openDialog(
                      "set-observations-dialog",
                      production.observation
                    )
                  }
                />
              </Grid>
            )}
            {!!production && hasSelfRegistration && (
              <Grid item xs={3}>
                <ButtonComponent
                  label="ENVIAR LINK"
                  backgroundColor="#774E98"
                  color="white"
                  fullWidth
                  height="5vh"
                  onClick={() =>
                    openDialog("send-link-dialog", production.linkCpr)
                  }
                />
              </Grid>
            )}
            {!!production && production.situation != "REPROVADA" && (
              <Grid item xs={3}>
                <ButtonComponent
                  label="REPROVAR"
                  backgroundColor="#858585"
                  color="white"
                  fullWidth
                  height="5vh"
                  onClick={() => onChangeSituation({ situation: "REPROVADA" })}
                />
              </Grid>
            )}
            {!!production && production.situation != "APROVADA" && (
              <Grid item xs={3}>
                <ButtonComponent
                  label="APROVAR"
                  backgroundColor="#774E98"
                  color="white"
                  fullWidth
                  height="5vh"
                  onClick={() => openDialog("approve-production-dialog")}
                />
              </Grid>
            )}
          </Grid>
        </Grid>
      </div>
    </GenericDialogComponent>
  );
};

export default ProductionDetailDialogComponent;
