import httpClient from "./axios.config";

class DashboardService {
  getCampaignVsCopiedLinks = () => {
    return new Promise((resolve, reject) => {
      httpClient
        .get("/dashboard/campanhaVsLinksCopiados")
        .then((response) => {
          const chartResponse = response.data.response;
          resolve(chartResponse);
        })
        .catch((error) =>
          reject(
            error.response && error.response.data && error.response.data.message
              ? error.response.data.message
              : "Erro inesperado, tente novamente mais tarde."
          )
        );
    });
  };

  getCampaignVsSolicitedLinks = () => {
    return new Promise((resolve, reject) => {
      httpClient
        .get("/dashboard/campanhaVsLinksSolicitados")
        .then((response) => {
          const chartResponse = response.data.response;
          resolve(chartResponse);
        })
        .catch((error) =>
          reject(
            error.response && error.response.data && error.response.data.message
              ? error.response.data.message
              : "Erro inesperado, tente novamente mais tarde."
          )
        );
    });
  };

  getCampaignVsAttachedLinks = () => {
    return new Promise((resolve, reject) => {
      httpClient
        .get("/dashboard/campanhaVsLinksFornecidos")
        .then((response) => {
          const chartResponse = response.data.response;
          resolve(chartResponse);
        })
        .catch((error) =>
          reject(
            error.response && error.response.data && error.response.data.message
              ? error.response.data.message
              : "Erro inesperado, tente novamente mais tarde."
          )
        );
    });
  };

  getCampaignVsLinksCPR = (query) => {
    return new Promise((resolve, reject) => {
      httpClient
        .get("/dashboard/campanhaVsLinksCPR", { params: query })
        .then((response) => {
          const chartResponse = response.data.response;
          resolve(chartResponse);
        })
        .catch((error) =>
          reject(
            error.response && error.response.data && error.response.data.message
              ? error.response.data.message
              : "Erro inesperado, tente novamente mais tarde."
          )
        );
    });
  };

  getUserVsRegisterDate = (query) => {
    return new Promise((resolve, reject) => {
      httpClient
        .get("/dashboard/usuarioVsCadastro", { params: query })
        .then((response) => {
          const chartResponse = response.data.response;
          resolve(chartResponse);
        })
        .catch((error) =>
          reject(
            error.response && error.response.data && error.response.data.message
              ? error.response.data.message
              : "Erro inesperado, tente novamente mais tarde."
          )
        );
    });
  };

  getCampaignVsIntaraction = (query) => {
    return new Promise((resolve, reject) => {
      httpClient
        .get("/dashboard/campanhaVsInteracao", { params: query })
        .then((response) => {
          const chartResponse = response.data.response;
          resolve(chartResponse);
        })
        .catch((error) =>
          reject(
            error.response && error.response.data && error.response.data.message
              ? error.response.data.message
              : "Erro inesperado, tente novamente mais tarde."
          )
        );
    });
  };

  getRentals = (query) => {
    return new Promise((resolve, reject) => {
      httpClient
        .get("/dashboard/rentabilidade", { params: query })
        .then((response) => {
          const chartResponse = response.data.response;
          resolve(chartResponse);
        })
        .catch((error) =>
          reject(
            error.response && error.response.data && error.response.data.message
              ? error.response.data.message
              : "Erro inesperado, tente novamente mais tarde."
          )
        );
    });
  };

  getProposals = (query) => {
    return new Promise((resolve, reject) => {
      httpClient
        .get("/dashboard/propostas", { params: query })
        .then((response) => {
          const chartResponse = response.data.response;
          resolve(chartResponse);
        })
        .catch((error) =>
          reject(
            error.response && error.response.data && error.response.data.message
              ? error.response.data.message
              : "Erro inesperado, tente novamente mais tarde."
          )
        );
    });
  };

  getReportVsStatus = (query) => {
    return new Promise((resolve, reject) => {
      httpClient
        .get("/dashboard/producaoVsStatus", { params: query })
        .then((response) => {
          const chartResponse = response.data.response;
          resolve(chartResponse);
        })
        .catch((error) =>
          reject(
            error.response && error.response.data && error.response.data.message
              ? error.response.data.message
              : "Erro inesperado, tente novamente mais tarde."
          )
        );
    });
  };

  getCounters = () => {
    return new Promise((resolve, reject) => {
      httpClient
        .get("/dashboard/contadores")
        .then((response) => {
          const countersResponse = response.data.response;
          resolve(countersResponse);
        })
        .catch((error) =>
          reject(
            error.response && error.response.data && error.response.data.message
              ? error.response.data.message
              : "Erro inesperado, tente novamente mais tarde."
          )
        );
    });
  };
}

export default new DashboardService();
