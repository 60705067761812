import Button from "@mui/material/Button/Button";
import styled from "@mui/material/styles/styled";

const StyledButton = styled(Button)({
  textTransform: "none",
  borderRadius: "30px",
  fontWeight: "700",
  fontSize: "1.46vh",
  height: "6vh",
  paddingInline: "1vw",
});

const IconButtonComponent = ({
  icon,
  type,
  disabled,
  onClick,
  backgroundColor,
  height,
  borderRadius,
}) => {
  return (
    <StyledButton
      variant="contained"
      disabled={disabled}
      disableElevation
      disableRipple
      disableFocusRipple
      disableTouchRipple
      type={type}
      onClick={onClick}
      style={{
        backgroundColor: backgroundColor ?? "white",
        height: height ?? "6vh",
        borderRadius: borderRadius ?? "30px",
      }}
    >
      {icon}
    </StyledButton>
  );
};

export default IconButtonComponent;
