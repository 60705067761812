import React from "react";
import { campaignService } from "../../../../../infrastructure/services";
import { useAlert, useAuth } from "../../../../providers";
import { useIsMounted } from "../../../../hooks";
import { useNavigate } from "react-router-dom";

const useSearchBarController = () => {
  const { showError } = useAlert();
  const isMounted = useIsMounted();
  const { user } = useAuth();
  const { hasPermission } = useAuth();
  const navigate = useNavigate();

  const PAGINA = "Páginas";
  const CAMPANHA = "Campanha";

  const [nameFilter, setNameFilter] = React.useState(null);
  const [list, setList] = React.useState([
    { group: PAGINA, name: "Início", url: "/home" },
    { group: PAGINA, name: "Perfil", url: "/perfil" },
    { group: PAGINA, name: "Afiliados", url: "/afiliados" },
    { group: PAGINA, name: "Relatórios", url: "/relatorios" },
    { group: PAGINA, name: "Campanhas", url: "/campanhas" },
    { group: PAGINA, name: "Financeiro", url: "/financeiro" },
    { group: PAGINA, name: "Pagamentos", url: "/admin/pagamentos" },
    { group: PAGINA, name: "Gestão de usuários", url: "/admin/usuarios" },
    { group: PAGINA, name: "Gestão de campanhas", url: "/admin/campanhas" },
    {
      group: PAGINA,
      name: "Gestão de anunciantes",
      url: "/admin/anunciantes",
    },
    { group: PAGINA, name: "Gestão de categorias", url: "/admin/categorias" },
    {
      group: PAGINA,
      name: "Gestão de notificações",
      url: "/admin/notificacoes",
    },
    {
      group: PAGINA,
      name: "Gestão de perfis de afiliados",
      url: "/admin/perfis",
    },
    { group: PAGINA, name: "Dashboard", url: "/dashboard" },
    { group: PAGINA, name: "Dúvidas", url: "/duvidas" },
    {
      group: PAGINA,
      name: "Licenciamento",
      url: "/licenciamento",
    },
  ]);

  const redirectUrl = (value) => {
    setNameFilter(null);

    if (value !== null && value !== "" && value.hasOwnProperty("url")) {
      navigate(value.url);

      if (
        value.group === CAMPANHA &&
        value.url.includes(window.location.pathname)
      ) {
        window.location.reload();
      }
    }
  };

  React.useEffect(() => {
    if (user != null) {
      campaignService
        .getUserCampaigns()
        .then((response) => {
          if (isMounted.current) {
            setList(
              list.concat(
                response.map((campaign) => ({
                  group: CAMPANHA,
                  name: campaign.name,
                  url: "/campanhas/" + parseInt(campaign.id),
                }))
              )
            );
          }
        })
        .catch(showError);
    }
  }, [user]);

  const filter = () => {
    return removerRotaAtual().filter((e) => {
      return e.group === CAMPANHA ? true : hasPermission(e.url);
    });
  };

  const removerRotaAtual = () => {
    return list
      .filter((e) => e.url !== window.location.pathname)
      .sort((a, b) => {
        const groupComparison = a.group.localeCompare(b.group);

        if (groupComparison === 0) {
          return a.name.localeCompare(b.name);
        }

        return groupComparison;
      });
  };

  return {
    list: filter(),
    nameFilter,
    redirectUrl,
  };
};

export default useSearchBarController;
