import Divider from "@mui/material/Divider/Divider";
import Grid from "@mui/material/Grid/Grid";
import Typography from "@mui/material/Typography/Typography";

import {
  AutocompleteComponent,
  DatapickerComponent,
  InputFilterComponent,
} from "../../../../../components";

const LeadSectionComponent = ({
  control,
  isLoading,
  userOptions,
  campaingsOptions,
}) => {
  return (
    <div>
      <Typography
        style={{
          fontWeight: "600",
          fontSize: "1.75vh",
          color: "#5C5C5C",
        }}
      >
        INFORMAÇÕES DA PROPOSTA
      </Typography>
      <Divider style={{ marginBottom: "2vh" }} />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <AutocompleteComponent
                name="affiliated"
                control={control}
                label="AFILIADO"
                acessorLabel="label"
                acessorValue="id"
                options={userOptions}
                disabled={isLoading}
                isLarge={true}
              />
            </Grid>
            <Grid item xs={4}>
              <AutocompleteComponent
                name="campaign"
                control={control}
                label="CAMPANHA"
                acessorLabel="label"
                acessorValue="id"
                options={campaingsOptions}
                disabled={isLoading}
              />
            </Grid>
            <Grid item xs={4}>
              <InputFilterComponent
                name="proposalType"
                control={control}
                label="TIPO DE PROPOSTA"
                placeholder="TIPO DE PROPOSTA"
                disabled={isLoading}
              />
            </Grid>
            <Grid item xs={4}>
              <DatapickerComponent
                name="eventDate"
                control={control}
                label="DATA DA PROPOSTA"
                disabled={isLoading}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default LeadSectionComponent;
