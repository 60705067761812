import Grid from "@mui/material/Grid/Grid";

import { BodyComponent, ButtonComponent } from "../../../../components";
import useAdminProposalFormController from "./admin-proposal-form.controller";
import ClientSectionComponent from "./components/client-section.component";
import LeadSectionComponent from "./components/lead-section.component";
import ValuesSectionComponent from "./components/values-section.component";
import CommissionSectionComponent from "./components/commission-section.component";
import AdminApproveProposalFormDialogComponent from "./components/admin-approve-proposal-form-dialog.component";
import { useParams } from "react-router-dom";

const AdminProposalFormPage = () => {
  const { id: proposalId } = useParams();
  const {
    control,
    onSubmit,
    isToEdit,
    isLoading,
    onDeleteProposal,
    userOptions,
    campaingsOptions,
    bankOptions,
    onApproveProposal,
    onRepproveProposal,
    onSubmitApproveProposal,
    onSubmitRepproveProposal,
    situation,
    commissionTypeOptions,
    affiliatedCommissionType,
    houseCommissionType,
  } = useAdminProposalFormController({ proposalId });

  return (
    <BodyComponent>
      <AdminApproveProposalFormDialogComponent
        onApprove={onSubmitApproveProposal}
        onRepprove={onSubmitRepproveProposal}
      />

      <Grid component="form" onSubmit={onSubmit} container>
        <Grid item xs={12} style={{ marginBottom: "4vh" }}>
          <ClientSectionComponent
            control={control}
            isLoading={isLoading}
            situation={situation}
            isToEdit={isToEdit}
          />
        </Grid>

        <Grid item xs={12} style={{ marginBottom: "4vh" }}>
          <LeadSectionComponent
            control={control}
            isLoading={isLoading}
            userOptions={userOptions}
            campaingsOptions={campaingsOptions}
          />
        </Grid>

        <Grid item xs={12} style={{ marginBottom: "4vh" }}>
          <ValuesSectionComponent
            control={control}
            isLoading={isLoading}
            bankOptions={bankOptions}
          />
        </Grid>

        <Grid item xs={12} style={{ marginBottom: "4vh" }}>
          <CommissionSectionComponent
            control={control}
            isLoading={isLoading}
            commissionTypeOptions={commissionTypeOptions}
            affiliatedCommissionType={affiliatedCommissionType}
            houseCommissionType={houseCommissionType}
          />
        </Grid>

        <Grid item xs={12} style={{ marginBottom: "4vh" }}>
          <div style={{ display: "flex", justifyContent: "end" }}>
            {isToEdit && situation != "APROVADO" && (
              <div style={{ marginLeft: "1vw" }}>
                <ButtonComponent
                  label={isLoading == false ? "APROVAR" : "CARREGANDO..."}
                  backgroundColor="#40215F"
                  color="white"
                  size="small"
                  height="5vh"
                  onClick={onApproveProposal}
                  disabled={isLoading}
                />
              </div>
            )}
            {isToEdit && situation != "REPROVADO" && (
              <div style={{ marginLeft: "1vw" }}>
                <ButtonComponent
                  label={isLoading == false ? "REPROVAR" : "CARREGANDO..."}
                  backgroundColor="#858585"
                  color="white"
                  size="small"
                  height="5vh"
                  onClick={onRepproveProposal}
                  disabled={isLoading}
                />
              </div>
            )}
            <div style={{ marginLeft: "1vw" }}>
              <ButtonComponent
                label={
                  isLoading == false
                    ? isToEdit
                      ? "EDITAR"
                      : "SUBIR"
                    : "CARREGANDO..."
                }
                backgroundColor="#40215F"
                color="white"
                size="small"
                height="5vh"
                type="submit"
                disabled={isLoading}
              />
            </div>
            {isToEdit && (
              <div style={{ marginLeft: "1vw" }}>
                <ButtonComponent
                  label={isLoading == false ? "EXCLUIR" : "CARREGANDO..."}
                  backgroundColor="#858585"
                  color="white"
                  size="small"
                  height="5vh"
                  onClick={onDeleteProposal}
                  disabled={isLoading}
                />
              </div>
            )}
          </div>
        </Grid>
      </Grid>
    </BodyComponent>
  );
};

export default AdminProposalFormPage;
