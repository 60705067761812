import httpClient from "./axios.config";

class TypePaymentService {
  getTypePayment = () => {
    return new Promise((resolve, reject) => {
      httpClient
        .get("/publico/listaTipoPagamento")
        .then((response) => {
          const typePaymentResponse = response.data.response;

          const typePayments = typePaymentResponse.map((category) => ({
            id: parseInt(category.id),
            name: category.nome,
          }));

          resolve(typePayments);
        })
        .catch((error) =>
          reject(
            error.response && error.response.data && error.response.data.message
              ? error.response.data.message
              : "Erro inesperado, tente novamente mais tarde."
          )
        );
    });
  };
}

export default new TypePaymentService();
