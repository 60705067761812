import React from "react";
import Badge from "@mui/material/Badge/Badge";
import Divider from "@mui/material/Divider/Divider";
import Paper from "@mui/material/Paper/Paper";
import Typography from "@mui/material/Typography/Typography";
import makeStyles from "@mui/styles/makeStyles/makeStyles";

import CloseIcon from "@mui/icons-material/Close";
import GetAppOutlinedIcon from "@mui/icons-material/GetAppOutlined";
import _ from "lodash";
import { saveAs } from "file-saver";

import { useResponsive } from "../../providers";
import ButtonPickerComponent from "./button-picker.component";

const useStyles = makeStyles(() => ({
  cardTitle: {
    marginTop: "4vh",
    marginBottom: "4vh",
    fontWeight: "700",
    fontSize: "1.75vh",
    color: "#5C5C5C",
  },
  mediaRow: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    borderRadius: "100px",
    marginTop: "4vh",
    marginBottom: "4vh",
    marginInline: "3vw",
    height: "11vh",
  },
  mediaRowItem: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  mediaObject: {
    maxWidth: "100%",
    maxHeight: "100%",
    cursor: "pointer",
  },
  closeButton: {
    "&:hover": {
      cursor: "pointer",
    },
  },
}));

const ImageVideoPdfListCardComponent = ({
  title,
  mediaList,
  addMedia,
  removeMedia,
  isDynamic,
  width,
  disabled,
  name,
}) => {
  const classes = useStyles();
  const { isDesktop } = useResponsive();

  function getFileTypeFromUrl(url) {
    const parts = url.split("/");
    const fileName = parts[parts.length - 1];
    return fileName.split(".").pop();
  }

  return (
    <Paper
      variant="outlined"
      style={{
        width: width ?? "71vw",
        borderRadius: "10px",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginInline: "4vh",
        }}
      >
        <Typography className={classes.cardTitle}>{title}</Typography>{" "}
        {isDynamic == true && (
          <ButtonPickerComponent
            label="ADICIONAR"
            backgroundColor="#40215F"
            color="white"
            acceptFile="image/png,image/jpeg,application/pdf,video/mp4"
            size="small"
            height="5vh"
            onUpload={disabled == false ? (data) => addMedia(data) : null}
            name={name ?? "media"}
            disabled={disabled}
          />
        )}
      </div>
      <Divider />
      {mediaList.length > 0 ? (
        _.chunk(mediaList, 4).map((row, i) => (
          <Paper
            key={i}
            variant="outlined"
            className={classes.mediaRow}
            style={{
              display: isDesktop ? "flex" : "column",
              justifyContent: "space-around",
              alignItems: "center",
              paddingInline: isDesktop ? "3vw" : "6vw",
            }}
          >
            {row.map((media, i) => {
              const MediaElement = () => {
                const handleClick = () => {
                  let url = media.url ?? media.caminho;

                  const nameSplit = url.split("/");
                  const duplicateName = nameSplit.pop();
                  saveAs(url, duplicateName);
                };

                return (
                  <div className={classes.mediaRowItem}>
                    {(getFileTypeFromUrl(media.url ?? media.caminho) ===
                      "jpg" ||
                      getFileTypeFromUrl(media.url ?? media.caminho) ===
                        "jpeg" ||
                      getFileTypeFromUrl(media.url ?? media.caminho) ===
                        "png") && (
                      <img
                        src={media.url ?? media.caminho}
                        alt={media.alt}
                        style={{ maxHeight: isDesktop ? "15vh" : "10vh" }}
                        className={classes.mediaObject}
                      />
                    )}
                    {getFileTypeFromUrl(media.url ?? media.caminho) ===
                      "pdf" && (
                      <iframe
                        src={media.url ?? media.caminho}
                        title="PDF"
                        style={{ maxHeight: isDesktop ? "15vh" : "10vh" }}
                        className={classes.mediaObject}
                      />
                    )}
                    {getFileTypeFromUrl(media.url ?? media.caminho) ===
                      "mp4" && (
                      <video
                        controls
                        src={media.url ?? media.caminho}
                        style={{ maxHeight: isDesktop ? "15vh" : "10vh" }}
                        className={classes.mediaObject}
                      />
                    )}
                    {isDynamic ? (
                      <Badge
                        overlap="rectangular"
                        className={classes.closeButton}
                        onClick={
                          disabled == false ? () => removeMedia(media.id) : null
                        }
                        badgeContent={
                          <CloseIcon
                            style={{ color: "white", fontSize: "1.5vh" }}
                          />
                        }
                        color="primary"
                      />
                    ) : (
                      <Badge
                        overlap="rectangular"
                        className={classes.closeButton}
                        onClick={handleClick}
                        badgeContent={
                          <GetAppOutlinedIcon
                            style={{ color: "white", fontSize: "1.5vh" }}
                          />
                        }
                        color="primary"
                      />
                    )}
                  </div>
                );
              };

              return <MediaElement key={i} />;
            })}
          </Paper>
        ))
      ) : (
        <div style={{ height: "4vh" }}></div>
      )}
    </Paper>
  );
};

export default ImageVideoPdfListCardComponent;
