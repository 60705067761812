import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import Typography from "@mui/material/Typography/Typography";
import makeStyles from "@mui/styles/makeStyles/makeStyles";

import {
  ButtonComponent,
  GenericDialogComponent,
  InputFilterComponent,
} from "../../../../components";
import { useDialog } from "../../../../providers";

const useStyles = makeStyles(() => ({
  content: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: "1vh",
    marginBottom: "2vh",
  },
}));

const SetObservationDilaogComponent = ({ onSetObservations }) => {
  const classes = useStyles();
  const { metadata: observation } = useDialog();

  const schema = yup.object().shape({
    observations: yup.string().required("Campo obrigatório"),
  });

  const { control, handleSubmit, reset } = useForm({
    defaultValues: {
      observations: "",
    },
    resolver: yupResolver(schema),
  });

  const onSubmit = handleSubmit(({ observations }) => {
    onSetObservations(observations);
  });

  useEffect(() => {
    reset({ observations: observation ?? "" });
  }, [observation]);

  return (
    <GenericDialogComponent name="set-observations-dialog">
      <form onSubmit={onSubmit} className={classes.content}>
        <Typography
          style={{
            marginBottom: "6vh",
            fontWeight: "700",
            fontSize: "1.95vh",
            color: "#40215F",
          }}
        >
          ANEXAR OBSERVAÇÕES
        </Typography>

        <InputFilterComponent
          placeholder="OBSERVAÇÕES"
          name={"observations"}
          control={control}
          multiline={true}
          minRows={7}
        />
        <div style={{ marginBottom: "3vh" }}></div>

        <ButtonComponent
          label="ANEXAR"
          backgroundColor="#774E98"
          color="white"
          height="5vh"
          type="submit"
        />
      </form>
    </GenericDialogComponent>
  );
};

export default SetObservationDilaogComponent;
