import { useRef } from "react";
import Box from "@mui/material/Box/Box";

import DefaultSectionComponent from "./components/default-section/default-section.component";
import CampaignsSectionComponent from "./components/campaigns-section.component";
import AboutSectionComponent from "./components/about-section/about-section.component";
import HelpSectionComponent from "./components/help-section/help-section.component";
import { FooterComponent } from "../../../components";

const LandPagePage = () => {
  const aboutRef = useRef(null);
  const campaignsRef = useRef(null);
  const helpRef = useRef(null);

  const handleSectionClick = (ref) => {
    ref.current.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <Box>
      <DefaultSectionComponent
        handleSectionClick={handleSectionClick}
        aboutRef={aboutRef}
        helpRef={helpRef}
        campaignsRef={campaignsRef}
      />
      <AboutSectionComponent ref={aboutRef} />
      <CampaignsSectionComponent ref={campaignsRef} />
      <HelpSectionComponent ref={helpRef} />
      <FooterComponent />
    </Box>
  );
};

export default LandPagePage;
