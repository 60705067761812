import InputAdornment from "@mui/material/InputAdornment/InputAdornment";
import TextField from "@mui/material/TextField/TextField";
import makeStyles from "@mui/styles/makeStyles/makeStyles";

import ButtonComponent from "./button.component";
import { useAlert } from "../../providers";

const useStyles = makeStyles(() => ({
  input: {
    "& .MuiInputLabel-root": {
      fontWeight: "bold",
      fontSize: "1.46vh",
      color: "#5C5C5C",
    },
    "& .MuiOutlinedInput-root": {
      color: "#5C5C5C",
      fontWeight: "400",
      fontSize: "1.46vh",
      "& fieldset": {
        borderRadius: "10px",
        borderColor: "#C4C4C4",
      },
      "&:hover fieldset": {
        borderRadius: "10px",
        borderColor: "#C4C4C4",
      },
      "&.Mui-focused fieldset": {
        borderRadius: "10px",
        borderColor: "#C4C4C4",
      },
    },
  },
}));

const InputCopyComponent = ({ label, value, onClick, width, disabled }) => {
  const classes = useStyles();
  const { showInfo } = useAlert();

  const onCopy = () => {
    navigator.clipboard.writeText(value);
    showInfo("O Link foi copiado para a área de transferências!");
  };

  return (
    <TextField
      variant="outlined"
      label={label}
      fullWidth
      className={classes.input}
      style={{ width: width ?? "46.87vw" }}
      value={value}
      disabled
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <ButtonComponent
              label="COPIAR"
              height="4vh"
              backgroundColor="#C4C4C4"
              color="white"
              borderRadius="10px"
              disabled={disabled}
              onClick={() => onClick(onCopy)}
            />
          </InputAdornment>
        ),
      }}
    />
  );
};

export default InputCopyComponent;
