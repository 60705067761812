import { useState } from "react";
import Paper from "@mui/material/Paper/Paper";
import Divider from "@mui/material/Divider/Divider";
import Grid from "@mui/material/Grid/Grid";
import Typography from "@mui/material/Typography/Typography";
import makeStyles from "@mui/styles/makeStyles/makeStyles";

import BarChartV2Component from "./bar-chart-v2.component";
import { useAlert } from "../../../../providers";
import { dashboardService } from "../../../../../infrastructure/services";
import moment from "moment";
import FilterChartComponent from "./filter-chart.component";
import dayjs from "dayjs";

const useStyles = makeStyles(() => ({
  card: {
    width: "100%",
    borderRadius: "10px",
    marginBottom: "4vh",
  },
  cardTitle: {
    marginInline: "4vh",
    marginTop: "4vh",
    marginBottom: "4vh",
    fontWeight: "700",
    fontSize: "1.75vh",
    color: "#5C5C5C",
  },
}));

const ProposalsChartComponent = () => {
  const classes = useStyles();
  const { showError } = useAlert();
  const [proposals, setProposals] = useState(null);

  const fetchData = (query) => {
    dashboardService
      .getProposals(query)
      .then((response) => {
        let dataVariables = {};
        Object.keys(response).forEach((variableName) => {
          dataVariables[variableName] = Object.entries(
            response[variableName]
          ).map(([key, value]) => {
            return {
              key: dayjs(new Date(parseInt(key))).format("DD/MM/YYYY"),
              value: parseInt(value),
            };
          });
        });
        setProposals(dataVariables);
      })
      .catch(showError);
  };

  return (
    <Paper variant="outlined" className={classes.card}>
      <Grid container style={{ alignItems: "center", padding: "1vh" }}>
        <Grid item xs={3}>
          <Typography className={classes.cardTitle}>PROPOSTAS</Typography>
        </Grid>
        <FilterChartComponent
          onSearch={fetchData}
          data={proposals}
          downloadName={"propostas"}
        />
      </Grid>
      <Divider />
      <div style={{ height: "34vh", marginBottom: "1vh", padding: "1vh" }}>
        <BarChartV2Component
          direction="xAxis"
          data={proposals}
          hasZoom={true}
        />
      </div>
    </Paper>
  );
};

export default ProposalsChartComponent;
