import httpClient from "./axios.config";

class AffiliatedProfileService {
  getProfiles = () => {
    return new Promise((resolve, reject) => {
      httpClient
        .get("/usuario/config")
        .then((response) => {
          const config = response.data.response;

          resolve(
            config.perfilSubUsuario.map((perfil) => ({
              id: parseInt(perfil.id),
              name: perfil.nome,
            }))
          );
        })
        .catch((error) =>
          reject(
            error.response && error.response.data && error.response.data.message
              ? error.response.data.message
              : "Erro inesperado, tente novamente mais tarde."
          )
        );
    });
  };

  createProfile = ({ name }) => {
    return new Promise((resolve, reject) => {
      httpClient
        .post("/perfilAfiliado/criarPerfilAfiliado", {
          perfilAfiliado: { nome: name },
        })
        .then(() => resolve())
        .catch((error) =>
          reject(
            error.response && error.response.data && error.response.data.message
              ? error.response.data.message
              : "Erro inesperado, tente novamente mais tarde."
          )
        );
    });
  };

  editProfile = ({ id, name }) => {
    return new Promise((resolve, reject) => {
      httpClient
        .put("/perfilAfiliado/editarPerfilAfiliado", {
          perfilAfiliado: { id, nome: name },
        })
        .then(() => resolve())
        .catch((error) =>
          reject(
            error.response && error.response.data && error.response.data.message
              ? error.response.data.message
              : "Erro inesperado, tente novamente mais tarde."
          )
        );
    });
  };

  deleteProfile = ({ id }) => {
    return new Promise((resolve, reject) => {
      httpClient
        .delete(
          "/perfilAfiliado/deletarPerfilAfiliado?idPerfilDeAfiliado=" + id
        )
        .then(() => resolve())
        .catch((error) =>
          reject(
            error.response && error.response.data && error.response.data.message
              ? error.response.data.message
              : "Erro inesperado, tente novamente mais tarde."
          )
        );
    });
  };
}

export default new AffiliatedProfileService();
