import Grid from "@mui/material/Grid/Grid";

import { BodyComponent } from "../../../../components";
import useAdminAdvertiserDetailController from "./admin-advertiser-detail.controller";
import BasicSectionComponent from "./components/basic-section.component";
import ProposalSectionComponent from "./components/proposal-section.component";
import SocialSectionComponent from "./components/social-section.component";
import { useParams } from "react-router-dom";

const AdminAdvertiserDetailPage = () => {
  const { id: advertiserId } = useParams();
  const { advertiser } = useAdminAdvertiserDetailController({ advertiserId });

  return (
    <BodyComponent>
      <Grid container>
        <Grid item xs={12} style={{ marginBottom: "4vh" }}>
          <BasicSectionComponent advertiser={advertiser} />
        </Grid>
        <Grid item xs={12} style={{ marginBottom: "4vh" }}>
          <SocialSectionComponent advertiser={advertiser} />
        </Grid>
        <Grid item xs={12} style={{ marginBottom: "30vh" }}>
          <ProposalSectionComponent advertiser={advertiser} />
        </Grid>
      </Grid>
    </BodyComponent>
  );
};

export default AdminAdvertiserDetailPage;
