import httpClient from "./axios.config";

class PixelService {
  criarPixel = ({ afiliado, campanha, eventos, codigo }) => {
    return new Promise((resolve, reject) => {
      httpClient
        .post("/pixel/pixel", {
          idUsuario: afiliado,
          idProduto: campanha,
          codigo: codigo,
          eventos: eventos.join(","),
        })
        .then((response) => {
          const pixel = response.data.response;

          resolve({
            id: parseInt(pixel.idPixel),
            afiliado: pixel.idUsuario,
            campanha: pixel.idProduto,
            codigo: pixel.codigo,
            eventos: pixel.eventos.split(","),
          });
        })
        .catch((error) =>
          reject(
            error.response && error.response.data && error.response.data.message
              ? error.response.data.message
              : "Erro inesperado, tente novamente mais tarde."
          )
        );
    });
  };

  atualizarPixel = ({ id, afiliado, campanha, eventos, codigo }) => {
    return new Promise((resolve, reject) => {
      httpClient
        .put("/pixel/pixel", {
          idPixel: id,
          idUsuario: afiliado,
          idProduto: campanha,
          codigo: codigo,
          eventos: eventos.join(","),
        })
        .then((response) => {
          const pixel = response.data.response;

          resolve({
            id: parseInt(pixel.idPixel),
            afiliado: pixel.idUsuario,
            campanha: pixel.idProduto,
            codigo: pixel.codigo,
            eventos: pixel.eventos.split(","),
          });
        })
        .catch((error) =>
          reject(
            error.response && error.response.data && error.response.data.message
              ? error.response.data.message
              : "Erro inesperado, tente novamente mais tarde."
          )
        );
    });
  };

  deletarPixel = ({ id }) => {
    return new Promise((resolve, reject) => {
      httpClient
        .delete("/pixel/pixel?idPixel=" + id)
        .then(() => resolve())
        .catch((error) =>
          reject(
            error.response && error.response.data && error.response.data.message
              ? error.response.data.message
              : "Erro inesperado, tente novamente mais tarde."
          )
        );
    });
  };

  buscarPixel = ({ idUsuario, idProduto }) => {
    return new Promise((resolve, reject) => {
      httpClient
        .get("/pixel/pixel?idProduto=" + idProduto + "&idUsuario=" + idUsuario)
        .then((response) => {
          const pixel = response.data.response;

          resolve({
            id: parseInt(pixel.idPixel),
            afiliado: pixel.idUsuario,
            campanha: pixel.idProduto,
            codigo: pixel.codigo,
            eventos: pixel.eventos.split(","),
          });
        })
        .catch((error) => resolve(null));
    });
  };
}

export default new PixelService();
