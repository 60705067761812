import React from "react";
import makeStyles from "@mui/styles/makeStyles/makeStyles";

import { GenericDialogComponent } from "../../../../components";
import { useDialog, useResponsive } from "../../../../providers";

const useStyles = makeStyles(() => ({
  content: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: "1vh",
    marginBottom: "2vh",
    overflow: "hidden",
  },
}));

const YoutubeDialogComponent = () => {
  const classes = useStyles();
  const { isDesktop } = useResponsive();

  const { metadata: dados } = useDialog();

  return (
    <GenericDialogComponent name="youtube-dialog">
      <div
        className={classes.content}
        style={{ height: isDesktop ? "35vh" : "25vh" }}
      >
        <iframe
          xs={12}
          width={"100%"}
          height={"100%"}
          src={dados?.link ?? ""}
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
        ></iframe>
      </div>
    </GenericDialogComponent>
  );
};

export default YoutubeDialogComponent;
