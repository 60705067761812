import TextField from "@mui/material/TextField/TextField";
import styled from "@mui/material/styles/styled";
import { Controller } from "react-hook-form";

const StyledTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    color: "white",
    height: "7vh",
    fontWeight: "600",
    fontSize: "1.75vh",
    "& fieldset": {
      borderRadius: "35px",
      borderColor: "#f0f4f8",
    },
    "&:hover fieldset": {
      borderRadius: "35px",
      borderColor: "#f0f4f8",
    },
    "&.Mui-focused fieldset": {
      borderRadius: "35px",
      borderColor: "#d8dbdf",
    },
    "&.Mui-error fieldset": {
      borderRadius: "35px",
      borderColor: "red",
    },
    "&.Mui-disabled fieldset": {
      borderRadius: "35px",
      borderColor: "#F0F4F8",
    },
  },
});

const InputGradientComponent = ({
  name,
  label,
  placeholder,
  control,
  disabled,
  type,
  textHelp,
}) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, formState }) => (
        <StyledTextField
          {...field}
          variant="outlined"
          margin="normal"
          fullWidth
          type={type}
          disabled={disabled || formState.isSubmitting}
          label={label}
          placeholder={placeholder}
          error={!!formState.errors[name]}
          helperText={
            !!formState.errors[name]
              ? formState.errors[name]?.message
              : textHelp
          }
          FormHelperTextProps={{
            style: {
              color: !!formState.errors[name] ? "red" : "white",
            },
          }}
          style={{
            backgroundColor: "inherit",
            color: "white",
          }}
          InputLabelProps={{
            style: {
              color: !!formState.errors[name] ? "red" : "inherit",
            },
          }}
          InputProps={{
            inputProps: {
              style: {
                textAlign: "center",
              },
            },
          }}
          inputProps={{
            autocomplete: "new-password",
            form: {
              autocomplete: "off",
            },
          }}
        />
      )}
    />
  );
};

export default InputGradientComponent;
