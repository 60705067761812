import Grid from "@mui/material/Grid/Grid";

import useAdminUserFormController from "./admin-user-form.controller";
import PermissionsSectionComponent from "./components/permissions-section.component";
import BasicSectionComponent from "./components/basic-section.component";
import { BodyComponent, ButtonComponent } from "../../../../components";
import PaymentSectionComponent from "./components/payment-section.component";
import AddressSectionComponent from "./components/address-section.component";
import MenuSectionComponent from "./components/menu-section.component";
import { useParams } from "react-router-dom";

const AdminUserFormPage = () => {
  const { id: userId } = useParams();
  const {
    control,
    userTypes,
    groupTypes,
    profileTypes,
    userLevels,
    genders,
    banks,
    bankAccountTypes,
    addressStates,
    addressCities,
    primaryUserOptions,
    onSubmit,
    withPrimary,
    isToEdit,
    situation,
    onToggleSituation,
    isLoading,
  } = useAdminUserFormController({ userId });

  return (
    <BodyComponent>
      <Grid component="form" onSubmit={onSubmit} container>
        <Grid item xs={12} style={{ marginBottom: "4vh" }}>
          <MenuSectionComponent
            situation={situation}
            onToggleSituation={onToggleSituation}
            isLoading={isLoading}
          />
        </Grid>
        <Grid item xs={12} style={{ marginBottom: "4vh" }}>
          <PermissionsSectionComponent
            control={control}
            userTypeOptions={userTypes}
            groupTypeOptions={groupTypes}
            profileTypeOptions={profileTypes}
            userLevelOptions={userLevels}
            primaryUserOptions={primaryUserOptions}
            withPrimary={withPrimary}
            isLoading={isLoading}
          />
        </Grid>
        <Grid item xs={12} style={{ marginBottom: "4vh" }}>
          <BasicSectionComponent
            control={control}
            genderOptions={genders}
            isLoading={isLoading}
          />
        </Grid>
        <Grid item xs={12} style={{ marginBottom: "4vh" }}>
          <PaymentSectionComponent
            control={control}
            bankOptions={banks}
            bankAccountTypeOptions={bankAccountTypes}
            isLoading={isLoading}
          />
        </Grid>
        <Grid item xs={12} style={{ marginBottom: "4vh" }}>
          <AddressSectionComponent
            control={control}
            addressStateOptions={addressStates}
            addressCityOptions={addressCities}
            isLoading={isLoading}
          />
        </Grid>
        <Grid item xs={12} style={{ marginBottom: "4vh", textAlign: "end" }}>
          <ButtonComponent
            label={
              isLoading == false
                ? isToEdit
                  ? "EDITAR USUÁRIO"
                  : "CRIAR USUÁRIO"
                : "CARREGANDO..."
            }
            backgroundColor="#40215F"
            color="white"
            size="small"
            height="5vh"
            type="submit"
            disabled={isLoading}
          />
        </Grid>
      </Grid>
    </BodyComponent>
  );
};

export default AdminUserFormPage;
