import { BodyComponent, TableComponent } from "../../../../components";
import useAdminAdvertisersController from "./admin-advertisers.controller";
import { Format } from "../../../../utils";
import { useNavigate } from "react-router-dom";

const AdminAdvertisersPage = () => {
  const navigate = useNavigate();
  const { advertisers } = useAdminAdvertisersController();

  return (
    <BodyComponent>
      <TableComponent
        columns={[
          {
            label: "PESSOA",
            acessor: "userName",
            width: "14vw",
          },
          {
            label: "EMPRESA",
            acessor: "companyName",
            width: "14vw",
          },
          {
            label: "TELEFONE",
            acessor: "phone",
            width: "13vw",
          },
          {
            label: "TIPO",
            acessor: "campaignType",
            width: "13vw",
          },
          {
            label: "PROPOSTA",
            acessor: "proposal",
            width: "9vw",
            formatText: (row) => {
              if (row["proposalType"] == "Preço Fixo") {
                return Format.toCurrency(row["proposal"]);
              } else {
                return row["proposal"] + "%";
              }
            },
          },
          {
            label: "TIPO PROPOSTA",
            acessor: "proposalType",
            width: "13vw",
          },
        ]}
        rows={advertisers}
        onClick={(row) => navigate("/admin/anunciantes/detalhe/" + row.id)}
      />

      <div style={{ height: "60vh" }}></div>
    </BodyComponent>
  );
};

export default AdminAdvertisersPage;
