import Grid from "@mui/material/Grid/Grid";
import green from "@mui/material/colors/green";
import red from "@mui/material/colors/red";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { useNavigate } from "react-router-dom";

import {
  BodyComponent,
  ButtonComponent,
  TableComponent,
} from "../../../../components";
import useAdminNotificationsController from "./admin-notifications.controller";
import LinkDialogComponent from "./components/link-dialog.component";
import { useDialog } from "../../../../providers";

const AdminNotificationsPage = () => {
  const navigate = useNavigate();
  const { preferences, linkForm, onSubmitLink } =
    useAdminNotificationsController();
  const { openDialog } = useDialog();

  return (
    <BodyComponent>
      <Grid
        container
        spacing={4}
        style={{ marginBottom: "2vh", width: "71vw", justifyContent: "end" }}
      >
        <Grid item>
          <ButtonComponent
            label="NOSSA COMUNIDADE LINK"
            backgroundColor="#40215F"
            color="white"
            size="small"
            height="4vh"
            onClick={() => openDialog("link-dialog")}
          />
        </Grid>
        <Grid item>
          <ButtonComponent
            label="EDITAR MODAL INICIAL"
            backgroundColor="#40215F"
            color="white"
            size="small"
            height="4vh"
            onClick={() => navigate("/admin/modais")}
          />
        </Grid>
        <Grid item>
          <ButtonComponent
            label="ENVIAR NOTIFICAÇÃO"
            backgroundColor="#40215F"
            color="white"
            size="small"
            height="4vh"
            onClick={() => navigate("/admin/notificacoes/formulario")}
          />
        </Grid>
      </Grid>
      <TableComponent
        columns={[
          { label: "NOME", acessor: "name" },
          { label: "TIPO DE ENVIO", acessor: "sendType" },
          { label: "GRUPO DE ENVIO", acessor: "sendGroup" },
          { label: "TIPO DE NOTIFICAÇÃO", acessor: "notificationType" },
          {
            label: "ATIVA",
            acessor: "isActive",
            width: "5vw",
            formatWidget: (row) => {
              if (row["isActive"] == 1) {
                return <CheckCircleIcon style={{ color: green[500] }} />;
              }
              return <HighlightOffIcon style={{ color: red[500] }} />;
            },
          },
        ]}
        onClick={(row) => navigate("/admin/notificacoes/formulario/" + row.id)}
        rows={preferences}
      />
      <div style={{ height: "60vh" }}></div>
      <LinkDialogComponent
        linkForm={linkForm}
        onSubmitLink={onSubmitLink}
      ></LinkDialogComponent>
    </BodyComponent>
  );
};

export default AdminNotificationsPage;
