import { useState } from "react";
import Collapse from "@mui/material/Collapse/Collapse";
import Grid from "@mui/material/Grid/Grid";
import Typography from "@mui/material/Typography/Typography";
import makeStyles from "@mui/styles/makeStyles/makeStyles";
import { useNavigate } from "react-router-dom";

import { Constants } from "../../../../utils";
import { useDialog } from "../../../../providers";
import ArrowToRightIcon from "../../../../../assets/icones/arrow-to-right.svg";

const useStyles = makeStyles(() => ({
  root: {
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "0.97vh",
    "&:hover": {
      backgroundColor: "#f0f0f0",
      cursor: "pointer",
    },
  },
  label: {
    fontWeight: "700",
    fontSize: "1.36vh",
    color: "#5C5C5C",
  },
}));

const SidebarItemComponent = ({
  label,
  iconAsset,
  path,
  dialogPath,
  paddingLeft,
  children,
  height,
  isOnlyIcon,
  paddingRight,
}) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { openDialog } = useDialog();
  const [expanded, setExpanded] = useState(false);

  const Field = () => {
    return (
      <Grid
        container
        className={classes.root}
        style={{
          paddingLeft:
            paddingLeft != undefined
              ? paddingLeft
              : isOnlyIcon
              ? "1.16vw"
              : "4.16vw",
          height: height ?? "3.9vh",
          paddingRight: paddingRight ?? "3.33vw",
        }}
        onClick={() => {
          if (!!children) {
            setExpanded(!expanded);
          } else {
            if (!!path) {
              navigate(path);
            } else {
              openDialog(dialogPath);
            }
          }
        }}
      >
        <Grid item>
          <Grid container style={{ alignItems: "center" }}>
            {!!iconAsset && (
              <Grid item style={{ marginRight: !isOnlyIcon ? "0.97vw" : 0 }}>
                <img src={iconAsset} />
              </Grid>
            )}
            {!isOnlyIcon && (
              <Grid item>
                <Typography className={classes.label}>{label}</Typography>
              </Grid>
            )}
          </Grid>
        </Grid>
        {!isOnlyIcon && dialogPath == null && (
          <Grid item>
            <img src={ArrowToRightIcon} />
          </Grid>
        )}
      </Grid>
    );
  };

  if (!!children) {
    return (
      <div>
        <Field />
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          {children}
        </Collapse>
      </div>
    );
  }
  return <Field />;
};

export default SidebarItemComponent;
