import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";

import {
  ButtonComponent,
  GenericDialogComponent,
} from "../../../../../components";
import { useDialog } from "../../../../../providers";
import { Carousel } from "react-responsive-carousel";

const ReceiptForPaymentsDialogComponent = ({ onConfirmPayUser }) => {
  const { metadata, isOpen } = useDialog();

  if (isOpen != "receipt-for-payments-dialog") {
    return <></>;
  }

  return (
    <GenericDialogComponent name="receipt-for-payments-dialog" maxWidth="lg">
      <div
        style={{
          marginTop: "4vh",
          marginBottom: "4vh",
        }}
      >
        <Carousel
          autoPlay={false}
          showArrows={true}
          showStatus={false}
          showThumbs={false}
        >
          {(Array.isArray(metadata ?? []) ? metadata : []).map((url, index) => {
            return (
              <div key={index} style={{ width: "100%", height: "60vh" }}>
                <iframe src={url} width="100%" height="100%"></iframe>
              </div>
            );
          })}
        </Carousel>
      </div>
      <div style={{ width: "100%", textAlign: "end" }}>
        <ButtonComponent
          label={
            !!metadata && metadata.length > 1
              ? "CONFIRMAR PAGAMENTOS"
              : "CONFIRMAR PAGAMENTO"
          }
          backgroundColor="#774E98"
          color="white"
          height="5vh"
          onClick={onConfirmPayUser}
        />
      </div>
    </GenericDialogComponent>
  );
};

export default ReceiptForPaymentsDialogComponent;
