import { useEffect, useState } from "react";
import { useIsMounted } from "../../../../hooks";
import { campaignCategoryService } from "../../../../../infrastructure/services";
import { useAlert, useDialog } from "../../../../providers";
import { useLoadingContext } from "../../../../context/loading.context";

const useAdminCategoriesController = () => {
  const isMounted = useIsMounted();
  const { openDialog, closeDialog } = useDialog();
  const { showError, showSuccess } = useAlert();
  const [categories, setCategories] = useState([]);
  const { setLoadingState } = useLoadingContext();

  useEffect(() => onFetch(), []);

  const onFetch = () => {
    setLoadingState(true);
    campaignCategoryService
      .getCategories()
      .then((response) => {
        if (isMounted.current) {
          setCategories(response);
        }
      })
      .catch(showError)
      .finally(() => {
        setLoadingState(false);
      });
  };

  const onOpenCreateForm = () => {
    openDialog("admin-category-form-dialog");
  };

  const onOpenEditForm = (category) => {
    openDialog("admin-category-form-dialog", category);
  };

  const onDelete = ({ id }) => {
    setLoadingState(true);
    campaignCategoryService
      .deleteCategory({ id })
      .then(() => {
        onFetch();
        closeDialog();
        showSuccess("Categoria exluída!");
      })
      .catch(showError)
      .finally(() => {
        setLoadingState(false);
      });
  };

  const onCreate = ({ name }) => {
    setLoadingState(true);
    campaignCategoryService
      .createCategory({ name })
      .then(() => {
        onFetch();
        closeDialog();
        showSuccess("Categoria criada!");
      })
      .catch(showError)
      .finally(() => {
        setLoadingState(false);
      });
  };

  const onEdit = ({ id, name }) => {
    setLoadingState(true);
    campaignCategoryService
      .editCategory({ id, name })
      .then(() => {
        onFetch();
        closeDialog();
        showSuccess("Categoria editada!");
      })
      .catch(showError)
      .finally(() => {
        setLoadingState(false);
      });
  };

  return {
    categories,
    onOpenCreateForm,
    onOpenEditForm,
    onDelete,
    onCreate,
    onEdit,
  };
};

export default useAdminCategoriesController;
