import { useNavigate } from "react-router-dom";
import { BodyComponent, TableComponent } from "../../../../components";
import useAdminProposalsController from "./admin-proposals.controller";
import AdminProposalsFilterComponent from "./components/admin-proposal-filter.component";

const AdminProposalsPage = () => {
  const navigate = useNavigate();
  const {
    proposals,
    count,
    page,
    rowsPerPage,
    handleChangePage,
    control,
    onSubmit,
    userOptions,
    campaignOptions,
    situationOptions,
    bankOptions,
    onDownloadSubmit,
    counters,
  } = useAdminProposalsController();

  return (
    <BodyComponent>
      <AdminProposalsFilterComponent
        control={control}
        onSubmit={onSubmit}
        userOptions={userOptions}
        campaignOptions={campaignOptions}
        situationOptions={situationOptions}
        bankOptions={bankOptions}
        onDownloadSubmit={onDownloadSubmit}
        counters={counters}
      />
      <TableComponent
        columns={[
          {
            label: "TIPO DE PROPOSTA",
            acessor: "proposalType",
          },
          {
            label: "CLIENTE",
            acessor: "clientName",
          },
          {
            label: "AFILIADO",
            acessor: "affiliatedName",
          },
          {
            label: "CAMPANHA",
            acessor: "campaignName",
          },
          {
            label: "CATEGORIA",
            acessor: "campaignCategory",
          },
          {
            label: "SITUAÇÃO",
            acessor: "situation",
          },
        ]}
        rows={proposals}
        onClick={(row) => navigate("/admin/propostas/formulario/" + row.id)}
        pagination={{
          page: page,
          count: count,
          rowsPerPage: rowsPerPage,
          handleChangePage: handleChangePage,
        }}
      />
      <div style={{ height: "60vh" }}></div>
    </BodyComponent>
  );
};

export default AdminProposalsPage;
