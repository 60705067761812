import {
  BodyComponent,
  ButtonComponent,
  TableComponent,
} from "../../../../components";
import useAdminCategoriesController from "./admin-categories.controller";
import AdminCategoryFormDialogComponent from "./components/admin-category-form-dialog.component";

const AdminCategoriesPage = () => {
  const {
    categories,
    onOpenCreateForm,
    onOpenEditForm,
    onDelete,
    onCreate,
    onEdit,
  } = useAdminCategoriesController();

  return (
    <BodyComponent>
      <AdminCategoryFormDialogComponent
        onDelete={onDelete}
        onCreate={onCreate}
        onEdit={onEdit}
      />

      <div style={{ marginBottom: "2vh", width: "71vw", textAlign: "end" }}>
        <ButtonComponent
          label="CRIAR NOVA CATEGORIA"
          backgroundColor="#40215F"
          color="white"
          size="small"
          height="4vh"
          onClick={onOpenCreateForm}
        />
      </div>

      <TableComponent
        onClick={(row) => onOpenEditForm(row)}
        columns={[
          { label: "ID", acessor: "id", width: "10vw" },
          { label: "NOME", acessor: "name" },
        ]}
        rows={categories}
      />
      <div style={{ height: "60vh" }}></div>
    </BodyComponent>
  );
};

export default AdminCategoriesPage;
