import React from "react";

import { productService } from "../../../../infrastructure/services";
import { useAlert } from "../../../providers";
import { Constants } from "../../../utils";
import { useIsMounted } from "../../../hooks";

const useLandPageController = () => {
  const { showError } = useAlert();
  const isMounted = useIsMounted();

  const [products, setProducts] = React.useState([
    Constants.defaultSelectPlaceholder,
  ]);

  React.useEffect(() => {
    productService
      .getProductByBanner()
      .then((response) => {
        if (isMounted.current) {
          setProducts([
            ...response.map((item) => ({
              id: item.id,
              label: item.banner,
            })),
          ]);
        }
      })
      .catch(showError);
  }, []);

  return {
    products,
  };
};

export default useLandPageController;
