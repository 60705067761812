import httpClient from "./axios.config";

class AdvertiserService {
  register = ({ form }) => {
    return new Promise((resolve, reject) => {
      httpClient
        .post("/publico/cadastroAnunciante", { advertiser: form })
        .then((response) => {
          const json = response.data.response;
          resolve(json);
        })
        .catch((error) =>
          reject(
            error.response && error.response.data && error.response.data.message
              ? error.response.data.message
              : "Erro inesperado, tente novamente mais tarde."
          )
        );
    });
  };

  fetchAdvertisers = () => {
    return new Promise((resolve, reject) => {
      httpClient
        .get("/anunciante/buscarAnunciantes")
        .then((response) => {
          const advertisersResponse = response.data.response;

          resolve(
            advertisersResponse.map((anunciante) => ({
              id: parseInt(anunciante.id),
              userName: anunciante.nome,
              companyName: anunciante.nomeEmpresa,
              email: anunciante.email,
              phone: anunciante.telefone,
              siteUrl: anunciante.site,
              address: anunciante.endereco,
              proposal: parseFloat(anunciante.valor),
              proposalType: anunciante.idTipoPagamento,
              campaignType: anunciante.idTipoProduto,
              facebookUrl: anunciante.facebook,
              instagramUrl: anunciante.instagram,
              documentUrl: anunciante.urlDocumento,
            }))
          );
        })
        .catch((error) =>
          reject(
            error.response && error.response.data && error.response.data.message
              ? error.response.data.message
              : "Erro inesperado, tente novamente mais tarde."
          )
        );
    });
  };
}

export default new AdvertiserService();
