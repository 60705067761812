import { forwardRef } from "react";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box/Box";
import Typography from "@mui/material/Typography/Typography";

import SectionComponent from "./section.component";
import { useResponsive } from "../../../../../providers";
import FacaSeuCadastroBannerImage from "../../../../../../assets/imagens/faca-seu-cadastro-banner.png";
import DescubraAgoraBannerImage from "../../../../../../assets/imagens/descubra-agora-banner.png";
import MonteASuaRedeBannerImage from "../../../../../../assets/imagens/monte-a-sua-rede-banner.png";

const AboutSectionComponent = forwardRef(({}, ref) => {
  const { isDesktop } = useResponsive();
  const navigate = useNavigate();

  const styledText = (text) => {
    return (
      <span style={{ fontWeight: "bold", fontStyle: "italic" }}>{text}</span>
    );
  };

  const boldText = (text) => {
    return <span style={{ fontWeight: "bold" }}>{text}</span>;
  };

  return (
    <Box
      component="section"
      ref={ref}
      display="flex"
      flexDirection="column"
      alignItems="center"
      marginX={isDesktop ? "15vw" : "7vw"}
      marginBottom="10vh"
    >
      <Typography
        variant="h5"
        style={{ color: "#858585", fontWeight: "bold", marginTop: "10vh" }}
      >
        SOBRE
      </Typography>
      <Typography
        style={{
          fontFamily: "sans-serif",
          fontSize: isDesktop ? "4.88vh" : "4vh",
          color: "#858585",
          textAlign: "center",
          marginTop: "2vh",
          marginBottom: "6vh",
        }}
      >
        A {styledText("Lucre +")} é uma {boldText("plataforma gratuita")} para
        quem deseja ampliar seus ganhos na internet!
      </Typography>

      <SectionComponent
        isReverse={false}
        bannerUrl={FacaSeuCadastroBannerImage}
        buttonLabel={"FAÇA SEU CADASTRO"}
        buttonOnClick={() => navigate("/criar-conta")}
        describle={
          <DescribleTextComponent>
            É possível {styledText("obter um bom lucro")} usando apenas a sua
            rede de contatos!
          </DescribleTextComponent>
        }
        points={[
          "Crie o seu perfil em poucos minutos.",
          "Assista e leia os nossos conteúdos para você aprender a divulgar.",
          "Compartilhe links de divulgação de produtos para sua rede de contatos.",
        ]}
      />
      <SectionComponent
        isReverse={isDesktop ? true : false}
        bannerUrl={DescubraAgoraBannerImage}
        buttonLabel={"DESCUBRA AGORA"}
        buttonOnClick={() => navigate("/criar-conta")}
        describle={
          <DescribleTextComponent>
            Descubra todos os benefícios que a {styledText("Lucre+")} pode
            oferecer para você!
          </DescribleTextComponent>
        }
        points={[
          "Obtenha uma renda extra todo mês.",
          "Empreenda com a gente.",
          "Conquiste a sua independência financeira!",
        ]}
      />
      <SectionComponent
        isReverse={false}
        bannerUrl={MonteASuaRedeBannerImage}
        buttonLabel={"MONTE A SUA REDE"}
        buttonOnClick={() => navigate("/criar-conta")}
        describle={
          <DescribleTextComponent>
            Construa a sua rede de {styledText("afiliados!")}
          </DescribleTextComponent>
        }
        points={[
          "Seja parceiro de empresas líderes de mercado.",
          "Trabalhe com produtos que você acredita.",
          "Ganhar comissões e consiga bons lucros trabalhando onde estiver!",
        ]}
      />
    </Box>
  );
});

const DescribleTextComponent = ({ children }) => {
  return (
    <Typography
      style={{
        fontFamily: "sans-serif",
        fontSize: "3.61vh",
        color: "#858585",
      }}
    >
      {children}
    </Typography>
  );
};

export default AboutSectionComponent;
