import { BodyComponent, TableComponent } from "../../../components";
import useProposalController from "./proposals.controller";
import ProposalsFilterComponent from "./components/proposal-filter.component";
import { useNavigate } from "react-router-dom";

const ProposalsPage = () => {
  const navigate = useNavigate();
  const {
    control,
    onSubmit,
    proposals,
    handleChangePage,
    page,
    count,
    rowsPerPage,
    campaignOptions,
    situationOptions,
    onDownloadSubmit,
    counters,
  } = useProposalController();

  return (
    <BodyComponent>
      <ProposalsFilterComponent
        control={control}
        onSubmit={onSubmit}
        campaignOptions={campaignOptions}
        situationOptions={situationOptions}
        data={proposals}
        onDownloadSubmit={onDownloadSubmit}
        counters={counters}
      />
      <TableComponent
        columns={[
          {
            label: "TIPO DE PROPOSTA",
            acessor: "proposalType",
          },
          {
            label: "CLIENTE",
            acessor: "clientName",
          },
          {
            label: "CAMPANHA",
            acessor: "campaignName",
          },
          {
            label: "CATEGORIA",
            acessor: "campaignCategory",
          },
          {
            label: "SITUAÇÃO",
            acessor: "situation",
          },
        ]}
        rows={proposals}
        onClick={(row) => navigate("/propostas/" + row.id)}
        pagination={{
          page: page,
          count: count,
          rowsPerPage: rowsPerPage,
          handleChangePage: handleChangePage,
        }}
      />

      <div style={{ height: "60vh" }}></div>
    </BodyComponent>
  );
};

export default ProposalsPage;
