import Divider from "@mui/material/Divider/Divider";
import Grid from "@mui/material/Grid/Grid";
import Typography from "@mui/material/Typography/Typography";

import { InputFilterComponent } from "../../../../../components";
import { Format } from "../../../../../utils";

const ProposalSectionComponent = ({ advertiser }) => {
  return (
    <div>
      <Typography
        style={{
          fontWeight: "600",
          fontSize: "1.75vh",
          color: "#5C5C5C",
        }}
      >
        PROPOSTA
      </Typography>
      <Divider style={{ marginBottom: "2vh" }} />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <InputFilterComponent
                value={advertiser?.campaignType ?? ""}
                disabled={true}
                label="TIPO DE CAMPANHA"
                placeholder="TIPO DE CAMPANHA"
              />
            </Grid>
            <Grid item xs={4}>
              <InputFilterComponent
                value={
                  !!advertiser
                    ? advertiser.proposalType == "Preço Fixo"
                      ? Format.toCurrency(advertiser.proposal)
                      : advertiser.proposal + "%"
                    : 0
                }
                disabled={true}
                label="VALOR DA PROPOSTA"
                placeholder="VALOR DA PROPOSTA"
              />
            </Grid>
            <Grid item xs={4}>
              <InputFilterComponent
                value={advertiser?.proposalType ?? ""}
                disabled={true}
                label="TIPO DE PAGAMENTO"
                placeholder="TIPO DE PAGAMENTO"
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default ProposalSectionComponent;
