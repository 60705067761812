import Typography from "@mui/material/Typography/Typography";

import {
  BodyComponent,
  FiltersComponent,
  TableComponent,
} from "../../../components";
import { useResponsive } from "../../../providers";
import { Constants, Format } from "../../../utils";
import ProductionDetailDialogComponent from "./components/production-detail-dialog.component";
import useReportsController from "./reports.controller";

const ReportsPage = () => {
  const { isDesktop } = useResponsive();
  const {
    productions,
    campaigns,
    users,
    onSearch,
    handleChangePage,
    page,
    count,
    rowsPerPage,
    onShowProduction,
    counters,
  } = useReportsController();

  return (
    <BodyComponent>
      <ProductionDetailDialogComponent />
      <FiltersComponent
        userOptions={users}
        campaignOptions={campaigns}
        onSearch={onSearch}
        counters={counters}
      />
      <TableComponent
        columns={[
          {
            label: "PRODUTO",
            acessor: "campaign",
            width: "15vw",
            formatWidget: (row) => {
              return (
                <Typography
                  style={{
                    fontWeight: "700",
                    fontSize: isDesktop ? "1.86vh" : "0.77vh",
                    color: "#5C5C5C",
                    whiteSpace: "pre-line",
                  }}
                >
                  {row["campaign"]}
                </Typography>
              );
            },
          },
          {
            label: "ETAPA",
            acessor: "step",
            width: "16vw",
            formatText: (row) => Constants.getProductionStep(row["step"]),
          },
          {
            label: "USUÁRIO",
            acessor: "affiliate",
            width: isDesktop ? "14vw" : "14vw",
          },
          {
            label: "DATA",
            acessor: "eventDate",
            width: isDesktop ? "9vw" : "12vw",
          },
          {
            label: "GANHO",
            acessor: "payment",
            width: "8vw",
            formatText: (row) => Format.toCurrency(row["payment"]),
          },
        ]}
        onClick={onShowProduction}
        rows={productions}
        pagination={{
          page: page,
          count: count,
          rowsPerPage: rowsPerPage,
          handleChangePage: handleChangePage,
        }}
      />
      <div style={{ height: "60vh" }}></div>
    </BodyComponent>
  );
};

export default ReportsPage;
