import httpClient from "./axios.config";

class ProfileService {
  getProfile = () => {
    return new Promise((resolve, reject) => {
      httpClient
        .get("/perfil/buscar")
        .then((response) => {
          const profile = response.data.response;

          resolve({
            photoUrl: profile.foto,
            displayName: profile.nome,
            email: profile.email,
            phone: profile.telefone,
            cpf: profile.cpf,
            pix: profile.chavePix,
            birthDate: profile.dtNascimento,
            rg: profile.rg,
            address:
              profile.endereco != null
                ? {
                    streetName: profile.endereco.logradouro,
                    number: profile.endereco.numero,
                    neighborhood: profile.endereco.bairro,
                    city: profile.endereco.cidade,
                    state: profile.endereco.estado,
                    zipCode: profile.endereco.cep,
                  }
                : null,
            pendingConfirmation: profile.confirmacaoPendente,
          });
        })
        .catch((error) =>
          reject(
            error.response && error.response.data && error.response.data.message
              ? error.response.data.message
              : "Erro inesperado, tente novamente mais tarde."
          )
        );
    });
  };

  editProfile = ({ query }) => {
    return new Promise((resolve, reject) => {
      httpClient
        .put(`/perfil/editar`, query)
        .then((response) => {
          const profile = response.data.response;

          resolve({
            photoUrl: profile.foto,
            displayName: profile.nome,
            email: profile.email,
            phone: profile.telefone,
            cpf: profile.cpf,
            pix: profile.chavePix,
            pendingConfirmation: profile.confirmacaoPendente,
          });
        })
        .catch((error) =>
          reject(
            error.response && error.response.data && error.response.data.message
              ? error.response.data.message
              : "Erro inesperado, tente novamente mais tarde."
          )
        );
    });
  };

  confirmEdit = ({ code }) => {
    return new Promise((resolve, reject) => {
      httpClient
        .get(`/perfil/confirmar/?code=${code}`)
        .then((response) => {
          const profile = response.data.response;

          resolve({
            photoUrl: profile.foto,
            displayName: profile.nome,
            email: profile.email,
            phone: profile.telefone,
            cpf: profile.cpf,
            pix: profile.chavePix,
            pendingConfirmation: profile.confirmacaoPendente,
          });
        })
        .catch((error) =>
          reject(
            error.response && error.response.data && error.response.data.message
              ? error.response.data.message
              : "Erro inesperado, tente novamente mais tarde."
          )
        );
    });
  };
}

export default new ProfileService();
