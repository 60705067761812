import Box from "@mui/material/Box/Box";
import Grid from "@mui/material/Grid/Grid";
import { useNavigate } from "react-router-dom";

import { useResponsive } from "../../../providers";
import { ButtonGradientComponent } from "../../../components";
import DescribleComponent from "./components/describle.component";

const RegisterUserSuccessPage = () => {
  const { isDesktop } = useResponsive();
  const navigate = useNavigate();

  return (
    <Box>
      <Grid
        container
        sx={{
          background: "linear-gradient(292.46deg, #85539D, #6B2D87, #40215F)",
          minHeight: "auto",
          justifyContent: "space-between",
        }}
      >
        <Grid item xs={6}>
          <DescribleComponent />
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        style={!isDesktop ? { marginTop: "7vh" } : null}
        sx={isDesktop ? { marginTop: "2vw", marginLeft: "7vw" } : null}
      >
        <div
          style={
            !isDesktop
              ? {
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }
              : null
          }
        >
          <ButtonGradientComponent
            label="ACESSAR MINHA CONTA"
            onClick={() => navigate("/entrar")}
          />
        </div>
      </Grid>
    </Box>
  );
};

export default RegisterUserSuccessPage;
