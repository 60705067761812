const Format = {
  percentToCurrency: (percent, totalCurrency) => {
    const currency = (percent * totalCurrency) / 100;

    return currency;
  },

  currencyToFloat: (currency) => {
    if (currency == "") {
      currency = 0;
    } else {
      currency = currency.replace("R$", "");
      currency = currency.replace(".", "");
      currency = currency.replace(",", ".");
      currency = parseFloat(currency);
    }
    return currency;
  },

  getFileNameFromURL: (url) => {
    const parts = url.split("/");
    const fileName = parts.pop();
    const fileNameWithoutExtensin = fileName.split(".")[0];
    return fileNameWithoutExtensin;
  },

  formatCurrency: (value) => {
    if (value == "") return "";
    value = value.replace(/\D/g, "");
    value = value.replace(/(\d)(\d{2})$/, "$1,$2");
    value = value.replace(/(?=(\d{3})+(\D))\B/g, ".");
    return value;
  },

  formatMoney: (rawValue) => {
    // Remove todos os caracteres não numéricos
    const onlyDigits = rawValue.replace(/[^\d]/g, "");

    // Formata para a máscara monetária
    let formattedValue = "";
    if (onlyDigits.length >= 3) {
      formattedValue =
        "R$ " + onlyDigits.slice(0, -2) + "," + onlyDigits.slice(-2);
    } else if (onlyDigits.length === 2) {
      formattedValue = "R$ 0," + onlyDigits;
    } else if (onlyDigits.length === 1) {
      formattedValue = "R$ 0,0" + onlyDigits;
    }

    return formattedValue;
  },

  toCurrency: (value) => {
    return value.toLocaleString("pt-BR", {
      style: "currency",
      currency: "BRL",
    });
  },

  formatDate: (dateString) => {
    var parts = dateString.split(" ");
    var dateParts = parts[0].split("/");
    var timeParts = parts[1].split(":");
    var isoString =
      dateParts[2] +
      "-" +
      dateParts[1] +
      "-" +
      dateParts[0] +
      "T" +
      timeParts[0] +
      ":" +
      timeParts[1];

    var parsedDate = Date.parse(isoString);

    return parsedDate;
  },

  downloadCsv: (csvString, filename) => {
    const blob = new Blob([csvString], { type: "text/csv;charset=utf-8" });

    const url = window.URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = url;
    link.download = filename + ".csv";
    link.style.display = "none";

    document.body.appendChild(link);
    link.click();
    window.URL.revokeObjectURL(url);
    document.body.removeChild(link);
  },
};

export default Format;
