import { Format } from "../../presentation/utils";
import httpClient from "./axios.config";

class CampaignService {
  getUserCampaigns = () => {
    return new Promise((resolve, reject) => {
      httpClient
        .get("/usuario/produtoCliente?idUsuario=0")
        .then((response) => {
          const campaignsResponse = response.data.response;

          httpClient
            .get("/usuario/produtoComissaoAReceber")
            .then((response) => {
              const commissions = response.data.response;

              const commission = (campaignId) =>
                commissions.filter(
                  (commission) => commission.idProduto == campaignId
                )[0];

              const campaigns = campaignsResponse.map((campaign) => {
                return {
                  id: parseInt(campaign.produto.produtoId),
                  logoUrl: campaign.produto.banner,
                  name: campaign.produto.nome,
                  describle: campaign.produto.descricao,
                  commission: commission(campaign.produto.produtoId).comissao,
                  commissionType: commission(campaign.produto.produtoId)
                    .tipoComissao,
                  category: {
                    id: parseInt(campaign.produto.categoria.id),
                    name: campaign.produto.categoria.nome,
                  },
                  withLink: campaign.produto.comLink == "1",
                  situation: campaign.produto.disponivel == "1",
                  isIntern: true,
                  shareId: "",
                  createdAt: Format.formatDate(campaign.produto.dtCriacao),
                  clickRankValue: campaign.cliques,
                };
              });

              resolve(campaigns);
            })
            .catch((error) =>
              reject(
                error.response &&
                  error.response.data &&
                  error.response.data.message
                  ? error.response.data.message
                  : "Erro inesperado, tente novamente mais tarde."
              )
            );
        })
        .catch((error) =>
          reject(
            error.response && error.response.data && error.response.data.message
              ? error.response.data.message
              : "Erro inesperado, tente novamente mais tarde."
          )
        );
    });
  };

  getCampaigns = () => {
    return new Promise((resolve, reject) => {
      httpClient
        .get("/produto/cadastro")
        .then((response) => {
          const campaignsResponse = response.data.response;

          const campaigns = campaignsResponse.map((produto) => ({
            id: parseInt(produto.produtoId),
            name: produto.nome,
            category: {
              id: parseInt(produto.categoria.id),
              name: produto.categoria.nome,
            },
            withLink: produto.comLink == "1",
            situation: produto.disponivel == "1",
            isIntern: produto.isInterno == "1",
            shareId: !!produto.foregonId ? parseInt(produto.foregonId) : null,
          }));

          resolve(campaigns);
        })
        .catch((error) =>
          reject(
            error.response && error.response.data && error.response.data.message
              ? error.response.data.message
              : "Erro inesperado, tente novamente mais tarde."
          )
        );
    });
  };

  getCampaignById = ({ id }) => {
    return new Promise((resolve, reject) => {
      httpClient
        .get("/usuario/produtoPorUsuario?idProduto=" + id)
        .then((response) => {
          const campaign = response.data.response;

          httpClient
            .get("/usuario/produtoComissaoAReceber")
            .then((response) => {
              const commissions = response.data.response;

              const commission = (campaignId) =>
                commissions.filter(
                  (commission) => commission.idProduto == campaignId
                )[0];

              resolve({
                id: campaign.produtoId,
                logoUrl: campaign.banner,
                name: campaign.nome,
                commission: commission(campaign.produtoId).comissao,
                commissionType: commission(campaign.produtoId).tipoComissao,
                describle: campaign.descricao,
                shareLink: campaign.link,
                hasSelfRegistration: campaign.hasAutocadastro,
                hasOpenLink: campaign.hasOpenLink,
                guid: campaign.guid,
                metas: campaign.metas ?? [],
                affiliateKey: !!campaign.chaveAfiliado
                  ? campaign.chaveAfiliado
                  : null,
                category: {
                  id: parseInt(campaign.categoria.id),
                  name: campaign.categoria.nome,
                },
                media: campaign.imagens.map((image) => image.caminho),
                mediaList: campaign.imagens,
                bannerLP: campaign.bannerDescricao,
                corBotao: campaign.corBotao,
                textoBotao: campaign.textoBotao,
                isYoutube: campaign.isYoutube === "1",
                linkYoutube: campaign.linkYoutube,
                formCPR: campaign.formularioCPR,
                metas: campaign.metas,
                describleLP: campaign.descricaoLp,
                productsCPV: !!campaign.subProdutosCPV
                  ? campaign.subProdutosCPV.map((produto) => ({
                      images: produto.imagens,
                      title: produto.titulo,
                      value: parseFloat(produto.valor),
                      code: produto.codigo,
                      describle: produto.descricao,
                      commission: parseFloat(produto.comissao),
                      commissionType: parseInt(produto.tipoComissao),
                    }))
                  : null,
              });
            })
            .catch((error) =>
              reject(
                error.response &&
                  error.response.data &&
                  error.response.data.message
                  ? error.response.data.message
                  : "Erro inesperado, tente novamente mais tarde."
              )
            );
        })
        .catch((error) =>
          reject(
            error.response && error.response.data && error.response.data.message
              ? error.response.data.message
              : "Erro inesperado, tente novamente mais tarde."
          )
        );
    });
  };

  getCampaignToEditById = ({ id }) => {
    return new Promise((resolve, reject) => {
      httpClient
        .get("/produto/cadastro")
        .then((response) => {
          const campaignsResponse = response.data.response;

          const campaign = campaignsResponse.filter(
            (produto) => parseInt(produto.produtoId) == id
          )[0];

          resolve({
            id: campaign.produtoId,
            logoUrl: campaign.banner,
            bannerUrl: campaign.bannerDescricao,
            internLogoUrl: campaign.logoLp,
            internDescrible: campaign.descricaoLp,
            name: campaign.nome,
            commission: campaign.comissao,
            commissionType: campaign.tipoComissao,
            images: campaign.imagens.map((imagem) => ({
              id: imagem.id,
              url: imagem.caminho,
            })),
            formCPL: campaign.formularioCPL,
            formCPV: campaign.formularioCPV,
            formCPR: campaign.formularioCPR,
            goals: !!campaign.metas
              ? campaign.metas.map((meta) => ({
                  id: parseInt(meta.id),
                  label: meta.nome,
                }))
              : null,
            users: !!campaign.usuarios
              ? campaign.usuarios.map((usuario) => ({
                  id: parseInt(usuario.idUsuario),
                  displayName: usuario.nome,
                }))
              : null,
            productsCPV: !!campaign.subProdutosCPV
              ? campaign.subProdutosCPV.map((produto) => ({
                  images: produto.imagens,
                  title: produto.titulo,
                  value: parseFloat(produto.valor),
                  code: produto.codigo,
                  describle: produto.descricao,
                  commission: parseFloat(produto.comissao),
                  commissionType: parseInt(produto.tipoComissao),
                }))
              : null,
            withLink: campaign.comLink == "1" ? 1 : 2,
            isActive: campaign.disponivel == "1" ? 1 : 2,
            isIntern: campaign.isInterno == "1" ? 1 : 2,
            shareId: !!campaign.foregonId ? parseInt(campaign.foregonId) : null,
            describle: campaign.descricao,
            shareLink: campaign.link,
            linkCPLA: campaign.link,
            category: {
              id: parseInt(campaign.categoria.id),
              name: campaign.categoria.nome,
            },
            media: campaign.imagens.map((image) => image.caminho),
            corBotao: campaign.corBotao,
            textoBotao: campaign.textoBotao,
            isYoutube: campaign.isYoutube === "1",
            linkYoutube: campaign.linkYoutube,

            commissions: campaign.remuneracaoPerfil.map((remuneracao) => ({
              id: parseInt(remuneracao.id),
              profile: remuneracao.perfil.nome,
              commission:
                parseInt(remuneracao.tipoComissao) == 1
                  ? Format.currencyToFloat(remuneracao.comissao)
                  : parseFloat(remuneracao.comissao),
              commissionType: parseInt(remuneracao.tipoComissao),
            })),
          });
        })
        .catch((error) =>
          reject(
            error.response && error.response.data && error.response.data.message
              ? error.response.data.message
              : "Erro inesperado, tente novamente mais tarde."
          )
        );
    });
  };

  incrementCampaignSharedLink = ({ id }) => {
    return new Promise((resolve, reject) => {
      httpClient
        .put("/produto/rankCompartilhados?idProduto=" + id)
        .then((response) => {
          const json = response.data.response;
          resolve(json);
        })
        .catch((error) =>
          reject(
            error.response && error.response.data && error.response.data.message
              ? error.response.data.message
              : "Erro inesperado, tente novamente mais tarde."
          )
        );
    });
  };

  deleteCampaign = ({ id }) => {
    return new Promise((resolve, reject) => {
      httpClient
        .delete("/produto/excluirCampanha?idProduto=" + id)
        .then(() => resolve())
        .catch((error) =>
          reject(
            error.response && error.response.data && error.response.data.message
              ? error.response.data.message
              : "Erro inesperado, tente novamente mais tarde."
          )
        );
    });
  };

  createCampaign = (query) => {
    return new Promise((resolve, reject) => {
      httpClient
        .post("/produto/cadastro", { form: query })
        .then(() => resolve())
        .catch((error) =>
          reject(
            error.response && error.response.data && error.response.data.message
              ? error.response.data.message
              : "Erro inesperado, tente novamente mais tarde."
          )
        );
    });
  };

  changeCommissionByProfile = (form) => {
    return new Promise((resolve, reject) => {
      httpClient
        .post("/produto/remuneracao", { form })
        .then(() => resolve())
        .catch((error) =>
          reject(
            error.response && error.response.data && error.response.data.message
              ? error.response.data.message
              : "Erro inesperado, tente novamente mais tarde."
          )
        );
    });
  };

  removeCampaignSupportImage = ({ id }) => {
    return new Promise((resolve, reject) => {
      httpClient
        .delete("/produto/upload?imagemId=" + id)
        .then((response) => {
          const campaign = response.data.response;
          resolve(
            campaign.imagens.map((imagem) => ({
              id: imagem.id,
              url: imagem.caminho,
            }))
          );
        })
        .catch((error) =>
          reject(
            error.response && error.response.data && error.response.data.message
              ? error.response.data.message
              : "Erro inesperado, tente novamente mais tarde."
          )
        );
    });
  };

  addCampaignSupportImage = (form) => {
    return new Promise((resolve, reject) => {
      httpClient
        .post("/produto/upload", { form })
        .then((response) => {
          const campaign = response.data.response;
          resolve(
            campaign.imagens.map((imagem) => ({
              id: imagem.id,
              url: imagem.caminho,
            }))
          );
        })
        .catch((error) =>
          reject(
            error.response && error.response.data && error.response.data.message
              ? error.response.data.message
              : "Erro inesperado, tente novamente mais tarde."
          )
        );
    });
  };

  resetCommissionsToProfile = (form) => {
    return new Promise((resolve, reject) => {
      httpClient
        .put("/produto/redefineTipoComissao", { form })
        .then((response) => {
          const remuneracaoPerfil = response.data.response;

          resolve(
            remuneracaoPerfil.map((remuneracao) => ({
              id: parseInt(remuneracao.id),
              profile: remuneracao.perfil.nome,
              commission:
                parseInt(remuneracao.tipoComissao) == 1
                  ? Format.currencyToFloat(remuneracao.comissao)
                  : parseFloat(remuneracao.comissao),
              commissionType: parseInt(remuneracao.tipoComissao),
            }))
          );
        })
        .catch((error) =>
          reject(
            error.response && error.response.data && error.response.data.message
              ? error.response.data.message
              : "Erro inesperado, tente novamente mais tarde."
          )
        );
    });
  };

  changeHighlightCampaigns = (campaignIds) => {
    return new Promise((resolve, reject) => {
      httpClient
        .put("/produto/destaque", { idProdutos: campaignIds })
        .then(() => resolve())
        .catch((error) =>
          reject(
            error.response && error.response.data && error.response.data.message
              ? error.response.data.message
              : "Erro inesperado, tente novamente mais tarde."
          )
        );
    });
  };

  getHighlightCampaigns = () => {
    return new Promise((resolve, reject) => {
      httpClient
        .get("/produto/destaque")
        .then((response) => resolve(response.data.response))
        .catch((error) =>
          reject(
            error.response && error.response.data && error.response.data.message
              ? error.response.data.message
              : "Erro inesperado, tente novamente mais tarde."
          )
        );
    });
  };

  changeFeaturedCampaign = (form) => {
    return new Promise((resolve, reject) => {
      httpClient
        .put("/produto/amostra", form)
        .then(() => resolve())
        .catch((error) =>
          reject(
            error.response && error.response.data && error.response.data.message
              ? error.response.data.message
              : "Erro inesperado, tente novamente mais tarde."
          )
        );
    });
  };

  getFeaturedCampaigns = () => {
    return new Promise((resolve, reject) => {
      httpClient
        .get("/produto/amostra")
        .then((response) => {
          const featured = response.data.response;

          resolve(
            featured.map((item) => ({
              campaign: {
                id: parseInt(item.produtoId),
                name: item.produtoNome,
                logoUrl: item.produtoLogoUrl,
              },
              title: item.titulo,
              describle: item.descricao,
              isActive: item.disponivel,
              color: item.cor,
              id: parseInt(item.id),
            }))
          );
        })
        .catch((error) =>
          reject(
            error.response && error.response.data && error.response.data.message
              ? error.response.data.message
              : "Erro inesperado, tente novamente mais tarde."
          )
        );
    });
  };
}

export default new CampaignService();
