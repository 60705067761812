import Grid from "@mui/material/Grid/Grid";
import Typography from "@mui/material/Typography/Typography";
import Card from "@mui/material/Card/Card";
import makeStyles from "@mui/styles/makeStyles/makeStyles";
import { useNavigate } from "react-router-dom";

import { ButtonGradientComponent } from "../../../../components";
import { useResponsive } from "../../../../providers";
//TODO: Organizar assets em um arquivo e trocar este homebanner por um png
import HomeBannerImage from "../../../../../assets/imagens/home-banner.png";
import GrupoRizomaImage from "../../../../../assets/imagens/grupo-rizoma.png";

const useStyles = makeStyles(() => ({
  banner: {
    backgroundColor: "#094E86",
    marginBottom: "8vh",
    borderRadius: "10px",
    position: "relative",
    overflow: "hidden",
  },
  bannerBackground: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },
  contentContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
    padding: "1vh 4vw",
    height: "100%",
  },
  imageContainer: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    height: "100%",
    position: "relative",
    left: "10%",
    top: "30%",
  },
  image: {
    width: "100%",
    maxWidth: "15.34vw",
    marginLeft: "2vw",
  },
}));

const HelpBannerComponent = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { isDesktop } = useResponsive();

  return (
    <Card
      elevation={0}
      className={classes.banner}
      style={{
        width: isDesktop ? "60vw" : "70vw",
        height: isDesktop ? "14vw" : "24vw",
      }}
    >
      <img
        src={HomeBannerImage}
        alt="Background"
        className={classes.bannerBackground}
      />
      <Grid container className={classes.contentContainer}>
        <Grid item xs={12} sm={isDesktop ? 7 : 12}>
          <Typography
            variant="h6"
            style={{
              fontWeight: "600",
              fontSize: isDesktop ? "1.5vw" : "1.26vh",
              color: "white",
            }}
          >
            TORNE-SE UM CONSULTOR BANCÁRIO AUTÔNOMO
          </Typography>
          <Typography
            variant="body2"
            style={{
              fontWeight: "400",
              fontSize: isDesktop ? "1vw" : "1.06vh",
              color: "white",
              marginBottom: "0.9vw",
            }}
          >
            Quer ver o efeito que comissões semanais podem ter na sua vida?
            Venha ser um licenciado do Grupo Rizoma!
          </Typography>
          <ButtonGradientComponent
            padding="1.3vw"
            label="SAIBA MAIS"
            height={isDesktop ? "4vh" : "3vh"}
            fontSize={isDesktop ? "1.75vh" : "1.56vh"}
            background="linear-gradient(91.84deg, #ADC312, #FBD000)"
            onClick={() => navigate("/licenciamento")}
          />
        </Grid>
        {isDesktop && (
          <Grid item xs={12} sm={5} className={classes.imageContainer}>
            <img
              src={GrupoRizomaImage}
              alt="Grupo Rizoma"
              className={classes.image}
            />
          </Grid>
        )}
      </Grid>
    </Card>
  );
};

export default HelpBannerComponent;
