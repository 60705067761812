import { useEffect, useState } from "react";
import { useIsMounted } from "../../../../hooks";
import { bankService } from "../../../../../infrastructure/services";
import { useAlert, useDialog } from "../../../../providers";
import { useLoadingContext } from "../../../../context/loading.context";

const useAdminBanksController = () => {
  const isMounted = useIsMounted();
  const { openDialog, closeDialog } = useDialog();
  const { showError, showSuccess } = useAlert();
  const [banks, setBanks] = useState([]);
  const { setLoadingState } = useLoadingContext();

  useEffect(() => onFetch(), []);

  const onFetch = () => {
    setLoadingState(true);
    bankService
      .getBanks()
      .then((response) => {
        if (isMounted.current) {
          setBanks(response);
        }
      })
      .catch(showError)
      .finally(() => {
        setLoadingState(false);
      });
  };

  const onOpenCreateForm = () => {
    openDialog("admin-bank-form-dialog");
  };

  const onOpenEditForm = (bank) => {
    openDialog("admin-bank-form-dialog", bank);
  };

  const onCreate = ({ name, number }) => {
    setLoadingState(true);
    bankService
      .addBank({ descricao: name, numero: number })
      .then(() => {
        onFetch();
        closeDialog();
        showSuccess("Banco cadastrado!");
      })
      .catch(showError)
      .finally(() => {
        setLoadingState(false);
      });
  };

  const onEdit = ({ id, name, number }) => {
    setLoadingState(true);
    bankService
      .editBank({ id: id, descricao: name, numero: number })
      .then(() => {
        onFetch();
        closeDialog();
        showSuccess("Banco editado!");
      })
      .catch(showError)
      .finally(() => {
        setLoadingState(false);
      });
  };

  return {
    banks,
    onOpenCreateForm,
    onOpenEditForm,
    onCreate,
    onEdit,
  };
};

export default useAdminBanksController;
