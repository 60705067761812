import React from "react";
import Badge from "@mui/material/Badge/Badge";
import makeStyles from "@mui/styles/makeStyles/makeStyles";
import ThemeProvider from "@mui/material/styles/ThemeProvider";
import createTheme from "@mui/material/styles/createTheme";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";

import { useNotification } from "../../../../providers";
import { useWhiteLabel } from "../../../../providers/white-label.provider";
import NotificationsIcon from "../../../../../assets/icones/notifications.svg";

const theme = createTheme({
  overrides: {
    MuiBadge: {
      badge: {
        backgroundColor: "#61C3BD",
      },
    },
    MuiTypography: {
      root: {
        color: "#ffffff",
        fontWeight: "bold",
      },
    },
  },
});

const useStyles = makeStyles((theme) => ({
  small: {
    width: theme.spacing(2.5),
    height: theme.spacing(2.5),
  },
}));

const NotificationsButtonComponent = () => {
  const classes = useStyles();
  const { hasCustomTheme, theme: customTheme } = useWhiteLabel();
  const { notificationsNotVisualizedLength, openNotifications } =
    useNotification();

  return (
    <ThemeProvider theme={theme}>
      <Badge
        badgeContent={notificationsNotVisualizedLength}
        overlap="rectangular"
        classes={{ badge: "notificacoes" }}
        sx={{
          "&:hover": {
            cursor: "pointer",
          },
        }}
        onClick={openNotifications}
      >
        {hasCustomTheme ? (
          <NotificationsNoneIcon
            className={classes.small}
            style={{ color: customTheme.palette.secondary.main }}
          />
        ) : (
          <img src={NotificationsIcon} className={classes.small} />
        )}
      </Badge>
    </ThemeProvider>
  );
};

export default NotificationsButtonComponent;
