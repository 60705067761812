import * as yup from "yup";

const Validate = {
  fullName: yup
    .string()
    .required("O Nome completo é obrigatório")
    .test(
      "nome-completo",
      "Insira o nome completo, incluindo o sobrenome",
      (value) => {
        if (!value) return false;
        const nomes = value.trim().split(" ");
        return nomes.length >= 2;
      }
    )
    .matches(
      /^[A-Za-zÀ-ÖØ-öø-ÿ\s]+$/,
      "O Nome completo deve conter apenas letras e espaços"
    )
    .min(3, "O Nome completo deve ter pelo menos 3 caracteres")
    .max(50, "O Nome completo deve ter no máximo 50 caracteres"),
  email: yup
    .string()
    .required("O Email é obrigatório")
    .matches(
      /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/,
      "Email inválido"
    ),
  password: yup
    .string()
    .required("A senha é obrigatória")
    .matches(
      /^(?=.*[A-Z])(?=.*[a-z])(?=.*[\d])(?=.*[@#$%&*!-+&*]).{6,20}$/,
      "Senha inválida"
    ),
  cpf: yup
    .string()
    .required("O CPF é obrigatório")
    .matches(/^(\d{3}\.\d{3}\.\d{3}-\d{2}|\d{11})$/, "CPF inválido")
    .test("cpf", "CPF inválido", (value) => {
      if (!value) {
        return false;
      }
      const cpfLimpo = value.replace(/[^\d]+/g, "");
      return validarCPF(cpfLimpo);
    }),
  phone: yup
    .string()
    .required("O Celular é obrigatório")
    .matches(/^\(\d{2}\) \d{4,5}-\d{4}|\d{11}$/, "Celular inválido"),
  address: yup.string().required("O Endereço é obrigatório"),
  pix: yup
    .string()
    .required("A Chave PIX é obrigatória")
    .test("chavePix", "Chave PIX inválida", (value) => {
      if (!value) {
        return false;
      }

      const regexEmail =
        /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;

      if (regexEmail.test(value)) return true;

      const regexCPFMascara = /^(\d{3}\.\d{3}\.\d{3}-\d{2}|\d{11})$/;

      if (regexCPFMascara.test(value)) {
        const cpfLimpo = value.replace(/[^\d]+/g, "");

        if (validarCPF(cpfLimpo)) {
          return true;
        }
      }

      const regexTelefone = /^\(\d{2}\) \d{4,5}-\d{4}|\d{11}$/;

      if (regexTelefone.test(value)) return true;

      const regexAleatoria =
        /([a-z\d]{8})\-([a-z\d]{4})\-([a-z\d]{4})\-([a-z\d]{4})\-([a-z\d]{12})/;

      if (regexAleatoria.test(value)) return true;

      return false;
    }),
};

const validarCPF = (cpf) => {
  // Limpa o CPF removendo pontos e traços
  cpf = cpf.replace(/[^\d]+/g, "");

  // Verifica se o CPF tem 11 dígitos
  if (cpf.length !== 11) {
    return false;
  }

  // Verifica se todos os dígitos são iguais (ex.: 111.111.111-11)
  if (/^(\d)\1{10}$/.test(cpf)) {
    return false;
  }

  // Verifica se os dois dígitos verificadores são válidos
  let soma = 0;
  let resto;
  for (let i = 1; i <= 9; i++) {
    soma += parseInt(cpf.substring(i - 1, i)) * (11 - i);
  }
  resto = (soma * 10) % 11;
  if (resto === 10 || resto === 11) {
    resto = 0;
  }
  if (resto !== parseInt(cpf.substring(9, 10))) {
    return false;
  }

  soma = 0;
  for (let i = 1; i <= 10; i++) {
    soma += parseInt(cpf.substring(i - 1, i)) * (12 - i);
  }
  resto = (soma * 10) % 11;
  if (resto === 10 || resto === 11) {
    resto = 0;
  }
  if (resto !== parseInt(cpf.substring(10, 11))) {
    return false;
  }

  return true;
};

export default Validate;
