import Divider from "@mui/material/Divider/Divider";
import Grid from "@mui/material/Grid/Grid";
import Typography from "@mui/material/Typography/Typography";

import {
  AutocompleteComponent,
  InputFilterComponent,
  SelectComponent,
} from "../../../../../components";

const PaymentSectionComponent = ({
  control,
  bankAccountTypeOptions,
  bankOptions,
  isLoading,
}) => {
  return (
    <div>
      <Typography
        style={{
          fontWeight: "600",
          fontSize: "1.75vh",
          color: "#5C5C5C",
        }}
      >
        PAGAMENTO
      </Typography>
      <Divider style={{ marginBottom: "2vh" }} />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <AutocompleteComponent
                name="bank"
                control={control}
                label="BANCO"
                acessorLabel="name"
                acessorValue="id"
                options={bankOptions}
                disabled={isLoading}
              />
            </Grid>
            <Grid item xs={3}>
              <SelectComponent
                name="bankAccountType"
                control={control}
                label="TIPO DE CONTA"
                acessor="label"
                options={bankAccountTypeOptions}
                disabled={isLoading}
              />
            </Grid>
            <Grid item xs={3}>
              <InputFilterComponent
                name="bankAgency"
                control={control}
                label="AGÊNCIA"
                placeholder="AGÊNCIA"
                disabled={isLoading}
              />
            </Grid>
            <Grid item xs={3}>
              <InputFilterComponent
                name="bankAccount"
                control={control}
                label="N° DA CONTA"
                placeholder="N° DA CONTA"
                disabled={isLoading}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <InputFilterComponent
                name="pix"
                control={control}
                label="CHAVE DO PIX"
                placeholder="CHAVE DO PIX"
                disabled={isLoading}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default PaymentSectionComponent;
