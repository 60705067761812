import React from "react";

import {
  campaignService,
  pixelService,
  profileService,
  userService,
} from "../../../../infrastructure/services";
import { useAlert, useAuth } from "../../../providers";
import { useIsMounted } from "../../../hooks";
import { useLoadingContext } from "../../../context/loading.context";
import { useParams } from "react-router-dom";

const useCampaignDetailController = () => {
  let { id } = useParams();
  const { showError, showSuccess } = useAlert();
  const isMounted = useIsMounted();
  const { user } = useAuth();
  const [campaign, setCampaign] = React.useState(null);
  const [userProfile, setUserProfile] = React.useState(null);
  const [pixel, setPixel] = React.useState(null);
  const { setLoadingState } = useLoadingContext();

  React.useEffect(() => {
    if (user != null) {
      if (!!id) {
        setLoadingState(true);
        campaignService
          .getCampaignById({ id: id })
          .then((response) => {
            if (isMounted.current) {
              setCampaign(ajusteUrl(response));

              if (response.hasSelfRegistration) {
                setLoadingState(true);
                profileService
                  .getProfile()
                  .then((profile) => {
                    setUserProfile(profile);

                    setLoadingState(true);
                    pixelService
                      .buscarPixel({
                        idUsuario: user.id,
                        idProduto: id,
                      })
                      .then(setPixel)
                      .catch(showError)
                      .finally(() => {
                        setLoadingState(false);
                      });
                  })
                  .catch(showError)
                  .finally(() => {
                    setLoadingState(false);
                  });
              } else {
                setLoadingState(true);
                pixelService
                  .buscarPixel({
                    idUsuario: user.id,
                    idProduto: id,
                  })
                  .then(setPixel)
                  .catch(showError)
                  .finally(() => {
                    setLoadingState(false);
                  });
              }
            }
          })
          .catch(showError)
          .finally(() => {
            setLoadingState(false);
          });
      }
    }
  }, [user]);

  const ajusteUrl = (obj) => {
    if (obj.shareLink) {
      const urlObj = new URL(obj.shareLink);
      const parametroAB = urlObj.searchParams.get("AB");
      obj.shareLink = parametroAB ? parametroAB : obj.shareLink;
    }
    return obj;
  };

  const onCopyShareLink = (onCopy) => {
    setLoadingState(true);
    campaignService
      .incrementCampaignSharedLink({ id: id })
      .then(onCopy)
      .catch(showError)
      .finally(() => {
        setLoadingState(false);
      });
  };

  const selfRegistrationInCampaign = () => {
    setLoadingState(true);
    campaignService
      .incrementCampaignSharedLink({ id: id })
      .then(() => {
        setLoadingState(true);
        userService
          .selfRegistrationInCampaign({
            campaignGuid: campaign.guid,
            affiliateKey: campaign.affiliateKey,
            form: makeSelfRegistrationForm(),
          })
          .then(() => {
            showSuccess(
              "Seu link foi solicitado, você será notificado quando ele estiver disponível"
            );
          })
          .catch(showError)
          .finally(() => {
            setLoadingState(false);
          });
      })
      .catch(showError)
      .finally(() => {
        setLoadingState(false);
      });
  };

  const onClickInCanpaignButton = () => {
    if (campaign.hasSelfRegistration == true) {
      selfRegistrationInCampaign();
    } else if (campaign.hasOpenLink == true) {
      onOpenLink();
    }
  };

  const onOpenLink = () => {
    setLoadingState(true);
    campaignService
      .incrementCampaignSharedLink({ id: id })
      .then(() => {
        setLoadingState(true);
        userService
          .openLinkInCampaign({
            campaignGuid: campaign.guid,
            affiliateKey: campaign.affiliateKey,
          })
          .then(() => {
            window.open(campaign.shareLink, "_blank");
          })
          .catch(showError)
          .finally(() => {
            setLoadingState(false);
          });
      })
      .catch(showError)
      .finally(() => {
        setLoadingState(false);
      });
  };

  const makeSelfRegistrationForm = () => {
    var form = {};

    if (campaign.formCPR.includes("nome completo")) {
      form["nome completo"] = userProfile.displayName;
    }
    if (campaign.formCPR.includes("data de nascimento")) {
      form["data de nascimento"] = userProfile.birthDate;
    }
    if (campaign.formCPR.includes("email")) {
      form["email"] = userProfile.email;
    }
    if (campaign.formCPR.includes("cpf")) {
      form["cpf"] = userProfile.cpf;
    }
    if (campaign.formCPR.includes("rg")) {
      form["rg"] = userProfile.rg;
    }
    if (campaign.formCPR.includes("telefone")) {
      form["telefone"] = userProfile.phone;
    }
    if (campaign.formCPR.includes("pix")) {
      form["pix"] = userProfile.pix;
    }
    if (campaign.formCPR.includes("cnpj")) {
      form["cnpj"] = userProfile.cnpj ?? "";
    }
    if (campaign.formCPR.includes("endereço")) {
      const address = userProfile.address;

      form["endereço"] =
        address.streetName +
        ", " +
        address.number +
        ", " +
        address.neighborhood +
        ", " +
        address.city +
        ", " +
        address.state +
        ", " +
        address.zipCode;
    }

    return JSON.stringify(form);
  };

  return { campaign, onCopyShareLink, onClickInCanpaignButton, pixel };
};

export default useCampaignDetailController;
