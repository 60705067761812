import React, { forwardRef, useState } from "react";
import Box from "@mui/material/Box/Box";
import Grid from "@mui/material/Grid/Grid";
import Card from "@mui/material/Card/Card";
import CardMedia from "@mui/material/CardMedia/CardMedia";
import Typography from "@mui/material/Typography/Typography";
import makeStyles from "@mui/styles/makeStyles/makeStyles";

import useLandPageController from "../land-page.controller";
import { useResponsive } from "../../../../providers";
import ArrowToLeftLargeIcon from "../../../../../assets/icones/arrow-to-left-large.svg";
import ArrowToRightLargeIcon from "../../../../../assets/icones/arrow-to-right-large.svg";

const useStyles = makeStyles(() => ({
  clicked: {
    backgroundColor: "#555",
    opacity: "0.1",
  },
}));

const CampaignsSectionComponent = forwardRef(({}, ref) => {
  const classes = useStyles();
  const { isDesktop } = useResponsive();
  const { products } = useLandPageController();
  const [currentImageIndex, setCurrentImageIndex] = React.useState(0);
  const [isClickedLeft, setIsClickedLeft] = useState(false);
  const [isClickedRight, setIsClickedRight] = useState(false);

  const styledText = (text) => {
    return (
      <span style={{ fontWeight: "bold", fontStyle: "italic" }}>{text}</span>
    );
  };

  const boldText = (text) => {
    return <span style={{ fontWeight: "bold" }}>{text}</span>;
  };

  const handleNext = () => {
    setCurrentImageIndex((currentImageIndex + 1) % products.length);
    setIsClickedRight(true);

    setTimeout(() => {
      setIsClickedRight(false);
    }, 100);
  };

  const handlePrev = () => {
    setCurrentImageIndex(
      (currentImageIndex - 1 + products.length) % products.length
    );
    setIsClickedLeft(true);

    setTimeout(() => {
      setIsClickedLeft(false);
    }, 100);
  };

  const getVisibleImages = () => {
    const lastIndex = currentImageIndex + (isDesktop ? 3 : 1);
    if (lastIndex < products.length) {
      return products.slice(currentImageIndex, lastIndex + 1);
    } else {
      const overflow = lastIndex - (products.length - 1);
      return products
        .slice(currentImageIndex)
        .concat(products.slice(0, overflow));
    }
  };

  const visibleProductsArray = getVisibleImages();

  return (
    <Box
      component="section"
      ref={ref}
      display="flex"
      flexDirection="column"
      alignItems="center"
      height="67vh"
      style={{
        background: "linear-gradient(292.46deg, #85539D, #6B2D87, #40215F)",
      }}
    >
      <Typography
        variant="h5"
        style={{
          color: "white",
          fontWeight: "bold",
          marginTop: "10vh",
          marginBottom: "2vh",
        }}
      >
        CAMPANHAS
      </Typography>
      <Typography
        style={{
          fontFamily: "sans-serif",
          fontSize: isDesktop ? "4.88vh" : "3.5vh",
          color: "white",
          textAlign: "center",
          paddingInline: isDesktop ? "20vw" : "10vw",
        }}
      >
        Descubra todos os produtos e serviços{" "}
        {boldText("para você compartilhar e ")}
        {styledText("ganhar comissões diárias.")}
      </Typography>

      <Grid
        container
        style={{
          alignItems: "center",
          justifyContent: "space-between",
          height: isDesktop ? "14.64vh" : "10.64vh",
          marginTop: "5vh",
          paddingInline: isDesktop ? "12vw" : "6vw",
        }}
      >
        <Grid item>
          <div
            onClick={handlePrev}
            aria-label="Anterior"
            className={isClickedLeft ? classes.clicked : ""}
          >
            <img
              src={ArrowToLeftLargeIcon}
              alt="Anterior"
              style={{
                cursor: "pointer",
                height: isDesktop ? null : "10vh",
                marginTop: isDesktop ? null : "0.5vh",
              }}
            />
          </div>
        </Grid>
        <Grid item>
          <Box display="flex">
            {visibleProductsArray.map((campanha, index) => {
              return (
                <Card
                  key={index}
                  style={{
                    width: isDesktop ? "14vw" : "30vw",
                    height: isDesktop ? "14vh" : "10vh",
                    marginInline: "1vw",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <CardMedia
                    src={campanha.label}
                    component="img"
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "contain",
                    }}
                  />
                </Card>
              );
            })}
          </Box>
        </Grid>
        <Grid item>
          <div
            onClick={handleNext}
            aria-label="Próximo"
            className={isClickedRight ? classes.clicked : ""}
          >
            <img
              src={ArrowToRightLargeIcon}
              alt="Próximo"
              style={{
                cursor: "pointer",
                height: isDesktop ? null : "10vh",
                marginTop: isDesktop ? null : "0.5vh",
              }}
            />
          </div>
        </Grid>
      </Grid>
    </Box>
  );
});

export default CampaignsSectionComponent;
