import Grid from "@mui/material/Grid/Grid";
import Typography from "@mui/material/Typography/Typography";
import makeStyles from "@mui/styles/makeStyles/makeStyles";

import { useResponsive } from "../../../../providers";
import {
  AutocompleteComponent,
  ButtonComponent,
  DatapickerComponent,
  InputFilterComponent,
} from "../../../../components";

const useStyles = makeStyles(() => ({
  counter: {
    display: "flex",
    borderRadius: "10px",
    borderColor: "#858585",
    border: "1px solid",
    fontWeight: "700",
    fontSize: "1.46vh",
    color: "#5C5C5C",
    height: "5.5vh",
    alignItems: "center",
    justifyContent: "center",
  },
}));

const ProposalsFilterComponent = ({
  control,
  onSubmit,
  campaignOptions,
  situationOptions,
  onDownloadSubmit,
  counters,
}) => {
  const { isDesktop } = useResponsive();
  const classes = useStyles();

  return (
    <div style={{ marginBottom: "8vh", width: "71vw" }}>
      <Grid component="form" onSubmit={onSubmit} container spacing={4}>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={isDesktop ? 3 : 6}>
              <InputFilterComponent
                name="clientName"
                control={control}
                label="NOME DO CLIENTE"
                placeholder="NOME DO CLIENTE"
              />
            </Grid>
            <Grid item xs={isDesktop ? 3 : 6}>
              <InputFilterComponent
                name="clientCpf"
                control={control}
                label="CPF DO CLIENTE"
                placeholder="CPF DO CLIENTE"
              />
            </Grid>
            <Grid item xs={isDesktop ? 3 : 6}>
              <AutocompleteComponent
                name="situation"
                control={control}
                label="SITUAÇÃO"
                acessorLabel="name"
                acessorValue="id"
                options={situationOptions}
              />
            </Grid>
            <Grid item xs={isDesktop ? 3 : 6}>
              <AutocompleteComponent
                name="campaign"
                control={control}
                label="CAMPANHA"
                acessorLabel="name"
                acessorValue="id"
                options={campaignOptions}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={isDesktop ? 4 : 6}>
              <DatapickerComponent
                name="startDate"
                control={control}
                label="DATA INICIAL"
                readOnly={true}
              />
            </Grid>
            <Grid item xs={isDesktop ? 4 : 6}>
              <DatapickerComponent
                name="endDate"
                control={control}
                label="DATA FINAL"
                readOnly={true}
              />
            </Grid>
            <Grid item xs={isDesktop ? 4 : 12}>
              <InputFilterComponent
                name="proposalType"
                control={control}
                label="TIPO DE PROPOSTA"
                placeholder="TIPO DE PROPOSTA"
              />
            </Grid>
          </Grid>
        </Grid>
        {!!counters && (
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={isDesktop ? 4 : 6}>
                <Typography className={classes.counter}>
                  PENDENTES: {counters.pending}
                </Typography>
              </Grid>
              <Grid item xs={isDesktop ? 4 : 6}>
                <Typography className={classes.counter}>
                  REPROVADAS: {counters.disapproved}
                </Typography>
              </Grid>
              <Grid item xs={isDesktop ? 4 : 6}>
                <Typography className={classes.counter}>
                  APROVADAS: {counters.approved}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        )}
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={isDesktop ? 2 : 6}>
              <ButtonComponent
                label="PESQUISAR"
                backgroundColor="#858585"
                color="white"
                size="small"
                fullWidth
                height="5vh"
                type="submit"
              />
            </Grid>
            <Grid item xs={isDesktop ? 2 : 6}>
              <ButtonComponent
                label="DOWNLOAD"
                backgroundColor="#774E98"
                color="white"
                fullWidth
                height="5vh"
                onClick={onDownloadSubmit}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default ProposalsFilterComponent;
