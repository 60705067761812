import Grid from "@mui/material/Grid/Grid";
import makeStyles from "@mui/styles/makeStyles/makeStyles";

import DescribleComponent from "./components/describle.component";
import FormComponent from "./components/form.component";
import BannerComponent from "./components/banner.component";
import { useResponsive } from "../../../providers";
import { useGlobalStyles } from "../../../hooks";

const useStyles = makeStyles(() => ({
  container: {
    alignItems: "center",
    justifyContent: "space-between",
  },
}));

const SignInPage = () => {
  const classes = useStyles();
  const { isDesktop } = useResponsive();
  const classesGlobal = useGlobalStyles();

  return (
    <Grid
      container
      className={classesGlobal.gradient + " " + classes.container}
    >
      {isDesktop && (
        <Grid item xs={4}>
          <DescribleComponent />
        </Grid>
      )}
      <Grid item xs={isDesktop ? 3 : 12}>
        <FormComponent />
      </Grid>
      {isDesktop && (
        <Grid item xs={4}>
          <BannerComponent />
        </Grid>
      )}
    </Grid>
  );
};

export default SignInPage;
