import React from "react";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

const AlertContext = React.createContext({
  showError: (message) => {},
  showSuccess: (message) => {},
  showInfo: (message) => {},
});

export const useAlert = () => React.useContext(AlertContext);

export const AlertProvider = ({ children }) => {
  const [message, setMessage] = React.useState();
  const [isOpen, setIsOpen] = React.useState(false);
  const [severity, setSeverity] = React.useState();

  const showError = (message) => showAlert(message, "error");

  const showSuccess = (message) => showAlert(message, "success");

  const showInfo = (message) => showAlert(message, "info");

  const showAlert = (message, severity) => {
    setSeverity(severity);
    setMessage(message);
    setIsOpen(true);
  };

  const closeAlert = () => {
    setIsOpen(false);
  };

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  return (
    <AlertContext.Provider value={{ showError, showSuccess, showInfo }}>
      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        open={isOpen}
        onClose={closeAlert}
      >
        <Alert severity={severity} onClose={closeAlert}>
          {message}
        </Alert>
      </Snackbar>
      {children}
    </AlertContext.Provider>
  );
};
