import LucreMaisBannerImage from "../../../../../assets/imagens/lucre-mais-banner.png";

const BannerComponent = () => {
  return (
    <img
      src={LucreMaisBannerImage}
      style={{ width: "100%", maxWidth: "39vw" }}
    />
  );
};

export default BannerComponent;
