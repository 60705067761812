import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import React, { createRef } from "react";
import * as yup from "yup";

import {
  advertiserService,
  campaignCategoryService,
  typePaymentService,
} from "../../../../infrastructure/services";
import { useAlert } from "../../../providers";
import { Constants, Validate } from "../../../utils";
import { useIsMounted } from "../../../hooks";
import { useLoadingContext } from "../../../context/loading.context";
import { useNavigate } from "react-router-dom";

const useRegisterAdvertiserController = () => {
  const { showError, showInfo, showSuccess } = useAlert();
  const _reCaptchaRef = createRef();
  const isMounted = useIsMounted();
  const navigate = useNavigate();
  const { setLoadingState } = useLoadingContext();

  const [categories, setCategories] = React.useState([
    Constants.defaultSelectPlaceholder,
  ]);
  const [typePayments, setTypePayments] = React.useState([
    Constants.defaultSelectPlaceholder,
  ]);

  const schema = yup.object().shape({
    nome: Validate.fullName,
    nomeEmpresa: yup.string().required("Campo obrigatório"),
    site: yup.string().required("Campo obrigatório"),
    email: Validate.email,
    telefone: Validate.phone,
    endereco: Validate.address,
    instagram: yup.string(),
    facebook: yup.string(),
    documento: yup.mixed().required("Campo obrigatório"),
    idTipoProduto: yup
      .object()
      .typeError("Campo obrigatório")
      .shape({
        id: yup
          .number()
          .positive("Campo obrigatório")
          .required("Campo obrigatório"),
      })
      .required("Campo obrigatório"),
    idTipoPagamento: yup
      .object()
      .typeError("Campo obrigatório")
      .shape({
        id: yup
          .number()
          .positive("Campo obrigatório")
          .required("Campo obrigatório"),
      })
      .required("Campo obrigatório"),
    valor: yup.string().required("Campo obrigatório"),
  });

  const { control, handleSubmit, setValue, reset } = useForm({
    defaultValues: {
      nome: "",
      nomeEmpresa: "",
      site: "",
      email: "",
      telefone: "",
      endereco: "",
      idTipoProduto: "",
      instagram: "",
      facebook: "",
      documento: "",
      idTipoPagamento: "",
      valor: "",
      reCaptcha: "",
    },
    resolver: yupResolver(schema),
  });

  React.useEffect(() => {
    setLoadingState(true);
    campaignCategoryService
      .getCategories()
      .then((response) => {
        if (isMounted.current) {
          setCategories([
            ...response.map((item) => ({ id: item.id, label: item.name })),
          ]);
        }
      })
      .catch(showError)
      .finally(() => {
        setLoadingState(false);
      });
    setLoadingState(true);
    typePaymentService
      .getTypePayment()
      .then((response) => {
        if (isMounted.current) {
          setTypePayments([
            ...response.map((item) => ({ id: item.id, label: item.name })),
          ]);
        }
      })
      .catch(showError)
      .finally(() => {
        setLoadingState(false);
      });
  }, []);

  const onSubmit = handleSubmit((data) => {
    if (data.reCaptcha === "") {
      showInfo(
        "O uso do reCAPTCHA é obrigatório. Por favor, complete o desafio para continuar."
      );
    } else {
      setLoadingState(true);
      advertiserService
        .register({
          form: {
            ...data,
            idTipoPagamento: data.idTipoPagamento.id,
            idTipoProduto: data.idTipoProduto.id,
          },
        })
        .then((res) => {
          showSuccess("Cadastro enviado com sucesso.");
          navigate("/");
        })
        .catch(showError)
        .finally(() => {
          setLoadingState(false);
        });
    }
  });

  const handleBase64Change = (value) => {
    setValue("documento", value);
  };

  return {
    control,
    onSubmit,
    setValue,
    _reCaptchaRef,
    categories,
    typePayments,
    handleBase64Change,
  };
};

export default useRegisterAdvertiserController;
