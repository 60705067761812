import Grid from "@mui/material/Grid/Grid";
import IconButton from "@mui/material/IconButton/IconButton";
import Typography from "@mui/material/Typography/Typography";
import Card from "@mui/material/Card/Card";
import makeStyles from "@mui/styles/makeStyles/makeStyles";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useNavigate } from "react-router-dom";

import {
  BodyComponent,
  ButtonComponent,
  CurrencyChipComponent,
  InputSearchComponent,
  SelectComponent,
  TableComponent,
} from "../../../components";
import useCampaignsController from "./campaigns.controller";
import { useAuth, useResponsive } from "../../../providers";
import CampanhasBannerImage from "../../../../assets/imagens/campanhas-banner.png";

const useStyles = makeStyles(() => ({
  banner: {
    width: "71vw",
    height: "31.25vh",
    backgroundColor: "#40215F",
    marginBottom: "4vh",
    borderRadius: "10px",
  },
  bannerTitle: {
    color: "#AA4291",
    fontSize: "4.88vh",
    fontWeight: "800",
    fontStyle: "italic",
  },
  bannerDescrible: {
    color: "white",

    fontWeight: "400",
    marginRight: "3vw",
  },
  tableHeader: {
    display: "flex",
    alignItems: "center",
    borderColor: "transparent",
    marginInline: "3vw",
    height: "11vh",
  },
  tableHeaderItem: {
    color: "#5C5C5C",
    fontWeight: "700",
    width: "17vw",
    textAlign: "start",
    fontSize: "1.75vh",
  },
  tableBodyRow: {
    display: "flex",
    alignItems: "center",
    borderRadius: "100px",
    marginTop: "4vh",
    marginBottom: "4vh",
    marginInline: "3vw",
    paddingRight: "1vw",
    height: "11vh",
  },
  tableBodyRowItem: {
    color: "#5C5C5C",
    fontWeight: "400",
    width: "17vw",
    textAlign: "start",
    fontSize: "1.46vh",
  },
  tableBodyRowFirstItem: {
    marginLeft: "3vw",

    display: "flex",
    justifyContent: "center",
    width: "11vh",
  },
}));

const CampaignsPage = () => {
  const navigate = useNavigate();
  const classes = useStyles();
  const { user } = useAuth();
  const { isDesktop } = useResponsive();
  const {
    campaigns,
    categories,
    categoryToFilter,
    setCategoryToFilter,
    categoryNameFilter,
    setCategoryNameFilter,
  } = useCampaignsController();

  return (
    <BodyComponent>
      <Card elevation={0} className={classes.banner}>
        <Grid container style={{ alignItems: "center", height: "100%" }}>
          <Grid item xs={isDesktop ? 9 : 12} style={{ paddingLeft: "4vw" }}>
            <Typography className={classes.bannerTitle}>CAMPANHAS</Typography>
            <Typography
              className={classes.bannerDescrible}
              style={{ fontSize: isDesktop ? "1.75vh" : "1.27vh" }}
            >
              Escolha as campanhas que você se identifica +, copie o LINK de
              auto contratação e divulgue como quiser para LUCRAR muito. Algumas
              campanhas é necessário solicitar o LINK de acesso para
              compartilhar, após liberado já poderá compartilhar na sua rede de
              contatos e Lucre muito +. A Lucre+ é a maior plataforma de vendas
              por link de auto contratação, seu desafio é apenas criar
              estratégias de divulgação do link. Cada campanha, possui a data de
              pagamento de comissão em sua descrição.
            </Typography>
          </Grid>
          {isDesktop && (
            <Grid item xs={3} style={{ textAlign: "center" }}>
              <img
                src={CampanhasBannerImage}
                style={{ height: "100%", maxHeight: "23.43vh" }}
              />
            </Grid>
          )}
        </Grid>
      </Card>

      <Grid
        container
        spacing={isDesktop ? 4 : 2}
        style={{
          width: "71vw",
          marginBottom: "4vh",
          alignItems: "center",
          justifyContent: "start",
        }}
      >
        <Grid item xs={isDesktop ? 4 : 12}>
          <InputSearchComponent
            placeholder="PESQUISAR CAMPANHA"
            fullWidth={true}
            value={categoryNameFilter}
            onChange={(value) => setCategoryNameFilter(value)}
          />
        </Grid>
        <Grid item xs={isDesktop ? 4 : 12}>
          <SelectComponent
            label="BUSCAR POR CATEGORIA"
            value={categoryToFilter}
            onChange={(_, value) => setCategoryToFilter(value)}
            options={categories}
          />
        </Grid>
      </Grid>

      <TableComponent
        columns={[
          {
            label: "MARCA",
            acessor: "logoUrl",
            width: isDesktop ? "14vh" : "8vh",
            formatWidget: (row) => {
              return (
                <div
                  style={{
                    width: isDesktop ? "13vh" : "6vh",
                    height: isDesktop ? "6vh" : "3vh",
                    marginLeft: isDesktop ? null : "0.5vw",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <img
                    src={row["logoUrl"]}
                    style={{
                      maxHeight: isDesktop ? "6vh" : "3vh",
                      maxWidth: isDesktop ? "13vh" : "6vh",
                    }}
                  />
                </div>
              );
            },
          },
          {
            label: "CAMPANHA",
            acessor: "name",
            width: isDesktop ? "36vw" : "28vw",
            formatWidget: (row) => {
              return (
                <Typography
                  style={{
                    fontWeight: "700",
                    fontSize: isDesktop ? "1.86vh" : "0.77vh",
                    color: "#5C5C5C",
                    whiteSpace: "pre-line",
                  }}
                >
                  {row["name"]}
                </Typography>
              );
            },
          },
          {
            label:
              !!user && user.role == "ADMINISTRADOR"
                ? "COMISSÃO DA CASA"
                : "COMISSÃO",
            acessor: "commission",
            width: isDesktop ? "7vw" : "15vw",
            formatWidget: (row) => (
              <CurrencyChipComponent
                backgroundColor="#774E98"
                value={
                  row["commissionType"] == "R$"
                    ? row["commission"].toLocaleString("pt-BR", {
                        style: "currency",
                        currency: "BRL",
                      })
                    : row["commission"] + "%"
                }
              />
            ),
          },
          {
            label: "",
            acessor: "id",
            width: isDesktop ? "11vw" : "9vw",
            formatWidget: (row) => {
              if (isDesktop) {
                return (
                  <ButtonComponent
                    label={"SOLICITAR ACESSO"}
                    backgroundColor="#40215F"
                    color="white"
                    fontSize={null}
                    size="small"
                    height={"4vh"}
                    onClick={() => navigate("/campanhas/" + row["id"])}
                  />
                );
              } else {
                return (
                  <IconButton
                    size="small"
                    style={{ backgroundColor: "#40215F" }}
                    onClick={() => navigate("/campanhas/" + row["id"])}
                  >
                    <ArrowForwardIosIcon style={{ color: "white" }} />
                  </IconButton>
                );
              }
            },
          },
        ]}
        rows={campaigns}
      />
    </BodyComponent>
  );
};

export default CampaignsPage;
