import React from "react";
import Typography from "@mui/material/Typography/Typography";

import { GenericDialogComponent, ButtonComponent } from "../../..";

const TEXT =
  "Você pode ser um anunciante e colocar seus produtos online e infoprodutos para venda em nossa plataforma.\n Se você vende produtos físicos, também poderá incluir. Apenas ressaltamos que precisa ter capacidade de distribuição nacional.\n Pode anunciar prestação de serviços, assim como qualquer outro tipo de oportunidade, apenas precisa passar pela nossa análise interna.\n Se você tiver contato com empresas, pode intermediar os anúncios e trazer os negócios para dentro de nossa plataforma também.\n Está pronto para LUCRAR MUITO? Aproveite está oportunidade única de transformar seus ganhos!";

const BeAnAdvertiserDialogComponent = () => {
  const onWhatsApp = () => {
    window.open(
      "https://api.whatsapp.com/send?phone=554791670447&text=Olá, estou vindo da plataforma LUCRE MAIS. Quero ser um ANUNCIANTE!",
      "_blank"
    );
  };

  return (
    <GenericDialogComponent name="be-an-advertiser-dialog">
      <Typography
        style={{
          marginBottom: "4vh",
          fontWeight: "700",
          fontSize: "1.95vh",
          color: "#40215F",
        }}
      >
        SEJA UM ANUNCIANTE
      </Typography>

      <Typography paragraph>
        {TEXT.split("\n").map((line, index) => (
          <React.Fragment key={index}>
            {line}
            <br />
          </React.Fragment>
        ))}
      </Typography>

      <div
        style={{
          marginTop: "4vh",
          display: "flex",
          width: "100%",
          justifyContent: "end",
        }}
      >
        <div style={{ marginLeft: "2vw" }}>
          <ButtonComponent
            label={"ANUNCIAR"}
            backgroundColor="#40215F"
            color="white"
            size="small"
            height="5vh"
            onClick={onWhatsApp}
          />
        </div>
      </div>
    </GenericDialogComponent>
  );
};

export default BeAnAdvertiserDialogComponent;
