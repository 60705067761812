import React, { useEffect } from "react";
import Typography from "@mui/material/Typography/Typography";
import makeStyles from "@mui/styles/makeStyles/makeStyles";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import {
  ButtonComponent,
  GenericDialogComponent,
  InputComponent,
} from "../../../../../components";
import { useDialog } from "../../../../../providers";

const useStyles = makeStyles(() => ({
  content: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: "1vh",
    marginBottom: "2vh",
  },
}));

const AdminCategoryFormDialogComponent = ({ onDelete, onCreate, onEdit }) => {
  const { metadata: category } = useDialog();
  const classes = useStyles();
  const schema = yup.object().shape({
    name: yup.string().required("Campo obrigatório"),
  });

  const { control, handleSubmit, reset } = useForm({
    defaultValues: {
      name: "",
    },
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (!!category) {
      reset({ name: category.name });
    } else {
      reset({ name: "" });
    }
  }, [category]);

  const onSubmit = handleSubmit(({ name }) => {
    if (!!category) {
      onEdit({ id: category.id, name: name });
    } else {
      onCreate({ name: name });
    }
  });

  return (
    <GenericDialogComponent name="admin-category-form-dialog">
      <form onSubmit={onSubmit} className={classes.content}>
        <Typography
          style={{
            marginBottom: "2vh",
            fontWeight: "700",
            fontSize: "1.95vh",
            color: "#40215F",
          }}
        >
          {!!category ? "EDITAR" : "CRIAR NOVA"} CATEGORIA
        </Typography>

        <InputComponent
          name="name"
          placeholder="NOME DA CATEGORIA"
          control={control}
        />

        <div
          style={{
            marginTop: "4vh",
            display: "flex",
            width: "100%",
            justifyContent: "end",
          }}
        >
          {!!category && (
            <ButtonComponent
              label="EXCLUIR"
              backgroundColor="#774E98"
              color="white"
              size="small"
              height="5vh"
              onClick={() => onDelete({ id: category.id })}
            />
          )}
          <div style={{ marginLeft: "2vw" }}>
            <ButtonComponent
              label={!!category ? "EDITAR" : "CRIAR"}
              backgroundColor="#40215F"
              color="white"
              size="small"
              height="5vh"
              type="submit"
            />
          </div>
        </div>
      </form>
    </GenericDialogComponent>
  );
};

export default AdminCategoryFormDialogComponent;
