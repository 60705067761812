import { useEffect, useState } from "react";

import { useAlert, useAuth } from "../../../../providers";
import { menuService } from "../../../../../infrastructure/services";

const useSidebarController = () => {
  const { showError } = useAlert();
  const { user } = useAuth();

  const [menu, setMenu] = useState(null);

  useEffect(() => {
    if (user != null) {
      menuService
        .getMenu()
        .then((menu) => {
          setMenu(menu);
        })
        .catch(showError);
    }
  }, [user]);

  return { menu };
};

export default useSidebarController;
