import Box from "@mui/material/Box/Box";
import Grid from "@mui/material/Grid/Grid";
import Typography from "@mui/material/Typography/Typography";
import Divider from "@mui/material/Divider/Divider";
import Card from "@mui/material/Card/Card";
import CardContent from "@mui/material/CardContent/CardContent";
import makeStyles from "@mui/styles/makeStyles/makeStyles";

import {
  BodyComponent,
  ButtonComponent,
  InputComponent,
  PhotoPickerComponent,
} from "../../../components";
import useProfileController from "./profile.controller";
import { useResponsive } from "../../../providers";

const useStyles = makeStyles(() => ({
  cardTitle: {
    padding: "4vh",
    fontSize: "1.75vh",
    color: "#5C5C5C",
    fontWeight: "700",
    marginLeft: "1.5vw",
  },
  cardContent: {
    paddingTop: "4vh",
    paddingBottom: "4vh",
    paddingInline: "3vw",
  },
}));

const ProfilePage = () => {
  const classes = useStyles();
  const { isDesktop } = useResponsive();
  const { control, onSubmit, pendingConfirmation, deactivateAccount } =
    useProfileController();

  return (
    <BodyComponent>
      <Card variant="outlined" style={{ width: "71vw", borderRadius: "10px" }}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <Typography className={classes.cardTitle}>PERFIL</Typography>
          {pendingConfirmation && (
            <Typography color="error">
              Você possui alterações pendentes de confirmação. Acesse seu email
              e confirme as alterações!
            </Typography>
          )}
        </div>
        <Divider />
        <CardContent className={classes.cardContent}>
          <Box
            component="form"
            display="flex"
            flexDirection="column"
            alignItems="center"
            onSubmit={onSubmit}
            style={{ display: "flex", justifyContent: "center" }}
          >
            <Grid
              container
              style={{ maxWidth: isDesktop ? "25.13vw" : "100%" }}
            >
              <Grid item xs={12}>
                <PhotoPickerComponent
                  label={"Carregar imagem"}
                  name={"photoUrl"}
                  control={control}
                />
              </Grid>
              <Grid item xs={12}>
                <InputComponent
                  name="displayName"
                  control={control}
                  label="NOME COMPLETO"
                />
              </Grid>
              <Grid item xs={12}>
                <InputComponent name="email" control={control} label="E-MAIL" />
              </Grid>
              <Grid item xs={12}>
                <InputComponent
                  name="phone"
                  control={control}
                  label="TELEFONE"
                />
              </Grid>
              <Grid item xs={12}>
                <InputComponent
                  name="cpf"
                  control={control}
                  label="CPF"
                  disabled={true}
                />
              </Grid>
              <Grid item xs={12}>
                <InputComponent
                  name="pix"
                  control={control}
                  label="CHAVE DO PIX"
                />
              </Grid>
            </Grid>
            <div
              style={{
                display: "flex",
                gap: "50%",
                width: "100%",
                flexDirection: isDesktop ? "row" : "column",
                textAlign: "center",
                marginTop: "4vh",
              }}
            >
              <ButtonComponent
                disabled={pendingConfirmation}
                type="submit"
                label="SALVAR"
                backgroundColor="#774E98"
                height="5vh"
                color="white"
                fullWidth
              />
              <ButtonComponent
                onClick={deactivateAccount}
                label="DESATIVAR CONTA"
                backgroundColor="#960909"
                height="5vh"
                color="white"
                fullWidth
              />
            </div>
          </Box>
        </CardContent>
      </Card>
    </BodyComponent>
  );
};

export default ProfilePage;
