import React from "react";
import Grid from "@mui/material/Grid/Grid";
import Typography from "@mui/material/Typography/Typography";
import makeStyles from "@mui/styles/makeStyles/makeStyles";

import CheckIcon from "../../../../../assets/icones/check.svg";

const useStyles = makeStyles(() => ({
  quadrado: {
    position: "relative",
    width: "203px",
    height: "154px",
    backgroundColor: "#1E682E",
    borderRadius: "10px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "white",
  },
  circulo: {
    position: "absolute",
    top: "-25px",
    left: "50%",
    transform: "translateX(-50%)",
    backgroundColor: "#ADC312",
    width: "55px",
    height: "55px",
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

const CardInfoComponent = (text) => {
  const classes = useStyles();

  return (
    <Grid container style={{ justifyContent: "center" }}>
      <Grid item>
        <div className={classes.quadrado}>
          <div className={classes.circulo}>
            <img src={CheckIcon} alt="Ícone" />
          </div>
          <Typography
            style={{
              fontWeight: "700",
              fontSize: "18px",
              color: "white",
              margin: "1vw",
            }}
            align="center"
          >
            {text.text}
          </Typography>
        </div>
      </Grid>
    </Grid>
  );
};

export default CardInfoComponent;
