import Avatar from "@mui/material/Avatar/Avatar";
import Typography from "@mui/material/Typography/Typography";
import makeStyles from "@mui/styles/makeStyles/makeStyles";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import { Controller } from "react-hook-form";

const useStyles = makeStyles(() => ({
  photoCard: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  photoAvatar: {
    width: "11.13vh",
    height: "11.13vh",
    "&:hover": {
      cursor: "pointer",
    },
  },
  photoButton: {
    marginTop: "1vh",
    fontSize: "1.46vh",
    color: "#858585",
    fontWeight: "400",
    "&:hover": {
      cursor: "pointer",
    },
  },
}));

const PhotoPickerComponent = ({ label, name, control }) => {
  const classes = useStyles();

  return (
    <div className={classes.photoCard}>
      <Controller
        name={name}
        control={control}
        render={({ field, formState }) => {
          const handleChange = (event) => {
            if (event.target.files && event.target.files[0]) {
              const reader = new FileReader();
              reader.onload = (e) => {
                field.onChange(e.target.result);
              };
              reader.readAsDataURL(event.target.files[0]);
            }
          };

          return (
            <div>
              <input
                id="photo-picker"
                accept="image/png, image/jpeg"
                style={{ display: "none" }}
                type="file"
                onChange={handleChange}
              />
              <label htmlFor="photo-picker" className={classes.photoCard}>
                <Avatar src={field.value} className={classes.photoAvatar}>
                  <CameraAltIcon style={{ height: "5vh", width: "5vh" }} />
                </Avatar>
                {field.value == null && (
                  <Typography className={classes.photoButton}>
                    {label}
                  </Typography>
                )}
              </label>
            </div>
          );
        }}
      />
    </div>
  );
};

export default PhotoPickerComponent;
