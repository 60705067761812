import makeStyles from "@mui/styles/makeStyles/makeStyles";

const useGlobalStyles = makeStyles((theme) => ({
  gradient: {
    background: "linear-gradient(292.46deg, #85539D, #6B2D87, #40215F)",
    minHeight: "100vh",
  },
  background: {
    backgroundColor: "#F9F6F8",
  },
  minHeightAuto: {
    minHeight: "auto",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 2,
  },
  bottomBar: {
    zIndex: theme.zIndex.drawer + 1,
    top: "auto",
    bottom: 0,
  },
  linkDecorationNone: {
    color: "inherit",
    textDecoration: "none",
  },
}));

export default useGlobalStyles;
