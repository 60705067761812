import Divider from "@mui/material/Divider/Divider";
import Typography from "@mui/material/Typography/Typography";
import Card from "@mui/material/Card/Card";
import CardContent from "@mui/material/CardContent/CardContent";
import makeStyles from "@mui/styles/makeStyles/makeStyles";

import {
  AccordionComponent,
  CurrencyChipComponent,
  TableComponent,
} from "../../../../components";
import { Format } from "../../../../utils";
import { useResponsive } from "../../../../providers";

const useStyles = makeStyles(() => ({
  cardTitle: {
    padding: "4vh",
    fontSize: "1.75vh",
    color: "#5C5C5C",
    fontWeight: "700",
    marginLeft: "1.5vw",
  },
  cardContent: {
    paddingTop: "4vh",
    paddingBottom: "4vh",
    paddingInline: "3vw",
  },
}));

const CommissionsTableComponent = ({
  affiliated,
  toggleExpanded,
  openCommissionDialog,
  expanded,
}) => {
  const classes = useStyles();
  const { isDesktop } = useResponsive();

  return (
    <Card variant="outlined" style={{ width: "71vw", borderRadius: "10px" }}>
      <Typography className={classes.cardTitle}>AFILIADOS</Typography>
      <Divider />
      <CardContent className={classes.cardContent}>
        {affiliated.map((affiliate, index) => {
          return (
            <AccordionComponent
              key={index}
              border="hidden"
              marginBottom={0}
              padding={0}
              expanded={expanded == affiliate.id}
              toggleExpanded={toggleExpanded(affiliate.id)}
              summary={
                <Typography
                  style={{
                    fontWeight: "700",
                    fontSize: "1.75vh",
                    color: "#5C5C5C",
                  }}
                >
                  {affiliate.displayName}
                </Typography>
              }
              details={
                <TableComponent
                  hiddenBorder
                  width="65vw"
                  columns={[
                    {
                      label: "PRODUTO",
                      acessor: "campaign",
                      width: isDesktop ? "20vw" : "22vw",
                      formatWidget: (row) => {
                        return (
                          <Typography
                            style={{
                              fontWeight: "700",
                              fontSize: isDesktop ? "1.86vh" : "0.77vh",
                              color: "#5C5C5C",
                              whiteSpace: "pre-line",
                            }}
                          >
                            {row["campaign"]["name"]}
                          </Typography>
                        );
                      },
                    },
                    {
                      label: "SUA COMISSÃO",
                      acessor: "commissionReceivable",
                      width: isDesktop ? "10vw" : "19vw",
                      formatWidget: (row) => {
                        return (
                          <CurrencyChipComponent
                            backgroundColor="#774E98"
                            value={
                              row["commissionType"] == "R$"
                                ? row["commissionReceivable"].toLocaleString(
                                    "pt-BR",
                                    {
                                      style: "currency",
                                      currency: "BRL",
                                    }
                                  )
                                : row["commissionReceivable"] + "%"
                            }
                          />
                        );
                      },
                    },
                    {
                      label: "AFILIADO (%)",
                      acessor: "commissionPayablePercent",
                      width: "10vw",
                      formatText: (row) => {
                        return row["commissionPayablePercent"] + "%";
                      },
                    },
                    {
                      label: "AFILIADO (R$)",
                      acessor: "commissionPayable",
                      width: isDesktop ? "10vw" : "11vw",
                      formatWidget: (row) => {
                        if (row["commissionType"] == "R$") {
                          return (
                            <CurrencyChipComponent
                              value={row["commissionPayable"].toLocaleString(
                                "pt-BR",
                                {
                                  style: "currency",
                                  currency: "BRL",
                                }
                              )}
                            />
                          );
                        }
                        return;
                      },
                    },
                    {
                      label: "SUA NOVA COMISSÃO",
                      acessor: "commissionPayable",
                      width: isDesktop ? "10vw" : "11vw",
                      formatWidget: (row) => {
                        var novaComissao = row["commissionReceivable"];
                        novaComissao =
                          row["commissionReceivable"] -
                          (row["commissionReceivable"] *
                            row["commissionPayablePercent"]) /
                            100;
                        return (
                          <CurrencyChipComponent
                            backgroundColor="#774E98"
                            value={
                              row["commissionType"] == "R$"
                                ? novaComissao.toLocaleString("pt-BR", {
                                    style: "currency",
                                    currency: "BRL",
                                  })
                                : novaComissao.toLocaleString("fullwide", {
                                    maximumFractionDigits: 2,
                                  }) + "%"
                            }
                          />
                        );
                      },
                    },
                  ]}
                  rows={affiliate.commissions}
                  onClick={(row) =>
                    openCommissionDialog({
                      affiliate,
                      commissionReceivable: row["commissionReceivable"],
                      commissionPayablePercent: row["commissionPayablePercent"],
                      commissionType: row["commissionType"],
                      campaignId: row["campaign"]["id"],
                    })
                  }
                />
              }
            />
          );
        })}
      </CardContent>
    </Card>
  );
};

export default CommissionsTableComponent;
