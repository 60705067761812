import TextField from "@mui/material/TextField/TextField";
import styled from "@mui/material/styles/styled";
import { Controller } from "react-hook-form";
import { NumericFormat } from "react-number-format";

const StyledTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    fontWeight: "700",
    fontSize: "1.46vh",
    color: "#858585",
    height: "5.5vh",
    "& fieldset": {
      borderRadius: "10px",
    },
    "&:hover fieldset": {
      borderRadius: "10px",
    },
    "&.Mui-focused fieldset": {
      borderRadius: "10px",
    },
    "&.Mui-error fieldset": {
      borderRadius: "10px",
      borderColor: "red",
    },
    "&.Mui-disabled fieldset": {
      borderRadius: "10px",
    },
  },
});

const InputFormatComponent = ({
  name,
  control,
  label,
  placeholder,
  disabled,
  multiline,
  minRows,
  type,
  value,
  onChange,
  onClick,
  format,
}) => {
  const Field = ({ field, formState }) => {
    const handleInputChange = (event) => {
      const { value } = event.target;
      if (!!field) {
        field.onChange(value);
      } else {
        onChange(value);
      }
    };

    return (
      <NumericFormat
        customInput={StyledTextField}
        format={format == "%" ? "##%" : null}
        suffix={format == "%" ? " %" : undefined}
        prefix={format == "R$" ? "R$ " : undefined}
        allowNegative={false}
        allowLeadingZeros={false}
        decimalScale={format == "R$" ? 2 : undefined}
        fixedDecimalScale={format == "R$"}
        thousandSeparator={format == "R$" ? "." : false}
        decimalSeparator={format == "R$" ? "," : undefined}
        onValueChange={(values) => {
          const { formattedValue } = values;
          handleInputChange({ target: { value: formattedValue } });
        }}
        placeholder={label}
        label={placeholder}
        disabled={disabled || (!!field && formState.isSubmitting)}
        error={!!field ? !!formState.errors[name] : false}
        helperText={!!field ? formState.errors[name]?.message : null}
        style={{ height: multiline ? null : "5.5vh" }}
        InputLabelProps={{
          style: {
            fontWeight: "700",
            fontSize: "1.46vh",
            color: "#5C5C5C",
          },
        }}
        name={name}
        variant="outlined"
        margin="none"
        fullWidth
        type={type}
        value={!!field ? field.value : value}
      />
    );
  };

  if (!!control) {
    return (
      <Controller
        name={name}
        control={control}
        render={({ field, formState }) => (
          <Field field={field} formState={formState} />
        )}
      />
    );
  }

  return <Field field={null} formState={null} />;
};

export default InputFormatComponent;
