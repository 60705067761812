import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Box from "@mui/material/Box/Box";
import Grid from "@mui/material/Grid/Grid";
import Typography from "@mui/material/Typography/Typography";
import makeStyles from "@mui/styles/makeStyles/makeStyles";

import {
  AutocompleteComponent,
  ButtonComponent,
  GenericDialogComponent,
  InputFilterComponent,
} from "../../../../components";
import { Constants } from "../../../../utils";
import { useDialog } from "../../../../providers";
import CampaignCard from "./campaign-card.component";
import ColorPickerComponent from "../../../../components/form/color-picker.component";

const useStyles = makeStyles(() => ({
  content: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: "1vh",
    marginBottom: "2vh",
  },
}));

const ChangeFeaturedCampaignDialogComponent = ({
  campaignOptions,
  campaigns,
  onChangeFeatureCampaign,
}) => {
  const classes = useStyles();
  const { metadata: campaignCard, isOpen } = useDialog();

  const schema = yup.object().shape({
    title: yup.string().required("Campo obrigatório"),
    describle: yup.string().required("Campo obrigatório"),
    color: yup.string().required("Campo obrigatório"),
    campaign: yup
      .object()
      .required("Campo obrigatório")
      .test("invalid", "Selecione uma das opções", (value) => {
        return value.id > 0;
      }),
  });

  const defaultValues = {
    title: "",
    describle: "",
    color: "#1E682E",
    campaign: Constants.defaultSelectPlaceholder,
  };

  const { control, handleSubmit, reset, getValues, setValue } = useForm({
    defaultValues: defaultValues,
    resolver: yupResolver(schema),
  });

  const [campaignCardToPreview, setCampaignCardToPreview] =
    useState(defaultValues);

  useEffect(() => {
    if (!!campaignCard && isOpen == "change-featured-campaign-dialog") {
      const values = {
        title: campaignCard.title,
        describle: campaignCard.describle,
        color: campaignCard.color,
        campaign: {
          id: campaignCard.campaign.id,
          label: campaignCard.campaign.name,
          name: campaignCard.campaign.name,
          logoUrl: campaignCard.campaign.logoUrl,
        },
      };

      reset(values);
      setCampaignCardToPreview(values);
    }
  }, [campaignCard]);

  const onUpdatePreview = () => {
    setCampaignCardToPreview({
      ...getValues(),
      campaign: campaigns.find((c) => c.id == getValues("campaign.id")),
    });
  };

  const onSubmit = handleSubmit((data) => {
    const form = {
      idProdutoEmAmostra: campaignCard.id,
      idProduto: data.campaign.id,
      titulo: data.title,
      descricao: data.describle,
      cor: data.color,
    };

    onChangeFeatureCampaign(form);
  });

  return (
    <GenericDialogComponent
      name="change-featured-campaign-dialog"
      maxWidth="lg"
    >
      <form onSubmit={onSubmit} className={classes.content}>
        <Typography
          style={{
            marginBottom: "6vh",
            fontWeight: "700",
            fontSize: "1.95vh",
            color: "#40215F",
          }}
        >
          EDITE O CARD
        </Typography>

        <Grid container spacing={2} style={{ marginBottom: "4vh" }}>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <InputFilterComponent
                  name="title"
                  control={control}
                  label="TITULO"
                  placeholder="TITULO"
                />
              </Grid>
              <Grid item xs={6}>
                <AutocompleteComponent
                  name="campaign"
                  control={control}
                  label="CAMPANHA"
                  acessorLabel="label"
                  acessorValue="id"
                  options={campaignOptions}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <InputFilterComponent
                  name="describle"
                  control={control}
                  label="DESCRIÇÃO"
                  placeholder="DESCRIÇÃO"
                  multiline={true}
                  minRows={4}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <ColorPickerComponent
                  style={{ width: "100%" }}
                  name="color"
                  label="COR"
                  control={control}
                  isExterna={true}
                  setValue={setValue}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <div
          style={{
            padding: "2vh",
            backgroundColor: "#F9F6F8",
            marginBottom: "4vh",
            borderRadius: "10px",
          }}
        >
          <CampaignCard featured={campaignCardToPreview} isPreview={true} />
        </div>

        <Box style={{ display: "flex", justifyContent: "end" }}>
          <ButtonComponent
            label="PRÉ-VISUALIZAR"
            backgroundColor="#774E98"
            height="5vh"
            color="white"
            onClick={onUpdatePreview}
          />
          <div style={{ marginLeft: "2vw" }}>
            <ButtonComponent
              label="SALVAR"
              backgroundColor="#774E98"
              height="5vh"
              color="white"
              type="submit"
            />
          </div>
        </Box>
      </form>
    </GenericDialogComponent>
  );
};

export default ChangeFeaturedCampaignDialogComponent;
