import React, { useEffect, useState } from "react";

import { useIsMounted } from "../../../../hooks";
import { useAlert, useAuth } from "../../../../providers";
import {
  campaignCategoryService,
  campaignService,
} from "../../../../../infrastructure/services";
import { Constants } from "../../../../utils";
import { useLoadingContext } from "../../../../context/loading.context";

const useAdminCampaignsController = () => {
  const { user } = useAuth();
  const isMounted = useIsMounted();
  const { showError } = useAlert();
  const [campaigns, setCampaigns] = useState([]);
  const [categories, setCategories] = React.useState([Constants.defaultSelect]);
  const { setLoadingState } = useLoadingContext();

  useEffect(() => {
    if (user != null) {
      setLoadingState(true);
      campaignService
        .getCampaigns()
        .then((response) => {
          if (isMounted.current) {
            setCampaigns(response);
          }
        })
        .catch(showError)
        .finally(() => {
          setLoadingState(false);
        });

      setLoadingState(true);
      campaignCategoryService
        .getCategories()
        .then((response) => {
          if (isMounted.current) {
            setCategories([
              Constants.defaultSelect,
              ...response.map((item) => ({ id: item.id, label: item.name })),
            ]);
          }
        })
        .catch(showError)
        .finally(() => {
          setLoadingState(false);
        });
    }
  }, [user]);

  return { campaigns, categories };
};

export default useAdminCampaignsController;
