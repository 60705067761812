import createTheme from "@mui/material/styles/createTheme";
import { red } from "@mui/material/colors";
import { ptBR } from "@mui/material/locale";

const theme = createTheme(
  {
    typography: {
      fontFamily: "Open Sans, sans-serif",
    },
    palette: {
      primary: {
        main: "#774E98",
      },
      secondary: {
        main: red.A400,
      },
      error: {
        main: red.A400,
      },
      background: {
        default: "#F9F6F8",
      },
    },
  },
  ptBR
);

export default theme;
