import { useState } from "react";
import Badge from "@mui/material/Badge/Badge";
import Divider from "@mui/material/Divider/Divider";
import Grid from "@mui/material/Grid/Grid";
import IconButton from "@mui/material/IconButton/IconButton";
import Typography from "@mui/material/Typography/Typography";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";

import {
  AutocompleteMultipleComponent,
  ButtonComponent,
  ImageListCardComponent,
  InputFilterComponent,
  InputFormatComponent,
  InputWithButtonComponent,
  SelectComponent,
  TableComponent,
} from "../../../../../components";

const CPVSectionComponent = ({
  control,
  formCPVOptions,
  productsCPV,
  onAddProduct,
  onRemoveProduct,
  onAddCPVQuestion,
  onEditProduct,
  onAddProductToEdit,
  editProductMode,
  editImage,
  commissionTypeOptions,
  productCommissionType,
  isLoading,
  productImages,
  addProductImage,
  removeProductImage,
}) => {
  const [question, setQuestion] = useState("");
  const [questionError, setQuestionError] = useState(null);

  return (
    <div>
      <Typography
        style={{
          fontWeight: "600",
          fontSize: "1.75vh",
          color: "#5C5C5C",
        }}
      >
        CPV - POR VENDA:
      </Typography>
      <Divider style={{ marginBottom: "2vh" }} />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={8}>
              <AutocompleteMultipleComponent
                name="formCPV"
                control={control}
                label="CAMPOS DO FORMULÁRIO"
                withoutAcessor={true}
                options={formCPVOptions}
                disabled={isLoading}
              />
            </Grid>
            <Grid item xs={4}>
              <InputWithButtonComponent
                placeholder="PERGUNTAS"
                color="#5C5C5C"
                isLight={true}
                value={question}
                onChange={setQuestion}
                disabled={isLoading}
                onClick={
                  isLoading == false
                    ? () => {
                        setQuestionError(null);
                        if (
                          !!question &&
                          question.length > 1 &&
                          !formCPVOptions.includes(question)
                        ) {
                          onAddCPVQuestion(question);
                          setQuestion("");
                        } else {
                          setQuestionError("Pergunta inválida!");
                        }
                      }
                    : null
                }
                buttonLabel="ADICIONAR"
                errorMessage={questionError}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2} style={{ alignItems: "center" }}>
            <Grid item xs={12}>
              <Grid container spacing={2} style={{ alignItems: "center" }}>
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={4}>
                      <InputFilterComponent
                        name="productTitle"
                        label="TÍTULO"
                        placeholder="TÍTULO"
                        control={control}
                        disabled={isLoading}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <InputFormatComponent
                        name="productValue"
                        control={control}
                        label="PREÇO (R$)"
                        placeholder="PREÇO (R$)"
                        format={"R$"}
                        disabled={isLoading}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <InputFilterComponent
                        name="productCode"
                        label="CÓDIGO"
                        placeholder="CÓDIGO"
                        control={control}
                        disabled={editProductMode || isLoading}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={4}>
                      <SelectComponent
                        name="productCommissionType"
                        control={control}
                        label="TIPO DE COMISSÃO"
                        acessor="label"
                        options={commissionTypeOptions}
                        disabled={isLoading}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <InputFormatComponent
                        name="productCommission"
                        control={control}
                        label="COMISSÃO DA CASA"
                        placeholder="COMISSÃO DA CASA"
                        format={productCommissionType == 1 ? "R$" : "%"}
                        disabled={isLoading}
                      />
                    </Grid>
                    <Grid item xs={4}></Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <InputFilterComponent
                name="productDescrible"
                control={control}
                label="DESCRIÇÃO DO PRODUTO"
                placeholder="DESCRIÇÃO DO PRODUTO"
                multiline={true}
                minRows={7}
                disabled={isLoading}
              />
            </Grid>
            <Grid item xs={12}>
              <ImageListCardComponent
                name={"sub-product"}
                title="IMAGENS"
                images={productImages}
                addSupportImage={addProductImage}
                removeSupportImage={removeProductImage}
                isDynamic={true}
                width="100%"
                disabled={isLoading}
              />
            </Grid>
            <Grid item xs={12}>
              <ButtonComponent
                label={editProductMode ? "EDITAR PRODUTO" : "ADICIONAR PRODUTO"}
                backgroundColor="#40215F"
                color="white"
                size="small"
                height="5vh"
                disabled={isLoading}
                onClick={
                  isLoading == false
                    ? () => {
                        if (editProductMode) {
                          onEditProduct();
                        } else {
                          onAddProduct();
                        }
                      }
                    : null
                }
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          {productsCPV.length > 0 && (
            <TableComponent
              width="100%"
              columns={[
                {
                  label: "IMAGENS",
                  acessor: "images",
                  formatWidget: (row) => (
                    <Badge
                      overlap="rectangular"
                      color="primary"
                      badgeContent={
                        row.images.length > 1 ? (
                          <Typography>+{row.images.length - 1}</Typography>
                        ) : null
                      }
                    >
                      <img
                        src={row.images[0].url}
                        style={{ height: "5vh", width: "auto" }}
                      />
                    </Badge>
                  ),
                },
                { label: "TÍTULO", acessor: "title" },
                {
                  label: "VALOR",
                  acessor: "value",
                  formatText: (row) =>
                    parseFloat(row.value).toLocaleString("pt-BR", {
                      style: "currency",
                      currency: "BRL",
                    }),
                },
                { label: "CÓDIGO", acessor: "code" },
                {
                  label: "COMISSÃO",
                  acessor: "commission",
                  formatText: (row) => {
                    if (row.commissionType == 1) {
                      return row.commission.toLocaleString("pt-BR", {
                        style: "currency",
                        currency: "BRL",
                      });
                    } else {
                      if (String(row.commission).endsWith("%")) {
                        return row.commission;
                      } else {
                        return row.commission + "%";
                      }
                    }
                  },
                },
                {
                  label: "AÇÕES",
                  acessor: "commission",
                  center: true,
                  formatWidget: (row) => {
                    return (
                      <div>
                        <IconButton
                          disabled={isLoading}
                          onClick={
                            isLoading == false
                              ? () => onAddProductToEdit(row.code)
                              : null
                          }
                        >
                          <EditOutlinedIcon color="primary" />
                        </IconButton>
                        <IconButton
                          disabled={isLoading}
                          onClick={
                            isLoading == false
                              ? () => onRemoveProduct(row.code)
                              : null
                          }
                        >
                          <DeleteOutlineOutlinedIcon color="primary" />
                        </IconButton>
                      </div>
                    );
                  },
                },
              ]}
              rows={productsCPV}
            />
          )}
        </Grid>
      </Grid>
    </div>
  );
};

export default CPVSectionComponent;
