import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import { useAlert, useAuth, useDialog } from "../../../../providers";
import { useIsMounted } from "../../../../hooks";
import React, { useEffect } from "react";
import { Constants, Format, Validate } from "../../../../utils";
import {
  bankService,
  campaignService,
  proposalService,
  userService,
} from "../../../../../infrastructure/services";
import { useLoadingContext } from "../../../../context/loading.context";
import { useNavigate } from "react-router-dom";

const useAdminProposalFormController = ({ proposalId }) => {
  const commissionTypeOptions = [
    { id: 1, label: "PREÇO FIXO" },
    { id: 2, label: "PORCENTAGEM" },
  ];

  const schema = yup.object().shape({
    clientName: Validate.fullName,
    clientCpf: Validate.cpf,
    affiliated: yup
      .object()
      .required("Campo obrigatório")
      .test("invalid", "Selecione uma das opções", (value) => {
        return value.id > 0;
      }),
    campaign: yup
      .object()
      .required("Campo obrigatório")
      .test("invalid", "Selecione uma das opções", (value) => {
        return value.id > 0;
      }),
    proposalType: yup.string().required("Campo obrigatório"),
    bank: yup.object().optional(),
    parcelValue: yup.string().optional(),
    parcelQuantity: yup.number().optional(),
    value: yup.string().required("Campo obrigatório"),

    houseCommission: yup.string().required("Campo obrigatório"),
    houseCommissionType: yup
      .number()
      .required("Campo obrigatório")
      .test("invalid", "Selecione uma das opções", (value) => {
        return value > 0;
      }),

    affiliatedCommission: yup.string().required("Campo obrigatório"),
    affiliatedCommissionType: yup
      .number()
      .required("Campo obrigatório")
      .test("invalid", "Selecione uma das opções", (value) => {
        return value > 0;
      }),
    eventDate: yup
      .date()
      .typeError("Data inválida")
      .required("Campo obrigatório"),
  });

  const { control, handleSubmit, reset, watch } = useForm({
    defaultValues: {
      clientName: "",
      clientCpf: "",
      affiliated: Constants.defaultSelectPlaceholder,
      campaign: Constants.defaultSelectPlaceholder,
      proposalType: "",
      bank: Constants.defaultSelectPlaceholder,
      parcelValue: "",
      parcelQuantity: 0,
      value: "",
      houseCommission: 0,
      houseCommissionType: commissionTypeOptions[0].id,
      affiliatedCommission: 0,
      affiliatedCommissionType: commissionTypeOptions[0].id,
      eventDate: "",
    },
    resolver: yupResolver(schema),
  });

  const navigate = useNavigate();
  const { user } = useAuth();
  const { showError, showSuccess } = useAlert();
  const isMounted = useIsMounted();
  const { openDialog, closeDialog } = useDialog();
  const { setLoadingState } = useLoadingContext();
  const [houseCommissionType, setHouseCommissionType] = React.useState(
    commissionTypeOptions[0].id
  );
  const [affiliatedCommissionType, setAffiliatedCommissionType] =
    React.useState(commissionTypeOptions[0].id);

  const [isLoading, setIsLoading] = React.useState(false);
  const [campaigns, setCampaigns] = React.useState([
    Constants.defaultSelectPlaceholder,
  ]);
  const [users, setUsers] = React.useState([
    Constants.defaultSelectPlaceholder,
  ]);
  const [banks, setBanks] = React.useState([
    Constants.defaultSelectPlaceholder,
  ]);
  const [filterIsLoaded, setFilterIsLoaded] = React.useState(false);
  const [situation, setSituation] = React.useState("PENDENTE");

  useEffect(() => {
    if (!!user) {
      setIsLoading(true);
      setLoadingState(true);
      campaignService
        .getUserCampaigns()
        .then((response) => {
          if (isMounted.current) {
            setCampaigns([
              Constants.defaultSelectPlaceholder,
              ...response.map((item) => ({ id: item.id, label: item.name })),
            ]);

            setLoadingState(true);
            userService
              .getAffilietdToReports({ id: user.id })
              .then((response) => {
                if (isMounted.current) {
                  setUsers([
                    Constants.defaultSelectPlaceholder,
                    ...response.map((item) => ({
                      id: item.id,
                      label: item.displayName,
                    })),
                  ]);

                  setLoadingState(true);
                  bankService
                    .getBanks()
                    .then((response) => {
                      if (isMounted.current) {
                        setBanks([
                          Constants.defaultSelectPlaceholder,
                          ...response.map((item) => ({
                            id: item.id,
                            label: item.name,
                          })),
                        ]);
                        setIsLoading(false);
                        setFilterIsLoaded(true);
                      }
                    })
                    .catch(showError)
                    .finally(() => {
                      setLoadingState(false);
                    });
                }
              })
              .catch(showError)
              .finally(() => {
                setLoadingState(false);
              });
          }
        })
        .catch(showError)
        .finally(() => {
          setLoadingState(false);
        });
    }
  }, [user]);

  useEffect(() => {
    if (!!proposalId && filterIsLoaded == true) {
      fetchProposal();
    }
  }, [proposalId, filterIsLoaded]);

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (name == "houseCommissionType") {
        setHouseCommissionType(value.houseCommissionType);
      } else if (name == "affiliatedCommissionType") {
        setAffiliatedCommissionType(value.affiliatedCommissionType);
      }
    });

    return () => subscription.unsubscribe();
  }, [watch]);

  const fetchProposal = () => {
    setIsLoading(true);

    setLoadingState(true);
    proposalService
      .getProposalById({ id: proposalId, withCommission: 0 })
      .then((response) => {
        setHouseCommissionType(response.houseCommissionType);
        setAffiliatedCommissionType(response.affiliatedCommissionType);
        setSituation(response.situation);
        reset({
          ...response,
          affiliated: users.filter(
            (o) => o.label == response.affiliatedName
          )[0],
          campaign: campaigns.filter(
            (o) => o.label == response.campaignName
          )[0],
          bank:
            response.bankName != null
              ? banks.filter((o) => o.label == response.bankName)[0]
              : Constants.defaultSelectPlaceholder,
        });
        setIsLoading(false);
      })
      .catch(showRequestError)
      .finally(() => {
        setLoadingState(false);
      });
  };

  const onSubmit = handleSubmit((data) => {
    var query = {
      idAfiliado: data.affiliated.id,
      idProduto: data.campaign.id,
      clienteNome: data.clientName,
      clienteCpf: data.clientCpf,
      comissaoCasa:
        houseCommissionType == 1
          ? Format.currencyToFloat(data.houseCommission)
          : data.houseCommission,
      tipoComissaoCasa: data.houseCommissionType,
      comissaoAfiliado:
        affiliatedCommissionType == 1
          ? Format.currencyToFloat(data.affiliatedCommission)
          : data.affiliatedCommission,
      tipoComissaoAfiliado: data.affiliatedCommissionType,
      tipoProposta: data.proposalType,
      valor: Format.currencyToFloat(data.value),
      dtEvento: data.eventDate,
    };

    if (data.bank.id != Constants.defaultSelectPlaceholder.id) {
      query["idBanco"] = data.bank.id;
    }

    if (!!data.parcelValue) {
      query["parcelaValor"] = Format.currencyToFloat(data.parcelValue);
    }

    if (data.parcelQuantity > 0) {
      query["parcelaQuantidade"] = data.parcelQuantity;
    }

    setIsLoading(true);

    if (!!proposalId) {
      setLoadingState(true);
      proposalService
        .editProposal({ id: proposalId, query })
        .then(() => {
          setIsLoading(false);
          showSuccess("Proposta editada!");
        })
        .catch(showRequestError)
        .finally(() => {
          setLoadingState(false);
        });
    } else {
      setLoadingState(true);
      proposalService
        .createProposal({ query })
        .then(() => {
          navigate("/admin/propostas");
          setIsLoading(false);
          showSuccess("Proposta criada!");
        })
        .catch(showRequestError)
        .finally(() => {
          setLoadingState(false);
        });
    }
  });

  const onDeleteProposal = () => {
    setIsLoading(true);

    setLoadingState(true);
    proposalService
      .deleteProposal({ id: proposalId })
      .then(() => {
        setIsLoading(false);
        showSuccess("Proposta deletada!");
        navigate("/admin/propostas");
      })
      .catch(showRequestError)
      .finally(() => {
        setLoadingState(false);
      });
  };

  const showRequestError = (error) => {
    setIsLoading(false);
    showError(error);
  };

  const onApproveProposal = () => {
    openDialog("admin-approve-proposal-form-dialog", true);
  };

  const onRepproveProposal = () => {
    openDialog("admin-approve-proposal-form-dialog", false);
  };

  const onSubmitApproveProposal = (observations) => {
    setIsLoading(true);

    var form = { status: "APROVADA" };

    if (!!observations) {
      form["observacoes"] = observations;
    }

    setLoadingState(true);
    proposalService
      .changeSituationOfProposal({
        id: proposalId,
        query: form,
      })
      .then(() => {
        setSituation("APROVADO");
        setIsLoading(false);
        closeDialog();
        showSuccess("Proposta aprovada");
      })
      .catch(showRequestError)
      .finally(() => {
        setLoadingState(false);
      });
  };

  const onSubmitRepproveProposal = (observations) => {
    setIsLoading(true);

    var form = { status: "REPROVADA" };

    if (!!observations) {
      form["observacoes"] = observations;
    }

    setLoadingState(true);
    proposalService
      .changeSituationOfProposal({
        id: proposalId,
        query: form,
      })
      .then(() => {
        setSituation("REPROVADO");
        setIsLoading(false);
        closeDialog();
        showSuccess("Proposta reprovada");
      })
      .catch(showRequestError)
      .finally(() => {
        setLoadingState(false);
      });
  };

  return {
    control,
    isToEdit: !!proposalId,
    onSubmit,
    onDeleteProposal,
    isLoading,
    userOptions: users,
    campaingsOptions: campaigns,
    bankOptions: banks,
    onApproveProposal,
    onRepproveProposal,
    onSubmitApproveProposal,
    onSubmitRepproveProposal,
    situation,
    commissionTypeOptions,
    affiliatedCommissionType,
    houseCommissionType,
  };
};

export default useAdminProposalFormController;
