import Grid from "@mui/material/Grid/Grid";
import Typography from "@mui/material/Typography/Typography";
import Divider from "@mui/material/Divider/Divider";

import { InputFormatComponent } from "../../../../components";

const CommissionSectionComponent = ({ control }) => {
  return (
    <div>
      <Typography
        style={{
          fontWeight: "600",
          fontSize: "1.75vh",
          color: "#5C5C5C",
        }}
      >
        COMISSÕES DA PROPOSTA
      </Typography>
      <Divider style={{ marginBottom: "2vh" }} />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <InputFormatComponent
                name="value"
                control={control}
                label="VALOR DA PROPOSTA"
                placeholder="VALOR DA PROPOSTA"
                format="R$"
                disabled={true}
              />
            </Grid>
            <Grid item xs={4}>
              <InputFormatComponent
                name="affiliatedCommissionCurrency"
                control={control}
                label="SUA COMISSÃO (R$)"
                placeholder="SUA COMISSÃO (R$)"
                format="R$"
                disabled={true}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default CommissionSectionComponent;
