import Grid from "@mui/material/Grid/Grid";
import Typography from "@mui/material/Typography/Typography";
import makeStyles from "@mui/styles/makeStyles/makeStyles";
import { useForm } from "react-hook-form";
import React, { useEffect, useState } from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import SelectComponent from "./form/select.component";
import AutocompleteComponent from "./form/autocomplete.component";
import DatapickerComponent from "./form/datapicker.component";
import ButtonComponent from "./form/button.component";
import { useAuth, useResponsive } from "../providers";
import { Constants } from "../utils";

const useStyles = makeStyles(() => ({
  counter: {
    display: "flex",
    borderRadius: "10px",
    borderColor: "#858585",
    border: "1px solid",
    fontWeight: "700",
    fontSize: "1.46vh",
    color: "#5C5C5C",
    height: "5.5vh",
    alignItems: "center",
    justifyContent: "center",
  },
}));

const FiltersComponent = ({
  userOptions,
  campaignOptions,
  onSearch,
  autoSearch,
  situationOptions,
  actions,
  counters,
}) => {
  const classes = useStyles();
  const defaultSituationOptions = [
    { id: 0, label: "TODOS" },
    { id: 1, label: "APROVADAS" },
    { id: 2, label: "REPROVADAS" },
    { id: 3, label: "PENDENTES" },
  ];

  const schema = yup.object().shape({
    startDate: yup
      .date()
      .typeError("Data inválida")
      .required("Campo obrigatório"),
    endDate: yup
      .date()
      .typeError("Data inválida")
      .required("Campo obrigatório")
      .when("startDate", (startDate, schema) =>
        schema.min(startDate, "A data final deve ser maior que a data inicial")
      ),
    user: yup.object().required("Campo obrigatório"),
    campaign: yup.object().required("Campo obrigatório"),
    situation: yup.number().required("Campo obrigatório"),
  });

  const { user } = useAuth();
  const { isDesktop } = useResponsive();
  const { control, handleSubmit, watch, getValues } = useForm({
    defaultValues: {
      startDate: new Date(new Date().setDate(new Date().getDate() - 5)),
      endDate: new Date(),
      user: userOptions[0],
      campaign: campaignOptions[0],
      situation: 0,
      step: null,
    },
    resolver: yupResolver(schema),
  });
  const [situations, setSituations] = useState(
    situationOptions ?? defaultSituationOptions
  );

  useEffect(() => {
    if (user != null) {
      if (!!autoSearch) {
        onSubmit();
      }
    }
  }, [user]);

  React.useEffect(() => {
    var subscription;

    if (!!autoSearch) {
      subscription = watch((value, { name, type }) => onSubmit());
    }

    return () => {
      if (!!subscription) subscription.unsubscribe();
    };
  }, [watch]);

  const onSubmit = handleSubmit((data) =>
    onSearch({
      newPage: 0,
      ...data,
      situation: situations.filter(
        (situation) => situation.id == data.situation
      )[0],
      isDownload: false,
    })
  );

  const onDownloadSubmit = () => {
    onSearch({
      newPage: 0,
      ...getValues(),
      situation: situations.filter(
        (situation) => situation.id == getValues("situation")
      )[0],
      isDownload: true,
    });
  };

  return (
    <div style={{ width: "71vw" }}>
      <Grid
        component="form"
        onSubmit={onSubmit}
        container
        spacing={3}
        style={{ marginBottom: "3vh" }}
      >
        <Grid item xs={12}>
          <Grid container spacing={isDesktop ? 4 : 2}>
            <Grid item xs={isDesktop ? 6 : 12}>
              <Grid container spacing={isDesktop ? 4 : 2}>
                <Grid item xs={6}>
                  <DatapickerComponent
                    name="startDate"
                    control={control}
                    label="DATA INICIAL"
                    readOnly={true}
                  />
                </Grid>
                <Grid item xs={6}>
                  <DatapickerComponent
                    name="endDate"
                    control={control}
                    label="DATA FINAL"
                    readOnly={true}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={isDesktop ? 6 : 12}>
              {isDesktop ? (
                <AutocompleteComponent
                  name="user"
                  control={control}
                  label="USUÁRIO"
                  acessorLabel="displayName"
                  acessorValue="id"
                  options={userOptions}
                  isLarge={true}
                />
              ) : (
                <SelectComponent
                  name="situation"
                  control={control}
                  label="SITUAÇÃO"
                  acessor="label"
                  options={situations}
                />
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={isDesktop ? 4 : 2}>
            <Grid item xs={6}>
              <AutocompleteComponent
                name="campaign"
                control={control}
                label="PRODUTO/SERVIÇO"
                acessorLabel="name"
                acessorValue="id"
                options={campaignOptions}
              />
            </Grid>
            <Grid item xs={6}>
              {isDesktop ? (
                <SelectComponent
                  name="situation"
                  control={control}
                  label="SITUAÇÃO"
                  acessor="label"
                  options={situations}
                />
              ) : (
                <AutocompleteComponent
                  name="user"
                  control={control}
                  label="USUÁRIO"
                  acessorLabel="displayName"
                  acessorValue="id"
                  options={userOptions}
                  isLarge={true}
                />
              )}
            </Grid>
          </Grid>
          {!!user && user.isAdmin && (
            <Grid container spacing={isDesktop ? 4 : 2}>
              <Grid item xs={6}>
                <AutocompleteComponent
                  name="step"
                  control={control}
                  label="Etapa"
                  acessorLabel="label"
                  acessorValue="id"
                  options={Constants.reportSteps}
                />
              </Grid>
            </Grid>
          )}
        </Grid>
        {!!counters && (
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={isDesktop ? 4 : 6}>
                <Typography className={classes.counter}>
                  PENDENTES: {counters.pending}
                </Typography>
              </Grid>
              <Grid item xs={isDesktop ? 4 : 6}>
                <Typography className={classes.counter}>
                  REPROVADAS: {counters.disapproved}
                </Typography>
              </Grid>
              <Grid item xs={isDesktop ? 4 : 6}>
                <Typography className={classes.counter}>
                  APROVADAS: {counters.approved}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        )}
        {!autoSearch && (
          <Grid item xs={12} style={{ marginTop: isDesktop ? "2vh" : 0 }}>
            <Grid container spacing={isDesktop ? 4 : 2}>
              <Grid item xs={isDesktop ? 2 : 6}>
                <ButtonComponent
                  label="PESQUISAR"
                  backgroundColor="#858585"
                  color="white"
                  fullWidth
                  height="5vh"
                  type="submit"
                />
              </Grid>
              <Grid item xs={isDesktop ? 2 : 6}>
                <ButtonComponent
                  label="DOWNLOAD"
                  backgroundColor="#774E98"
                  color="white"
                  fullWidth
                  height="5vh"
                  onClick={onDownloadSubmit}
                />
              </Grid>
              {(actions ?? []).map((action, index) => (
                <Grid key={index} item xs={2}>
                  {action}
                </Grid>
              ))}
            </Grid>
          </Grid>
        )}
      </Grid>
    </div>
  );
};

export default FiltersComponent;
