import React, { createContext, useContext, useState } from "react";
import SpinnerComponent from "../components/spinner.component";

const LoadingContext = createContext();

export const useLoadingContext = () => useContext(LoadingContext);

export const LoadingProvider = ({ children }) => {
  const [showSpinner, setShowSpinner] = useState(false);

  const setLoadingState = (isLoading) => {
    setShowSpinner(isLoading);
  };

  return (
    <LoadingContext.Provider value={{ showSpinner, setLoadingState }}>
      {showSpinner && <SpinnerComponent />}
      {children}
    </LoadingContext.Provider>
  );
};
