import Grid from "@mui/material/Grid/Grid";
import Typography from "@mui/material/Typography/Typography";

import { ButtonGradientComponent } from "../../../../components";
import { useResponsive } from "../../../../providers";

const TitleComponent = () => {
  const { isDesktop } = useResponsive();

  return (
    <Grid
      container
      style={{
        alignItems: "center",
        justifyContent: "center",
        paddingTop: isDesktop ? "4vh" : "1vh",
        paddingBottom: isDesktop ? "1vh" : "9vh",
      }}
    >
      <Grid
        item
        xs={isDesktop ? 5 : 12}
        style={{
          paddingTop: "1vh",
          marginRight: "1vh",
          textAlign: isDesktop ? "justify" : "center",
        }}
      >
        <Typography
          style={{ fontWeight: "600", fontSize: "3.9vh", color: "white" }}
        >
          SEJA UM
        </Typography>
        <Typography
          style={{ fontWeight: "800", fontSize: "3.9vh", color: "white" }}
        >
          LICENCIADO RIZOMA
        </Typography>
      </Grid>
      <Grid
        item
        xs={isDesktop ? 4 : 12}
        style={{
          textAlign: "justify",
          paddingLeft: isDesktop ? "0" : "3vh",
          paddingRight: isDesktop ? "0" : "3vh",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography
          style={{
            fontWeight: "400",
            fontSize: "1.95vh",
            color: "white",
            marginBottom: "2vh",
          }}
        >
          Mentoria e treinamento gratuito para tornar-se um consultor financeiro
          e trabalhar como um banco autônomo.
        </Typography>
        <ButtonGradientComponent
          height="5vh"
          label="FAÇA SEU CADASTRO"
          background="linear-gradient(91.84deg, #ADC312, #FBD000)"
          onClick={() =>
            window.open("https://gruporizoma.com.br/cadastro/", "_blank")
          }
        />
      </Grid>
    </Grid>
  );
};

export default TitleComponent;
