const Constants = {
  defaultSelect: { id: 0, label: "TODOS" },
  defaultSelectPlaceholder: { id: 0, label: "SELECIONE" },
  iconsPath: (icon) => "../../../../assets/icons/" + icon,
  imagesPath: (image) => "../../../../assets/images/" + image,
  getProductionStep: (step) => {
    switch (step) {
      case "conta-pessoal_etapa_aprovacao_start_2167":
        return "Conta Pessoal - Aprovação - Visualização";
      case "conta-pessoal_etapa_aprovacao_inicio-proposta_2167":
        return "Conta Pessoal - Aprovação - Início";
      case "conta-pessoal_etapa_aprovacao_proposta-finalizada_2167":
        return "Conta Pessoal - Aprovação - Finalizada";
      case "conta-pessoal_sucesso_aprovacao_conta-qualificada_2167":
        return "Conta Pessoal - Aprovação - Qualificado";

      case "conta-pessoal_etapa_lead_start_1187":
        return "Conta Pessoal - Lead - Visualização";
      case "conta-pessoal_etapa_lead_conta-pendente-qualificacao_1187":
        return "Conta Pessoal - Lead - Qualifacação";
      case "conta-pessoal_sucesso_conta-qualificada_1187":
        return "Conta Pessoal - Lead - Qualificada";

      case "cartao-de-credito_etapa_lead_cadastro-completo_1325":
        return "Cartão de Crédito - Cadastro Completo";
      case "cartao-de-credito_sucesso_aprovacao_1325":
        return "Cartão de Crédito - Aprovado";

      case "conta-pessoal_etapa_aprovacao_start_aj_1865":
        return "Conta Pessoal - Aprovação - Visualização";
      case "conta-pessoal_etapa_aprovacao_carregamento-inicial_aj_1865":
        return "Conta Pessoal - Aprovação - Início";
      case "conta-pessoal_etapa_aprovacao_digite-cpf_aj_1865":
        return "Conta Pessoal - Aprovação - CPF";
      case "conta-pessoal_etapa_aprovacao_dados-iniciais_aj_1865":
        return "Conta Pessoal - Aprovação - Dados iniciais";
      case "conta-pessoal_etapa_aprovacao_profissao_aj_1865":
        return "Conta Pessoal - Aprovação - Profissão";
      case "conta-pessoal_etapa_aprovacao_selfie_aj_1865":
        return "Conta Pessoal - Aprovação - Selfie";
      case "conta-pessoal_etapa_aprovacao_conta-em-processo-abertura_aj_1865":
        return "Conta Pessoal - Aprovação - Processo de abertura";
      case "conta-pessoal_sucesso_aprovacao_aj_1865":
        return "Conta Pessoal - Aprovação - Sucesso";

      case "cartao-de-credito_etapa_lead_preencheu-dados-minimos_1695":
        return "Cartão de Crédito - Lead - Dados mínimos";
      case "cartao-de-credito_sucesso_aprovacao_cartao-internacional_1695":
        return "Cartão de Crédito - Lead - Aprovado";

      case "cartao-de-credito_etapa_lead_preencheu-dados-minimos_2198":
        return "Cartão de Crédito - Lead - Dados mínimos";

      case "cartao-de-credito_fracasso_aprovacao_pre-aprovado-nao_2084":
        return "Cartão de Crédito - Aprovação - Não Aprovado";

      case "contato_para_compra":
        return "Contato para compra";
      case "formulario_preenchido":
        return "Formulário preenchido";
      case "cadastro_preenchido":
        return "Cadastro preenchido";
      case "pagina_aberta":
        return "Página aberta";
      case "link_aberto":
        return "Link aberto";
    }
  },
  states: [
    { id: 1, label: "AC" },
    { id: 2, label: "AL" },
    { id: 3, label: "AM" },
    { id: 4, label: "AP" },
    { id: 5, label: "BA" },
    { id: 6, label: "CE" },
    { id: 7, label: "DF" },
    { id: 8, label: "ES" },
    { id: 9, label: "GO" },
    { id: 10, label: "MA" },
    { id: 11, label: "MG" },
    { id: 12, label: "MS" },
    { id: 13, label: "MT" },
    { id: 14, label: "PA" },
    { id: 15, label: "PB" },
    { id: 16, label: "PE" },
    { id: 17, label: "PI" },
    { id: 18, label: "PR" },
    { id: 19, label: "RJ" },
    { id: 20, label: "RN" },
    { id: 21, label: "RO" },
    { id: 22, label: "RR" },
    { id: 23, label: "RS" },
    { id: 24, label: "SC" },
    { id: 25, label: "SE" },
    { id: 26, label: "SP" },
    { id: 27, label: "TO" },
  ],
  reportSteps: [
    {
      id: "conta-pessoal_etapa_aprovacao_start_2167",
      label: "Conta Pessoal - Aprovação - Visualização",
    },
    {
      id: "conta-pessoal_etapa_aprovacao_inicio-proposta_2167",
      label: "Conta Pessoal - Aprovação - Início",
    },
    {
      id: "conta-pessoal_etapa_aprovacao_proposta-finalizada_2167",
      label: "Conta Pessoal - Aprovação - Finalizada",
    },
    {
      id: "conta-pessoal_sucesso_aprovacao_conta-qualificada_2167",
      label: "Conta Pessoal - Aprovação - Qualificado",
    },
    {
      id: "conta-pessoal_etapa_lead_start_1187",
      label: "Conta Pessoal - Lead - Visualização",
    },
    {
      id: "conta-pessoal_etapa_lead_conta-pendente-qualificacao_1187",
      label: "Conta Pessoal - Lead - Qualificação",
    },
    {
      id: "conta-pessoal_sucesso_conta-qualificada_1187",
      label: "Conta Pessoal - Lead - Qualificada",
    },
    {
      id: "cartao-de-credito_etapa_lead_cadastro-completo_1325",
      label: "Cartão de Crédito - Cadastro Completo",
    },
    {
      id: "cartao-de-credito_sucesso_aprovacao_1325",
      label: "Cartão de Crédito - Aprovado",
    },
    {
      id: "conta-pessoal_etapa_aprovacao_start_aj_1865",
      label: "Conta Pessoal - Aprovação - Visualização",
    },
    {
      id: "conta-pessoal_etapa_aprovacao_carregamento-inicial_aj_1865",
      label: "Conta Pessoal - Aprovação - Início",
    },
    {
      id: "conta-pessoal_etapa_aprovacao_digite-cpf_aj_1865",
      label: "Conta Pessoal - Aprovação - CPF",
    },
    {
      id: "conta-pessoal_etapa_aprovacao_dados-iniciais_aj_1865",
      label: "Conta Pessoal - Aprovação - Dados iniciais",
    },
    {
      id: "conta-pessoal_etapa_aprovacao_profissao_aj_1865",
      label: "Conta Pessoal - Aprovação - Profissão",
    },
    {
      id: "conta-pessoal_etapa_aprovacao_selfie_aj_1865",
      label: "Conta Pessoal - Aprovação - Selfie",
    },
    {
      id: "conta-pessoal_etapa_aprovacao_conta-em-processo-abertura_aj_1865",
      label: "Conta Pessoal - Aprovação - Processo de abertura",
    },
    {
      id: "conta-pessoal_sucesso_aprovacao_aj_1865",
      label: "Conta Pessoal - Aprovação - Sucesso",
    },
    {
      id: "cartao-de-credito_etapa_lead_preencheu-dados-minimos_1695",
      label: "Cartão de Crédito - Lead - Dados mínimos",
    },
    {
      id: "cartao-de-credito_sucesso_aprovacao_cartao-internacional_1695",
      label: "Cartão de Crédito - Lead - Aprovado",
    },
    {
      id: "cartao-de-credito_etapa_lead_preencheu-dados-minimos_2198",
      label: "Cartão de Crédito - Lead - Dados mínimos",
    },
    {
      id: "cartao-de-credito_fracasso_aprovacao_pre-aprovado-nao_2084",
      label: "Cartão de Crédito - Aprovação - Não Aprovado",
    },
    {
      id: "contato_para_compra",
      label: "Contato para compra",
    },
    {
      id: "formulario_preenchido",
      label: "Formulário preenchido",
    },
    {
      id: "cadastro_preenchido",
      label: "Cadastro preenchido",
    },
    {
      id: "pagina_aberta",
      label: "Página aberta",
    },
  ],
  months: [
    { id: 0, label: "Janeiro" },
    { id: 1, label: "Fevereiro" },
    { id: 2, label: "Março" },
    { id: 3, label: "Abril" },
    { id: 4, label: "Maio" },
    { id: 5, label: "Junho" },
    { id: 6, label: "Julho" },
    { id: 7, label: "Agosto" },
    { id: 8, label: "Setembro" },
    { id: 9, label: "Outubro" },
    { id: 10, label: "Novembro" },
    { id: 11, label: "Dezembro" },
  ],
  getYearsList: () => {
    const currentYear = new Date().getFullYear();
    const years = [];

    for (let i = 0; i <= 20; i++) {
      const year = currentYear - i;
      years.push({ id: year, label: year.toString() });
    }

    return years;
  },
  getTypeOptionsList: () => [
    { id: 1, label: "DIRECIONADO", value: "DIRECIONADO" },
    { id: 2, label: "POR GRUPO", value: "POR GRUPO" },
  ],
  getSendTypeOptionsList: () => [
    { id: 1, label: "DIRECIONADO", value: "DIRECIONADO" },
    { id: 2, label: "POR GRUPO", value: "POR GRUPO" },
    { id: 3, label: "DATA CADASTRO", value: "DATA CADASTRO" },
    { id: 4, label: "EXTERNO", value: "EXTERNO" },
  ],
  DIRECIONADO: 1,
  POR_GRUPO: 2,
  DATA_CADASTRO: 3,
  EXTERNO: 4,
};

export default Constants;
