import Badge from "@mui/material/Badge/Badge";
import Divider from "@mui/material/Divider/Divider";
import Paper from "@mui/material/Paper/Paper";
import Typography from "@mui/material/Typography/Typography";
import makeStyles from "@mui/styles/makeStyles/makeStyles";

import _ from "lodash";

import { useResponsive } from "../../providers";
import ButtonPickerComponent from "./button-picker.component";
import CloseIcon from "@mui/icons-material/Close";

const useStyles = makeStyles(() => ({
  cardTitle: {
    marginTop: "4vh",
    marginBottom: "4vh",
    fontWeight: "700",
    fontSize: "1.75vh",
    color: "#5C5C5C",
  },
  mediaRow: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    borderRadius: "100px",
    marginTop: "4vh",
    marginBottom: "4vh",
    marginInline: "3vw",
    height: "11vh",
  },
  mediaRowItem: {
    height: "100%",
    "&:hover": {
      cursor: "pointer",
    },
  },
  closeButton: {
    "&:hover": {
      cursor: "pointer",
    },
  },
}));

const ImageListCardComponent = ({
  title,
  images,
  addSupportImage,
  removeSupportImage,
  isDynamic,
  width,
  disabled,
  name,
}) => {
  const classes = useStyles();
  const { isDesktop } = useResponsive();

  return (
    <Paper
      variant="outlined"
      style={{
        width: width ?? "71vw",
        borderRadius: "10px",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginInline: "4vh",
        }}
      >
        <Typography className={classes.cardTitle}>{title}</Typography>{" "}
        {isDynamic == true && (
          <ButtonPickerComponent
            label="ADICIONAR"
            backgroundColor="#40215F"
            color="white"
            size="small"
            height="5vh"
            onUpload={
              disabled == false ? (data) => addSupportImage(data) : null
            }
            name={name ?? "image"}
            disabled={disabled}
          />
        )}
      </div>
      <Divider />
      {images.length > 0 ? (
        _.chunk(images, 4).map((row, i) => (
          <Paper
            key={i}
            variant="outlined"
            className={classes.mediaRow}
            style={{
              paddingInline: isDesktop ? "3vw" : "6vw",
            }}
          >
            {row.map((image, i) => {
              const Image = () => {
                return (
                  <img
                    src={isDynamic == true ? image.url : image}
                    className={classes.mediaRowItem}
                    style={{ maxHeight: isDesktop ? "9vh" : "8vh" }}
                    onClick={() =>
                      window.open(
                        isDynamic == true ? image.url : image,
                        "_blank"
                      )
                    }
                  />
                );
              };

              if (isDynamic == true) {
                return (
                  <Badge
                    key={i}
                    overlap="rectangular"
                    className={classes.closeButton}
                    onClick={
                      disabled == false
                        ? () => removeSupportImage(image.id)
                        : null
                    }
                    badgeContent={
                      <CloseIcon
                        style={{ color: "white", fontSize: "1.5vh" }}
                      />
                    }
                    color="primary"
                  >
                    <Image />
                  </Badge>
                );
              }

              return <Image key={i} />;
            })}
          </Paper>
        ))
      ) : (
        <div style={{ height: "4vh" }}></div>
      )}
    </Paper>
  );
};

export default ImageListCardComponent;
