import React from "react";
import Button from "@mui/material/Button/Button";
import ButtonGroup from "@mui/material/ButtonGroup/ButtonGroup";
import makeStyles from "@mui/styles/makeStyles/makeStyles";

import {
  GenericDialogComponent,
  InputCopyComponent,
  InputWithButtonComponent,
} from "../../../../components";
import { useResponsive } from "../../../../providers";
import NovoLinkcadastroImage from "../../../../../assets/imagens/novo-link-cadastro.png";

const useStyles = makeStyles(() => ({
  content: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: "1vh",
    marginBottom: "2vh",
  },
  inviteButton: {
    fontWeight: "700",
    fontSize: "1.17vh",
    color: "white",

    height: "6.34vh",
    borderRadius: "10px",
  },
}));

const InviteDialogComponent = ({ inviteUrl, onSendEmail, onSendWhatsApp }) => {
  const classes = useStyles();
  const { isDesktop } = useResponsive();
  const [sendType, setSendType] = React.useState(0);
  const [sendTo, setSendTo] = React.useState("");
  const [errorMessage, setErrorMessage] = React.useState(null);

  const onSend = () => {
    setErrorMessage(null);
    if (!!sendTo) {
      if (sendType == 0) {
        if (
          new RegExp(
            /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/
          ).test(sendTo)
        ) {
          onSendEmail(sendTo);
        } else {
          setErrorMessage("E-mail inválido!");
        }
      } else {
        if (
          new RegExp(
            /^(?:(?:\+|00)?(55)\s?)?(?:\(?([1-9][0-9])\)?\s?)?(?:((?:9\d|[2-9])\d{3})\-?(\d{4}))$/
          ).test(sendTo)
        ) {
          onSendWhatsApp(sendTo);
        } else {
          setErrorMessage("Telefone inválido!");
        }
      }
    } else {
      setErrorMessage("Campo obrigatório!");
    }
  };

  return (
    <GenericDialogComponent name="invite-dialog">
      <div className={classes.content}>
        <img
          src={NovoLinkcadastroImage}
          style={{ marginBottom: "6vh", width: isDesktop ? null : "100%" }}
        />
        <InputCopyComponent
          label="LINK AUTORIZADO PARA COMPARTILHAMENTO"
          value={inviteUrl}
          onClick={(onCopy) => onCopy()}
          width={isDesktop ? "34.37vw" : "100%"}
        />
        <ButtonGroup
          disableElevation
          disableRipple
          disableFocusRipple
          variant="contained"
          style={{
            marginTop: "2vh",
            marginBottom: "2vh",
          }}
        >
          <Button
            className={classes.inviteButton}
            style={{
              backgroundColor: "#61C3BD",
              width: isDesktop ? "17.20vw" : "50%",
            }}
            onClick={() => setSendType(0)}
          >
            ENVIAR POR E-MAIL
          </Button>
          <Button
            className={classes.inviteButton}
            style={{
              backgroundColor: "#5DF888",
              width: isDesktop ? "17.20vw" : "50%",
            }}
            onClick={() => setSendType(1)}
          >
            ENVIAR POR WHATSAPP
          </Button>
        </ButtonGroup>
        <InputWithButtonComponent
          placeholder={
            sendType == 0 ? "DIGITAR O E-MAIL AQUI" : "DIGITAR O TELEFONE AQUI"
          }
          value={sendTo}
          onClick={onSend}
          width={isDesktop ? "34.37vw" : "100%"}
          backgroundColor="#C4C4C4"
          buttonLabel="ENVIAR"
          onChange={setSendTo}
          errorMessage={errorMessage}
        />
      </div>
    </GenericDialogComponent>
  );
};

export default InviteDialogComponent;
