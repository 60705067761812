import React from "react";
import TextField from "@mui/material/TextField/TextField";
import Typography from "@mui/material/Typography/Typography";
import ListSubheader from "@mui/material/ListSubheader";
import Autocomplete, { autocompleteClasses } from "@mui/material/Autocomplete";
import { Controller } from "react-hook-form";
import makeStyles from "@mui/styles/makeStyles/makeStyles";
import PropTypes from "prop-types";
import { VariableSizeList } from "react-window";
import useMediaQuery from "@mui/material/useMediaQuery";
import useTheme from "@mui/material/styles/useTheme";
import styled from "@mui/material/styles/styled";
import Popper from "@mui/material/Popper";

import { useResponsive } from "../../providers";
import ArrowToBottomIcon from "../../../assets/icones/arrow-to-bottom.svg";

const useStyles = makeStyles(() => ({
  option: {
    fontWeight: "700",
    fontSize: "1.46vh",
    color: "#5C5C5C",
  },
}));

const AutocompleteComponent = ({
  control,
  name,
  label,
  acessorValue,
  options,
  value,
  onChange,
  disabled,
  isLarge = false,
}) => {
  const classes = useStyles();
  const { isDesktop } = useResponsive();

  const ArrowIcon = () => {
    return (
      <img
        src={ArrowToBottomIcon}
        style={{ width: isDesktop ? null : "5vw" }}
      />
    );
  };

  const Field = ({ field, formState }) => {
    return (
      <Autocomplete
        includeInputInList
        value={!!field ? field.value : value}
        disabled={disabled}
        onChange={(e, v) => {
          if (!!field) {
            field.onChange(v);
          } else {
            onChange(v);
          }
        }}
        getOptionLabel={(o) => o.label}
        isOptionEqualToValue={(o, v) => {
          return !!v && o[acessorValue] == v[acessorValue];
        }}
        PopperComponent={StyledPopper}
        ListboxComponent={isLarge ? ListboxComponent : null}
        options={options}
        popupIcon={<ArrowIcon />}
        renderOption={
          isLarge == false
            ? (props, option) => (
                <div className={classes.option} {...props}>
                  {option.label}
                </div>
              )
            : (props, option, state) => [props, option, state.index]
        }
        renderInput={(params) => (
          <TextField
            {...params}
            label={label}
            type="text"
            variant="outlined"
            autoComplete="false"
            disabled={disabled}
            error={!!field ? !!formState.errors[name] : false}
            helperText={!!field ? formState.errors[name]?.message : null}
            InputLabelProps={{
              style: {
                fontWeight: "700",
                fontSize: "1.46vh",
                color: "#5C5C5C",
              },
            }}
            InputProps={{
              ...params.InputProps,
              style: {
                borderRadius: "10px",
                fontWeight: "700",
                fontSize: isDesktop ? "1.46vh" : "1.26vh",
                color: "#858585",
                height: "5.5vh",
              },
            }}
          />
        )}
      />
    );
  };

  if (!!control) {
    return (
      <Controller
        name={name}
        control={control}
        render={({ field, formState }) => (
          <Field field={field} formState={formState} />
        )}
      />
    );
  }

  return <Field field={null} formState={null} />;
};

const StyledPopper = styled(Popper)({
  [`& .${autocompleteClasses.listbox}`]: {
    boxSizing: "border-box",
    "& ul": {
      padding: 0,
      margin: 0,
    },
  },
});

function renderRow(props) {
  const { data, index, style } = props;
  const dataSet = data[index];
  const inlineStyle = {
    ...style,
    top: style.top + 8,
  };

  if (dataSet.hasOwnProperty("group")) {
    return (
      <ListSubheader key={dataSet.key} component="div" style={inlineStyle}>
        {dataSet.group}
      </ListSubheader>
    );
  }

  return (
    <Typography component="li" {...dataSet[0]} noWrap style={inlineStyle}>
      {dataSet[1].label}
    </Typography>
  );
}

const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef((props, ref) => {
  const outerProps = React.useContext(OuterElementContext);
  return <div ref={ref} {...props} {...outerProps} />;
});

function useResetCache(data) {
  const ref = React.useRef(null);
  React.useEffect(() => {
    if (ref.current != null) {
      ref.current.resetAfterIndex(0, true);
    }
  }, [data]);
  return ref;
}

const ListboxComponent = React.forwardRef(function ListboxComponent(
  props,
  ref
) {
  const { children, ...other } = props;
  const itemData = [];
  children.forEach((item) => {
    itemData.push(item);
    itemData.push(...(item.children || []));
  });

  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up("sm"), {
    noSsr: true,
  });
  const itemCount = itemData.length;
  const itemSize = smUp ? 36 : 48;

  const getChildSize = (child) => {
    if (child.hasOwnProperty("group")) {
      return 48;
    }

    return itemSize;
  };

  const getHeight = () => {
    if (itemCount > 8) {
      return 8 * itemSize;
    }
    return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
  };

  const gridRef = useResetCache(itemCount);

  return (
    <div ref={ref}>
      <OuterElementContext.Provider value={other}>
        <VariableSizeList
          itemData={itemData}
          height={getHeight() + 2 * 8}
          width="100%"
          ref={gridRef}
          outerElementType={OuterElementType}
          innerElementType="ul"
          itemSize={(index) => getChildSize(itemData[index])}
          overscanCount={5}
          itemCount={itemCount}
        >
          {renderRow}
        </VariableSizeList>
      </OuterElementContext.Provider>
    </div>
  );
});

ListboxComponent.propTypes = {
  children: PropTypes.node,
};

export default AutocompleteComponent;
