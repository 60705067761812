import Checkbox from "@mui/material/Checkbox/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel/FormControlLabel";
import FormHelperText from "@mui/material/FormHelperText/FormHelperText";
import makeStyles from "@mui/styles/makeStyles/makeStyles";

import { Controller } from "react-hook-form";

const useStyles = makeStyles({
  label: {
    fontWeight: "500",
    fontSize: "1.75vh",
  },
});

const CheckBoxComponent = ({
  name,
  label,
  control,
  disabled,
  isExterna = true,
  value,
}) => {
  const classes = useStyles();

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={value}
      render={({ field, formState }) => (
        <>
          <FormControlLabel
            disabled={disabled}
            classes={{ label: classes.label }}
            style={{ color: isExterna ? "white" : "" }}
            control={
              <Checkbox
                {...field}
                checked={value}
                disabled={disabled}
                name={name}
                style={{ color: isExterna ? "white" : "" }}
              />
            }
            label={label}
          />
          <FormHelperText error={!!formState.errors[name]}>
            {formState.errors[name]?.message}
          </FormHelperText>
        </>
      )}
    />
  );
};

export default CheckBoxComponent;
