import React from "react";
import Box from "@mui/material/Box/Box";
import Grid from "@mui/material/Grid/Grid";
import Typography from "@mui/material/Typography/Typography";
import makeStyles from "@mui/styles/makeStyles/makeStyles";

import { useResponsive } from "../../../../providers";
import LucreMaisLightImage from "../../../../../assets/imagens/lucre-mais-light.png";
import CrieSuaContaAnuncianteImage from "../../../../../assets/imagens/crie-sua-conta-anunciante.png";
import GrupoRizomaImage from "../../../../../assets/imagens/grupo-rizoma.png";

const useStyles = makeStyles(() => ({
  logo: {
    "&:hover": {
      cursor: "pointer",
    },
  },
}));

const DescribleComponent = () => {
  const classes = useStyles();

  const { isDesktop } = useResponsive();

  return (
    <Grid
      container
      style={{
        marginLeft: "5vw",
        justifyContent: "start",
        paddingTop: "5vh",
      }}
    >
      <Grid item xs={12}>
        <img
          src={LucreMaisLightImage}
          style={{ width: "100%", maxWidth: "12.01vw" }}
          className={classes.logo}
          onClick={() => (window.location.href = "/")}
        />
      </Grid>
      <Grid
        item
        xs={12}
        style={{ marginTop: "10vh", marginBottom: "45vh", marginLeft: "2vw" }}
      >
        <img
          src={CrieSuaContaAnuncianteImage}
          style={{ width: "100%", maxWidth: "31vw", marginBottom: "2vw" }}
        />
        <iframe
          xs={12}
          width={isDesktop ? "90%" : "60%"}
          height={isDesktop ? "100%" : "100%"}
          src="https://www.youtube.com/embed/2oq7Zkwj4sw"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
        ></iframe>
      </Grid>
      <Grid item xs={12}>
        <Box>
          <Typography
            style={{
              color: "white",
              fontWeight: "400",
              fontSize: "0.97vh",
              marginBottom: "0.5vh",
            }}
          >
            MAIS UMA EMPRESA DO
          </Typography>
          <img
            src={GrupoRizomaImage}
            style={{ width: "100%", maxWidth: "9.3vw" }}
          />
        </Box>
      </Grid>
    </Grid>
  );
};

export default DescribleComponent;
