import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import Typography from "@mui/material/Typography/Typography";
import makeStyles from "@mui/styles/makeStyles/makeStyles";

import {
  ButtonComponent,
  GenericDialogComponent,
  InputFilterComponent,
} from "../../../../components";
import { useDialog } from "../../../../providers";

const useStyles = makeStyles(() => ({
  content: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: "1vh",
    marginBottom: "2vh",
  },
}));

const SendLinkDialogComponent = ({ onSendLink }) => {
  const classes = useStyles();
  const { metadata: linkCpr } = useDialog();

  const schema = yup.object().shape({
    link: yup.string().required("Campo obrigatório"),
  });

  const { control, handleSubmit, reset } = useForm({
    defaultValues: {
      link: "",
    },
    resolver: yupResolver(schema),
  });

  const onSubmit = handleSubmit(({ link }) => {
    onSendLink(link);
  });

  useEffect(() => {
    reset({ link: linkCpr ?? "" });
  }, [linkCpr]);

  return (
    <GenericDialogComponent name="send-link-dialog">
      <form onSubmit={onSubmit} className={classes.content}>
        <Typography
          style={{
            marginBottom: "6vh",
            fontWeight: "700",
            fontSize: "1.95vh",
            color: "#40215F",
          }}
        >
          ENVIAR LINK DO USUÁRIO
        </Typography>

        <InputFilterComponent
          placeholder="LINK DO USUÁRIO"
          name={"link"}
          control={control}
        />
        <div style={{ marginBottom: "3vh" }}></div>

        <ButtonComponent
          label="ENVIAR"
          backgroundColor="#774E98"
          color="white"
          height="5vh"
          type="submit"
        />
      </form>
    </GenericDialogComponent>
  );
};

export default SendLinkDialogComponent;
