import React from "react";

import { userService } from "../../../../infrastructure/services";
import { useAlert, useAuth, useDialog } from "../../../providers";
import { useIsMounted } from "../../../hooks";
import { Format } from "../../../utils";
import { useLoadingContext } from "../../../context/loading.context";

const useAffiliatedController = () => {
  const { showError, showInfo, showSuccess } = useAlert();
  const { user } = useAuth();
  const { openDialog } = useDialog();
  const isMounted = useIsMounted();
  const [affiliated, setAffiliated] = React.useState([]);
  const [defaultCommissions, setDefaultCommissions] = React.useState([]);
  const [expanded, setExpanded] = React.useState();
  const [inviteUrl, setInviteUrl] = React.useState(null);
  const [commissionForm, setCommissionForm] = React.useState(null);
  const [nameFilter, setNameFilter] = React.useState("");
  const [percent, setPercent] = React.useState(0);
  const [defaultNewCampaignCommission, setDefaultNewCampaignCommission] =
    React.useState(0);
  const { showSpinner, setLoadingState } = useLoadingContext();

  React.useEffect(() => {
    if (user != null) {
      setLoadingState(true);
      setInviteUrl(
        window.location.origin + "/criar-conta?token=" + user.inviteToken
      );
      userService
        .getAffilietd({ id: user.id })
        .then((response) => {
          if (isMounted.current) {
            setAffiliated(response.users);
            setDefaultCommissions(response.defaultCommissions);
            setDefaultNewCampaignCommission(response.commissionToNewCampaign);
          }
        })
        .catch(showError)
        .finally(() => {
          setLoadingState(false);
        });
    }
  }, [user]);

  const toggleExpanded = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const onSendWhatsApp = (sendTo) => {
    const url =
      "https://api.whatsapp.com/send?phone=55" +
      sendTo.replace(/\D/g, "") +
      "&text=Ol%C3%A1,%20seja%20Bem%20Vindo%20a%20LUCRE%2B%0A%0AVenha%20fazer%20parte%20da%20MAIOR%20e%20MELHOR%20plataforma%20de%20divulga%C3%A7%C3%A3o%20de%20produtos%20e%20servi%C3%A7os.%20A%20Lucre+,%20gera%20links%20de%20indica%C3%A7%C3%A3o%20personalizados%20e%20te%20possibilita%20ganhar%20dinheiro%20de%20uma%20forma%20simples%20e%20f%C3%A1cil.%20O%20cadastro%20%C3%A9%20gratuito%20e%20voc%C3%AA%20recebe%20comiss%C3%B5es%20semanais%20de%20tudo%20que%20vende!%20%0A%0AVoc%C3%AA%20pode%20montar%20time%20de%20AFILIADOS%20e%20LUCRAR%20muito,%20com%20suas%20vendas%20e%20do%20seu%20time.%0A%0AClique%20no%20link%20abaixo,%20se%20cadastre%20e%20comece%20a%20LUCRAR%20MUITO%0A%20" +
      inviteUrl;

    window.open(url, "_blank");
  };

  const onSendEmail = (sendTo) => {
    setLoadingState(true);
    userService
      .sendInvite({ email: sendTo })
      .then(() => showInfo("Convite enviado para " + sendTo + "!"))
      .catch(showError)
      .finally(() => {
        setLoadingState(false);
      });
  };

  const openCommissionDialog = ({
    affiliate,
    commissionReceivable,
    commissionPayablePercent,
    commissionType,
    campaignId,
  }) => {
    setCommissionForm({
      affiliate: {
        id: affiliate.id,
        displayName: affiliate.displayName,
      },
      commission: {
        receivable: commissionReceivable,
        payablePercent: commissionPayablePercent,
        type: commissionType,
      },
      campaign: {
        id: campaignId,
      },
    });
    setPercent(commissionPayablePercent);
    openDialog("commission-dialog");
  };

  const onChangeCommission = (newCommission) => {
    if (!!commissionForm.affiliate) {
      setLoadingState(true);
      userService
        .changeAffiliateCommission({
          id: user.id,
          affiliate: commissionForm.affiliate.id,
          campaign: commissionForm.campaign.id,
          commission: newCommission,
        })
        .then(() => {
          const newAffiliated = affiliated.map((affiliate) => {
            if (affiliate.id == commissionForm.affiliate.id) {
              return {
                ...affiliate,
                commissions: affiliate.commissions.map((commission) => {
                  if (commission.campaign.id == commissionForm.campaign.id) {
                    return {
                      ...commission,
                      commissionPayablePercent: newCommission,
                      commissionPayable: Format.percentToCurrency(
                        newCommission,
                        commission.commissionReceivable
                      ),
                      getType: "user",
                    };
                  } else {
                    return commission;
                  }
                }),
              };
            }
            return affiliate;
          });
          setAffiliated(newAffiliated);
        })
        .catch(showError)
        .finally(() => {
          setLoadingState(false);
        });
    } else {
      setLoadingState(true);
      userService
        .changeDefaultCommission({
          idProduto: commissionForm.campaign.id,
          value: newCommission,
        })
        .then(() => {
          const newCommissions = defaultCommissions.map((commission) => {
            if (commission.campaign.id == commissionForm.campaign.id) {
              return {
                ...commission,
                commissionPayablePercent: newCommission,
                commissionPayable: Format.percentToCurrency(
                  newCommission,
                  commission.commissionReceivable
                ),
              };
            } else {
              return commission;
            }
          });
          setDefaultCommissions(newCommissions);

          const newAffiliated = affiliated.map((affiliate) => {
            return {
              ...affiliate,
              commissions: affiliate.commissions.map((commission) => {
                if (
                  commission.campaign.id == commissionForm.campaign.id &&
                  commission.getType == "default"
                ) {
                  return {
                    ...commission,
                    commissionPayablePercent: newCommission,
                    commissionPayable: Format.percentToCurrency(
                      newCommission,
                      commission.commissionReceivable
                    ),
                  };
                } else {
                  return commission;
                }
              }),
            };
          });
          setAffiliated(newAffiliated);
        })
        .catch(showError)
        .finally(() => {
          setLoadingState(false);
        });
    }
  };

  const clearFilterByName = () => {
    setNameFilter("");
  };

  const filterByName = () => {
    if (nameFilter === "") {
      return affiliated;
    }

    return affiliated.filter((affiliate) =>
      affiliate.displayName.toUpperCase().includes(nameFilter.toUpperCase())
    );
  };

  const openDefaultCommissionDialog = ({
    commissionReceivable,
    commissionPayablePercent,
    commissionType,
    campaignId,
  }) => {
    setCommissionForm({
      commission: {
        type: commissionType,
        receivable: commissionReceivable,
        payablePercent: commissionPayablePercent,
      },
      campaign: {
        id: campaignId,
      },
    });
    setPercent(commissionPayablePercent);
    openDialog("commission-dialog");
  };

  const onChangeCommissionToNewCampaign = (newValue) => {
    setLoadingState(true);
    userService
      .changeDefaultCommissionToNewCampaign({ commission: newValue })
      .then(() => {
        showSuccess("Comissão padrão para novas campanhas alterada!");
      })
      .catch(showError)
      .finally(() => {
        setLoadingState(false);
      });
  };

  return {
    affiliated: filterByName(),
    expanded,
    inviteUrl,
    commissionForm,
    toggleExpanded,
    openInviteDialog: () => openDialog("invite-dialog"),
    openCommissionDialog,
    onChangeCommission,
    onSendEmail,
    onSendWhatsApp,
    nameFilter,
    setNameFilter,
    clearFilterByName,
    defaultCommissions,
    openDefaultCommissionDialog,
    percent,
    setPercent,
    defaultNewCampaignCommission,
    setDefaultNewCampaignCommission,
    onChangeCommissionToNewCampaign,
  };
};

export default useAffiliatedController;
