import httpClient from "./axios.config";

class MediaService {
  upload = ({ filename, image }) => {
    return new Promise((resolve, reject) => {
      httpClient
        .post("/media/upload", { image, filename })
        .then((response) => {
          const imageUrl = response.data.response;
          resolve(imageUrl);
        })
        .catch((error) =>
          reject(
            error.response && error.response.data && error.response.data.message
              ? error.response.data.message
              : "Erro inesperado, tente novamente mais tarde."
          )
        );
    });
  };
}

export default new MediaService();
