import Grid from "@mui/material/Grid/Grid";

import { BodyComponent, ButtonComponent } from "../../../../components";
import useAdminNotificationFormController from "./admin-notification-form.controller";
import BasicSectionComponent from "./components/basic-section.component";
import SendSectionComponent from "./components/send-section.component";
import MessageSectionComponent from "./components/message-section.component";
import { useParams } from "react-router-dom";

const AdminNotificationFormPage = () => {
  const { id: preferenceId } = useParams();
  const {
    control,
    isActiveOptions,
    sendTypeOptions,
    sendGroupOptions,
    notificationTypeOptions,
    onSubmit,
    withSendGroup,
    linkOptions,
    isValidTest,
    onTest,
    editImage,
    setErrorInMedia,
    onDeleteMedia,
    isLoading,
  } = useAdminNotificationFormController({
    preferenceId,
  });

  return (
    <BodyComponent>
      <Grid component="form" onSubmit={onSubmit} container>
        <Grid item xs={12} style={{ marginBottom: "4vh" }}>
          <BasicSectionComponent
            control={control}
            isActiveOptions={isActiveOptions}
            isLoading={isLoading}
          />
        </Grid>

        <Grid item xs={12} style={{ marginBottom: "4vh" }}>
          <SendSectionComponent
            control={control}
            sendTypeOptions={sendTypeOptions}
            sendGroupOptions={sendGroupOptions}
            notificationTypeOptions={notificationTypeOptions}
            withSendGroup={withSendGroup}
            isLoading={isLoading}
          />
        </Grid>

        <Grid item xs={12} style={{ marginBottom: "4vh" }}>
          <MessageSectionComponent
            control={control}
            linkOptions={linkOptions}
            editImage={editImage}
            setErrorInMedia={setErrorInMedia}
            onDeleteMedia={onDeleteMedia}
            isLoading={isLoading}
          />
        </Grid>

        <Grid item xs={12} style={{ marginBottom: "4vh" }}>
          <div style={{ display: "flex", justifyContent: "end" }}>
            <div style={{ marginLeft: "1vw" }}>
              {/*isValidTest && (
                <ButtonComponent
                  label={"TESTAR NOTIFICAÇÃO"}
                  backgroundColor="#40215F"
                  color="white"
                  size="small"
                  height="5vh"
                  onClick={onTest}
                />
              )*/}
              <ButtonComponent
                label={
                  isLoading == false ? "EDITAR NOTIFICAÇÃO" : "CARREGANDO..."
                }
                backgroundColor="#40215F"
                color="white"
                size="small"
                height="5vh"
                type="submit"
                disabled={isLoading}
              />
            </div>
          </div>
        </Grid>
      </Grid>
    </BodyComponent>
  );
};

export default AdminNotificationFormPage;
