const BodyComponent = ({ children }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        marginTop: "5vh",
        marginBottom: "5vh",
      }}
    >
      {children}
    </div>
  );
};

export default BodyComponent;
