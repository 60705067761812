import { useEffect, useState } from "react";

import { useAlert, useAuth } from "../../../../providers";
import { useIsMounted } from "../../../../hooks";
import { useLoadingContext } from "../../../../context/loading.context";
import { notificationService } from "../../../../../infrastructure/services";

const useAdminModalsController = () => {
  const { showError } = useAlert();
  const { user } = useAuth();
  const isMounted = useIsMounted();

  const [modals, setModals] = useState([]);
  const { setLoadingState } = useLoadingContext();

  useEffect(() => {
    if (user != null) {
      setLoadingState(true);
      notificationService
        .getModals()
        .then((response) => {
          if (isMounted.current) {
            setModals(response);
          }
        })
        .catch(showError)
        .finally(() => {
          setLoadingState(false);
        });
    }
  }, [user]);

  return { modals };
};

export default useAdminModalsController;
