import Badge from "@mui/material/Badge/Badge";
import Card from "@mui/material/Card/Card";
import CardMedia from "@mui/material/CardMedia/CardMedia";
import FormHelperText from "@mui/material/FormHelperText/FormHelperText";
import Typography from "@mui/material/Typography/Typography";
import makeStyles from "@mui/styles/makeStyles/makeStyles";

import CameraAltIcon from "@mui/icons-material/CameraAlt";
import CloseIcon from "@mui/icons-material/Close";

import { Controller } from "react-hook-form";

const useStyles = makeStyles(() => ({
  photoCard: {
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
    justifyContent: "start",
  },
  logoCard: {
    width: "14.16vw",
    height: "15.76vh",
    borderRadius: "10px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    paddingInline: "1vw",
  },
  closeButton: {
    "&:hover": {
      cursor: "pointer",
    },
  },
}));

const BannerPickerComponent = ({
  name,
  control,
  label,
  value,
  onChange,
  onUpload,
  disabled,
  accept,
  maxSize,
  onError,
  onDelete,
}) => {
  const classes = useStyles();
  const hasVideoAndAudio =
    (!!accept && accept.split(", ").includes("audio/mp3")) ||
    (!!accept && accept.split(", ").includes("video/mp4"));

  const Field = ({ field, formState }) => {
    const handleChange = (event) => {
      if (event.target.files && event.target.files[0]) {
        if (!!maxSize && event.target.files[0].size > maxSize) {
          onError("O tamanho máximo permitido é de 4mb!");
          return;
        }

        const reader = new FileReader();
        reader.onload = (e) => {
          if (!!control) {
            const img = new Image();
            img.onload = () => {
              onUpload({
                name: name,
                data: e.target.result,
                width: img.width,
                height: img.height,
                callback: (imageUrl) => {
                  field.onChange(
                    imageUrl +
                      (hasVideoAndAudio == true
                        ? ""
                        : `?${new Date().getTime()}`)
                  );
                },
              });
            };
            img.src = e.target.result;
          } else {
            onChange(name, e.target.result);
          }
        };
        reader.readAsDataURL(event.target.files[0]);
      }
    };

    const url = !!control ? field.value : value;

    const getMediaType = () => {
      const extension = url.match(/\.([^.]+)$/)[1];

      switch (extension) {
        case "mp4":
          return "video";
        case "mpeg":
          return "audio";
        default:
          return "img";
      }
    };

    const MediaCard = () => {
      return (
        <Card elevation={0} className={classes.logoCard}>
          {url != null ? (
            <CardMedia
              component={getMediaType()}
              src={
                url.startsWith("http")
                  ? url +
                    (hasVideoAndAudio == true ? "" : `?${new Date().getTime()}`)
                  : url
              }
              controls={["video", "audio"].includes(getMediaType())}
              style={{
                width: "100%",
                height: ["audio"].includes(getMediaType())
                  ? "30%"
                  : ["video"].includes(getMediaType())
                  ? "80%"
                  : "100%",
                objectFit: "contain",
              }}
            />
          ) : (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <CameraAltIcon style={{ height: "5vh", width: "5vh" }} />
              <Typography
                style={{
                  fontWeight: "700",
                  fontSize: "1.46vh",
                  color: "#5C5C5C",
                }}
              >
                {label}
              </Typography>
              <FormHelperText error={!!control && !!formState.errors[name]}>
                {!!control && formState.errors[name]?.message}
              </FormHelperText>
            </div>
          )}
        </Card>
      );
    };

    return (
      <div className={classes.photoCard}>
        <div>
          <input
            id={"logo-picker-" + name}
            accept={accept ?? "image/png, image/jpeg"}
            style={{ display: "none" }}
            type="file"
            disabled={disabled}
            onChange={handleChange}
          />
          <label htmlFor={"logo-picker-" + name}>
            {url != null && hasVideoAndAudio == true ? (
              <Badge
                overlap="rectangular"
                color="primary"
                className={classes.closeButton}
                onClick={onDelete}
                badgeContent={
                  <CloseIcon style={{ color: "white", fontSize: "1.5vh" }} />
                }
              >
                <MediaCard />
              </Badge>
            ) : (
              <MediaCard />
            )}
          </label>
        </div>
      </div>
    );
  };

  if (!!control) {
    return (
      <Controller
        name={name}
        control={control}
        render={({ field, formState }) => {
          return <Field field={field} formState={formState} />;
        }}
      />
    );
  }
  return <Field field={null} formState={null} />;
};

export default BannerPickerComponent;
