import Divider from "@mui/material/Divider/Divider";
import Grid from "@mui/material/Grid/Grid";
import Typography from "@mui/material/Typography/Typography";

import {
  AutocompleteMultipleComponent,
  BannerPickerComponent,
  CheckBoxComponent,
  InputFilterComponent,
} from "../../../../../components";
import ColorPickerComponent from "../../../../../components/form/color-picker.component";

const InternalSectionComponent = ({
  control,
  usersOptions,
  goalsOptions,
  goalsSelecteds,
  onGoalsSelecteds,
  editImage,
  watch,
  setValue,
  isLoading,
}) => {
  return (
    <div>
      <Typography
        style={{
          fontWeight: "600",
          fontSize: "1.75vh",
          color: "#5C5C5C",
        }}
      >
        INFORMAÇÕES ADICIONAIS
      </Typography>
      <Divider style={{ marginBottom: "2vh" }} />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid container spacing={2} style={{ alignItems: "center" }}>
            <Grid item xs={6}>
              <AutocompleteMultipleComponent
                name="users"
                control={control}
                label="USUÁRIOS RESPONSÁVEIS"
                acessorValue="id"
                options={usersOptions}
                disabled={isLoading}
              />
            </Grid>
            <Grid item xs={6}>
              <AutocompleteMultipleComponent
                name="goals"
                value={goalsSelecteds}
                onChange={onGoalsSelecteds}
                label="METAS"
                acessorValue="id"
                options={goalsOptions}
                disabled={isLoading}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2} style={{ alignItems: "center" }}>
            <Grid item xs={3}>
              <BannerPickerComponent
                name="internLogo"
                control={control}
                label="LOGO DA LP"
                onUpload={editImage}
                disabled={isLoading}
              />
            </Grid>
            <Grid item xs={9}>
              <InputFilterComponent
                name="internDescrible"
                control={control}
                label="DESCRIÇÃO DA LP"
                placeholder="DESCRIÇÃO DA LP"
                multiline={true}
                minRows={7}
                disabled={isLoading}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <InputFilterComponent
                name="textoBotao"
                control={control}
                label="TEXTO BOTÃO"
                placeholder="TEXTO BOTÃO"
                disabled={isLoading}
              />
            </Grid>

            <Grid item xs={4}>
              <ColorPickerComponent
                style={{ width: "100%" }}
                name="corBotao"
                label="COR BOTÃO"
                control={control}
                disabled={isLoading}
                isExterna={true}
                setValue={setValue}
              />
            </Grid>
            <Grid item xs={4}>
              <CheckBoxComponent
                isExterna={false}
                name="isYoutube"
                control={control}
                value={watch("isYoutube")}
                label="É UM LINK DO YOUTUBE?"
                disabled={isLoading}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {!watch("isYoutube") ? (
                <Grid item xs={3}>
                  <BannerPickerComponent
                    name="banner"
                    control={control}
                    label="BANNER"
                    onUpload={editImage}
                    disabled={isLoading}
                  />
                </Grid>
              ) : (
                <InputFilterComponent
                  name="linkYoutube"
                  control={control}
                  label="LINK YOUTUBE"
                  placeholder="LINK YOUTUBE"
                  disabled={isLoading}
                />
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default InternalSectionComponent;
