import React from "react";
import CircularProgress from "@mui/material/CircularProgress/CircularProgress";

const styles = {
  spinnerContainer: {
    position: "fixed",
    background: "rgba(0, 0, 0, 0.32)",
    height: "100%",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    top: 0,
    left: 0,
    zIndex: 2000,
  },
};

const Spinner = () => {
  return (
    <div style={styles.spinnerContainer}>
      <CircularProgress sx={{ strokeWidth: 10, color: "#774E98" }} />
    </div>
  );
};

export default Spinner;
