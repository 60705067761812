import { useState } from "react";
import Paper from "@mui/material/Paper/Paper";
import Divider from "@mui/material/Divider/Divider";
import Grid from "@mui/material/Grid/Grid";
import Typography from "@mui/material/Typography/Typography";
import makeStyles from "@mui/styles/makeStyles/makeStyles";

import BarChartV2Component from "./bar-chart-v2.component";
import { dashboardService } from "../../../../../infrastructure/services";
import { useAlert } from "../../../../providers";
import moment from "moment";
import FilterChartComponent from "./filter-chart.component";
import { Format } from "../../../../utils";
import dayjs from "dayjs";

const useStyles = makeStyles(() => ({
  card: {
    width: "100%",
    borderRadius: "10px",
    marginBottom: "4vh",
  },
  cardTitle: {
    marginInline: "4vh",
    marginTop: "4vh",
    marginBottom: "4vh",
    fontWeight: "700",
    fontSize: "1.75vh",
    color: "#5C5C5C",
  },
}));

const RentalChartComponent = () => {
  const classes = useStyles();
  const { showError } = useAlert();
  const [rentals, setRentals] = useState(null);
  const [total, setTotal] = useState(0);

  const fetchData = (query) => {
    dashboardService
      .getRentals(query)
      .then((response) => {
        let dataVariables = {};
        let totalAux = 0;
        Object.keys(response).forEach((variableName) => {
          dataVariables[variableName] = Object.entries(
            response[variableName]
          ).map(([key, value]) => {
            totalAux += parseFloat(value);
            return {
              key: dayjs(new Date(parseInt(key))).format("DD/MM/YYYY"),
              value: parseFloat(value),
            };
          });
        });
        setTotal(totalAux);
        setRentals(dataVariables);
      })
      .catch(showError);
  };

  return (
    <Paper variant="outlined" className={classes.card}>
      <Grid container style={{ alignItems: "center", padding: "1vh" }}>
        <Grid item xs={3}>
          <Typography className={classes.cardTitle}>GANHOS DA CASA</Typography>
          <Typography className={classes.cardTitle}>
            TOTAL: {Format.toCurrency(total)}
          </Typography>
        </Grid>
        <FilterChartComponent
          onSearch={fetchData}
          data={rentals}
          downloadName={"rentabilidade"}
        />
      </Grid>
      <Divider />
      <div style={{ height: "34vh", marginBottom: "1vh", padding: "1vh" }}>
        <BarChartV2Component
          direction="xAxis"
          data={rentals}
          hasZoom={true}
          formatter={function (params) {
            return `${params[0].axisValue}<br />
            ${Format.toCurrency(params[0].data)}`;
          }}
        />
      </div>
    </Paper>
  );
};

export default RentalChartComponent;
