import { useEffect, useState } from "react";

import { useIsMounted } from "../../../../hooks";
import { affiliatedProfileService } from "../../../../../infrastructure/services";
import { useAlert, useDialog } from "../../../../providers";
import { useLoadingContext } from "../../../../context/loading.context";

const useAdminAffiliatedProfileController = () => {
  const isMounted = useIsMounted();
  const { openDialog, closeDialog } = useDialog();
  const { showError, showSuccess } = useAlert();
  const [profiles, setProfiles] = useState([]);
  const { setLoadingState } = useLoadingContext();

  useEffect(() => onFetch(), []);

  const onFetch = () => {
    setLoadingState(true);
    affiliatedProfileService
      .getProfiles()
      .then((response) => {
        if (isMounted.current) {
          setProfiles(response);
        }
      })
      .catch(showError)
      .finally(() => {
        setLoadingState(false);
      });
  };

  const onOpenCreateForm = () => {
    openDialog("admin-affiliated-profile-form-dialog");
  };

  const onOpenEditForm = (profile) => {
    openDialog("admin-affiliated-profile-form-dialog", profile);
  };

  const onDelete = ({ id }) => {
    setLoadingState(true);
    affiliatedProfileService
      .deleteProfile({ id })
      .then(() => {
        onFetch();
        closeDialog();
        showSuccess("Perfil exluído!");
      })
      .catch(showError)
      .finally(() => {
        setLoadingState(false);
      });
  };

  const onCreate = ({ name }) => {
    setLoadingState(true);
    affiliatedProfileService
      .createProfile({ name })
      .then(() => {
        onFetch();
        closeDialog();
        showSuccess("Perfil criado!");
      })
      .catch(showError)
      .finally(() => {
        setLoadingState(false);
      });
  };

  const onEdit = ({ id, name }) => {
    setLoadingState(true);
    affiliatedProfileService
      .editProfile({ id, name })
      .then(() => {
        onFetch();
        closeDialog();
        showSuccess("Perfil editado!");
      })
      .catch(showError)
      .finally(() => {
        setLoadingState(false);
      });
  };

  return {
    profiles,
    onOpenCreateForm,
    onOpenEditForm,
    onDelete,
    onCreate,
    onEdit,
  };
};

export default useAdminAffiliatedProfileController;
