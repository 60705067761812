import Link from "@mui/material/Link/Link";
import Typography from "@mui/material/Typography/Typography";

const LinkComponent = ({ label, onClick }) => {
  return (
    <Link
      onClick={onClick}
      sx={{
        color: "white",
        cursor: "pointer",
        textAlign: "center",
      }}
    >
      <Typography sx={{ fontWeight: "500", fontSize: "1.75vh" }}>
        {label}
      </Typography>
    </Link>
  );
};

export default LinkComponent;
