import React from "react";
import { Controller } from "react-hook-form";
import { useParams } from "react-router-dom";
import Grid from "@mui/material/Grid/Grid";
import makeStyles from "@mui/styles/makeStyles/makeStyles";

import {
  BannerPickerComponent,
  BodyComponent,
  ButtonComponent,
  CheckBoxComponent,
  InputFilterComponent,
  SelectComponent,
} from "../../../../components";
import useAdminModalFormController from "./admin-modal-form.controller";
import { useIsDesktop } from "../../../../hooks";

const useStyles = makeStyles({
  banner: {
    paddingBottom: "1vw",
  },
});

const AdminModalFormPage = () => {
  const classes = useStyles();
  const { id: modalId } = useParams();

  const {
    control,
    onSubmit,
    editImage,
    setErrorInMedia,
    onDeleteMedia,
    isLoading,
    isActiveOptions,
    isValidToPreview,
    onPreview,
    listTypeUser,
    bannerType,
    bannerTypeOptions,
    watch,
  } = useAdminModalFormController({ modalId });
  const { isDesktop } = useIsDesktop();

  return (
    <BodyComponent>
      <Grid component="form" onSubmit={onSubmit} container>
        <Grid container spacing={2} style={{ width: "71vw" }}>
          <Grid item xs={12}>
            <Grid container spacing={2} className={classes.banner}>
              <Grid item xs={3}>
                <SelectComponent
                  name="idTipoUsuario"
                  control={control}
                  label="TIPO USUÁRIO"
                  acessor="label"
                  options={listTypeUser}
                  disabled={isLoading}
                />
              </Grid>
              <Grid item xs={4}>
                <InputFilterComponent
                  name="link"
                  control={control}
                  label="LINK PARA REDIRECIONAR"
                  placeholder="LINK PARA REDIRECIONAR"
                  disabled={isLoading || bannerType == 2}
                />
              </Grid>
              <Grid item xs={3}>
                <SelectComponent
                  name="active"
                  control={control}
                  label="NOTIFICAÇÃO ATIVA?"
                  acessor="label"
                  options={isActiveOptions}
                  disabled={isLoading}
                />
              </Grid>
              <Grid item xs={2}>
                <CheckBoxComponent
                  isExterna={false}
                  name="fundoTransparente"
                  control={control}
                  value={watch("fundoTransparente")}
                  label="FUNDO TRANSPARENTE?"
                  disabled={isLoading}
                />
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={2} className={classes.banner}>
                <Grid item xs={4}>
                  <SelectComponent
                    name="bannerType"
                    control={control}
                    label="TIPO DE MÍDIA"
                    acessor="label"
                    options={bannerTypeOptions}
                    disabled={isLoading}
                  />
                </Grid>
                <Grid item xs={4}>
                  {bannerType == 1 ? (
                    <BannerPickerComponent
                      name="bannerUrl"
                      control={control}
                      label="BANNER"
                      onUpload={editImage}
                      accept={"image/*"}
                      maxSize={4000000}
                      onError={setErrorInMedia}
                      onDelete={onDeleteMedia}
                      disabled={isLoading}
                    />
                  ) : (
                    <InputFilterComponent
                      name="bannerUrl"
                      control={control}
                      label="URL VÍDEO NO YOUTUBE"
                      placeholder="Ex. https://www.youtube.com/embed/1Ty2WrKAHso"
                      disabled={isLoading}
                    />
                  )}
                </Grid>
                <Grid item xs={4}>
                  {bannerType == 2 && (
                    <Controller
                      name="bannerUrl"
                      control={control}
                      render={({ field }) => {
                        if (!!field.value) {
                          return (
                            <iframe
                              width={isDesktop ? "90%" : "100%"}
                              height={isDesktop ? "100%" : "100%"}
                              src={field.value}
                              title="YouTube video player"
                              frameBorder="0"
                              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                              allowFullScreen
                            />
                          );
                        }
                        return <div></div>;
                      }}
                    />
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} style={{ marginBottom: "4vh" }}>
          <div style={{ display: "flex", justifyContent: "end" }}>
            {isValidToPreview && (
              <ButtonComponent
                label={isLoading == false ? "PRÉ-VISUALIZAR" : "CARREGANDO..."}
                backgroundColor="#40215F"
                color="white"
                size="small"
                height="5vh"
                disabled={isLoading}
                onClick={onPreview}
              />
            )}
            <div style={{ marginLeft: "1vw" }}>
              <ButtonComponent
                label={
                  isLoading == false
                    ? `${!!modalId ? "EDITAR" : "CRIAR"} MODAL`
                    : "CARREGANDO..."
                }
                backgroundColor="#40215F"
                color="white"
                size="small"
                height="5vh"
                type="submit"
                disabled={isLoading}
              />
            </div>
          </div>
        </Grid>
      </Grid>
      <div style={{ height: "60vh" }}></div>
    </BodyComponent>
  );
};

export default AdminModalFormPage;
