import { forwardRef } from "react";

import { HelpComponent } from "../../../../../components";
import { useResponsive } from "../../../../../providers";

const HelpSectionComponent = forwardRef(({}, ref) => {
  const { isDesktop } = useResponsive();

  return (
    <section
      ref={ref}
      style={{
        backgroundColor: "#D9D9D9",
        padding: isDesktop ? "15vh" : "5vh",
        minHeight: isDesktop ? "100vh" : "80vh",
      }}
    >
      <HelpComponent />
    </section>
  );
});

export default HelpSectionComponent;
