import Grid from "@mui/material/Grid/Grid";
import Typography from "@mui/material/Typography/Typography";
import IconButton from "@mui/material/IconButton/IconButton";

import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";

import { useResponsive } from "../../../../providers";
import { useWhiteLabel } from "../../../../providers/white-label.provider";
import LucreMaisLightImage from "../../../../../assets/imagens/lucre-mais-light.png";
import GrupoRizomaImage from "../../../../../assets/imagens/grupo-rizoma.png";

const SectionOneComponent = () => {
  const { isDesktop } = useResponsive();
  const { hasCustomTheme, preferencias } = useWhiteLabel();

  return (
    <Grid
      container
      direction="column"
      style={{
        marginRight: isDesktop ? "3vw" : 0,
        alignItems: isDesktop ? "start" : "center",
        width: isDesktop ? null : "90vw",
      }}
    >
      <Grid item>
        <img
          src={hasCustomTheme ? preferencias.logoUrl : LucreMaisLightImage}
          style={{ width: "100%", maxWidth: isDesktop ? "8.47vw" : "33.88vw" }}
        />
      </Grid>
      {!hasCustomTheme && (
        <Grid item style={{ marginTop: "1vh" }}>
          <SocialNetworkButton
            url="https://www.facebook.com/profile.php?id=100090141008139&mibextid=LQQJ4d"
            backgroundColor="#AA4291"
          >
            <FacebookIcon />
          </SocialNetworkButton>
          <SocialNetworkButton
            url="https://www.instagram.com/lucremaisrzm/"
            backgroundColor="#AA4291"
          >
            <InstagramIcon />
          </SocialNetworkButton>
        </Grid>
      )}
      {!hasCustomTheme && (
        <Grid item style={{ marginTop: "2vh" }}>
          <img
            src={GrupoRizomaImage}
            style={{
              width: "100%",
              maxWidth: isDesktop ? "9.37vw" : "37.48vw",
            }}
          />
        </Grid>
      )}
      {!hasCustomTheme && (
        <Grid item style={{ marginTop: "1vh" }}>
          <SocialNetworkButton
            url="https://www.facebook.com/rizomagrupo"
            backgroundColor="#094E86"
          >
            <FacebookIcon />
          </SocialNetworkButton>
          <SocialNetworkButton
            url="https://www.instagram.com/gruporizomaofc/"
            backgroundColor="#094E86"
          >
            <InstagramIcon />
          </SocialNetworkButton>
        </Grid>
      )}
      {!hasCustomTheme && (
        <Grid item style={{ marginTop: "1vh" }}>
          <Typography style={{ fontSize: "1.17vh", fontWeight: "400" }}>
            A Lucre+ é uma plataforma desenvolvida pelo Grupo Rizoma.
          </Typography>
        </Grid>
      )}
    </Grid>
  );
};

const SocialNetworkButton = ({ backgroundColor, url, children }) => {
  const { isDesktop } = useResponsive();

  return (
    <IconButton
      style={{
        color: "#f6f6f6",
        backgroundColor: backgroundColor,
        marginRight: "0.5vw",
        width: isDesktop ? "2.5vw" : "10vw",
        height: isDesktop ? "2.5vw" : "10vw",
      }}
      onClick={() => window.open(url, "_blank")}
    >
      {children}
    </IconButton>
  );
};

export default SectionOneComponent;
