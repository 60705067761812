import React from "react";
import makeStyles from "@mui/styles/makeStyles/makeStyles";
import { useNavigate } from "react-router-dom";

import { useAuth, useResponsive } from "../../../../providers";
import { useWhiteLabel } from "../../../../providers/white-label.provider";
import LucreMaisLightImage from "../../../../../assets/imagens/lucre-mais-light.png";

const useStyles = makeStyles(() => ({
  logo: {
    "&:hover": {
      cursor: "pointer",
    },
  },
}));

const LogoComponent = () => {
  const navigate = useNavigate();
  const classes = useStyles();
  const { user } = useAuth();
  const { isDesktop } = useResponsive();
  const { hasCustomTheme, preferencias } = useWhiteLabel();

  return (
    <img
      src={hasCustomTheme ? preferencias.logoUrl : LucreMaisLightImage}
      className={!!user && user.role != "ANUNCIANTE" ? classes.logo : null}
      style={{ width: "100%", maxWidth: isDesktop ? "7.29vw" : "14.58vw" }}
      onClick={
        !!user && user.role != "ANUNCIANTE" ? () => navigate("/home") : null
      }
    />
  );
};

export default LogoComponent;
