import httpClient from "./axios.config";

class ProductService {
  getProductByBanner = () => {
    return new Promise((resolve, reject) => {
      httpClient
        .get("/publico/listaProdutoBanner")
        .then((response) => {
          const productsResponse = response.data.response;

          resolve(
            productsResponse.map((product) => ({
              id: parseInt(product.produtoId),
              name: product.nome,
              banner: product.banner,
            }))
          );
        })
        .catch((error) =>
          reject(
            error.response && error.response.data && error.response.data.message
              ? error.response.data.message
              : "Erro inesperado, tente novamente mais tarde."
          )
        );
    });
  };
}

export default new ProductService();
