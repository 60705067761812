import httpClient from "./axios.config";

class FinancialService {
  getExtract = (query) => {
    return new Promise((resolve, reject) => {
      httpClient
        .get("/financeiro/buscarDadosFinanceiros", {
          params: query,
        })
        .then((response) => {
          const extract = response.data.response;
          resolve({
            amountReceivable: extract.valorAReceber.toLocaleString("pt-BR", {
              style: "currency",
              currency: "BRL",
            }),
            balance: extract.saldo.toLocaleString("pt-BR", {
              style: "currency",
              currency: "BRL",
            }),
            count: extract.count,
            pendingInvoice: extract.notaFiscalPendente,
            valueToAnticipate: extract.valorParaAntecipar,
            extract: extract.extrato.map((item) => ({
              campaign: item.campanha,
              affiliate: item.afiliadoNome,
              eventDate: item.data.substring(0, 10),
              received: item.recebido == 1,
              payment: parseFloat(item.pagamento),
            })),
          });
        })
        .catch((error) =>
          reject(
            error.response && error.response.data && error.response.data.message
              ? error.response.data.message
              : "Erro inesperado, tente novamente mais tarde."
          )
        );
    });
  };

  sendInvoice = ({ invoice }) => {
    return new Promise((resolve, reject) => {
      httpClient
        .post("/financeiro/incluirNotaFiscal", { notaFiscal: invoice })
        .then((response) => {
          const json = response.data.response;
          resolve(json);
        })
        .catch((error) =>
          reject(
            error.response && error.response.data && error.response.data.message
              ? error.response.data.message
              : "Erro inesperado, tente novamente mais tarde."
          )
        );
    });
  };

  getPayments = (query) => {
    return new Promise((resolve, reject) => {
      httpClient
        .get("/financeiro/buscarPagamentos", { params: query })
        .then((response) => {
          const payments = response.data.response.data;

          resolve({
            data: payments.map((payment) => ({
              user: {
                id: payment.idAfiliado,
                name: payment.afiliadoNome,
                paymentData: {
                  cpf: payment.dadosParaPagamento.cpf,
                  pix: payment.dadosParaPagamento.chavePix,
                  email: payment.dadosParaPagamento.email,
                  bank: payment.dadosParaPagamento.banco,
                  bankAgency: payment.dadosParaPagamento.agencia,
                  bankAccount: payment.dadosParaPagamento.conta,
                  bankAccountType: payment.dadosParaPagamento.tipoConta,
                },
              },
              id: payment.idExtrato,
              campaign: payment.campanha,
              eventDate: payment.data.substring(0, 10),
              paidOut: payment.recebido == 1,
              commission: parseFloat(payment.comissao),
              payment: parseFloat(payment.pagamento),
            })),
            count: response.data.response.count,
          });
        })
        .catch((error) =>
          reject(
            error.response && error.response.data && error.response.data.message
              ? error.response.data.message
              : "Erro inesperado, tente novamente mais tarde."
          )
        );
    });
  };

  confirmPayments = ({ paymentsId }) => {
    return new Promise((resolve, reject) => {
      httpClient
        .put("/financeiro/confirmarPagamentos", { pagamentos: paymentsId })
        .then((response) => {
          const json = response.data.response;
          resolve(json);
        })
        .catch((error) =>
          reject(
            error.response && error.response.data && error.response.data.message
              ? error.response.data.message
              : "Erro inesperado, tente novamente mais tarde."
          )
        );
    });
  };

  advancePayment = () => {
    return new Promise((resolve, reject) => {
      httpClient
        .post("/financeiro/adiantarComissao")
        .then(() => resolve())
        .catch((error) =>
          reject(
            error.response && error.response.data && error.response.data.message
              ? error.response.data.message
              : "Erro inesperado, tente novamente mais tarde."
          )
        );
    });
  };
}

export default new FinancialService();
