import httpClient from "./axios.config";

class AuthService {
  singIn = (username, password) => {
    return new Promise((resolve, reject) => {
      httpClient
        .post("/security/authenticate", { username, password })
        .then((response) => resolve(response.data.response))
        .catch((error) =>
          reject(
            error.response && error.response.data && error.response.data.message
              ? error.response.data.message
              : "Erro inesperado, tente novamente mais tarde."
          )
        );
    });
  };

  getProfile = () => {
    return new Promise((resolve, reject) => {
      httpClient
        .get("/security/me")
        .then((response) => {
          const user = response.data.response;

          resolve({
            id: parseInt(user.idUsuario),
            displayName: user.nome,
            photoUrl: user.foto,
            role: !!user.produtosInternos ? "ANUNCIANTE" : user.tipoUsuario,
            email: user.email,
            inviteToken: user.meuLink,
            idTipoUsuario: user.idTipoUsuario,
            isReset: user.isMudarSenha,
            theme: user.tema,
          });
        })
        .catch((error) =>
          reject(
            error.response && error.response.data && error.response.data.message
              ? error.response.data.message
              : "Erro inesperado, tente novamente mais tarde."
          )
        );
    });
  };

  getUsersPermissions = () => {
    return new Promise((resolve, reject) => {
      httpClient
        .get("/usuario/config")
        .then((response) => {
          const config = response.data.response;

          resolve({
            userTypes: config.tipos.map((tipo) => ({
              id: parseInt(tipo.id),
              name: tipo.nome,
            })),
            groupTypes: config.grupos.map((grupo) => ({
              id: parseInt(grupo.id),
              name: grupo.nome,
            })),
            profileTypes: config.perfilSubUsuario.map((perfil) => ({
              id: parseInt(perfil.id),
              name: perfil.nome,
            })),
          });
        })
        .catch((error) =>
          reject(
            error.response && error.response.data && error.response.data.message
              ? error.response.data.message
              : "Erro inesperado, tente novamente mais tarde."
          )
        );
    });
  };
}

export default new AuthService();
