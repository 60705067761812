import React from "react";
import useSearchBarController from "./search-bar.controller";
import AutoCompleteGroupComponent from "../../../form/autocomplete-group.component";

const SearchBarComponent = () => {
  const { list, nameFilter, redirectUrl } = useSearchBarController();

  return (
    <AutoCompleteGroupComponent
      options={list}
      placeholder="BUSCAR"
      valor={nameFilter}
      onChange={async (value) => {
        await redirectUrl(value);
      }}
    />
  );
};

export default SearchBarComponent;
