import { createRef } from "react";
import Box from "@mui/material/Box/Box";
import Grid from "@mui/material/Grid/Grid";
import Typography from "@mui/material/Typography/Typography";
import IconButton from "@mui/material/IconButton/IconButton";
import ReCAPTCHA from "react-google-recaptcha";
import { useNavigate } from "react-router-dom";

import {
  InputGradientComponent,
  ButtonGradientComponent,
  LinkComponent,
} from "../../../../components";
import useSignInController from "../sign-in.controller";
import { useResponsive } from "../../../../providers";
import ForgotDialogComponent from "./forgot-dialog.component";
import AddressDialogComponent from "./address-dialog.component";
import WhatsappIcon from "../../../../../assets/icones/whatsapp.svg";
import LucreMaisLightImage from "../../../../../assets/imagens/lucre-mais-light.png";
import GrupoRizomaImage from "../../../../../assets/imagens/grupo-rizoma.png";

const FormComponent = () => {
  const navigate = useNavigate();
  const _reCaptchaRef = createRef();
  const { isDesktop } = useResponsive();
  const {
    control,
    onSubmit,
    setValue,
    openDialogForgot,
    forgotForm,
    onForgot,
    addressForm,
    onSubmitAddress,
    estados,
    cidades,
  } = useSignInController();

  return (
    <Box>
      <Box style={{ display: "flex", justifyContent: "center" }}>
        <Grid
          container
          style={{
            maxWidth: isDesktop ? "22vw" : "100vw",
            paddingInline: isDesktop ? "0" : "4vw",
          }}
        >
          <Grid
            item
            xs={12}
            style={{ textAlign: "center", marginBottom: "5vh" }}
          >
            <img
              src={LucreMaisLightImage}
              style={{
                width: "100%",
                maxWidth: isDesktop ? "12.01vw" : "36.94vw",
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <InputGradientComponent
              name="username"
              control={control}
              placeholder="CPF OU E-MAIL"
            />
          </Grid>
          <Grid item xs={12}>
            <InputGradientComponent
              name="password"
              control={control}
              placeholder="SENHA"
              type="password"
            />
          </Grid>
          <Grid item xs={12} style={{ marginTop: "2vh" }}>
            <div style={{ textAlign: "center" }}>
              <ReCAPTCHA
                style={{ display: "inline-block" }}
                theme="light"
                ref={_reCaptchaRef}
                sitekey={import.meta.env.VITE_RECAPTCHA_KEY}
                onChange={(token) => setValue("reCaptcha", token)}
              />
            </div>
          </Grid>
          <Grid item xs={12} style={{ marginBottom: "5vh" }}>
            <LinkComponent
              onClick={openDialogForgot}
              label="ESQUECI MINHA SENHA"
            />
          </Grid>
          <Grid item xs={12} style={{ marginBottom: "2vh" }}>
            <ButtonGradientComponent
              label="ENTRAR"
              fullWidth
              onClick={onSubmit}
            />
          </Grid>
          <Grid item xs={12} style={{ marginBottom: "5vh" }}>
            <ButtonGradientComponent
              label="CRIAR CONTA"
              fullWidth
              onClick={() => navigate("/criar-conta")}
            />
          </Grid>
          <Grid item xs={12}>
            <Box textAlign="center">
              <Typography
                style={{
                  color: "white",
                  fontWeight: "400",
                  fontSize: "0.97vh",
                  marginBottom: "0.5vh",
                }}
              >
                MAIS UMA EMPRESA DO
              </Typography>
              <img
                src={GrupoRizomaImage}
                style={{
                  width: "100%",
                  maxWidth: isDesktop ? "9.3vw" : "28.61vw",
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box
              textAlign="center"
              style={{
                flexDirection: "row",
                display: "flex",
              }}
            >
              <Typography
                style={{
                  color: "white",
                  fontWeight: "400",
                  fontSize: "1.75vh",
                  marginBottom: "0.5vh",
                }}
              >
                Caso tenha dúvidas ao realizar o seu login, contate nosso
                suporte CLICANDO AQUI
              </Typography>
              <SocialNetworkButton url="https://api.whatsapp.com/send?phone=554792930983&text=Ol%C3%A1,%20estou%20vindo%20da%20plataforma%20LUCRE%20MAIS.%20Preciso%20de%20ajuda!">
                <img
                  src={WhatsappIcon}
                  style={{ height: "2.5vw", width: "2.5vw" }}
                />
              </SocialNetworkButton>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <ForgotDialogComponent
        forgotForm={forgotForm}
        onSubmitForgot={onForgot}
      />
      <AddressDialogComponent
        addressForm={addressForm}
        onSubmitAddress={onSubmitAddress}
        cidades={cidades}
        estados={estados}
      />
    </Box>
  );
};

const SocialNetworkButton = ({ backgroundColor, url, children }) => {
  const { isDesktop } = useResponsive();

  return (
    <IconButton
      style={{
        color: "#f6f6f6",
        backgroundColor: backgroundColor,
        marginRight: "0.5vw",
        width: isDesktop ? "2.5vw" : "10vw",
        height: isDesktop ? "2.5vw" : "10vw",
      }}
      onClick={() => window.open(url, "_blank")}
    >
      {children}
    </IconButton>
  );
};

export default FormComponent;
