import {
  BodyComponent,
  ButtonComponent,
  TableComponent,
} from "../../../../components";
import useAdminBanksController from "./admin-banks.controller";
import AdminBankFormDialogComponent from "./components/admin-bank-form-dialog.component";

const AdminBanksPage = () => {
  const { banks, onOpenCreateForm, onOpenEditForm, onCreate, onEdit } =
    useAdminBanksController();

  return (
    <BodyComponent>
      <AdminBankFormDialogComponent onCreate={onCreate} onEdit={onEdit} />

      <div style={{ marginBottom: "2vh", width: "71vw", textAlign: "end" }}>
        <ButtonComponent
          label="CADASTRAR NOVO BANCO"
          backgroundColor="#40215F"
          color="white"
          size="small"
          height="4vh"
          onClick={onOpenCreateForm}
        />
      </div>

      <TableComponent
        onClick={(row) => onOpenEditForm(row)}
        columns={[
          { label: "ID", acessor: "id", width: "10vw" },
          { label: "NOME", acessor: "name" },
          { label: "NÚMERO", acessor: "number" },
          { label: "DESCRIÇÃO", acessor: "describle" },
        ]}
        rows={banks}
      />
      <div style={{ height: "60vh" }}></div>
    </BodyComponent>
  );
};

export default AdminBanksPage;
