import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { v4 as uuidv4 } from "uuid";

import { Constants, Format } from "../../../../utils";
import { useAlert, useAuth } from "../../../../providers";
import { useIsMounted } from "../../../../hooks";
import {
  advertiserService,
  campaignCategoryService,
  campaignGoalService,
  campaignService,
  mediaService,
  userService,
} from "../../../../../infrastructure/services";
import { useEffect, useState } from "react";
import { useLoadingContext } from "../../../../context/loading.context";
import { useNavigate } from "react-router-dom";

const useAdminCampaignFormController = ({ campaignId }) => {
  const isInternOptions = [
    { id: 1, label: "SIM" },
    { id: 2, label: "NÃO" },
  ];
  const commissionTypeOptions = [
    { id: 1, label: "PREÇO FIXO" },
    { id: 2, label: "PORCENTAGEM" },
  ];
  const withLinkOptions = isInternOptions;
  const formCPLOptionsDefault = [
    "nome",
    "sobrenome",
    "nome completo",
    "data de nascimento",
    "email",
    "cpf",
    "cnpj",
    "cnh",
    "telefone",
    "celular",
    "telefone comercial",
    "pix",
    "endereço",
    "senha",
  ];
  const formCPVOptionsDefault = [
    "nome",
    "sobrenome",
    "nome completo",
    "data de nascimento",
    "email",
    "cpf",
    "cnpj",
    "cnh",
    "telefone",
    "celular",
    "telefone comercial",
    "pix",
    "endereço",
  ];
  const formCPROptionsDefault = [
    "nome completo",
    "data de nascimento",
    "email",
    "cpf",
    "rg",
    "telefone",
    "pix",
    "endereço",
  ];
  const isActiveOptions = [
    { id: 1, label: "SIM", value: "ATIVO" },
    { id: 2, label: "NÃO", value: "INATIVO" },
  ];

  const schema = yup.object().shape({
    name: yup.string().required("Campo obrigatório"),
    category: yup
      .number()
      .required("Campo obrigatório")
      .test("invalid", "Selecione uma das opções", (value) => {
        return value > 0;
      }),
    logo: yup.string().required("Campo obrigatório"),
    isIntern: yup
      .number()
      .required("Campo obrigatório")
      .test("invalid", "Selecione uma das opções", (value) => {
        return value > 0;
      }),
    isYoutube: yup.boolean(),
    textoBotao: yup
      .string()
      .nullable()
      .optional()
      .when("isIntern", {
        is: (isIntern) => isIntern == 1,
        then: () => yup.string().required("Campo obrigatório"),
      }),
    corBotao: yup
      .string()
      .nullable()
      .optional()
      .when("isIntern", {
        is: (isIntern) => isIntern == 1,
        then: () => yup.string().required("Campo obrigatório"),
      }),
    describle: yup.string().required("Campo obrigatório"),
    isActive: yup
      .number()
      .required("Campo obrigatório")
      .test("invalid", "Selecione uma das opções", (value) => {
        return value > 0;
      }),

    commission: yup.string().required("Campo obrigatório"),
    commissionType: yup
      .number()
      .required("Campo obrigatório")
      .test("invalid", "Selecione uma das opções", (value) => {
        return value > 0;
      }),

    externalLink: yup
      .string()
      .optional()
      .when("isIntern", {
        is: (isIntern) => isIntern == 2,
        then: () => yup.string().required("Campo obrigatório"),
      }),
    shareId: yup
      .string()
      .optional()
      .when("isIntern", {
        is: (isIntern) => isIntern == 2,
        then: () => yup.string().required("Campo obrigatório"),
      }),
    withLink: yup
      .number()
      .optional()
      .when("isIntern", {
        is: (isIntern) => isIntern == 2,
        then: () =>
          yup
            .number()
            .required("Campo obrigatório")
            .test("invalid", "Selecione uma das opções", (value) => {
              return value > 0;
            }),
      }),

    users: yup
      .array()
      .optional()
      .when("isIntern", {
        is: (isIntern) => isIntern == 1,
        then: () =>
          yup
            .array()
            .required("Campo obrigatório")
            .min(1, "Selecione no mínimo um usuário"),
      }),
    goals: yup
      .array()
      .optional()
      .when("isIntern", {
        is: (isIntern) => isIntern == 1,
        then: () =>
          yup
            .array()
            .required("Campo obrigatório")
            .min(1, "Selecione no mínimo uma meta"),
      }),
    banner: yup
      .string()
      .nullable()
      .optional()
      .when("isIntern", {
        is: (isIntern) => isIntern == 1,
        then: () =>
          yup
            .string()
            .nullable()
            .when("isYoutube", {
              is: (isYoutube) => isYoutube === false,
              then: () => yup.string().required("Campo obrigatório"),
            }),
      }),

    linkYoutube: yup
      .string()
      .nullable()
      .optional()
      .when("isYoutube", {
        is: (isYoutube) => isYoutube === true,
        then: () => yup.string().required("Campo obrigatório"),
      }),
    internLogo: yup
      .string()
      .nullable()
      .optional()
      .when("isIntern", {
        is: (isIntern) => isIntern == 1,
        then: () => yup.string().required("Campo obrigatório"),
      }),
    internDescrible: yup
      .string()
      .nullable()
      .optional()
      .when("isIntern", {
        is: (isIntern) => isIntern == 1,
        then: () => yup.string().required("Campo obrigatório"),
      }),

    formCPL: yup
      .array()
      .optional()
      .when("goals", {
        is: (goals) => goals.filter((goal) => goal.id == 1).length > 0,
        then: () =>
          yup
            .array()
            .required("Campo obrigatório")
            .min(1, "Selecione no mínimo um campo para o foumulário"),
      }),

    formCPV: yup
      .array()
      .optional()
      .when("goals", {
        is: (goals) => goals.filter((goal) => goal.id == 2).length > 0,
        then: () =>
          yup
            .array()
            .required("Campo obrigatório")
            .min(1, "Selecione no mínimo um campo para o foumulário"),
      }),

    formCPR: yup
      .array()
      .optional()
      .when("goals", {
        is: (goals) => goals.filter((goal) => goal.id == 3).length > 0,
        then: () =>
          yup
            .array()
            .required("Campo obrigatório")
            .min(1, "Selecione no mínimo um campo para o foumulário"),
      }),

    linkCPLA: yup
      .string()
      .optional()
      .when("goals", {
        is: (goals) => goals.filter((goal) => goal.id == 4).length > 0,
        then: () => yup.string().required("Campo obrigatório"),
      }),

    productsCPV: yup
      .array()
      .optional()
      .when("goals", {
        is: (goals) => goals.filter((goal) => goal.id == 2).length > 0,
        then: () =>
          yup
            .array()
            .required("Campo obrigatório")
            .min(1, "Adicione no mínimo um produto"),
      }),
  });

  const { control, handleSubmit, watch, reset, getValues, setValue } = useForm({
    defaultValues: {
      name: "",
      describle: "",
      category: Constants.defaultSelectPlaceholder.id,
      isIntern: isInternOptions[1].id,
      logo: null,
      isActive: isActiveOptions[0].id,

      externalLink: "",
      shareId: "",
      withLink: withLinkOptions[0].id,

      banner: null,
      internLogo: null,
      internDescrible: "",
      users: [],
      goals: [],

      formCPL: [],

      formCPV: [],
      productsCPV: [],

      formCPR: [],

      linkCPLA: "",

      productTitle: "",
      productImages: [],
      productValue: 0,
      productCode: "",
      productDescrible: "",
      productCommission: 0,
      productCommissionType: commissionTypeOptions[0].id,

      commissionType: commissionTypeOptions[0].id,
      linkYoutube: "",
      isYoutube: false,
      textoBotao: "",
      corBotao: "",
      commission: 0,
    },
    resolver: yupResolver(schema),
  });

  const navigate = useNavigate();
  const { user } = useAuth();
  const { showError, showSuccess, showInfo } = useAlert();
  const isMounted = useIsMounted();
  const { setLoadingState } = useLoadingContext();

  const [categoryOptions, setCategoryOptions] = useState([
    Constants.defaultSelectPlaceholder,
  ]);
  const [usersOptions, setUsersOptions] = useState([]);
  const [goalsOptions, setGoalsOptions] = useState([]);
  const [goalsSelecteds, setGoalsSelecteds] = useState([]);
  const [isIntern, setIsIntern] = useState(false);
  const [productsCPV, setProductsCPV] = useState([]);
  const [campaignToEdit, setCampaignToEdit] = useState(null);
  const [isValidPreview, setIsValidPreview] = useState(false);
  const [formCPLOptions, setFormCPLOptions] = useState(formCPLOptionsDefault);
  const [formCPVOptions, setFormCPVOptions] = useState(formCPVOptionsDefault);
  const [formCPROptions, setFormCPROptions] = useState(formCPROptionsDefault);
  const [editProductMode, setEditProductMode] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [images, setImages] = useState([]);
  const [productImages, setProductImages] = useState([]);
  const [commissions, setCommissions] = useState([]);
  const [commissionType, setCommissionType] = useState(
    commissionTypeOptions[0].id
  );
  const [productCommissionType, setProductCommissionType] = useState(
    commissionTypeOptions[0].id
  );

  useEffect(() => {
    if (!!user) {
      setLoadingState(true);
      campaignCategoryService
        .getCategories()
        .then((response) => {
          if (isMounted.current) {
            setCategoryOptions([
              Constants.defaultSelectPlaceholder,
              ...response.map((item) => ({
                id: item.id,
                label: item.name,
              })),
            ]);
          }
        })
        .catch(showError)
        .finally(() => {
          setLoadingState(false);
        });

      setLoadingState(true);
      userService
        .getActiveUsers({ mode: "NAO-ADMIN" })
        .then((response) => {
          if (isMounted.current) {
            setUsersOptions(
              response.map((item) => ({
                id: item.id,
                label: item.displayName,
              }))
            );
          }
        })
        .catch(showError)
        .finally(() => {
          setLoadingState(false);
        });

      setLoadingState(true);
      campaignGoalService
        .getGoals()
        .then((response) => {
          if (isMounted.current) {
            setGoalsOptions(
              response.map((item) => ({
                id: item.id,
                label: item.name,
              }))
            );
          }
        })
        .catch(showError)
        .finally(() => {
          setLoadingState(false);
        });
    }
  }, [user]);

  useEffect(() => {
    if (!!campaignId) {
      fetchCampaign();
    }
  }, [campaignId]);

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      checkIsValidPreview(value);
      if (name == "isIntern") {
        setIsIntern(value.isIntern == 1);
      } else if (name == "commissionType") {
        setCommissionType(value.commissionType);
      } else if (name == "productCommissionType") {
        setProductCommissionType(value.productCommissionType);
      }
    });

    return () => subscription.unsubscribe();
  }, [watch]);

  const fetchCampaign = () => {
    setIsLoading(true);

    setLoadingState(true);
    campaignService
      .getCampaignToEditById({ id: campaignId })
      .then((campaign) => {
        setIsLoading(false);

        const campaignForm = {
          name: campaign.name,
          describle: campaign.describle,
          category: campaign.category.id,
          isIntern: campaign.isIntern,
          logo: campaign.logoUrl,
          isActive: campaign.isActive,
          commission: campaign.commission,
          commissionType: campaign.commissionType,

          linkYoutube: campaign.linkYoutube,
          isYoutube: campaign.isYoutube,
          textoBotao: campaign.textoBotao,
          corBotao: campaign.corBotao,

          externalLink: campaign.shareLink ?? "",
          shareId: campaign.shareId ?? "",
          withLink: campaign.withLink ?? withLinkOptions[0].id,

          banner: campaign.bannerUrl ?? null,
          internLogo: campaign.internLogoUrl ?? null,
          internDescrible: campaign.internDescrible,
          users: !!campaign.users
            ? campaign.users.map((user) => ({
                id: user.id,
                label: user.displayName,
              }))
            : [],
          goals: campaign.goals ?? [],

          formCPL: !!campaign.formCPL ? campaign.formCPL.split(",") : [],

          formCPV: !!campaign.formCPV ? campaign.formCPV.split(",") : [],
          productsCPV: !!campaign.productsCPV ? campaign.productsCPV : [],

          formCPR: !!campaign.formCPR ? campaign.formCPR.split(",") : [],

          linkCPLA: campaign.linkCPLA ?? "",

          productTitle: "",
          productImages: [],
          productValue: 0,
          productCode: "",
          productDescrible: "",
          productCommission: 0,
          productCommissionType: commissionTypeOptions[0].id,

          commissions: campaign.commissions,
        };

        setCommissionType(campaign.commissionType);
        setImages(campaign.images);

        setFormCPVOptions(
          formCPVOptionsDefault.concat(
            campaignForm.formCPV.filter(
              (question) => !formCPVOptionsDefault.includes(question)
            )
          )
        );

        setFormCPLOptions(
          formCPLOptionsDefault.concat(
            campaignForm.formCPL.filter(
              (question) => !formCPLOptionsDefault.includes(question)
            )
          )
        );

        setFormCPROptions(
          formCPROptionsDefault.concat(
            campaignForm.formCPR.filter(
              (question) => !formCPROptionsDefault.includes(question)
            )
          )
        );

        reset(campaignForm);
        setCampaignToEdit(campaignForm);
        setIsIntern(campaignForm.isIntern == 1);
        setGoalsSelecteds(campaignForm.goals);
        setProductsCPV(!!campaign.productsCPV ? campaign.productsCPV : []);
        setCommissions(campaignForm.commissions);
      })
      .catch(showRequestError)
      .finally(() => {
        setLoadingState(false);
      });
  };

  const onSubmit = handleSubmit((data) => {
    var form = {
      nome: data.name,
      descricao: data.describle,
      categoriaId: data.category,
      situacao: isActiveOptions.filter(
        (option) => option.id == data.isActive
      )[0].value,
      comLink: withLinkOptions.filter((option) => option.id == data.withLink)[0]
        .label,
      interno: isInternOptions.filter((option) => option.id == data.isIntern)[0]
        .label,
      comissao:
        commissionType == 1
          ? Format.currencyToFloat(data.commission)
          : data.commission,
      tipoComissao: data.commissionType,
      linkYoutube: data.linkYoutube,
      isYoutube: data.isYoutube,
      textoBotao: data.textoBotao,
      corBotao: data.corBotao,
    };

    if (isIntern == false) {
      form["foregonId"] = data.shareId;
      form["linkExterno"] = data.externalLink;
      form["banner"] = data.logo.split("?")[0];

      if (!!campaignId) {
        form["produtoId"] = campaignId;
      }
      saveCampaign(form);
    } else {
      form["metas"] = data.goals.map((goal) => goal.id).join(",");
      form["usuarios"] = data.users.map((user) => user.id).join(",");
      form["banner"] = data.logo.split("?")[0];
      form["bannerDescricao"] = data.banner?.split("?")[0];
      form["descricaoLp"] = data.internDescrible;
      form["logoLp"] = data.internLogo.split("?")[0];

      if (data.goals.filter((goal) => goal.id == 1)) {
        form["formularioCPL"] = data.formCPL.join(",");
      }
      if (data.goals.filter((goal) => goal.id == 2)) {
        form["formularioCPV"] = data.formCPV.join(",");
        form["subProdutosCPV"] = productsCPV.map((item) => {
          return {
            titulo: item.title,
            imagens: item.images,
            codigo: item.code,
            descricao: item.describle,
            valor: item.value,
            comissao: item.commission,
            tipoComissao: item.commissionType,
          };
        });
      }
      if (goalsSelecteds.filter((goal) => goal.id == 3)) {
        form["formularioCPR"] = data.formCPR.join(",");
      }
      if (goalsSelecteds.filter((goal) => goal.id == 4)) {
        form["linkCPLA"] = data.linkCPLA;
      }

      if (!!campaignId) {
        form["produtoId"] = campaignId;
      }

      saveCampaign(form);
    }
  });

  const uploadLogo = ({ filename, image, callback }) => {
    setIsLoading(true);

    setLoadingState(true);
    mediaService
      .upload({ filename: filename, image: image })
      .then((imageUrl) => {
        setIsLoading(false);
        callback(imageUrl);
      })
      .catch(showRequestError)
      .finally(() => {
        setLoadingState(false);
      });
  };

  const saveCampaign = (form) => {
    setIsLoading(true);

    setLoadingState(true);
    campaignService
      .createCampaign(form)
      .then(() => {
        setIsLoading(false);

        if (!!campaignId) {
          fetchCampaign();
          showSuccess("Campanha editada!");
        } else {
          if (!campaignId) {
            if (isIntern == true) {
              onRegisterAdvertiser({
                form: form,
                callback: () => {
                  setIsLoading(false);
                  navigate("/admin/campanhas");
                  showSuccess("Nova campanha criada!");
                },
              });
            } else {
              setIsLoading(false);
              navigate("/admin/campanhas");
              showSuccess("Nova campanha criada!");
            }
          }
        }
      })
      .catch(showRequestError)
      .finally(() => {
        setLoadingState(false);
      });
  };

  const onRegisterAdvertiser = ({ form, callback }) => {
    const userId = getValues("users")[0].id;

    setLoadingState(true);
    userService
      .getUser({ id: userId })
      .then((advertiser) => {
        const formAdvertiser = {
          nome: advertiser.displayName,
          nomeEmpresa: null,
          site: null,
          email: advertiser.email,
          telefone: advertiser.phone,
          endereco: !!advertiser.address
            ? advertiser.address +
              ", " +
              advertiser.addressNumber +
              ", " +
              advertiser.addressDistrict +
              ", " +
              advertiser.addressCity +
              ", " +
              advertiser.addressState +
              ", " +
              advertiser.addressCep
            : null,
          idTipoProduto: form.categoriaId,
          instagram: null,
          facebook: null,
          documento: null,
          idTipoPagamento: form.tipoComissao,
          valor: form.comissao,
        };

        setLoadingState(true);
        advertiserService
          .register({ form: formAdvertiser })
          .then(() => callback())
          .catch(showRequestError)
          .finally(() => {
            setLoadingState(false);
          });
      })
      .catch(showRequestError)
      .finally(() => {
        setLoadingState(false);
      });
  };

  const onAddProduct = () => {
    const values = getValues();
    if (
      values.productImages.length > 0 &&
      !!values.productTitle &&
      !!values.productValue &&
      !!values.productCode &&
      !!values.productDescrible &&
      !!values.productCommission &&
      !!values.productCommissionType &&
      productsCPV.filter((product) => product.code == values.productCode)
        .length == 0
    ) {
      const product = {
        images: values.productImages,
        title: values.productTitle,
        code: values.productCode,
        describle: values.productDescrible,
        value: Format.currencyToFloat(values.productValue),
        commission:
          productCommissionType == 1
            ? Format.currencyToFloat(values.productCommission)
            : values.productCommission,
        commissionType: values.productCommissionType,
      };

      setProductsCPV([...productsCPV, product]);
      setValue("productsCPV", [...productsCPV, product]);
      setValue("productValue", 0);
      setValue("productTitle", "");
      setValue("productImages", []);
      setProductImages([]);
      setValue("productCode", "");
      setValue("productDescrible", "");
      setValue("productCommission", 0);
      setValue("productCommissionType", commissionTypeOptions[0].id);
    }
  };

  const onEditProduct = () => {
    const values = getValues();
    if (
      values.productImages.length > 0 &&
      !!values.productTitle &&
      !!values.productValue &&
      !!values.productCode &&
      !!values.productDescrible &&
      !!values.productCommission &&
      !!values.productCommissionType
    ) {
      const editedProduct = {
        images: values.productImages,
        title: values.productTitle,
        code: values.productCode,
        describle: values.productDescrible,
        value: values.productValue,
        commission: values.productCommission,
        commissionType: values.productCommissionType,
      };

      const newProducts = productsCPV.map((product) => {
        if (product.code == values.productCode) {
          return editedProduct;
        } else {
          return product;
        }
      });

      setProductsCPV(newProducts);
      setValue("productsCPV", newProducts);
      setValue("productValue", 0);
      setValue("productTitle", "");
      setValue("productImages", []);
      setProductImages([]);
      setValue("productCode", "");
      setValue("productDescrible", "");
      setValue("productCommission", 0);
      setEditProductMode(false);
      setValue("productCommissionType", commissionTypeOptions[0].id);
    }
  };

  const onRemoveProduct = (code) => {
    const newProducts = productsCPV.filter((product) => product.code != code);
    setProductsCPV(newProducts);
    setValue("productsCPV", newProducts);
  };

  const onAddProductToEdit = (code) => {
    setEditProductMode(true);

    const product = productsCPV.filter((product) => product.code == code)[0];

    setValue("productValue", product.value);
    setValue("productTitle", product.title);
    setValue("productImages", product.images);
    setProductImages(product.images);
    setValue("productCode", product.code);
    setValue("productDescrible", product.describle);
    setValue("productCommission", product.commission);
    setValue("productCommissionType", product.commissionType);
  };

  const onAddCPVQuestion = (question) => {
    const values = getValues("formCPV");
    setFormCPVOptions([...formCPVOptions, question]);
    setValue("formCPV", [...values, question]);
  };

  const onAddCPLQuestion = (question) => {
    const values = getValues("formCPL");
    setFormCPLOptions([...formCPLOptions, question]);
    setValue("formCPL", [...values, question]);
  };

  const editImage = ({ name, data, callback }) => {
    const formValue = getValues(name);

    var filename = uuidv4();
    if (!!formValue) {
      filename = Format.getFileNameFromURL(formValue);
    }

    uploadLogo({
      filename: filename,
      image: data,
      callback: (imageUrl) => {
        callback(imageUrl);
      },
    });
  };

  const addSupportImage = (data) => {
    const form = {
      produtoId: campaignId,
      tipo: "apoio",
      anexo: data,
    };

    setIsLoading(true);

    setLoadingState(true);
    campaignService
      .addCampaignSupportImage(form)
      .then((response) => {
        setIsLoading(false);
        setImages(response);
      })
      .catch(showRequestError)
      .finally(() => {
        setLoadingState(false);
      });
  };

  const addProductImage = (data) => {
    setIsLoading(true);
    var filename = uuidv4();

    uploadLogo({
      filename: filename,
      image: data,
      callback: (imageUrl) => {
        setIsLoading(false);
        setValue("productImages", [
          ...productImages,
          { url: imageUrl, id: filename },
        ]);
        setProductImages([...productImages, { url: imageUrl, id: filename }]);
      },
    });
  };

  const removeProductImage = (id) => {
    setValue(
      "productImages",
      productImages.filter((image) => image.id != id)
    );
    setProductImages(productImages.filter((image) => image.id != id));
  };

  const removeSupportImage = (id) => {
    setIsLoading(true);
    setLoadingState(true);
    campaignService
      .removeCampaignSupportImage({ id })
      .then((response) => {
        setIsLoading(false);
        setImages(response);
      })
      .catch(showRequestError)
      .finally(() => {
        setLoadingState(false);
      });
  };

  const checkIsValidPreview = (value) => {
    const containsCPLGoal = () => {
      return value.goals.filter((goal) => goal.id == 1).length > 0;
    };

    const containsCPVGoal = () => {
      return value.goals.filter((goal) => goal.id == 2).length > 0;
    };

    if (value.goals.filter((goal) => goal.id == 3 || goal.id == 4).length > 0) {
      setIsValidPreview(false);
    } else if (
      !!value.name &&
      !!value.describle &&
      !!value.logo &&
      !!value.internDescrible &&
      !!value.internLogo &&
      !!value.banner &&
      value.logo.startsWith("http") &&
      value.internLogo.startsWith("http") &&
      value.banner.startsWith("http") &&
      value.goals.length > 0 &&
      (containsCPLGoal() ? value.formCPL.length > 0 : true) &&
      (containsCPVGoal() ? value.formCPV.length > 0 : true) &&
      (containsCPVGoal() ? value.productsCPV.length > 0 : true)
    ) {
      setIsValidPreview(true);
    } else {
      setIsValidPreview(false);
    }
  };

  const onPreview = () => {
    const form = getValues();

    var preview = {
      nome: form.name,
      descricao: form.internDescrible.replace("%", "$percent$"),
      logoUrl: form.internLogo,
      bannerUrl: form.banner,
      metas: form.goals.map((goal) => goal.id),
      buttonColor: form.corBotao.replace("#", "$hashtag$"),
      buttonText: form.textoBotao,
      isYoutube: form.isYoutube,
      linkYoutube: form.linkYoutube,
    };

    if (preview["metas"].includes(1)) {
      preview["formularioCPL"] = form.formCPL.join(",");
    }

    if (preview["metas"].includes(2)) {
      preview["formularioCPV"] = form.formCPV.join(",");

      preview["subProdutosCPV"] = form.productsCPV.map((item) => ({
        titulo: item.title,
        imagens: item.images,
        valor: item.value,
        codigo: item.code,
        descricao: item.describle,
      }));
    }

    window.open(
      import.meta.env.VITE_URL_FORMULARIO +
        "/?preview=" +
        JSON.stringify(preview),
      "_blank"
    );
  };

  const onChangeCommissionToProfile = ({ form, callback }) => {
    setIsLoading(true);
    setLoadingState(true);
    campaignService
      .changeCommissionByProfile(form)
      .then(() => {
        setIsLoading(false);
        showSuccess("Comissão alterada com sucesso");
        setCommissions(
          commissions.map((item) => {
            if (item.id == form.id) {
              return {
                ...item,
                commission: form.valor,
                commissionType: form.tipoComissao,
              };
            }
            return item;
          })
        );
        callback();
      })
      .catch(showRequestError)
      .finally(() => {
        setLoadingState(false);
      });
  };

  const onGoalsSelecteds = (values) => {
    const resultCPR = values.filter((value) => value.id == 3);
    const resultCPLA = values.filter((value) => value.id == 4);

    if (resultCPR.length > 0) {
      setValue("goals", resultCPR);
      setGoalsSelecteds(resultCPR);
    } else if (resultCPLA.length > 0) {
      setValue("goals", resultCPLA);
      setGoalsSelecteds(resultCPLA);
    } else {
      setValue("goals", values);
      setGoalsSelecteds(values);
    }
  };

  const onDeleteCampaign = () => {
    setIsLoading(true);
    setLoadingState(true);
    campaignService
      .deleteCampaign({ id: campaignId })
      .then(() => {
        setIsLoading(false);
        navigate("/admin/campanhas");
        showInfo("Campanha excluida");
      })
      .catch(showRequestError)
      .finally(() => {
        setLoadingState(false);
      });
  };

  const showRequestError = (error) => {
    setIsLoading(false);
    showError(error);
  };

  return {
    control,
    isToEdit: !!campaignToEdit,
    categoryOptions,
    isInternOptions,
    withLinkOptions,
    usersOptions,
    goalsOptions,
    goalsSelecteds,
    isIntern,
    formCPLOptions,
    formCPVOptions,
    productsCPV,
    isActiveOptions,
    onSubmit,
    onAddProduct,
    isValidPreview,
    onPreview,
    onAddCPVQuestion,
    onAddCPLQuestion,
    onRemoveProduct,
    onEditProduct,
    onAddProductToEdit,
    editProductMode,
    editImage,
    images,
    commissions,
    onChangeCommissionToProfile,
    addSupportImage,
    removeSupportImage,
    commissionTypeOptions,
    commissionType,
    productCommissionType,
    watch,
    setValue,
    onGoalsSelecteds: onGoalsSelecteds,
    formCPROptions,
    onDeleteCampaign,
    isLoading,
    productImages,
    addProductImage,
    removeProductImage,
  };
};

export default useAdminCampaignFormController;
