import ReactDOM from "react-dom/client";
import { RouterProvider } from "react-router-dom";
import dayjs from "dayjs";
import "dayjs/locale/pt-br";

import {
  AlertProvider,
  AuthProvider,
  DialogProvider,
  NotificationProvider,
  ResponsiveProvider,
} from "./presentation/providers";
import { LoadingProvider } from "./presentation/context/loading.context";
import { WhiteLabelProvider } from "./presentation/providers/white-label.provider";
import { HelpButtonComponent } from "./presentation/components";
import Rotas from "./rotas";
import "./estilos.css";

dayjs.locale("pt-br");

ReactDOM.createRoot(document.getElementById("root")).render(
  <ResponsiveProvider>
    <AlertProvider>
      <LoadingProvider>
        <AuthProvider>
          <WhiteLabelProvider>
            <DialogProvider>
              <NotificationProvider>
                <RouterProvider router={Rotas} />
                <HelpButtonComponent />
              </NotificationProvider>
            </DialogProvider>
          </WhiteLabelProvider>
        </AuthProvider>
      </LoadingProvider>
    </AlertProvider>
  </ResponsiveProvider>
);
