import React from "react";

import AppBar from "@mui/material/AppBar/AppBar";
import Grid from "@mui/material/Grid/Grid";
import Toolbar from "@mui/material/Toolbar/Toolbar";
import styled from "@mui/material/styles/styled";

import LogoComponent from "./logo.component";
import SearchBarComponent from "./search-bar.component";
import ProfileButtonComponent from "./profile-button.component";
import NotificationsButtonComponent from "./notifications-button.component";
import { useAuth, useResponsive } from "../../../../providers";
import { useWhiteLabel } from "../../../../providers/white-label.provider";
import { useGlobalStyles } from "../../../../hooks";

const CustomAppBar = styled(AppBar)((props) => ({
  zIndex: props.theme.zIndex.drawer + 2,
}));

const NavbarComponent = () => {
  const { isDesktop } = useResponsive();
  const { user } = useAuth();
  const classesGlobal = useGlobalStyles();
  const { hasCustomTheme } = useWhiteLabel();

  return (
    <CustomAppBar
      position="fixed"
      elevation={hasCustomTheme ? 1 : 0}
      className={classesGlobal.appBar}
    >
      <Toolbar style={{ padding: 0 }}>
        <Grid
          container
          style={{ alignItems: "center", justifyContent: "space-between" }}
        >
          <Grid item style={{ paddingLeft: "6.59vw" }}>
            <LogoComponent />
          </Grid>
          <Grid item style={{ paddingRight: "6.87vw" }}>
            <Grid container spacing={4} style={{ alignItems: "center" }}>
              {isDesktop && !!user && user.role != "ANUNCIANTE" && (
                <Grid item style={{ marginRight: "1.38vw" }}>
                  <SearchBarComponent />
                </Grid>
              )}
              <Grid item>
                <ProfileButtonComponent />
              </Grid>
              <Grid item>
                <NotificationsButtonComponent />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Toolbar>
    </CustomAppBar>
  );
};

export default NavbarComponent;
