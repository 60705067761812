import React from "react";

import { authService } from "../../infrastructure/services";
import { useAlert } from "./alert.provider";
import { decodeToken } from "react-jwt";
import { useLoadingContext } from "../context/loading.context";

const USER_ROUTES = [
  "/home",
  "/perfil",
  "/afiliados",
  "/relatorios",
  "/propostas",
  "/propostas/[id]",
  "/campanhas",
  "/campanhas/[id]",
  "/financeiro",
  "/dashboard",
  "/duvidas",
  "/licenciamento",
];
const ADMIN_ROUTES = [
  "/home",
  "/perfil",
  "/dashboard",
  "/relatorios",
  "/admin/relatorios/formulario",
  "/campanhas",
  "/licenciamento",
  "/campanhas/[id]",
  "/admin/usuarios",
  "/admin/usuarios/formulario",
  "/admin/campanhas",
  "/admin/campanhas/formulario",
  "/admin/notificacoes",
  "/admin/notificacoes/formulario",
  "/admin/propostas",
  "/admin/propostas/formulario",
  "/admin/perfis",
  "/admin/anunciantes",
  "/admin/anunciantes/detalhe",
  "/admin/categorias",
  "/admin/bancos",
  "/admin/pagamentos",
  "/admin/modais",
  "/admin/modais/formulario",
];
const ADVERTISER_ROUTES = ["/perfil", "/anunciante/relatorios"];

const AuthContext = React.createContext({
  login: ({ username, password }) => {},
  logout: () => {},
  user: null,
  hasPermission: (page) => {},
  getProfile: () => {},
});

export const useAuth = () => React.useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const { showError } = useAlert();
  const [user, setUser] = React.useState();
  const [isLoading, setIsLoading] = React.useState(false);
  const { setLoadingState } = useLoadingContext();

  React.useEffect(() => {
    if (
      ![
        "/",
        "/entrar",
        "/nova-senha",
        "/criar-conta",
        "/anunciar",
        "/criar-conta/sucesso",
      ].includes(window.location.pathname)
    ) {
      setIsLoading(true);
      authService
        .getProfile()
        .then((user) => {
          setUser({
            ...user,
            isAdmin: user.role == "ADMINISTRADOR",
            isAdvertiser: user.role == "ANUNCIANTE",
          });

          if (!redirectToResetPassword(user.isReset)) {
            if (hasPermissionWithRole(window.location.pathname, user.role)) {
              setIsLoading(false);
            } else {
              redirectToRolePage(user.role);
            }
          }
        })
        .catch(() => (window.location.href = "/entrar"));
    }
  }, []);

  const login = async ({ username, password }) => {
    setLoadingState(true);
    return await authService
      .singIn(username, password)
      .then((jwt) => {
        localStorage.setItem("token", jwt);
        const json = decodeToken(jwt);
        const user = json.usuario;

        if (!redirectToResetPassword(user.mudarSenha)) {
          if (!user.enderecoPendente) {
            redirectToRolePage(
              !!user.produtosInternos ? "ANUNCIANTE" : user.tipoUsuario
            );
          } else {
            return user;
          }
        }
      })
      .catch(showError)
      .finally(() => {
        setLoadingState(false);
      });
  };

  const logout = () => {
    window.location.href = "/entrar";
    localStorage.removeItem("token");
  };

  const getProfile = () => {
    setLoadingState(true);
    authService
      .getProfile()
      .then((user) => {
        setUser({
          ...user,
          isAdmin: user.role == "ADMINISTRADOR",
          isAdvertiser: user.role == "ANUNCIANTE",
        });
      })
      .catch(showError)
      .finally(() => {
        setLoadingState(false);
      });
  };

  const redirectToRolePage = (role) => {
    if (role == "ADMINISTRADOR") {
      window.location.href = "/home";
    } else if (role == "ANUNCIANTE") {
      window.location.href = "/anunciante/relatorios";
    } else {
      window.location.href = "/home";
    }
  };

  const redirectToResetPassword = (isReset) => {
    if (isReset) {
      window.location.href = "/nova-senha";
      return true;
    }
    return false;
  };

  const hasPermission = (route) => {
    if (user == null) return false;

    if (user.isAdmin) {
      return ADMIN_ROUTES.includes(route);
    } else if (user.isAdvertiser) {
      return ADVERTISER_ROUTES.includes(route);
    } else {
      return USER_ROUTES.includes(route);
    }
  };

  const hasPermissionWithRole = (route, role) => {
    const campanhaDetalhesRegex = /^\/campanhas\/\d+$/;
    const adminUsuarioFormularioRegex = /^\/admin\/usuarios\/formulario\/\d+$/;
    const adminAnuncianteDetalhesRegex = /^\/admin\/anunciantes\/detalhe\/\d+$/;
    const adminNotificacoesFormularioRegex =
      /^\/admin\/notificacoes\/formulario\/\d+$/;
    const adminModaisFormularioRegex = /^\/admin\/modais\/formulario\/\d+$/;
    const adminPropostaFormularioRegex =
      /^\/admin\/propostas\/formulario\/\d+$/;
    if (campanhaDetalhesRegex.test(route)) {
      route = "/campanhas";
    } else if (adminUsuarioFormularioRegex.test(route)) {
      route = "/admin/usuarios/formulario";
    } else if (adminAnuncianteDetalhesRegex.test(route)) {
      route = "/admin/anunciantes/detalhe";
    } else if (adminNotificacoesFormularioRegex.test(route)) {
      route = "/admin/notificacoes/formulario";
    } else if (adminModaisFormularioRegex.test(route)) {
      route = "/admin/modais/formulario";
    } else if (adminPropostaFormularioRegex.test(route)) {
      route = "/admin/propostas/formulario";
    }

    if (role == "ADMINISTRADOR") {
      return ADMIN_ROUTES.includes(route);
    } else if (role == "ANUNCIANTE") {
      return ADVERTISER_ROUTES.includes(route);
    } else {
      return USER_ROUTES.includes(route);
    }
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        login,
        logout,
        hasPermission,
        getProfile,
        redirectToRolePage,
      }}
    >
      {isLoading == true ? (
        <div style={{ backgroundColor: "#F9F6F8" }}></div>
      ) : (
        !isLoading && children
      )}
    </AuthContext.Provider>
  );
};
