import React from "react";
import IconButton from "@mui/material/IconButton/IconButton";
import InputAdornment from "@mui/material/InputAdornment/InputAdornment";
import TextField from "@mui/material/TextField/TextField";
import makeStyles from "@mui/styles/makeStyles/makeStyles";

import SearchIcon from "../../../assets/icones/search.svg";

const useStyles = makeStyles(() => ({
  root: {
    "& .MuiOutlinedInput-root": {
      borderRadius: "100px",
      backgroundColor: "#ffffff",
      "&:hover fieldset": {
        borderColor: "transparent",
      },
      "&.Mui-focused fieldset": {
        borderColor: "transparent",
      },
    },
    "& .MuiOutlinedInput-input::placeholder": {
      color: "#858585",
      fontSize: "1.46vh",
      fontWeight: "700",
    },
    "& .MuiOutlinedInput-input": {
      color: "#000000",
    },
    "& .MuiInputAdornment-root": {
      color: "#000000",
    },
  },
}));

const InputSearchComponent = ({ placeholder, fullWidth, onChange, valor }) => {
  const classes = useStyles();

  return (
    <TextField
      className={classes.root}
      fullWidth={fullWidth}
      style={{ width: !fullWidth ? "29.30vw" : null }}
      variant="outlined"
      placeholder={placeholder}
      size="small"
      margin="none"
      value={valor}
      onChange={(e) =>
        onChange !== undefined ? onChange(e.target.value) : null
      }
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <IconButton edge="start" style={{ marginBottom: "0.39vh" }}>
              <img src={SearchIcon} />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};

export default InputSearchComponent;
