import httpClient from "./axios.config";

class CityService {
  getCities = ({ state }) => {
    return new Promise((resolve, reject) => {
      httpClient
        .get("/utilidades/cidades?uf=" + state)
        .then((response) => {
          const citiesResponse = response.data.response;

          resolve(
            citiesResponse.map((cidade) => ({
              id: parseInt(cidade.cidadeId),
              name: cidade.nome,
            }))
          );
        })
        .catch((error) =>
          reject(
            error.response && error.response.data && error.response.data.message
              ? error.response.data.message
              : "Erro inesperado, tente novamente mais tarde."
          )
        );
    });
  };
}

export default new CityService();
