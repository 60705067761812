import Chip from "@mui/material/Chip/Chip";

import { useResponsive } from "../providers";

const CurrencyChipComponent = ({ value, backgroundColor }) => {
  const { isDesktop } = useResponsive();

  return (
    <Chip
      style={{
        backgroundColor: backgroundColor ?? "#40215F",
        fontSize: isDesktop ? "1.46vh" : "0.97vh",
        color: "white",
        height: isDesktop ? null : "2.2vh",
      }}
      label={value}
      size="small"
    />
  );
};

export default CurrencyChipComponent;
