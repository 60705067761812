import React from "react";
import Grid from "@mui/material/Grid/Grid";
import Typography from "@mui/material/Typography/Typography";
import { useNavigate } from "react-router-dom";

import { ButtonGradientComponent } from "../../../../../components";
import { useResponsive } from "../../../../../providers";
import GanharMuitoDinheiroImage from "../../../../../../assets/imagens/ganhar-muito-dinheiro.png";
import GanharMuitoDinheiroMobileImage from "../../../../../../assets/imagens/ganhar-muito-dinheiro-mobile.png";

const DescribleComponent = () => {
  const navigate = useNavigate();
  const { isDesktop } = useResponsive();

  return (
    <Grid
      container
      style={{
        paddingLeft: isDesktop ? "5vw" : "10vw",
        marginTop: isDesktop ? "5vh" : "2vh",
      }}
    >
      <Grid item xs={isDesktop ? null : 12}>
        <img
          src={
            isDesktop
              ? GanharMuitoDinheiroImage
              : GanharMuitoDinheiroMobileImage
          }
          style={
            isDesktop
              ? { width: "80%", maxWidth: "60vw" }
              : { maxWidth: "50vw" }
          }
        />
        {!isDesktop && (
          <iframe
            xs={12}
            width={isDesktop ? "70%" : "60%"}
            height={isDesktop ? "100%" : "100%"}
            src="https://www.youtube.com/embed/MknMjo_SrS0"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
            style={{
              position: "relative",
              zIndex: 2,
              height: "46vw",
              width: "80vw",
              marginRight: "1vw",
              marginTop: "3vw",
            }}
          ></iframe>
        )}
      </Grid>
      <Grid container item>
        {isDesktop && (
          <iframe
            xs={12}
            width={isDesktop ? "70%" : "60%"}
            height={isDesktop ? "100%" : "100%"}
            src="https://www.youtube.com/embed/MknMjo_SrS0"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
            style={{
              paddingTop: "3vw",
              height: "20vw",
              paddingBottom: "1vw",
            }}
          ></iframe>
        )}
      </Grid>
      <Grid
        item
        xs={isDesktop ? null : 12}
        style={{
          marginTop: "3vh",
          marginBottom: "3vh",
          paddingRight: isDesktop ? 0 : "10vw",
        }}
      >
        <Typography
          style={{ color: "#F1F1F1", fontSize: "1.56vh", fontWeight: "400" }}
        >
          A Lucre+, que gera links de indicação personalizados e te possibilita
          ganhar dinheiro de uma forma simples e fácil. O cadastro é gratuito e
          você recebe comissões diárias de tudo que vende!
        </Typography>
      </Grid>
      <Grid
        item
        xs={isDesktop ? null : 12}
        style={{
          paddingRight: isDesktop ? 0 : "10vw",
          paddingBottom: isDesktop ? 0 : "15vw",
        }}
      >
        {!isDesktop && (
          <ButtonGradientComponent
            label="CRIAR CONTA"
            width={isDesktop ? "19.16vw" : null}
            height="5vh"
            fullWidth={!isDesktop}
            onClick={() => navigate("/criar-conta")}
          />
        )}
        {!isDesktop && (
          <div>
            <div style={{ marginTop: "2vh", marginBottom: "2vh" }}>
              <ButtonGradientComponent
                label="ACESSAR SUA CONTA"
                width={isDesktop ? "19.16vw" : null}
                height="5vh"
                fullWidth={!isDesktop}
                onClick={() => navigate("/entrar")}
              />
            </div>
            <ButtonGradientComponent
              label="ANUNCIE SEU PRODUTO AQUI"
              width={isDesktop ? "19.16vw" : null}
              height="5vh"
              fullWidth={!isDesktop}
              onClick={() => navigate("/anunciar")}
            />
          </div>
        )}
      </Grid>
    </Grid>
  );
};

export default DescribleComponent;
