import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { userService } from "../../../../infrastructure/services";
import { useAlert, useAuth } from "../../../providers";
import { useLoadingContext } from "../../../context/loading.context";

const useNewPasswordController = () => {
  const { showError, showInfo, showSuccess } = useAlert();
  const { user, logout } = useAuth();
  const { setLoadingState } = useLoadingContext();

  const minPassword = 6;
  const messagePasswordValidation = `Deve ter mais de ${minPassword} caracteres`;

  const schema = yup.object().shape({
    password: yup
      .string()
      .min(6, messagePasswordValidation)
      .required("Campo obrigatório"),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref("password"), null], "Senhas devem ser iguais")
      .min(6, messagePasswordValidation)
      .required("Campo obrigatório"),
  });

  const { control, handleSubmit, setValue } = useForm({
    defaultValues: {
      password: "",
      confirmPassword: "",
      acceptedTerm: false,
      reCaptcha: "",
    },
    resolver: yupResolver(schema),
  });

  const onSubmit = handleSubmit((data) => {
    if (data.reCaptcha === "") {
      showInfo(
        "O uso do reCAPTCHA é obrigatório. Por favor, complete o desafio para continuar."
      );
    } else {
      setLoadingState(true);
      userService
        .resetPassword({
          novaSenha: data.password,
          reptiSenha: data.confirmPassword,
        })
        .then(async () => {
          showSuccess("Senha redefinida com sucesso.");
          logout();
        })
        .catch(showError)
        .finally(() => {
          setLoadingState(false);
        });
    }
  });

  return { control, onSubmit, setValue, messagePasswordValidation };
};

export default useNewPasswordController;
