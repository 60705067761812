import TextField from "@mui/material/TextField/TextField";
import styled from "@mui/material/styles/styled";
import { Controller } from "react-hook-form";

const StyledTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    fontWeight: "700",
    fontSize: "1.46vh",
    color: "#858585",
    "& fieldset": {
      borderRadius: "10px",
    },
    "&:hover fieldset": {
      borderRadius: "10px",
    },
    "&.Mui-focused fieldset": {
      borderRadius: "10px",
    },
    "&.Mui-error fieldset": {
      borderRadius: "10px",
      borderColor: "red",
    },
    "&.Mui-disabled fieldset": {
      borderRadius: "10px",
    },
  },
});

const InputFilterComponent = ({
  name,
  control,
  label,
  placeholder,
  disabled,
  multiline,
  minRows,
  type,
  value,
  onChange,
  onClick,
  error,
  width,
}) => {
  const Field = ({ field, formState }) => {
    return (
      <StyledTextField
        name={name}
        variant="outlined"
        margin="none"
        fullWidth={width == null}
        type={type}
        minRows={minRows}
        maxRows={minRows}
        multiline={multiline}
        onClick={onClick}
        value={!!field ? field.value : value}
        onChange={(e) => {
          if (!!field) {
            field.onChange(e.target.value);
          } else {
            onChange(e.target.value);
          }
        }}
        placeholder={label}
        label={placeholder}
        disabled={disabled || (!!field && formState.isSubmitting)}
        error={!!field ? !!formState.errors[name] : !!error}
        helperText={!!field ? formState.errors[name]?.message : error}
        InputLabelProps={{
          style: {
            fontWeight: "700",
            fontSize: "1.46vh",
            color: "#5C5C5C",
          },
        }}
        InputProps={{
          style: { height: multiline ? null : "5.5vh", width: width },
        }}
      />
    );
  };

  if (!!control) {
    return (
      <Controller
        name={name}
        control={control}
        render={({ field, formState }) => (
          <Field field={field} formState={formState} />
        )}
      />
    );
  }

  return <Field field={null} formState={null} />;
};

export default InputFilterComponent;
