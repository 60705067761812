import Avatar from "@mui/material/Avatar/Avatar";
import Badge from "@mui/material/Badge/Badge";
import Box from "@mui/material/Box/Box";
import Grid from "@mui/material/Grid/Grid";
import Typography from "@mui/material/Typography/Typography";
import Card from "@mui/material/Card/Card";
import makeStyles from "@mui/styles/makeStyles/makeStyles";
import styled from "@mui/styles/styled/styled";
import { useNavigate } from "react-router-dom";

import { useAuth, useDialog, useResponsive } from "../../../../providers";
import { ButtonComponent } from "../../../../components";
import StartLightIcon from "../../../../../assets/icones/star-light.svg";

const StyledBadge = styled(Badge)(() => ({
  "& .MuiBadge-badge": {
    right: "50%",
    "&:hover": {
      cursor: "pointer",
    },
  },
}));

const useStyles = makeStyles({
  cardHover: {
    "&:hover": {
      cursor: "pointer",
    },
  },
});

const CampaignCard = ({ featured, isPreview }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { user } = useAuth();
  const { openDialog } = useDialog();
  const { isDesktop } = useResponsive();
  const showCard = featured.isActive == true || isPreview == true;

  return (
    <div>
      {!!user && user.role == "ADMINISTRADOR" && isPreview == false && (
        <Box
          style={{
            display: "flex",
            justifyContent: "end",
            marginBottom: "0.5vh",
          }}
        >
          <ButtonComponent
            label="EDITAR"
            height="3vh"
            backgroundColor="#C4C4C4"
            color="white"
            borderRadius="10px"
            onClick={() =>
              openDialog("change-featured-campaign-dialog", featured)
            }
          />
        </Box>
      )}
      {showCard && (
        <StyledBadge
          overlap="rectangular"
          onClick={() =>
            navigate("/campanhas/" + (featured?.campaign?.id ?? ""))
          }
          badgeContent={<BadgeAvatar styleColor={featured?.color ?? ""} />}
        >
          <div
            className={classes.cardHover}
            onClick={() =>
              navigate("/campanhas/" + (featured?.campaign?.id ?? ""))
            }
          >
            <Card
              elevation={0}
              style={{
                width: isDesktop ? "34.16vw" : "100%",
                height: "28vh",
                borderRadius: "10px",
              }}
            >
              <Grid container spacing={2} style={{ marginTop: "4vh" }}>
                <Grid
                  item
                  xs={12}
                  style={{
                    backgroundColor: featured?.color ?? "",
                    height: "5.17vh",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "100%",
                    }}
                  >
                    <Typography
                      style={{
                        fontWeight: "700",
                        fontSize: isDesktop ? "1.75vh" : "1.35vh",
                        color: "white",
                      }}
                    >
                      {featured?.title ?? ""}
                    </Typography>
                  </div>
                </Grid>
                <Grid
                  container
                  item
                  xs={12}
                  style={{
                    height: "19vh",
                    alignItems: "center",
                    paddingInline: "4vw",
                  }}
                >
                  <Grid item xs={6}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        height: "100%",
                      }}
                    >
                      <img
                        src={featured?.campaign?.logoUrl ?? ""}
                        style={{ maxWidth: "14vh", maxHeight: "10vh" }}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography
                      style={{
                        fontWeight: "700",
                        fontSize: "1.46vh",
                        color: "#5C5C5C",
                        marginBottom: "1.5vh",
                      }}
                    >
                      {featured?.campaign?.name ?? ""}
                    </Typography>
                    <Typography
                      style={{
                        fontWeight: "400",
                        fontSize: "1.46vh",
                        color: "#5C5C5C",
                      }}
                    >
                      {featured?.describle ?? ""}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Card>
          </div>
        </StyledBadge>
      )}
    </div>
  );
};

const BadgeAvatar = ({ styleColor }) => {
  return (
    <Avatar
      style={{
        backgroundColor: styleColor,
        width: "5.42vh",
        height: "5.42vh",
      }}
    >
      <img src={StartLightIcon} />
    </Avatar>
  );
};

export default CampaignCard;
