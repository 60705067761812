import { BodyComponent, HelpComponent } from "../../../components";

const HelpPage = () => {
  return (
    <BodyComponent>
      <HelpComponent />
    </BodyComponent>
  );
};

export default HelpPage;
