import Card from "@mui/material/Card/Card";

import DescribeComponent from "./components/describe.component";
import TitleComponent from "./components/title.component";
import RectangleComponent from "./components/middle.component";

//TODO: Verificar margem a esquerda
const BeLicenseePage = () => {
  return (
    <Card
      elevation={0}
      sx={{
        width: "71vw",
        height: "auto",
        background: "linear-gradient(180deg, #094E86 0%, #2E7BB4 100%);",
        marginBottom: "8vh",
        borderRadius: "10px",
      }}
    >
      <TitleComponent></TitleComponent>
      <RectangleComponent></RectangleComponent>
      <DescribeComponent></DescribeComponent>
    </Card>
  );
};

export default BeLicenseePage;
