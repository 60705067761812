import Grid from "@mui/material/Grid/Grid";

import { useResponsive } from "../../../../providers";
import CampaignCard from "./campaign-card.component";

const FeaturedCampaignsComponent = ({ featuredCampaigns }) => {
  const { isDesktop } = useResponsive();

  return (
    <div style={{ width: "71vw" }}>
      <Grid container direction={isDesktop ? "row" : "column"} spacing={5}>
        {featuredCampaigns.map((featured, i) => {
          return (
            <Grid key={i} item xs={isDesktop ? 6 : 12}>
              <CampaignCard isPreview={false} featured={featured} />
            </Grid>
          );
        })}
      </Grid>
    </div>
  );
};

export default FeaturedCampaignsComponent;
