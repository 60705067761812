import httpClient from "./axios.config";

class NotificationService {
  getNotifications = () => {
    return new Promise((resolve, reject) => {
      httpClient
        .get("/notificacao/buscar")
        .then((response) => {
          const notificationsResponse = response.data.response;

          const notifications = notificationsResponse.map((notification) => ({
            id: parseInt(notification.idNotificacao),
            title: notification.titulo,
            describle: notification.descricao,
            link: notification.link,
            eventDate: notification.dtCriacao,
          }));

          resolve(notifications);
        })
        .catch((error) =>
          reject(
            error.response && error.response.data && error.response.data.message
              ? error.response.data.message
              : "Erro inesperado, tente novamente mais tarde."
          )
        );
    });
  };
  getPreferences = () => {
    return new Promise((resolve, reject) => {
      httpClient
        .get("/notificacao/preferencias")
        .then((response) => {
          const preferencesResponse = response.data.response;

          const preferences = preferencesResponse.map((preference) => ({
            id: parseInt(preference.id),
            name: preference.nome,
            isActive: preference.ativa,
            sendType: preference.tipoEnvio,
            sendGroup: preference.grupoEnvio,
            notificationType: preference.tipoNotificacao,
            title: preference.titulo,
            content: preference.conteudo,
            media: preference.media,
            link: preference.link,
          }));

          resolve(preferences);
        })
        .catch((error) =>
          reject(
            error.response && error.response.data && error.response.data.message
              ? error.response.data.message
              : "Erro inesperado, tente novamente mais tarde."
          )
        );
    });
  };
  sendNotification = ({ query }) => {
    return new Promise((resolve, reject) => {
      httpClient
        .post("/notificacao/enviar", query)
        .then(() => resolve())
        .catch((error) =>
          reject(
            error.response && error.response.data && error.response.data.message
              ? error.response.data.message
              : "Erro inesperado, tente novamente mais tarde."
          )
        );
    });
  };
  updatePreference = ({ query }) => {
    return new Promise((resolve, reject) => {
      httpClient
        .put("/notificacao/preferencia", query)
        .then(() => resolve())
        .catch((error) =>
          reject(
            error.response && error.response.data && error.response.data.message
              ? error.response.data.message
              : "Erro inesperado, tente novamente mais tarde."
          )
        );
    });
  };
  testPreference = ({ query }) => {
    return new Promise((resolve, reject) => {
      httpClient
        .post("/notificacao/preferencia", query)
        .then(() => resolve())
        .catch((error) =>
          reject(
            error.response && error.response.data && error.response.data.message
              ? error.response.data.message
              : "Erro inesperado, tente novamente mais tarde."
          )
        );
    });
  };
  getPreferenceToEditById = ({ id }) => {
    return new Promise((resolve, reject) => {
      httpClient
        .get("/notificacao/preferencia?idPreferencia=" + id)
        .then((response) => {
          const preference = response.data.response;

          resolve({
            id: parseInt(preference.id),
            name: preference.nome,
            isActive: preference.ativa,
            sendType: preference.tipoEnvio,
            sendGroup: preference.grupoEnvio,
            notificationType: preference.tipoNotificacao,
            title: preference.titulo,
            content: preference.conteudo,
            media: preference.media,
            link: preference.link,
            imagemCabecalho: preference.imagemCabecalho,
            imagemRodape: preference.imagemRodape,
          });
        })
        .catch((error) =>
          reject(
            error.response && error.response.data && error.response.data.message
              ? error.response.data.message
              : "Erro inesperado, tente novamente mais tarde."
          )
        );
    });
  };
  getModals = () => {
    return new Promise((resolve, reject) => {
      httpClient
        .get("/notificacao/modal")
        .then((response) => {
          const modalsResponse = response.data.response;
          resolve(
            modalsResponse.map((modalResponse) => ({
              id: parseInt(modalResponse.id),
              active: modalResponse.ativo,
              bannerUrl: modalResponse.bannerUrl,
              link: modalResponse.link,
              width: modalResponse.width,
              idTipoUsuario: modalResponse.idTipoUsuario,
              height: modalResponse.height,
              order: modalResponse.ordem,
              bannerType: modalResponse.bannerTipo,
              fundoTransparente: modalResponse.fundoTransparente == "1",
            }))
          );
        })
        .catch((error) =>
          reject(
            error.response && error.response.data && error.response.data.message
              ? error.response.data.message
              : "Erro inesperado, tente novamente mais tarde."
          )
        );
    });
  };

  getDataOfModal = ({ modalId }) => {
    return new Promise((resolve, reject) => {
      httpClient
        .get("/notificacao/modal")
        .then((response) => {
          const modalsResponse = response.data.response;

          const modalResponse = modalsResponse.filter(
            (modal) => parseInt(modal.id) == modalId
          )[0];

          resolve({
            id: parseInt(modalResponse.id),
            active: modalResponse.ativo,
            bannerUrl: modalResponse.bannerUrl,
            link: modalResponse.link,
            width: modalResponse.width,
            idTipoUsuario: modalResponse.idTipoUsuario,
            height: modalResponse.height,
            order: modalResponse.ordem,
            bannerType: modalResponse.bannerTipo,
            fundoTransparente: modalResponse.fundoTransparente == "1",
          });
        })
        .catch((error) =>
          reject(
            error.response && error.response.data && error.response.data.message
              ? error.response.data.message
              : "Erro inesperado, tente novamente mais tarde."
          )
        );
    });
  };

  changeDataOfInitialModal = (form) => {
    return new Promise((resolve, reject) => {
      httpClient
        .put("/notificacao/modal", form)
        .then(() => resolve())
        .catch((error) =>
          reject(
            error.response && error.response.data && error.response.data.message
              ? error.response.data.message
              : "Erro inesperado, tente novamente mais tarde."
          )
        );
    });
  };

  createInitialModal = (form) => {
    return new Promise((resolve, reject) => {
      httpClient
        .post("/notificacao/modal", form)
        .then(() => resolve())
        .catch((error) =>
          reject(
            error.response && error.response.data && error.response.data.message
              ? error.response.data.message
              : "Erro inesperado, tente novamente mais tarde."
          )
        );
    });
  };
}

export default new NotificationService();
