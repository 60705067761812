import Divider from "@mui/material/Divider/Divider";
import Grid from "@mui/material/Grid/Grid";
import Typography from "@mui/material/Typography/Typography";

import {
  DatapickerComponent,
  InputFilterComponent,
  SelectComponent,
} from "../../../../../components";

const BasicSectionComponent = ({ control, genderOptions, isLoading }) => {
  return (
    <div>
      <Typography
        style={{
          fontWeight: "600",
          fontSize: "1.75vh",
          color: "#5C5C5C",
        }}
      >
        DADOS BÁSICOS
      </Typography>
      <Divider style={{ marginBottom: "2vh" }} />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <InputFilterComponent
                name="displayName"
                control={control}
                label="NOME COMPLETO"
                placeholder="NOME COMPLETO"
                disabled={isLoading}
              />
            </Grid>
            <Grid item xs={3}>
              <InputFilterComponent
                name="email"
                control={control}
                label="E-MAIL"
                placeholder="E-MAIL"
                disabled={isLoading}
              />
            </Grid>
            <Grid item xs={3}>
              <InputFilterComponent
                name="cpf"
                control={control}
                label="CPF"
                placeholder="CPF"
                disabled={isLoading}
              />
            </Grid>
            <Grid item xs={3}>
              <InputFilterComponent
                name="rg"
                control={control}
                label="RG"
                placeholder="RG"
                disabled={isLoading}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <InputFilterComponent
                name="phone"
                control={control}
                label="TELEFONE"
                placeholder="TELEFONE"
                disabled={isLoading}
              />
            </Grid>
            <Grid item xs={3}>
              <InputFilterComponent
                name="branch"
                control={control}
                label="RAMAL"
                placeholder="RAMAL"
                disabled={isLoading}
              />
            </Grid>
            <Grid item xs={3}>
              <DatapickerComponent
                name="birthDate"
                control={control}
                label="DATA DE NASCIMENTO"
                disabled={isLoading}
              />
            </Grid>
            <Grid item xs={3}>
              <SelectComponent
                name="gender"
                control={control}
                label="SEXO"
                acessor="label"
                options={genderOptions}
                disabled={isLoading}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <InputFilterComponent
                name="cnh"
                control={control}
                label="CNH"
                placeholder="CNH"
                disabled={isLoading}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default BasicSectionComponent;
