import InputAdornment from "@mui/material/InputAdornment/InputAdornment";
import TextField from "@mui/material/TextField/TextField";
import makeStyles from "@mui/styles/makeStyles/makeStyles";

import ButtonComponent from "./button.component";
import { NumericFormat } from "react-number-format";

const useStyles = makeStyles(() => ({
  inputLight: {
    "& .MuiOutlinedInput-input::placeholder": {
      color: "#5C5C5C",
      fontSize: "1.46vh",
      fontWeight: "700",
    },
  },
  inputDark: {
    "& .MuiOutlinedInput-input::placeholder": {
      color: "white",
      fontSize: "1.46vh",
      fontWeight: "700",
    },
    "& .MuiOutlinedInput-root": {
      color: "#5C5C5C",
      fontWeight: "400",
      fontSize: "1.46vh",
      "& fieldset": {
        borderRadius: "10px",
        borderColor: "#C4C4C4",
      },
      "&:hover fieldset": {
        borderRadius: "10px",
        borderColor: "#C4C4C4",
      },
      "&.Mui-focused fieldset": {
        borderRadius: "10px",
        borderColor: "#C4C4C4",
      },
    },
  },
}));

const InputWithButtonFormatComponent = ({
  placeholder,
  buttonLabel,
  value,
  onClick,
  width,
  backgroundColor,
  color,
  onChange,
  errorMessage,
  isLight,
  type,
  format,
  disabled,
}) => {
  const classes = useStyles();

  const handleInputChange = (event) => {
    const { value } = event.target;
    onChange(value);
  };

  return (
    <NumericFormat
      customInput={TextField}
      format={format == "%" ? "##%" : null}
      suffix={format == "%" ? " %" : undefined}
      prefix={format == "R$" ? "R$ " : undefined}
      allowNegative={false}
      allowLeadingZeros={false}
      decimalScale={format == "R$" ? 2 : undefined}
      fixedDecimalScale={format == "R$"}
      thousandSeparator={format == "R$" ? "." : false}
      decimalSeparator={format == "R$" ? "," : undefined}
      disabled={disabled}
      onValueChange={(values) => {
        const { formattedValue } = values;
        handleInputChange({ target: { value: formattedValue } });
      }}
      //Input
      variant="outlined"
      placeholder={placeholder}
      fullWidth
      className={!!isLight ? classes.inputLight : classes.inputDark}
      style={{ width: width, color: color }}
      value={value}
      error={!!errorMessage}
      helperText={errorMessage}
      type={type}
      InputProps={{
        style: {
          backgroundColor: backgroundColor,
          color: color,
          borderRadius: "10px",
        },
        endAdornment: (
          <InputAdornment position="end">
            <ButtonComponent
              label={buttonLabel}
              height="4vh"
              backgroundColor={isLight ? "#774E98" : "white"}
              color={isLight ? "white" : "#C4C4C4"}
              borderRadius="10px"
              onClick={onClick}
            />
          </InputAdornment>
        ),
      }}
    />
  );
};

export default InputWithButtonFormatComponent;
