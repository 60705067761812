import React, { useState, useEffect } from "react";
import Paper from "@mui/material/Paper/Paper";
import Checkbox from "@mui/material/Checkbox/Checkbox";
import Divider from "@mui/material/Divider/Divider";
import Grid from "@mui/material/Grid/Grid";
import TextField from "@mui/material/TextField/TextField";
import Select from "@mui/material/Select/Select";
import MenuItem from "@mui/material/MenuItem/MenuItem";
import Typography from "@mui/material/Typography/Typography";
import styled from "@mui/material/styles/styled";
import makeStyles from "@mui/styles/makeStyles/makeStyles";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

import { useResponsive } from "../../providers";
import IconButtonComponent from "../form/icon-button.component";
import SearchIcon from "../../../assets/icones/search.svg";

const CustomSelect = styled(Select)(({ theme }) => ({
  height: "40px",
}));

const useStyles = makeStyles(() => ({
  tableHeader: {
    display: "flex",
    alignItems: "center",
    borderColor: "transparent",
    marginInline: "3vw",
    paddingLeft: "1vw",
    paddingRight: "1vw",
  },
  tableBody: {
    display: "flex",
    alignItems: "center",
    borderRadius: "100px",
    marginTop: "4vh",
    marginBottom: "4vh",
    marginInline: "3vw",
    paddingRight: "1vw",
    paddingLeft: "1vw",
  },
  tableHeaderItem: {
    color: "#5C5C5C",
    fontWeight: "700",
    textAlign: "start",
    marginInline: "0.3vw",
  },
  tableBodyItem: {
    color: "#858585",
    fontWeight: "400",
    textAlign: "start",
    marginInline: "0.3vw",
  },
  tableBodyItemHover: {
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "#f0f0f0",
    },
  },
}));

const TableComponent = ({
  columns,
  rows,
  width,
  hiddenBorder,
  onClick,
  isSelected,
  onSelected,
  pagination,
}) => {
  const classes = useStyles();
  const { isDesktop } = useResponsive();
  const [selectedRows, setSelectedRows] = useState([]);
  const [filterValues, setFilterValues] = useState({});

  useEffect(() => {
    setSelectedRows([]);
  }, [rows]);

  const handleRowSelect = (row) => {
    const selectedIndex = selectedRows.indexOf(row);
    let newSelectedRows = [];

    if (selectedIndex === -1) {
      newSelectedRows = newSelectedRows.concat(selectedRows, row);
    } else if (selectedIndex === 0) {
      newSelectedRows = newSelectedRows.concat(selectedRows.slice(1));
    } else if (selectedIndex === selectedRows.length - 1) {
      newSelectedRows = newSelectedRows.concat(selectedRows.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelectedRows = newSelectedRows.concat(
        selectedRows.slice(0, selectedIndex),
        selectedRows.slice(selectedIndex + 1)
      );
    }

    setSelectedRows(newSelectedRows);
    onSelected(newSelectedRows);
  };

  const handleSelectAll = () => {
    if (isAllSelected()) {
      setSelectedRows([]);
      onSelected([]);
    } else {
      setSelectedRows(rows);
      onSelected(rows);
    }
  };

  const isAllSelected = () => rows.length === selectedRows.length;

  let filterRows = () => {
    return rows.filter((row) => {
      if (Object.keys(filterValues).length === 0) {
        return true;
      }
      let returnValue = 0;

      for (const key in filterValues) {
        const value = filterValues[key];

        if (value !== null && value !== "") {
          const column = columns.find((col) => col.acessor === key);

          if (column) {
            const columnValue = row[column.acessor];

            if (
              typeof columnValue === "string" &&
              columnValue.toLowerCase().includes(value.toLowerCase())
            ) {
              returnValue += 1;
            }
            if (
              (typeof columnValue === "number" && columnValue === value) ||
              columnValue.id === value ||
              value === 0
            ) {
              returnValue += 1;
            }

            if (typeof columnValue === "boolean" && columnValue === value) {
              returnValue += 1;
            }
          }
        } else {
          returnValue += 1;
        }
      }

      return returnValue >= Object.keys(filterValues).length;
    });
  };

  const filteredRows = filterRows();

  const containsFilter =
    columns.filter((column) => column.filter || column.selectFilter).length > 0;

  return (
    <Paper
      variant="outlined"
      style={{
        width: width ?? "71vw",
        border: !!hiddenBorder ? "hidden" : null,
        borderRadius: "10px",
        marginTop: !!hiddenBorder ? "1vh" : 0,
      }}
    >
      <Paper
        variant="outlined"
        className={classes.tableHeader}
        style={{
          height: !!hiddenBorder ? "2vh" : isDesktop ? "11vh" : "7vh",
          marginLeft: !!hiddenBorder ? 0 : "3vw",
          marginRight: "3vw",
        }}
      >
        {isSelected && (
          <div style={{ width: "4vw" }}>
            <Checkbox
              disableRipple
              disableFocusRipple
              disableTouchRipple
              color="primary"
              checked={isAllSelected()}
              indeterminate={!isAllSelected() && selectedRows.length > 0}
              onChange={handleSelectAll}
            />
          </div>
        )}
        {columns.map((column, index) => {
          if (column.hidden) return null;

          return (
            <div
              key={index}
              style={{
                paddingLeft: "0.1vw",
                width: column.width ?? "17vw",
                textAlign: column.center ? "center" : "start",
              }}
            >
              <Typography
                className={classes.tableHeaderItem}
                style={{
                  fontSize: isDesktop ? "1.75vh" : "0.97vh",
                  paddingBottom: containsFilter
                    ? !column.filter && !column.selectFilter
                      ? "45px"
                      : ""
                    : null,
                }}
              >
                {column.label}
              </Typography>
              {column.filter && (
                <TextField
                  variant="outlined"
                  size="small"
                  fullWidth
                  InputProps={{
                    endAdornment: <img src={SearchIcon} />,
                  }}
                  onChange={(e) =>
                    setFilterValues({
                      ...filterValues,
                      [column.acessor]: e.target.value,
                    })
                  }
                />
              )}
              {column.selectFilter && (
                <CustomSelect
                  variant="outlined"
                  size="small"
                  fullWidth
                  inputProps={{
                    style: {
                      paddingRight: "24px",
                      backgroundColor: "transparent",
                    },
                  }}
                  SelectDisplayProps={{
                    style: {
                      alignItems: "center",
                      backgroundColor: "transparent",
                    },
                  }}
                  IconComponent={() => (
                    <img
                      style={{ paddingRight: "10px" }}
                      src={SearchIcon}
                      alt="Search"
                    />
                  )}
                  onChange={(e) => {
                    setFilterValues({
                      ...filterValues,
                      [column.acessor]: e.target.value,
                    });
                  }}
                >
                  {column.selectData.map((option) => (
                    <MenuItem key={option.id} value={option.id}>
                      {option.label}
                    </MenuItem>
                  ))}
                </CustomSelect>
              )}
            </div>
          );
        })}
      </Paper>
      {!hiddenBorder && <Divider />}
      {!!hiddenBorder && <div style={{ marginBottom: "4vh" }}></div>}
      {filteredRows.map((row, index) => (
        <Paper
          key={index}
          variant="outlined"
          onClick={!!onClick ? () => onClick(row) : null}
          className={
            classes.tableBody +
            " " +
            (!!onClick ? classes.tableBodyItemHover : null)
          }
          style={{
            marginLeft: !!hiddenBorder ? "0vh" : "3vw",
            marginRight: "3vw",
            marginTop: !!hiddenBorder ? "1vh" : "2vh",
            marginBottom: !!hiddenBorder ? "1vh" : "2vh",
            height: isDesktop ? "7vh" : "5vh",
          }}
        >
          {isSelected && (
            <div style={{ width: "4vw" }}>
              <Checkbox
                disableFocusRipple
                disableTouchRipple
                disableRipple
                color="primary"
                checked={selectedRows.includes(row)}
                onChange={() => handleRowSelect(row)}
              />
            </div>
          )}
          {columns.map((column, index) => {
            if (column.hidden) return null;

            if (!!column.formatWidget) {
              return (
                <div
                  key={index}
                  className={classes.tableBodyItem}
                  style={{
                    width: column.width ?? "17vw",
                    textAlign: column.center ? "center" : "start",
                    fontSize: isDesktop ? "1.46vh" : "0.97vh",
                  }}
                >
                  {column.formatWidget(row)}
                </div>
              );
            }

            return (
              <Typography
                key={index}
                className={classes.tableBodyItem}
                style={{
                  width: column.width ?? "17vw",
                  fontSize: isDesktop ? "1.46vh" : "0.97vh",
                  whiteSpace: "pre-line",
                }}
              >
                {!!column.formatText
                  ? column.formatText(row)
                  : row[column.acessor]}
              </Typography>
            );
          })}
        </Paper>
      ))}

      {!!pagination && (
        <Grid container direction="column">
          <Grid item>
            <Grid
              container
              spacing={2}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: rows.length == 0 ? "2vh" : null,
              }}
            >
              <Grid item>
                <PaginationButton
                  onClick={() =>
                    pagination.handleChangePage(pagination.page - 1)
                  }
                  icon={<ArrowBackIosIcon style={{ color: "white" }} />}
                  disabled={pagination.page == 0 || pagination.count == 0}
                />
              </Grid>
              {pagination.count > 0 && (
                <Grid
                  item
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginRight: "1vh",
                    marginLeft: "1vh",
                  }}
                >
                  <h2 style={{ fontWeight: 600 }}>{pagination.page + 1}</h2>
                </Grid>
              )}
              <Grid item>
                <PaginationButton
                  onClick={() =>
                    pagination.handleChangePage(pagination.page + 1)
                  }
                  icon={<ArrowForwardIosIcon style={{ color: "white" }} />}
                  disabled={
                    pagination.page + 1 ==
                      Math.ceil(pagination.count / pagination.rowsPerPage) ||
                    pagination.count == 0
                  }
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            style={{
              display: "flex",
              justifyContent: "end",
              marginBottom: "2vh",
              marginRight: "2vh",
              marginTop: rows.length === 0 ? "2vh" : null,
            }}
          >
            {pagination.count > 0 && (
              <span style={{ fontWeight: 600 }}>
                {"Itens "}
                {pagination.page * pagination.rowsPerPage + 1} até{" "}
                {Math.min(
                  (pagination.page + 1) * pagination.rowsPerPage,
                  pagination.count
                )}{" "}
                de {pagination.count}
              </span>
            )}
          </Grid>
        </Grid>
      )}
    </Paper>
  );
};

const PaginationButton = ({ icon, disabled, onClick }) => {
  return (
    <IconButtonComponent
      backgroundColor={disabled ? "#858585" : "#774E98"}
      color="white"
      size="small"
      height="5vh"
      disabled={disabled}
      borderRadius="100px"
      onClick={onClick}
      icon={icon}
    />
  );
};

export default TableComponent;
