import Grid from "@mui/material/Grid/Grid";
import Typography from "@mui/material/Typography/Typography";

import { useResponsive } from "../../../../providers";
import { useGlobalStyles } from "../../../../hooks";

const SectionThreeComponent = () => {
  const { isDesktop } = useResponsive();

  return (
    <Grid
      container
      direction="column"
      spacing={2}
      style={{
        marginLeft: isDesktop ? "3vw" : 0,
        marginRight: isDesktop ? "6vw" : 0,
        alignItems: isDesktop ? "start" : "center",
        marginBottom: isDesktop ? 0 : "1vh",
      }}
    >
      <Grid item>
        <Typography style={{ fontSize: "1.95vh", fontWeight: "700" }}>
          LINKS ÚTEIS
        </Typography>
      </Grid>
      <Grid item>
        <CustomLink
          label="O Grupo Rizoma"
          url="https://gruporizoma.com.br/sobre-nos/"
        />
      </Grid>
      <Grid item>
        <CustomLink
          label="Produtos e serviços"
          url="https://gruporizoma.com.br/#elementor-action%3Aaction%3Dpopup%3Aopen%26settings%3DeyJpZCI6Ijc4OSIsInRvZ2dsZSI6ZmFsc2V9"
        />
      </Grid>
      <Grid item>
        <CustomLink label="Blog" url="https://gruporizoma.com.br/blog/" />
      </Grid>
      <Grid item>
        <CustomLink label="Contato" url="https://gruporizoma.com.br/contato/" />
      </Grid>
    </Grid>
  );
};

const CustomLink = ({ label, url }) => {
  const classesGlobal = useGlobalStyles();

  return (
    <Typography style={{ fontSize: "1.46vh", fontWeight: "400" }}>
      <a
        href={url}
        target="_blank"
        className={classesGlobal.linkDecorationNone}
      >
        {label}
      </a>
    </Typography>
  );
};

export default SectionThreeComponent;
