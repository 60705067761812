import Grid from "@mui/material/Grid/Grid";
import Typography from "@mui/material/Typography/Typography";

import { useResponsive } from "../../../../providers";
import { useGlobalStyles } from "../../../../hooks";

const SectionTwoComponent = () => {
  const { isDesktop } = useResponsive();
  const classesGlobal = useGlobalStyles();

  return (
    <Grid
      container
      direction="column"
      spacing={2}
      style={{
        marginInline: isDesktop ? "3vw" : 0,
        alignItems: isDesktop ? "start" : "center",
      }}
    >
      <Grid item>
        <Typography style={{ fontSize: "1.95vh", fontWeight: "700" }}>
          FALE CONOSCO
        </Typography>
      </Grid>
      <Grid item>
        <Typography style={{ fontSize: "1.46vh", fontWeight: "400" }}>
          <a href="tel:5132850302" className={classesGlobal.linkDecorationNone}>
            (51) 3285-0302
          </a>
        </Typography>
      </Grid>
      <Grid item>
        <Typography style={{ fontSize: "1.46vh", fontWeight: "400" }}>
          <a
            href="https://wa.me/555191195019"
            className={classesGlobal.linkDecorationNone}
          >
            (51) 9 9119-5019
          </a>
        </Typography>
      </Grid>
      <Grid item>
        <Typography style={{ fontSize: "1.46vh", fontWeight: "400" }}>
          <a
            href="mailto:novosnegocios@gruporizoma.com.br"
            className={classesGlobal.linkDecorationNone}
          >
            novosnegocios@gruporizoma.com.br
          </a>
        </Typography>
      </Grid>
    </Grid>
  );
};

export default SectionTwoComponent;
