import Divider from "@mui/material/Divider/Divider";
import Grid from "@mui/material/Grid/Grid";
import Typography from "@mui/material/Typography/Typography";

import {
  AutocompleteComponent,
  SelectComponent,
} from "../../../../../components";

const PermissionsSectionComponent = ({
  control,
  userTypeOptions,
  groupTypeOptions,
  profileTypeOptions,
  userLevelOptions,
  primaryUserOptions,
  withPrimary,
  isLoading,
}) => {
  return (
    <div>
      <Typography
        style={{
          fontWeight: "600",
          fontSize: "1.75vh",
          color: "#5C5C5C",
        }}
      >
        PERMISSÕES
      </Typography>
      <Divider style={{ marginBottom: "2vh" }} />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <SelectComponent
                name="userType"
                control={control}
                label="TIPO DE USUÁRIO"
                acessor="label"
                options={userTypeOptions}
                disabled={isLoading}
              />
            </Grid>
            <Grid item xs={3}>
              <SelectComponent
                name="groupType"
                control={control}
                label="GRUPO DE USUÁRIO"
                acessor="label"
                options={groupTypeOptions}
                disabled={isLoading}
              />
            </Grid>
            <Grid item xs={3}>
              <SelectComponent
                name="profileType"
                control={control}
                label="PERFIL DE USUÁRIO"
                acessor="label"
                options={profileTypeOptions}
                disabled={isLoading}
              />
            </Grid>
            <Grid item xs={3}>
              <SelectComponent
                name="userLevel"
                control={control}
                label="NÍVEL DE USUÁRIO"
                acessor="label"
                options={userLevelOptions}
                disabled={isLoading}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            {withPrimary == true && (
              <Grid item xs={3}>
                <AutocompleteComponent
                  name="primaryUser"
                  control={control}
                  label="USUÁRIO PRINCIPAL"
                  acessorLabel="name"
                  acessorValue="id"
                  options={primaryUserOptions}
                  disabled={isLoading}
                  isLarge={true}
                />
              </Grid>
            )}
            {/*<Grid item xs={3}>
              <TimepickerComponent
                name="startJob"
                control={control}
                label="HORÁRIO INICIAL DE ACESSO"
              />
            </Grid>
            <Grid item xs={3}>
              <TimepickerComponent
                name="endJob"
                control={control}
                label="HORÁRIO FINAL DE ACESSO"
              />
            </Grid>*/}
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default PermissionsSectionComponent;
