import React from "react";
import Button from "@mui/material/Button/Button";
import ButtonGroup from "@mui/material/ButtonGroup/ButtonGroup";
import makeStyles from "@mui/styles/makeStyles/makeStyles";

const useStyles = makeStyles(() => ({
  button: {
    textTransform: "none",
    fontWeight: "700",
    fontSize: "1.46vh",
  },
}));

const MenuSectionComponent = ({ situation, onToggleSituation, isLoading }) => {
  const classes = useStyles();

  return (
    <ButtonGroup
      disableElevation
      variant="contained"
      color="primary"
      disabled={isLoading}
    >
      <Button className={classes.button} disabled={true}>
        Sub Usuários
      </Button>
      <Button className={classes.button} disabled={true}>
        Redefinir Senha
      </Button>
      <Button
        className={classes.button}
        onClick={onToggleSituation}
        disabled={isLoading}
      >
        {situation == 1 ? "Suspender" : "Reativar"}
      </Button>
    </ButtonGroup>
  );
};

export default MenuSectionComponent;
