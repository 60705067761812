import React from "react";
import Box from "@mui/material/Box/Box";
import Grid from "@mui/material/Grid/Grid";
import Typography from "@mui/material/Typography/Typography";

import { ButtonComponent } from "../../../../components";

import { useResponsive } from "../../../../providers";

const DescribeComponent = () => {
  const { isDesktop } = useResponsive();

  return (
    <Grid item xs={12} style={{ paddingTop: "4vh", paddingBottom: "4vh" }}>
      <Box display="flex" flexDirection="column" alignItems="center">
        <Typography
          xs={3}
          style={{
            fontWeight: "600",
            fontSize: "18px",
            color: "white",
            marginLeft: "8vw",
            textAlign: "justify",
            marginRight: isDesktop ? "auto" : "8vw",
          }}
        >
          GANHE MAIS DINHEIRO E MAIS TEMPO
        </Typography>
        <Typography
          xs={3}
          style={{
            fontWeight: "600",
            fontSize: "18px",
            color: "white",
            marginLeft: "8vw",
            marginRight: "8vw",
            marginTop: "2vw",
            marginBottom: "3vw",
            textAlign: "justify",
          }}
        >
          Ficamos muito contentes em saber que você está interessado no Grupo
          Rizoma. Aqui, você tem total liberdade para trabalhar em home office
          ou até mesmo abrir sua própria loja de crédito, oferecendo uma ampla
          gama de produtos financeiros e seguros. Além disso, você pode definir
          seu próprio horário e receber comissões semanais sobre todos os
          produtos aprovados. Nós fornecemos todo o suporte necessário,
          incluindo mentoria, cursos e assessoria para ajudá-lo a atuar como um
          banco autônomo. Com nossas comissões pagas toda semana, você pode ter
          certeza de que será recompensado pelo seu trabalho. E não se esqueça:
          aqui, pagou, ganhou! Apenas para lembrar, este não é um convite para
          uma vaga de emprego, mas sim uma oportunidade de se tornar um
          licenciado do Grupo Rizoma.
        </Typography>
        <Grid item xs={12}>
          <ButtonComponent
            label="FAÇA SEU CADASTRO"
            backgroundColor="#FEF12A"
            color="#094E86"
            fullWidth
            height="5vh"
            onClick={() =>
              window.open("https://gruporizoma.com.br/cadastro/", "_blank")
            }
          />
        </Grid>
      </Box>
    </Grid>
  );
};

export default DescribeComponent;
