import React from "react";
import Timeline from "@mui/lab/Timeline/Timeline";
import TimelineItem from "@mui/lab/TimelineItem/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator/TimelineSeparator";
import TimelineDot from "@mui/lab/TimelineDot/TimelineDot";
import TimelineConnector from "@mui/lab/TimelineConnector/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent/TimelineContent";
import Grid from "@mui/material/Grid/Grid";
import Typography from "@mui/material/Typography/Typography";
import Chip from "@mui/material/Chip/Chip";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent/TimelineOppositeContent";
import makeStyles from "@mui/styles/makeStyles/makeStyles";
import moment from "moment/moment";
import "moment/locale/pt-br";

import { notificationService } from "../../infrastructure/services";
import { useAlert } from "./alert.provider";
import { useAuth } from "./auth.provider";
import GenericMenuComponent from "../components/modals/generic-menu.component";
import { useResponsive } from "./responsive.provider";
import { InitialModalComponent } from "../components";
import { useDialog } from "./dialog.provider";

moment.locale("pt-br");

const useStyles = makeStyles(() => ({
  notification: {
    "&:hover": {
      cursor: "pointer",
    },
  },
}));

const NotificationContext = React.createContext({
  notifications: [],
  notificationsNotVisualizedLength: 0,
  openNotifications: () => {},
  fetchNotifications: () => {},
});

export const useNotification = () => React.useContext(NotificationContext);

export const NotificationProvider = ({ children }) => {
  const classes = useStyles();
  const { isDesktop } = useResponsive();
  const { user } = useAuth();
  const { showError } = useAlert();
  const [notifications, setNotifications] = React.useState([]);
  const [visualizedNotifications, setVisualizedNotifications] = React.useState(
    []
  );
  const { openDialog } = useDialog();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const openNotifications = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const closeNotifications = () => {
    setAnchorEl(null);
  };

  React.useEffect(() => {
    var fetchTimer;

    if (user != null) {
      fetchNotifications();
      fetchInitialModal();

      const notificationKey = user.id + "_notifications";
      const localNotifications = localStorage.getItem(notificationKey);

      if (localNotifications == null) {
        localStorage.setItem(notificationKey, JSON.stringify([]));
        setVisualizedNotifications([]);
      } else {
        var list = JSON.parse(localNotifications);
        localStorage.setItem(notificationKey, JSON.stringify(list));
        setVisualizedNotifications(list);
      }

      fetchTimer = setInterval(() => fetchNotifications(), 3600000);
    }

    return () => {
      if (!!fetchTimer) clearTimeout(fetchTimer);
    };
  }, [user]);

  const fetchNotifications = () => {
    notificationService
      .getNotifications()
      .then(setNotifications)
      .catch(showError);
  };

  const fetchInitialModal = () => {
    const modalKey = user.id + "_modal";

    notificationService
      .getModals()
      .then((modalsResponse) => {
        const modals = modalsResponse.filter((m) => m.active == true);
        const modal = modals[0];
        if (modal.active == true && modal.idTipoUsuario == user.idTipoUsuario) {
          const modalHistoric = localStorage.getItem(modalKey);

          if (!!modalHistoric) {
            const data1 = moment(JSON.parse(modalHistoric));
            const data2 = moment(new Date());

            const isSameDate = data1
              .startOf("day")
              .isSame(data2.startOf("day"));

            if (!isSameDate) {
              localStorage.setItem(modalKey, JSON.stringify(new Date()));
              openDialog("initial-modal-dialog", {
                modal,
                nextModals: [...modals.filter((m) => m.id != modal.id)],
              });
            }
          } else {
            localStorage.setItem(modalKey, JSON.stringify(new Date()));
            openDialog("initial-modal-dialog", {
              modal,
              nextModals: [...modals.filter((m) => m.id != modal.id)],
            });
          }
        }
      })
      .catch(showError);
  };

  const openNotificationLink = (notification) => {
    if (!visualizedNotifications.includes(notification.id)) {
      visualizedNotifications.push(notification.id);
      localStorage.setItem(
        user.id + "_notifications",
        JSON.stringify(visualizedNotifications)
      );
      setVisualizedNotifications(visualizedNotifications);
    }

    if (!!notification.link) {
      window.location.href = notification.link;
    }
  };

  const isVisualizedNotification = (id) => {
    return visualizedNotifications.includes(id);
  };

  return (
    <NotificationContext.Provider
      value={{
        notifications,
        openNotifications,
        fetchNotifications,
        notificationsNotVisualizedLength:
          notifications.length == 0
            ? 0
            : notifications.length - visualizedNotifications.length,
      }}
    >
      <GenericMenuComponent
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={closeNotifications}
      >
        <Timeline>
          {notifications.map((notification, index) => {
            return (
              <TimelineItem
                key={index}
                style={{ width: isDesktop ? "23.75vw" : "60vw" }}
              >
                <TimelineSeparator>
                  <TimelineDot
                    variant="outlined"
                    color={
                      isVisualizedNotification(notification.id)
                        ? "grey"
                        : "primary"
                    }
                  />
                  <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent>
                  <Grid
                    container
                    direction="column"
                    className={
                      !!notification.link ? classes.notification : null
                    }
                    style={{ width: isDesktop ? "21vw" : "50vw" }}
                    onClick={() => openNotificationLink(notification)}
                  >
                    <Grid item xs={12}>
                      <Grid
                        container
                        style={{
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <Grid item xs={8}>
                          <Typography
                            style={{
                              fontWeight: "700",
                              fontSize: "1.17vh",
                              color: "#858585",
                            }}
                          >
                            {notification.title}
                          </Typography>
                        </Grid>
                        <Grid item xs={4}>
                          <Chip
                            label={moment(
                              new Date(notification.eventDate)
                            ).from(moment.now())}
                            style={{
                              width: isDesktop ? "7vw" : "20vw",
                              height: "3vh",
                              backgroundColor: "#FFEEF2",
                              color: "#FE5578",
                              fontSize: "0.97vh",
                            }}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} style={{ marginTop: "1vh" }}>
                      <Typography
                        style={{
                          fontWeight: "400",
                          fontSize: "0.97vh",
                          color: "#858585",
                        }}
                      >
                        {notification.describle}
                      </Typography>
                    </Grid>
                  </Grid>
                </TimelineContent>
                <TimelineOppositeContent></TimelineOppositeContent>
              </TimelineItem>
            );
          })}
        </Timeline>
      </GenericMenuComponent>
      <InitialModalComponent />
      {children}
    </NotificationContext.Provider>
  );
};
