import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import { useAlert, useAuth } from "../../../../providers";
import { useIsMounted } from "../../../../hooks";
import {
  authService,
  userService,
} from "../../../../../infrastructure/services";
import { Constants, Format } from "../../../../utils";
import moment from "moment";
import { useLoadingContext } from "../../../../context/loading.context";
import dayjs from "dayjs";

const useAdminUsersController = () => {
  const schema = yup.object().shape(
    {
      name: yup
        .string()
        .optional()
        .when("name", {
          is: (value) => value?.length,
          then: (rule) => rule.min(3, "O nome deve ter no mínimo 3 caracteres"),
        }),
      cpf: yup.string().optional(),
      userType: yup.object().required("Campo obrigatório"),
      profileType: yup.object().required("Campo obrigatório"),
    },
    [["name", "name"]]
  );

  const { control, handleSubmit, getValues } = useForm({
    defaultValues: {
      name: "",
      cpf: "",
      userType: Constants.defaultSelect,
      profileType: Constants.defaultSelect,
      dtCadastroInicio: "",
      dtCadastroFim: "",
    },
    resolver: yupResolver(schema),
  });

  const { showError } = useAlert();
  const { user } = useAuth();
  const isMounted = useIsMounted();
  const { setLoadingState } = useLoadingContext();

  const rowsPerPage = 10;
  const [users, setUsers] = useState([]);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [userTypes, setUserTypes] = useState([Constants.defaultSelect]);
  const [profileTypes, setProfileTypes] = useState([Constants.defaultSelect]);

  useEffect(() => {
    if (user != null) {
      setLoadingState(true);
      authService
        .getUsersPermissions()
        .then((permissions) => {
          if (isMounted.current) {
            setUserTypes([
              Constants.defaultSelect,
              ...permissions.userTypes.map((item) => ({
                id: item.id,
                label: item.name,
              })),
            ]);
            setProfileTypes([
              Constants.defaultSelect,
              ...permissions.profileTypes.map((item) => ({
                id: item.id,
                label: item.name,
              })),
            ]);
          }
        })
        .catch(showError)
        .finally(() => {
          setLoadingState(false);
        });
    }
  }, [user]);

  useEffect(() => {
    if (userTypes.length > 1 && profileTypes.length > 1) {
      onSearch({ page: page, ...getValues() });
    }
  }, [userTypes, profileTypes]);

  const onSubmit = handleSubmit(
    ({ name, cpf, userType, profileType, dtCadastroInicio, dtCadastroFim }) => {
      onSearch({
        page: page,
        name,
        cpf,
        userType,
        profileType,
        dtCadastroInicio,
        dtCadastroFim,
      });
    }
  );

  const onSearch = ({
    page,
    name,
    cpf,
    userType,
    profileType,
    dtCadastroInicio,
    dtCadastroFim,
    isDownload = false,
  }) => {
    var query = {
      page: page,
      rowsPerPage: rowsPerPage,
    };

    if (!!name) {
      query.busca = name;
    }
    if (!!dtCadastroInicio) {
      query.dtCadastroInicio = dayjs(dtCadastroInicio)
        .format()
        .substring(0, 10);
    }

    if (!!dtCadastroFim) {
      query.dtCadastroFim = dayjs(dtCadastroFim).format().substring(0, 10);
    }

    if (!!cpf) {
      query.cpf = cpf;
    }

    if (userType.id != 0) {
      query.tipo = userType.id;
    }

    if (profileType.id != 0) {
      query.perfil = profileType.id;
    }

    if (isDownload) {
      query.baixar = true;
    }

    setLoadingState(true);
    userService
      .searchUsers(query)
      .then((response) => {
        if (isDownload == true) {
          //makeCsvData(response.data);
        } else {
          setUsers(response.data);
          setCount(response.count);
        }
      })
      .catch(showError)
      .finally(() => {
        setLoadingState(false);
      });
  };

  const makeCsvData = (data) => {
    if (data.length == 0) return;

    var csvRows = [];

    csvRows.push([
      "NOME COMPLETO",
      "TELEFONE",
      "E-MAIL",
      "ESTADO",
      "SEXO",
      "IDADE",
      "DATA DE NASCIMENTO",
      "DATA DE CADASTRO",
    ]);

    data.map((row) => {
      csvRows.push([
        row.displayName,
        row.phone,
        row.email,
        row.estado,
        row.sexo,
        row.idade,
        row.dataDeNascimento,
        row.dtCadastro,
      ]);
    });

    const csvString = csvRows.join("\n");

    Format.downloadCsv(csvString, "relatório de usuários");
  };

  const handleChangePage = (newPage) => {
    setPage(newPage);
    onSearch({ page: newPage, ...getValues() });
  };

  const baixarRelatorio = () => {
    onSearch({ page: page, ...getValues(), isDownload: true });
  };

  return {
    users,
    page,
    count,
    rowsPerPage,
    handleChangePage,
    control,
    onSubmit,
    userTypes,
    profileTypes,
    baixarRelatorio,
  };
};

export default useAdminUsersController;
