import React from "react";
import Typography from "@mui/material/Typography/Typography";
import makeStyles from "@mui/styles/makeStyles/makeStyles";

import {
  GenericDialogComponent,
  InputWithButtonFormatComponent,
} from "../../../../components";
import { Format } from "../../../../utils";

const useStyles = makeStyles(() => ({
  content: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: "1vh",
    marginBottom: "2vh",
  },
}));

const ApproveProductionDialogComponent = ({
  onChangeSituation,
  production,
}) => {
  const classes = useStyles();
  const [commission, setCommission] = React.useState(0);

  const onSubmit = () => {
    const payment =
      !!production && production.commissionType == "R$"
        ? production.commission
        : Format.currencyToFloat(commission);

    var form = {
      situation: "APROVADA",
      payment: payment,
    };

    onChangeSituation(form);
  };

  return (
    <GenericDialogComponent name="approve-production-dialog">
      <div className={classes.content}>
        <Typography
          style={{
            marginBottom: "6vh",
            fontWeight: "700",
            fontSize: "1.95vh",
            color: "#40215F",
          }}
        >
          APROVAR PRODUÇÃO
        </Typography>

        {!!production && production.commissionType == "R$" ? (
          <Typography
            style={{
              fontWeight: "700",
              fontSize: "1.25vh",
              color: "#858585",
            }}
          >
            * VALOR DEFINIDO NO CADASTRO DA CAMPANHA
          </Typography>
        ) : (
          <Typography
            style={{
              fontWeight: "700",
              fontSize: "1.25vh",
              color: "#858585",
            }}
          >
            * COMISSÃO DA CASA: {production?.commission ?? 0}% DESTE VALOR
          </Typography>
        )}

        <InputWithButtonFormatComponent
          placeholder="COMISSÃO A PAGAR"
          color="#5C5C5C"
          isLight={true}
          disabled={!!production && production.commissionType == "R$"}
          value={
            !!production && production.commissionType == "R$"
              ? production.commission
              : commission
          }
          onChange={setCommission}
          width="100%"
          onClick={onSubmit}
          buttonLabel="APROVAR"
          errorMessage={""}
          format={"R$"}
        />
      </div>
    </GenericDialogComponent>
  );
};

export default ApproveProductionDialogComponent;
