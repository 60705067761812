import { useForm } from "react-hook-form";
import React, { useEffect } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { useAlert, useAuth, useNotification } from "../../../providers";
import {
  profileService,
  userService,
} from "../../../../infrastructure/services";
import { Validate } from "../../../utils";
import { useLoadingContext } from "../../../context/loading.context";
import { useLocation, useSearchParams } from "react-router-dom";

const useProfileController = () => {
  const { user, getProfile, logout } = useAuth();
  let [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();
  const { fetchNotifications } = useNotification();
  const { showError, showSuccess } = useAlert();
  const [pendingConfirmation, setPendingConfirmation] = React.useState(false);
  const { setLoadingState } = useLoadingContext();

  const schema = yup.object().shape({
    displayName: Validate.fullName,
    email: Validate.email,
    cpf: Validate.cpf,
    phone: Validate.phone,
    pix: Validate.pix,
  });

  const { control, handleSubmit, reset } = useForm({
    defaultValues: {
      photoUrl: "",
      displayName: "",
      email: "",
      phone: "",
      cpf: "",
      pix: "",
    },
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (searchParams.get("code")) {
      setLoadingState(true);
      profileService
        .confirmEdit({ code: searchParams.get("code") })
        .then((profile) => {
          resetForm(profile);
          showSuccess("Alterações confirmadas!");
          getProfile();
        })
        .catch(showError)
        .finally(() => {
          setLoadingState(false);
        });
    }
  }, [location]);

  useEffect(() => {
    if (user != null) {
      setLoadingState(true);
      profileService
        .getProfile()
        .then(resetForm)
        .catch(showError)
        .finally(() => {
          setLoadingState(false);
        });
    }
  }, [user]);

  const onSubmit = handleSubmit((data) => {
    var { form, changed } = hasChanges(data);

    if (changed) {
      setLoadingState(true);
      profileService
        .editProfile({ query: form })
        .then((profile) => {
          resetForm(profile);
          showSuccess("Alterações gravadas!");
          getProfile();
          fetchNotifications();
        })
        .catch(showError)
        .finally(() => {
          setLoadingState(false);
        });
    }
  });

  const resetForm = (profile) => {
    reset({
      photoUrl: profile.photoUrl,
      displayName: profile.displayName,
      email: profile.email,
      phone: profile.phone,
      cpf: profile.cpf,
      pix: profile.pix,
    });
    setPendingConfirmation(profile.pendingConfirmation);
  };

  const hasChanges = (data) => {
    if (user == null) return { form: null, changed: false };

    var form = generateForm(data);

    return {
      form: form,
      changed: !(Object.values(form).length === 0),
    };
  };

  const generateForm = (data) => {
    var form = {};

    const defaultValues = control._defaultValues;

    if (data.displayName != defaultValues.displayName) {
      form["nome"] = data.displayName;
    }
    if (data.email != defaultValues.email) {
      form["email"] = data.email;
    }
    if (data.phone != defaultValues.phone) {
      form["telefone"] = data.phone;
    }
    if (data.pix != defaultValues.pix) {
      form["chavePix"] = data.pix;
    }
    if (data.photoUrl != defaultValues.photoUrl) {
      form["foto"] = user.id + "::" + data.photoUrl;
    }

    return form;
  };

  const deactivateAccount = () => {
    setLoadingState(true);
    userService
      .deactivateAccount({ id: user.id })
      .then(() => {
        showSuccess("Usuário Suspenso!");
        logout();
      })
      .catch(showError)
      .finally(() => {
        setLoadingState(false);
      });
  };

  return { control, onSubmit, pendingConfirmation, deactivateAccount };
};

export default useProfileController;
