import makeStyles from "@mui/styles/makeStyles/makeStyles";
import { useDialog } from "../../providers";
import GenericDialogComponent from "../generic-dialog.component";
import React, { useEffect, useState } from "react";
import { useIsDesktop } from "../../hooks";

const useStyles = makeStyles(() => ({
  modal: {
    "&:hover": {
      cursor: "pointer",
    },
  },
}));

const InitialModalComponent = () => {
  const classes = useStyles();
  const { metadata: modals, openDialog, isOpen } = useDialog();
  const { isDesktop } = useIsDesktop();
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const calculateImageSize = (isDialog) => {
    const proportion = !!modals
      ? modals.modal.bannerType == "imagem"
        ? 0.8
        : 0.5
      : 0.8;

    const modalWidth = !!modals ? modals.modal.width : 100;
    const modalHeight = !!modals ? modals.modal.height : 100;
    const aspectRatio = modalWidth / modalHeight;
    const maxWidth = windowSize.width * proportion;
    const maxHeight = windowSize.height * proportion;

    let newWidth;
    let newHeight;

    if (aspectRatio > 1) {
      newWidth = maxWidth;
      newHeight = maxWidth / aspectRatio;
    } else {
      newWidth = maxHeight * aspectRatio;
      newHeight = maxHeight;
    }

    return {
      width: !isDialog ? newWidth - 55 : newWidth,
      height: !isDialog ? newHeight - 70 : newHeight,
    };
  };

  if (isOpen == "initial-modal-dialog") {
    const imageSize = calculateImageSize(false);
    const dialogSize = calculateImageSize(true);

    return (
      <GenericDialogComponent
        name="initial-modal-dialog"
        size={dialogSize}
        backgroundColor={
          !!modals && modals.modal.fundoTransparente == true
            ? "transparent"
            : undefined
        }
        onClose={() => {
          if (modals.nextModals.length > 0) {
            openDialog("initial-modal-dialog", {
              modal: modals.nextModals[0],
              nextModals: [
                ...modals.nextModals.filter(
                  (m) => m.id != modals.nextModals[0].id
                ),
              ],
            });
          }
        }}
      >
        {!!modals && modals.modal.bannerType == "imagem" ? (
          <img
            src={modals.modal.bannerUrl ?? ""}
            className={classes.modal}
            onClick={() => {
              var link = modals.modal.link ?? "";

              if (!link.startsWith("https://")) {
                link = "https://" + link;
              }

              window.open(link, "_blank");
            }}
            style={{ ...imageSize, marginTop: "1vh" }}
          />
        ) : (
          <iframe
            width={isDesktop ? "90%" : "100%"}
            height={isDesktop ? "100%" : "100%"}
            src={!!modals ? modals.modal.bannerUrl : ""}
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          />
        )}
      </GenericDialogComponent>
    );
  }

  return <></>;
};

export default InitialModalComponent;
