import FormControl from "@mui/material/FormControl/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel/FormControlLabel";
import Radio from "@mui/material/Radio/Radio";
import RadioGroup from "@mui/material/RadioGroup/RadioGroup";
import Grid from "@mui/material/Grid/Grid";
import makeStyles from "@mui/styles/makeStyles/makeStyles";

import {
  AutocompleteComponent,
  ButtonComponent,
  DatapickerComponent,
  SelectComponent,
} from "../../../../components";
import { Constants, Format } from "../../../../utils";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import moment from "moment";
import dayjs from "dayjs";

const useStyles = makeStyles(() => ({
  label: { fontWeight: "700", fontSize: "1.49vh", color: "#5C5C5C" },
}));

const FilterChartComponent = ({
  campaigns,
  status,
  users,
  data,
  onSearch,
  downloadName,
}) => {
  const [filter, setFilter] = useState("period");

  const { control, watch, getValues, setValue } = useForm({
    defaultValues: {
      startDate: new Date(new Date().setDate(new Date().getDate() - 5)),
      endDate: new Date(),
      year: new Date().getFullYear(),
      month: new Date().getMonth(),
      status: !!status ? status[0] : Constants.defaultSelect,
      campaign: Constants.defaultSelect,
      user: Constants.defaultSelect,
      filter: "period",
    },
  });

  useEffect(() => {
    fetchData(getValues());
  }, []);

  useEffect(() => {
    var subscription = watch((value, { name, type }) => fetchData(value));

    return () => {
      if (!!subscription) subscription.unsubscribe();
    };
  }, [watch]);

  const onDownload = () => {
    var csvRows = [];

    csvRows.push(["X", ...Object.values(data)[0].map((item) => item.key)]);

    Object.entries(data).map(([key, values]) => {
      csvRows.push([key, ...Object.values(values).map((item) => item.value)]);
    });

    const csvString = csvRows.join("\n");

    Format.downloadCsv(csvString, downloadName);
  };

  const onChangeFilter = (newFilter) => {
    setFilter(newFilter);
    setValue("filter", newFilter);
  };

  const fetchData = (values) => {
    let query = {};

    switch (values.filter) {
      case "period":
        query["startDate"] = dayjs(values.startDate).format().substring(0, 10);
        query["endDate"] = dayjs(values.endDate).format().substring(0, 10);
        break;
      case "month":
        const mothDate = dayjs(new Date().setMonth(values.month));

        query["startDate"] = mothDate
          .startOf("month")
          .format()
          .substring(0, 10);
        query["endDate"] = mothDate.endOf("month").format().substring(0, 10);
        break;
      case "year":
        const yearDate = dayjs(new Date().setFullYear(values.year));

        query["startDate"] = yearDate.startOf("year").format().substring(0, 10);
        query["endDate"] = yearDate.endOf("year").format().substring(0, 10);
        break;
    }

    if (values.campaign.id != 0) {
      query["campaign"] = values.campaign.id;
    }

    if (values.user.id != 0) {
      query["user"] = values.user.id;
    }

    if (values.status.id != 0) {
      query["status"] = values.status.id;
    }

    onSearch(query);
  };

  return (
    <Grid item xs={9} style={{ textAlign: "end" }}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Grid container spacing={1} style={{ justifyContent: "end" }}>
            {!!status && (
              <Grid item xs={4}>
                <AutocompleteComponent
                  name="status"
                  label="STATUS"
                  acessorLabel="label"
                  acessorValue="id"
                  control={control}
                  options={status}
                />
              </Grid>
            )}
            {!!campaigns && (
              <Grid item xs={4}>
                <AutocompleteComponent
                  name="campaign"
                  label="CAMPANHA"
                  acessorLabel="name"
                  acessorValue="id"
                  control={control}
                  options={campaigns}
                />
              </Grid>
            )}
            {!!users && (
              <Grid item xs={4}>
                <AutocompleteComponent
                  name="user"
                  label="USUÁRIO"
                  acessorLabel="name"
                  acessorValue="id"
                  control={control}
                  options={users}
                  isLarge={true}
                />
              </Grid>
            )}
            <Grid item xs={4}>
              <RadioButtonsGroup filter={filter} setFilter={onChangeFilter} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          {filter == "period" && (
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <DatapickerComponent
                  name="startDate"
                  label="DATA INICIAL"
                  control={control}
                  readOnly={true}
                />
              </Grid>
              <Grid item xs={6}>
                <DatapickerComponent
                  name="endDate"
                  label="DATA FINAL"
                  control={control}
                  readOnly={true}
                />
              </Grid>
            </Grid>
          )}
          {filter == "month" && (
            <SelectComponent
              name="month"
              label="MÊS"
              acessor="label"
              control={control}
              options={Constants.months}
            />
          )}
          {filter == "year" && (
            <SelectComponent
              name="year"
              label="ANO"
              acessor="label"
              control={control}
              options={Constants.getYearsList()}
            />
          )}
        </Grid>
        <Grid item xs={6}>
          <ButtonComponent
            label="DOWNLOAD"
            backgroundColor="#774E98"
            color="white"
            fullWidth
            height="5vh"
            onClick={onDownload}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

function RadioButtonsGroup({ filter, setFilter }) {
  const classes = useStyles();

  const handleChange = (event) => {
    setFilter(event.target.value);
  };

  return (
    <FormControl component="fieldset">
      <RadioGroup row value={filter} onChange={handleChange}>
        <Grid container spacing={1} style={{ justifyContent: "space-between" }}>
          <Grid item xs={12} sm={4} md={3}>
            <FormControlLabel
              value="period"
              control={<Radio />}
              label={<span className={classes.label}>PERÍODO</span>}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={3}>
            <FormControlLabel
              value="month"
              control={<Radio />}
              label={<span className={classes.label}>MÊS</span>}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={3}>
            <FormControlLabel
              value="year"
              control={<Radio />}
              label={<span className={classes.label}>ANO</span>}
            />
          </Grid>
        </Grid>
      </RadioGroup>
    </FormControl>
  );
}

export default FilterChartComponent;
