import React, { useState } from "react";
import Card from "@mui/material/Card/Card";
import CardContent from "@mui/material/CardContent/CardContent";
import Divider from "@mui/material/Divider/Divider";
import Typography from "@mui/material/Typography/Typography";
import Grid from "@mui/material/Grid/Grid";
import Box from "@mui/material/Box/Box";

import HelpItemComponent from "./help-item.component";
import { useIsDesktop } from "../../hooks";
import ArrowToLeftLargeBlackIcon from "../../../assets/icones/arrow-to-left-large-black.svg";
import ArrowToRightLargeBlackIcon from "../../../assets/icones/arrow-to-right-large-black.svg";

const classes = {
  card: {
    boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.2)",
    borderRadius: "20px",
  },
  cardTitle: {
    padding: "4vh",
    fontSize: "1.75vh",
    color: "#5C5C5C",
    fontWeight: "700",
    marginLeft: "1.5vw",
  },
  cardContent: {
    paddingTop: "4vh",
    paddingBottom: "4vh",
    paddingInline: "3vw",
  },
};

const HelpComponent = () => {
  const { isDesktop } = useIsDesktop();
  const [expanded, setExpanded] = React.useState();
  const [currentLinkIndex, setcurrentLinkIndex] = React.useState(0);
  const [isClickedLeft, setIsClickedLeft] = useState(false);
  const [isClickedRight, setIsClickedRight] = useState(false);

  const links = [
    "https://www.youtube.com/embed/1Ty2WrKAHso",
    "https://www.youtube.com/embed/bxCD9PTygNQ",
    "https://www.youtube.com/embed/2SQl08USI4U",
    "https://www.youtube.com/embed/MknMjo_SrS0",
    "https://www.youtube.com/embed/2oq7Zkwj4sw",
  ];

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const handlePrev = () => {
    setcurrentLinkIndex((currentLinkIndex - 1 + links.length) % links.length);
    setIsClickedLeft(true);

    setTimeout(() => {
      setIsClickedLeft(false);
    }, 100);
  };

  const handleNext = () => {
    setcurrentLinkIndex((currentLinkIndex + 1) % links.length);
    setIsClickedRight(true);

    setTimeout(() => {
      setIsClickedRight(false);
    }, 100);
  };

  const getVisibleLinks = () => {
    const lastIndex = currentLinkIndex + (isDesktop ? 2 : 2);
    if (lastIndex < links.length) {
      return links.slice(currentLinkIndex, lastIndex + 1);
    } else {
      const overflow = lastIndex - (links.length - 1);
      return links.slice(currentLinkIndex).concat(links.slice(0, overflow));
    }
  };

  const visibleLinksArray = getVisibleLinks();

  return (
    <Card sx={classes.card}>
      <Typography sx={classes.cardTitle}>DÚVIDAS</Typography>
      <Divider />
      <CardContent sx={classes.cardContent}>
        <Grid
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            marginBottom: "4vh",
          }}
        >
          <Grid item style={{ paddingLeft: "1vw", paddingRight: "1vw" }}>
            <div onClick={handlePrev} aria-label="Anterior">
              <img
                src={ArrowToLeftLargeBlackIcon}
                alt="Anterior"
                style={{
                  cursor: "pointer",
                  height: isDesktop ? null : "10vh",
                  marginTop: isDesktop ? null : "0.5vh",
                }}
              />
            </div>
          </Grid>
          <Grid item style={{ width: "100%", overflow: "scroll" }}>
            <Box
              display="flex"
              style={{
                width: "100%",
                display: "flex",
                flexDirection: isDesktop ? "row" : "column",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: "4vh",
                gap: "2%",
              }}
            >
              {visibleLinksArray.map((link, index) => {
                return (
                  <iframe
                    key={index}
                    style={{ marginBottom: "2%" }}
                    width={isDesktop ? "350" : "200"}
                    height={isDesktop ? "300" : "200"}
                    src={link}
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowFullScreen
                  />
                );
              })}
            </Box>
          </Grid>
          <Grid item style={{ paddingLeft: "1vw", paddingRight: "1vw" }}>
            <div onClick={handleNext} aria-label="Próximo">
              <img
                src={ArrowToRightLargeBlackIcon}
                alt="Próximo"
                style={{
                  cursor: "pointer",
                  height: isDesktop ? null : "10vh",
                  marginTop: isDesktop ? null : "0.5vh",
                }}
              />
            </div>
          </Grid>
        </Grid>
        <HelpItemComponent
          expanded={expanded == "help1"}
          toggleExpanded={handleChange("help1")}
          label="COMO GERAR UM LINK PARA INDICAÇÃO?"
          content="Os links para indicação podem ser encontrados no dashboard de produtos ou no menu lateral.\n Basta clicar em uma das caixinhas abaixo para escolher um produto. Caso o produto desejado não esteja nesta tela você pode clicar em “Ver todos os produtos”.\n Na página do produto selecionado você encontrará o seu link para ser usado para as indicações!"
          link="/campanhas"
        />
        <HelpItemComponent
          expanded={expanded == "help2"}
          toggleExpanded={handleChange("help2")}
          label="COMO VOU RECEBER MINHA BONIFICAÇÃO?"
          content="Através do PIX que você cadastrou, receberá comissões semanais. Incluindo comissões dos seus afiliados.\n Dúvidas basta chamar no WhatsApp disponível para ajuda."
          link="/perfil"
        />
        <HelpItemComponent
          expanded={expanded == "help3"}
          toggleExpanded={handleChange("help3")}
          label="COMO SEI SE UM PRODUTO QUE INDIQUEI FOI APROVADO PARA BONIFICAÇÃO?"
          content="Todos os produtos que estiverem habilitados para divulgação, o link estará disponível."
          link="/financeiro"
        />
        <HelpItemComponent
          expanded={expanded == "help4"}
          toggleExpanded={handleChange("help4")}
          label="COMO OBTER AFILIADOS?"
          content="Você escolhe o produto e cadastra a comissão que seu afiliado terá, dentro do valor disponível para recompensa.\n Toda semana seu afiliado recebe a comissão dele e você recebe o que reservou para você, nas conversões positivas."
          link="/afiliados"
        />
        <HelpItemComponent
          expanded={expanded == "help5"}
          toggleExpanded={handleChange("help5")}
          label="COMO ACOMPANHO MINHAS RECOMPENSAS?"
          content="No dashbord, ficará disponível suas reversões e valores a receber."
          link="/relatorios"
        />
      </CardContent>
    </Card>
  );
};

export default HelpComponent;
