import React from "react";
import Typography from "@mui/material/Typography/Typography";
import makeStyles from "@mui/styles/makeStyles/makeStyles";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import {
  ButtonComponent,
  GenericDialogComponent,
  InputFilterComponent,
} from "../../../../../components";
import { useDialog } from "../../../../../providers";

const useStyles = makeStyles(() => ({
  content: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: "1vh",
    marginBottom: "2vh",
  },
}));

const AdminApproveProposalFormDialogComponent = ({ onApprove, onRepprove }) => {
  const { metadata: isToApprove } = useDialog();
  const classes = useStyles();
  const schema = yup.object().shape({
    observations: yup.string().optional(),
  });

  const { control, handleSubmit } = useForm({
    defaultValues: {
      observations: "",
    },
    resolver: yupResolver(schema),
  });

  const onSubmit = handleSubmit(({ observations }) => {
    if (isToApprove) {
      onApprove(observations);
    } else {
      onRepprove(observations);
    }
  });

  return (
    <GenericDialogComponent name="admin-approve-proposal-form-dialog">
      <form onSubmit={onSubmit} className={classes.content}>
        <Typography
          style={{
            marginBottom: "2vh",
            fontWeight: "700",
            fontSize: "1.95vh",
            color: "#40215F",
          }}
        >
          {isToApprove ? "APROVAR" : "REPROVAR"} PROPOSTA
        </Typography>

        <InputFilterComponent
          name="observations"
          control={control}
          label="OBSERVAÇÕES"
          placeholder="OBSERVAÇÕES"
          multiline={true}
          minRows={7}
        />

        <div
          style={{
            marginTop: "4vh",
            display: "flex",
            width: "100%",
            justifyContent: "end",
          }}
        >
          <div style={{ marginLeft: "2vw" }}>
            <ButtonComponent
              label={isToApprove ? "APROVAR" : "REPROVAR"}
              backgroundColor="#40215F"
              color="white"
              size="small"
              height="5vh"
              type="submit"
            />
          </div>
        </div>
      </form>
    </GenericDialogComponent>
  );
};

export default AdminApproveProposalFormDialogComponent;
