import Grid from "@mui/material/Grid/Grid";
import Typography from "@mui/material/Typography/Typography";
import Card from "@mui/material/Card/Card";
import makeStyles from "@mui/styles/makeStyles/makeStyles";

import {
  BodyComponent,
  ButtonComponent,
  InputSearchComponent,
} from "../../../components";
import useAffiliatedController from "./affiliated.controller";
import InviteDialogComponent from "./components/invite-dialog.component";
import CommissionDialogComponent from "./components/commission-dialog.component";
import { useResponsive } from "../../../providers";
import CommissionsTableComponent from "./components/commissions-table.component";
import DefaultCommissionComponent from "./components/default-commission.component";
import DefaultNewCampaignCommissionComponent from "./components/default-new-campaign-commission.component";
import AfiliadosBannerImage from "../../../../assets/imagens/afiliados-banner.png";

const useStyles = makeStyles(() => ({
  banner: {
    width: "71vw",
    height: "31.25vh",
    backgroundColor: "#40215F",
    marginBottom: "4vh",
    borderRadius: "10px",
  },
  bannerTitle: {
    color: "#AA4291",
    fontSize: "4.88vh",
    fontWeight: "800",
    fontStyle: "italic",
  },
  bannerDescrible: {
    color: "white",
    fontSize: "1.95vh",
    fontWeight: "400",
    marginRight: "3vw",
  },
  form: {
    marginBottom: "4vh",
    alignItems: "center",
    justifyContent: "start",
  },
}));

const AffiliatedPage = () => {
  const classes = useStyles();
  const { isDesktop } = useResponsive();
  const {
    affiliated,
    expanded,
    inviteUrl,
    commissionForm,
    toggleExpanded,
    openInviteDialog,
    openCommissionDialog,
    onChangeCommission,
    onSendEmail,
    onSendWhatsApp,
    nameFilter,
    setNameFilter,
    clearFilterByName,
    defaultCommissions,
    openDefaultCommissionDialog,
    percent,
    setPercent,
    defaultNewCampaignCommission,
    setDefaultNewCampaignCommission,
    onChangeCommissionToNewCampaign,
  } = useAffiliatedController();

  return (
    <BodyComponent>
      <InviteDialogComponent
        inviteUrl={inviteUrl}
        onSendEmail={onSendEmail}
        onSendWhatsApp={onSendWhatsApp}
      />

      <CommissionDialogComponent
        commissionForm={commissionForm}
        onChangeCommission={onChangeCommission}
        percent={percent}
        setPercent={setPercent}
      />

      <Card elevation={0} className={classes.banner}>
        <Grid container style={{ alignItems: "center", height: "100%" }}>
          <Grid item xs={isDesktop ? 7 : 12} style={{ paddingLeft: "4vw" }}>
            <Typography className={classes.bannerTitle}>AFILIADOS</Typography>
            <Typography className={classes.bannerDescrible}>
              Para convidar um novo usuário, basta clicar no botão e
              compartilhar o link que será gerado. E aí, quem você vai convidar
              hoje?
            </Typography>
          </Grid>
          {isDesktop && (
            <Grid item xs={5} style={{ textAlign: "center" }}>
              <img
                src={AfiliadosBannerImage}
                style={{ height: "100%", maxHeight: "23.43vh" }}
              />
            </Grid>
          )}
        </Grid>
      </Card>

      <div style={{ width: "71vw" }}>
        <Grid container className={classes.form} spacing={isDesktop ? 4 : 2}>
          <Grid item xs={isDesktop ? 3 : 6}>
            <InputSearchComponent
              placeholder="BUSCAR USUÁRIO"
              fullWidth={true}
              valor={nameFilter}
              onChange={(value) => setNameFilter(value)}
            />
          </Grid>
          <Grid item xs={isDesktop ? 3 : 6}>
            <ButtonComponent
              label="TODOS OS USUÁRIOS"
              backgroundColor="#858585"
              color="white"
              fullWidth
              height="5vh"
              onClick={clearFilterByName}
            />
          </Grid>
          <Grid item xs={isDesktop ? 3 : 12}>
            <ButtonComponent
              label="CONVIDAR NOVO USUÁRIO"
              backgroundColor="#774E98"
              color="white"
              fullWidth
              height="5vh"
              onClick={openInviteDialog}
            />
          </Grid>
        </Grid>
      </div>

      <CommissionsTableComponent
        affiliated={affiliated}
        toggleExpanded={toggleExpanded}
        openCommissionDialog={openCommissionDialog}
        expanded={expanded}
      />

      <DefaultNewCampaignCommissionComponent
        percent={defaultNewCampaignCommission}
        setPercent={setDefaultNewCampaignCommission}
        onChangeCommissionToNewCampaign={onChangeCommissionToNewCampaign}
      />

      <DefaultCommissionComponent
        defaultCommissions={defaultCommissions}
        openDefaultCommissionDialog={openDefaultCommissionDialog}
      />
    </BodyComponent>
  );
};

export default AffiliatedPage;
