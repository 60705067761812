import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { v4 as uuidv4 } from "uuid";
import { useEffect, useState } from "react";

import { useAlert } from "../../../../providers";
import {
  mediaService,
  notificationService,
} from "../../../../../infrastructure/services";
import { Constants, Format } from "../../../../utils";
import { useLoadingContext } from "../../../../context/loading.context";

const useAdminNotificationFormController = ({ preferenceId }) => {
  const isActiveOptions = [
    { id: 1, label: "SIM", value: 1 },
    { id: 2, label: "NÃO", value: 0 },
  ];

  const sendTypeOptions = Constants.getTypeOptionsList();

  const sendGroupOptions = ["USUÁRIOS", "ADMINISTRADORES", "ANUNCIANTES"];

  const notificationTypeOptions = ["WHATSAPP", "EMAIL", "SISTEMA"];

  const linkOptions = [
    { id: 1, label: "NENHUM", value: "/" },
    { id: 2, label: "PÁGINA HOME INTERNA", value: "/home" },
    { id: 3, label: "PERFIL DO USUÁRIO", value: "/perfil" },
    { id: 4, label: "PÁGINA DE AFILIADOS", value: "/afiliados" },
    { id: 5, label: "PÁGINA DE RELATÓRIOS", value: "/relatorios" },
    { id: 6, label: "PÁGINA DE DASHBOARD", value: "/dashboard" },
    { id: 7, label: "PÁGINA DE CAMPANHAS", value: "/campanhas" },
    { id: 8, label: "PÁGINA DA CAMPANHA", value: "/campanhas/[id]" },
    { id: 9, label: "PÁGINA DE FINANCEIRO", value: "/financeiro" },
    { id: 10, label: "PÁGINA DE AJUDA", value: "/duvidas" },
    { id: 11, label: "CADASTRO DE ANUNCIANTE", value: "/anunciar" },
    { id: 12, label: "PÁGINA DE LICENCIAMENTO", value: "/licenciamento" },

    { id: 13, label: "GESTÃO DE PAGAMENTOS", value: "/admin/pagamentos" },
    { id: 14, label: "GESTÃO DE CATEGORIAS", value: "/admin/categorias" },
    { id: 15, label: "GESTÃO DE CAMPANHAS", value: "/admin/campanhas" },
    {
      id: 16,
      label: "PÁGINA DE DETALHES DA CAMPANHA",
      value: "/admin/campanhas/formulario/[id]",
    },
    { id: 17, label: "GESTÃO DE USUÁRIOS", value: "/admin/usuarios" },
    {
      id: 18,
      label: "PÁGINA DE DETALHES DO USUÁRIO",
      value: "/admin/usuarios/formulario/[id]",
    },
    { id: 19, label: "GESTÃO DE ANUNCIANTES", value: "/admin/anunciantes" },
    {
      id: 20,
      label: "PÁGINA DE DETALHES ANUNCIANTE",
      value: "/admin/anunciantes/detalhe/[id]",
    },
    { id: 21, label: "GESTÃO DE PERFIS DE AFILIADOS", value: "/admin/perfis" },

    { id: 22, label: "GESTÃO DA PRODUÇÃO", value: "/anunciante/relatorios" },
    { id: 23, label: "GESTÃO DA PROPOSTAS", value: "/propostas/[id]" },
  ];

  const schema = yup.object().shape({
    name: yup.string().required("Campo obrigatório"),
    isActive: yup
      .number()
      .required("Campo obrigatório")
      .test("invalid", "Selecione uma das opções", (value) => {
        return value > 0;
      }),
    sendType: yup.string().required("Campo obrigatório"),

    startDate: yup
      .date()
      .optional()
      .nullable()
      .when("sendType", {
        is: (sendType) => sendType === Constants.DATA_CADASTRO,
        then: () => yup.date().required("Campo obrigatório"),
      }),

    endDate: yup
      .date()
      .optional()
      .nullable()
      .when("sendType", {
        is: (sendType) => sendType === Constants.DATA_CADASTRO,
        then: () =>
          yup
            .date()
            .required("Campo obrigatório")
            .when("startDate", (startDate, schema) =>
              schema.min(
                startDate,
                "A data final deve ser maior que a data inicial"
              )
            ),
      }),

    sendGroup: yup
      .array()
      .optional()
      .when("sendType", {
        is: (sendType) => sendType == 2,
        then: () =>
          yup
            .array()
            .required("Campo obrigatório")
            .min(1, "Adicione no mínimo um grupo"),
      }),

    notificationType: yup
      .array()
      .required("Campo obrigatório")
      .min(1, "Adicione no mínimo um tipo de notificação"),
    title: yup.string().required("Campo obrigatório"),
    content: yup.string().required("Campo obrigatório"),
    media: yup.string().nullable().optional(),
    link: yup.string().optional(),
  });

  const { control, handleSubmit, reset, watch, setValue, getValues } = useForm({
    defaultValues: {
      name: "",
      isActive: isActiveOptions[0].id,
      sendType: sendTypeOptions[0].id,
      sendGroup: [],
      notificationType: [],
      title: "",
      content: "",
      media: null,
      link: linkOptions[0].id,
      startDate: null,
      endDate: null,
      header: null,
      footer: null,
    },
    resolver: yupResolver(schema),
  });

  const { showError, showSuccess, showInfo } = useAlert();
  const [withSendGroup, setWithSendGroup] = useState(false);
  const [isValidTest, setIsValidTest] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { setLoadingState } = useLoadingContext();

  useEffect(() => {
    if (!!preferenceId) {
      fetchPreference();
    }
  }, [preferenceId]);

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      checkIsValidTest(value);
      if (name == "sendType") {
        setWithSendGroup(value.sendType == 2);
        setValue("sendGroup", []);
      }
    });

    return () => subscription.unsubscribe();
  }, [watch]);

  const fetchPreference = () => {
    setIsLoading(true);

    setLoadingState(true);
    notificationService
      .getPreferenceToEditById({ id: preferenceId })
      .then((preference) => {
        setIsLoading(false);

        const preferenceForm = {
          name: preference.name ?? "",
          isActive: isActiveOptions.filter(
            (option) => option.value == preference.isActive
          )[0].id,
          sendType: sendTypeOptions.filter(
            (option) => option.value == preference.sendType
          )[0].id,
          sendGroup: !!preference.sendGroup
            ? preference.sendGroup.split(",")
            : [],
          notificationType: !!preference.notificationType
            ? preference.notificationType.split(",")
            : [],
          title: preference.title ?? "",
          content: preference.content ?? "",
          media: preference.media ?? null,
          link: !!preference.link
            ? linkOptions.filter((option) => option.value == preference.link)[0]
                .id
            : linkOptions[0].id,
          header: preference.imagemCabecalho,
          footer: preference.imagemRodape,
        };

        setWithSendGroup(preferenceForm.sendType == 2);
        reset(preferenceForm);
        checkIsValidTest(preferenceForm);
      })
      .catch(showRequestError)
      .finally(() => {
        setLoadingState(false);
      });
  };

  const showRequestError = (error) => {
    setIsLoading(false);
    showError(error);
  };

  const onSubmit = handleSubmit((data) => {
    var form = _makeForm(data);

    setIsLoading(true);

    setLoadingState(true);
    notificationService
      .updatePreference({ query: form })
      .then(() => {
        setIsLoading(false);
        showSuccess("Preferência da notificação editada!");
      })
      .catch(showRequestError)
      .finally(() => {
        setLoadingState(false);
      });
  });

  const checkIsValidTest = (data) => {
    setIsValidTest(_checkIsValidTest(data));
  };

  const _checkIsValidTest = (data) => {
    if (!data.name) return false;

    if (!data.isActive) return false;

    if (!data.sendType || ![1, 2].includes(data.sendType)) return false;

    if (data.sendType == 2) {
      if (!data.sendGroup || data.sendGroup.length <= 0) return false;
    }

    if (!data.notificationType) return false;

    if (!data.title) return false;

    if (!data.content) return false;

    return true;
  };

  const _makeForm = (data) => {
    var form = {
      id: parseInt(preferenceId),
      nome: data.name,
      ativa: isActiveOptions.filter((option) => option.id == data.isActive)[0]
        .value,
      tipoEnvio: sendTypeOptions.filter(
        (option) => option.id == data.sendType
      )[0].value,
      grupoEnvio: data.sendGroup.length > 0 ? data.sendGroup.join(",") : null,
      tipoNotificacao:
        data.notificationType.length > 0
          ? data.notificationType.join(",")
          : null,
      titulo: data.title,
      conteudo: data.content,
      media: !!data.media ? data.media.split("?")[0] : null,
      link:
        data.link != 1
          ? linkOptions.filter((option) => option.id == data.link)[0].value
          : null,
    };

    return form;
  };

  const onTest = () => {
    var form = _makeForm(data);

    setIsLoading(true);

    setLoadingState(true);
    notificationService
      .testPreference({ query: form })
      .then(() => {
        setIsLoading(false);
        showInfo("Verifique seu whatsapp e sua caixa de email e notificações!");
      })
      .catch(showRequestError)
      .finally(() => {
        setLoadingState(false);
      });
  };

  const editImage = ({ name, data, callback }) => {
    const formValue = getValues(name);

    var filename = uuidv4();
    if (!!formValue) {
      filename = Format.getFileNameFromURL(formValue);
    }

    setIsLoading(true);

    setLoadingState(true);
    mediaService
      .upload({ filename: filename, image: data })
      .then((imageUrl) => {
        setIsLoading(false);
        callback(imageUrl);
      })
      .catch(showRequestError)
      .finally(() => {
        setLoadingState(false);
      });
  };

  const setErrorInMedia = (error, key) => {
    setValue(key, null);
    setIsLoading(false);
    showError(error);
  };

  const onDeleteMedia = (key) => {
    setValue(key, null);
  };

  return {
    control,
    onSubmit,
    isActiveOptions,
    sendTypeOptions,
    sendGroupOptions,
    notificationTypeOptions,
    withSendGroup,
    linkOptions,
    isValidTest,
    onTest,
    editImage,
    setErrorInMedia,
    onDeleteMedia,
    isLoading,
  };
};

export default useAdminNotificationFormController;
