import Grid from "@mui/material/Grid/Grid";
import Typography from "@mui/material/Typography/Typography";
import Divider from "@mui/material/Divider/Divider";

import { InputFilterComponent } from "../../../../components";

const LeadSectionComponent = ({ control }) => {
  return (
    <div>
      <Typography
        style={{
          fontWeight: "600",
          fontSize: "1.75vh",
          color: "#5C5C5C",
        }}
      >
        INFORMAÇÕES DA PROPOSTA
      </Typography>
      <Divider style={{ marginBottom: "2vh" }} />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <InputFilterComponent
                name="campaign"
                control={control}
                label="CAMPANHA"
                placeholder="CAMPANHA"
                disabled={true}
              />
            </Grid>
            <Grid item xs={4}>
              <InputFilterComponent
                name="proposalType"
                control={control}
                label="TIPO DE PROPOSTA"
                placeholder="TIPO DE PROPOSTA"
                disabled={true}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default LeadSectionComponent;
