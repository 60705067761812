import Grid from "@mui/material/Grid/Grid";

import { BodyComponent } from "../../../components";
import ClientSectionComponent from "./components/client-section.component";
import CommissionSectionComponent from "./components/commission-section.component";
import LeadSectionComponent from "./components/lead-section.component";
import ValuesSectionComponent from "./components/values-section.component";
import useProposalDetailController from "./proposal-details.controller";

const ProposalDetailsPage = () => {
  const { control, withObservation } = useProposalDetailController();

  return (
    <BodyComponent>
      <Grid component="form" container>
        <Grid item xs={12} style={{ marginBottom: "4vh" }}>
          <ClientSectionComponent
            control={control}
            withObservation={withObservation}
          />
        </Grid>

        <Grid item xs={12} style={{ marginBottom: "4vh" }}>
          <LeadSectionComponent control={control} />
        </Grid>

        <Grid item xs={12} style={{ marginBottom: "4vh" }}>
          <ValuesSectionComponent control={control} />
        </Grid>

        <Grid item xs={12} style={{ marginBottom: "4vh" }}>
          <CommissionSectionComponent control={control} />
        </Grid>
      </Grid>
    </BodyComponent>
  );
};

export default ProposalDetailsPage;
