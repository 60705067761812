import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";

import { useResponsive } from "../providers";
import ArrowToRight from "../../assets/icones/arrow-to-right.svg";

const AccordionComponent = ({
  summary,
  details,
  expanded,
  toggleExpanded,
  border,
  marginBottom,
  padding,
}) => {
  const { isDesktop } = useResponsive();

  return (
    <Accordion
      square
      expanded={expanded}
      onChange={toggleExpanded}
      sx={{
        border: border ?? "1px solid rgba(0, 0, 0, .125)",
        borderRadius: "40px",
        padding: padding ?? (isDesktop ? "2vh" : 0),
        boxShadow: "none",
        marginBottom: marginBottom ?? "4vh",
        "&:before": {
          display: "none",
        },
        "&$expanded": {
          margin: "auto",
          marginBottom: "4vh",
        },
      }}
    >
      <AccordionSummary
        sx={{
          marginLeft: !!border ? 0 : "1vw",
          color: "#5C5C5C",
          fontSize: "1.75vh",
          fontWeight: "500",
          "&$expanded": {
            minHeight: 56,
          },
        }}
      >
        <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
          <img
            src={ArrowToRight}
            style={{
              marginRight: isDesktop ? "1vw" : "2vw",
              transform: expanded ? "rotate(180deg)" : "none",
            }}
          />
          {summary}
        </div>
      </AccordionSummary>
      <AccordionDetails
        sx={{
          display: "flex",
          flexDirection: "column",
          color: "#5C5C5C",
          fontSize: "1.75vh",
          fontWeight: "500",
        }}
      >
        {details}
      </AccordionDetails>
    </Accordion>
  );
};

export default AccordionComponent;
