import Grid from "@mui/material/Grid/Grid";
import Typography from "@mui/material/Typography/Typography";
import makeStyles from "@mui/styles/makeStyles/makeStyles";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import {
  AutocompleteComponent,
  ButtonComponent,
  DatapickerComponent,
  SelectComponent,
} from "../../../../components";
import { Constants } from "../../../../utils";

const useStyles = makeStyles(() => ({
  counter: {
    display: "flex",
    borderRadius: "10px",
    borderColor: "#858585",
    border: "1px solid",
    fontWeight: "700",
    fontSize: "1.46vh",
    color: "#5C5C5C",
    height: "5.5vh",
    alignItems: "center",
    justifyContent: "center",
  },
}));

const FiltersComponent = ({ onSearch, counters, userOptions }) => {
  const situationOptions = [
    { id: 0, label: "TODOS" },
    { id: 1, label: "PENDENTES" },
    { id: 2, label: "VISUALIZADAS" },
    { id: 3, label: "REPROVADAS" },
    { id: 4, label: "APROVADAS" },
  ];

  const schema = yup.object().shape({
    startDate: yup
      .date()
      .typeError("Data inválida")
      .required("Campo obrigatório"),
    endDate: yup
      .date()
      .typeError("Data inválida")
      .required("Campo obrigatório")
      .when("startDate", (startDate, schema) =>
        schema.min(startDate, "A data final deve ser maior que a data inicial")
      ),
    situation: yup.number().required("Campo obrigatório"),
    user: yup.object().required("Campo obrigatório"),
  });

  const { control, handleSubmit, getValues } = useForm({
    defaultValues: {
      startDate: new Date(new Date().setDate(new Date().getDate() - 5)),
      endDate: new Date(),
      situation: 0,
      step: null,
      user: userOptions[0],
    },
    resolver: yupResolver(schema),
  });

  const classes = useStyles();

  const onSubmit = handleSubmit((data) =>
    onSearch({
      newPage: 0,
      ...data,
      situation: situationOptions.filter(
        (situation) => situation.id == data.situation
      )[0],
      isDownload: false,
    })
  );

  const onDownloadSubmit = () => {
    onSearch({
      newPage: 0,
      ...getValues(),
      situation: situationOptions.filter(
        (situation) => situation.id == getValues("situation")
      )[0],
      isDownload: true,
    });
  };

  return (
    <Grid
      component="form"
      onSubmit={onSubmit}
      container
      spacing={2}
      style={{ marginBottom: "6vh", width: "71vw" }}
    >
      <Grid item md={4}>
        <DatapickerComponent
          name="startDate"
          control={control}
          label="DATA INICIAL"
          readOnly={true}
        />
      </Grid>
      <Grid item md={4}>
        <DatapickerComponent
          name="endDate"
          control={control}
          label="DATA FINAL"
          readOnly={true}
        />
      </Grid>
      <Grid item xs={4}>
        <SelectComponent
          name="situation"
          control={control}
          label="SITUAÇÃO"
          acessor="label"
          options={situationOptions}
        />
      </Grid>
      <Grid item xs={6}>
        <AutocompleteComponent
          name="user"
          control={control}
          label="USUÁRIO"
          acessorLabel="displayName"
          acessorValue="id"
          options={userOptions}
          isLarge={true}
        />
      </Grid>
      <Grid item xs={6}>
        <AutocompleteComponent
          name="step"
          control={control}
          label="Etapa"
          acessorLabel="label"
          acessorValue="id"
          options={Constants.reportSteps}
        />
      </Grid>
      {!!counters && (
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <Typography className={classes.counter}>
                PENDENTES: {counters.pending}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography className={classes.counter}>
                REPROVADAS: {counters.disapproved}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography className={classes.counter}>
                APROVADAS: {counters.approved}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography className={classes.counter}>
                VISUALIZADAS: {counters.visualized}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      )}

      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={2}>
            <ButtonComponent
              label="PESQUISAR"
              backgroundColor="#858585"
              color="white"
              fullWidth
              height="5vh"
              type="submit"
            />
          </Grid>

          <Grid item xs={2}>
            <ButtonComponent
              label="DOWNLOAD"
              backgroundColor="#774E98"
              color="white"
              fullWidth
              height="5vh"
              onClick={onDownloadSubmit}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default FiltersComponent;
