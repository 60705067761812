import React from "react";
import Typography from "@mui/material/Typography/Typography";
import makeStyles from "@mui/styles/makeStyles/makeStyles";

import {
  ButtonGradientComponent,
  GenericDialogComponent,
  InputGradientComponent,
} from "../../../../components";

const useStyles = makeStyles(() => ({
  content: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: "1vh",
    marginBottom: "2vh",
    gap: "1vh",
  },
}));

const ForgotDialogComponent = ({ forgotForm, onSubmitForgot }) => {
  const classes = useStyles();

  return (
    <GenericDialogComponent
      name="forgot-dialog"
      isPublic={true}
      className={classes.content}
    >
      <div className={classes.content}>
        <div style={{ marginBottom: "1vh" }}>
          <Typography
            sx={{
              color: "#FFF",
              fontSize: "2vh",
              fontWeight: "700",
            }}
          >
            ESQUECI MINHA SENHA
          </Typography>
        </div>
        <InputGradientComponent
          control={forgotForm}
          name="username"
          placeholder="CPF OU E-MAIL"
        />
        <ButtonGradientComponent
          label="SOLICITAR NOVA SENHA"
          onClick={() => onSubmitForgot()}
        />
      </div>
    </GenericDialogComponent>
  );
};

export default ForgotDialogComponent;
