import { createBrowserRouter } from "react-router-dom";

import {
  AdminAdvertiserDetailPage,
  AdminAdvertisersPage,
  AdminAffiliatedProfilePage,
  AdminBanksPage,
  AdminCampaignFormPage,
  AdminCampaignsPage,
  AdminCategoriesPage,
  AdminModalFormPage,
  AdminModalsPage,
  AdminNotificationFormPage,
  AdminNotificationSendPage,
  AdminNotificationsPage,
  AdminPaymentsPage,
  AdminProposalFormPage,
  AdminProposalsPage,
  AdminUserFormPage,
  AdminUsersPage,
  AdvertiserReportsPage,
  AffiliatedPage,
  BeLicenseePage,
  CampaignDetailsPage,
  CampaignsPage,
  DashboardPage,
  FinancialPage,
  HelpPage,
  HomePage,
  LandPagePage,
  NewPasswordPage,
  ProfilePage,
  ProposalDetailsPage,
  ProposalsPage,
  RegisterAdvertiserPage,
  RegisterUserPage,
  RegisterUserSuccessPage,
  ReportsPage,
  SignInPage,
} from "./presentation/pages";
import { PrivateLayoutComponent } from "./presentation/components";

//TODO: Refatorar rotas
const Rotas = createBrowserRouter([
  {
    path: "/",
    element: <LandPagePage />,
  },
  {
    path: "/home",
    element: (
      <PrivateLayoutComponent>
        <HomePage />
      </PrivateLayoutComponent>
    ),
  },
  {
    path: "/licenciamento",
    element: (
      <PrivateLayoutComponent>
        <BeLicenseePage />
      </PrivateLayoutComponent>
    ),
  },
  {
    path: "/nova-senha",
    element: <NewPasswordPage />,
  },
  {
    path: "/perfil",
    element: (
      <PrivateLayoutComponent>
        <ProfilePage />
      </PrivateLayoutComponent>
    ),
  },
  {
    path: "/relatorios",
    element: (
      <PrivateLayoutComponent>
        <ReportsPage />
      </PrivateLayoutComponent>
    ),
  },
  {
    path: "/financeiro",
    element: (
      <PrivateLayoutComponent>
        <FinancialPage />
      </PrivateLayoutComponent>
    ),
  },
  {
    path: "/entrar",
    element: <SignInPage />,
  },
  {
    path: "/duvidas",
    element: (
      <PrivateLayoutComponent>
        <HelpPage />
      </PrivateLayoutComponent>
    ),
  },
  {
    path: "/dashboard",
    element: (
      <PrivateLayoutComponent>
        <DashboardPage />
      </PrivateLayoutComponent>
    ),
  },
  {
    path: "/anunciar",
    element: <RegisterAdvertiserPage />,
  },
  {
    path: "/afiliados",
    element: (
      <PrivateLayoutComponent>
        <AffiliatedPage />
      </PrivateLayoutComponent>
    ),
  },
  {
    path: "/propostas",
    element: (
      <PrivateLayoutComponent>
        <ProposalsPage />
      </PrivateLayoutComponent>
    ),
    children: [
      {
        path: ":id",
        element: (
          <PrivateLayoutComponent>
            <ProposalDetailsPage />
          </PrivateLayoutComponent>
        ),
      },
    ],
  },
  {
    path: "/criar-conta",
    element: <RegisterUserPage />,
  },
  {
    path: "/criar-conta/sucesso",
    element: <RegisterUserSuccessPage />,
  },
  {
    path: "/campanhas",

    children: [
      {
        path: "",
        index: true,
        element: (
          <PrivateLayoutComponent>
            <CampaignsPage />
          </PrivateLayoutComponent>
        ),
      },
      {
        path: ":id",
        element: (
          <PrivateLayoutComponent>
            <CampaignDetailsPage />
          </PrivateLayoutComponent>
        ),
      },
    ],
  },
  {
    path: "/anunciante/relatorios",
    element: (
      <PrivateLayoutComponent>
        <AdvertiserReportsPage />
      </PrivateLayoutComponent>
    ),
  },
  {
    path: "/admin",
    children: [
      {
        path: "bancos",
        element: (
          <PrivateLayoutComponent>
            <AdminBanksPage />
          </PrivateLayoutComponent>
        ),
      },
      {
        path: "categorias",
        element: (
          <PrivateLayoutComponent>
            <AdminCategoriesPage />
          </PrivateLayoutComponent>
        ),
      },
      {
        path: "pagamentos",
        element: (
          <PrivateLayoutComponent>
            <AdminPaymentsPage />
          </PrivateLayoutComponent>
        ),
      },
      {
        path: "perfis",
        element: (
          <PrivateLayoutComponent>
            <AdminAffiliatedProfilePage />
          </PrivateLayoutComponent>
        ),
      },
      {
        path: "anunciantes",
        element: (
          <PrivateLayoutComponent>
            <AdminAdvertisersPage />
          </PrivateLayoutComponent>
        ),
      },
      {
        path: "anunciantes/detalhe/:id",
        element: (
          <PrivateLayoutComponent>
            <AdminAdvertiserDetailPage />
          </PrivateLayoutComponent>
        ),
      },
      {
        path: "campanhas",
        element: (
          <PrivateLayoutComponent>
            <AdminCampaignsPage />
          </PrivateLayoutComponent>
        ),
      },
      {
        path: "campanhas/formulario",
        children: [
          {
            path: "",
            index: true,
            element: (
              <PrivateLayoutComponent>
                <AdminCampaignFormPage />
              </PrivateLayoutComponent>
            ),
          },
          {
            path: ":id",
            element: (
              <PrivateLayoutComponent>
                <AdminCampaignFormPage />
              </PrivateLayoutComponent>
            ),
          },
        ],
      },
      {
        path: "modais",
        element: (
          <PrivateLayoutComponent>
            <AdminModalsPage />
          </PrivateLayoutComponent>
        ),
      },
      {
        path: "modais/formulario/:id",
        element: (
          <PrivateLayoutComponent>
            <AdminModalFormPage />
          </PrivateLayoutComponent>
        ),
      },
      {
        path: "notificacoes",
        element: (
          <PrivateLayoutComponent>
            <AdminNotificationsPage />
          </PrivateLayoutComponent>
        ),
      },
      {
        path: "notificacoes/formulario",
        children: [
          {
            path: "",
            index: true,
            element: (
              <PrivateLayoutComponent>
                <AdminNotificationSendPage />
              </PrivateLayoutComponent>
            ),
          },
          {
            path: ":id",
            element: (
              <PrivateLayoutComponent>
                <AdminNotificationFormPage />
              </PrivateLayoutComponent>
            ),
          },
        ],
      },
      {
        path: "propostas",
        element: (
          <PrivateLayoutComponent>
            <AdminProposalsPage />
          </PrivateLayoutComponent>
        ),
      },
      {
        path: "propostas/formulario",
        children: [
          {
            path: "",
            index: true,
            element: (
              <PrivateLayoutComponent>
                <AdminProposalFormPage />
              </PrivateLayoutComponent>
            ),
          },
          {
            path: ":id",
            index: true,
            element: (
              <PrivateLayoutComponent>
                <AdminProposalFormPage />
              </PrivateLayoutComponent>
            ),
          },
        ],
      },
      {
        path: "usuarios",
        element: (
          <PrivateLayoutComponent>
            <AdminUsersPage />
          </PrivateLayoutComponent>
        ),
      },
      {
        path: "usuarios/formulario",
        children: [
          {
            path: "",
            index: true,
            element: (
              <PrivateLayoutComponent>
                <AdminUserFormPage />
              </PrivateLayoutComponent>
            ),
          },
          {
            path: ":id",
            element: (
              <PrivateLayoutComponent>
                <AdminUserFormPage />
              </PrivateLayoutComponent>
            ),
          },
        ],
      },
    ],
  },
]);

export default Rotas;
