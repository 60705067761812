import Paper from "@mui/material/Paper/Paper";
import Divider from "@mui/material/Divider/Divider";
import Grid from "@mui/material/Grid/Grid";
import Typography from "@mui/material/Typography/Typography";
import makeStyles from "@mui/styles/makeStyles/makeStyles";

const useStyles = makeStyles(() => ({
  card: {
    width: "100%",
    borderRadius: "10px",
    marginBottom: "4vh",
  },
  cardTitle: {
    marginInline: "4vh",
    marginTop: "4vh",
    marginBottom: "4vh",
    fontWeight: "700",
    fontSize: "1.75vh",
    color: "#5C5C5C",
  },
  overflowText: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    maxWidth: "100%", // You can adjust this to your desired width
    paddingInline: "0.5vw",
  },
}));

const UserRankComponent = ({ counters }) => {
  const classes = useStyles();

  const Row = ({ position, user, quantity, isHeader }) => {
    return (
      <Paper
        variant="outlined"
        style={{
          display: "flex",
          justifyContent: "space-between",
          paddingInline: "1vw",
          marginBottom: "1vh",
        }}
      >
        <Typography style={{ fontWeight: isHeader ? "bold" : "normal" }}>
          {position}
        </Typography>
        <Typography
          className={classes.overflowText}
          style={{ fontWeight: isHeader ? "bold" : "normal" }}
        >
          {user}
        </Typography>
        <Typography style={{ fontWeight: isHeader ? "bold" : "normal" }}>
          {quantity}
        </Typography>
      </Paper>
    );
  };

  return (
    <Paper variant="outlined" className={classes.card}>
      <Typography className={classes.cardTitle}>
        USUÁRIOS QUE MAIS PRODUZEM
      </Typography>
      <Divider />
      <div style={{ marginBottom: "1vh", padding: "1vh" }}>
        <Grid container spacing={4}>
          <Grid item xs={4}>
            <Paper variant="outlined" style={{ padding: "1vh" }}>
              <Row
                position=""
                user="LINKS CONVERTIDOS"
                quantity=""
                isHeader={true}
              />
              <Row
                position="POSIÇÃO"
                user="USUÁRIO"
                quantity="QUANTIDADE"
                isHeader={true}
              />
              {(counters?.usuariosMaisEngajados.linksConvertidos ?? []).map(
                (item, i) => {
                  return (
                    <Row
                      key={i}
                      position={i + 1 + "º"}
                      user={item.nome}
                      quantity={parseFloat(item.producoes)}
                    />
                  );
                }
              )}
            </Paper>
          </Grid>
          <Grid item xs={4}>
            <Paper variant="outlined" style={{ padding: "1vh" }}>
              <Row
                position=""
                user="LINKS SOLICITADOS"
                quantity=""
                isHeader={true}
              />
              <Row
                position="POSIÇÃO"
                user="USUÁRIO"
                quantity="QUANTIDADE"
                isHeader={true}
              />
              {(counters?.usuariosMaisEngajados.linksSolicitados ?? []).map(
                (item, i) => {
                  return (
                    <Row
                      key={i}
                      position={i + 1 + "º"}
                      user={item.nome}
                      quantity={parseFloat(item.solicitacoes)}
                    />
                  );
                }
              )}
            </Paper>
          </Grid>
          <Grid item xs={4}>
            <Paper variant="outlined" style={{ padding: "1vh" }}>
              <Row
                position=""
                user="PROPOSTAS GERADAS"
                quantity=""
                isHeader={true}
              />
              <Row
                position="POSIÇÃO"
                user="USUÁRIO"
                quantity="QUANTIDADE"
                isHeader={true}
              />
              {(counters?.usuariosMaisEngajados.propostasGeradas ?? []).map(
                (item, i) => {
                  return (
                    <Row
                      key={i}
                      position={i + 1 + "º"}
                      user={item.nome}
                      quantity={parseFloat(item.propostas)}
                    />
                  );
                }
              )}
            </Paper>
          </Grid>
        </Grid>
        <div style={{ marginTop: "1vh", marginBottom: "1vh" }}>
          <Divider />
        </div>
        <Grid container spacing={4}>
          <Grid item xs={4}>
            <Paper variant="outlined" style={{ padding: "1vh" }}>
              <Row
                position=""
                user="PROPOSTAS APROVADAS"
                quantity=""
                isHeader={true}
              />
              <Row
                position="POSIÇÃO"
                user="USUÁRIO"
                quantity="QUANTIDADE"
                isHeader={true}
              />
              {(counters?.usuariosMaisEngajados.propostasAprovadas ?? []).map(
                (item, i) => {
                  return (
                    <Row
                      key={i}
                      position={i + 1 + "º"}
                      user={item.nome}
                      quantity={parseFloat(item.propostas)}
                    />
                  );
                }
              )}
            </Paper>
          </Grid>
        </Grid>
      </div>
    </Paper>
  );
};

export default UserRankComponent;
