import React, { useState } from "react";
import FormControl from "@mui/material/FormControl/FormControl";
import FormHelperText from "@mui/material/FormHelperText/FormHelperText";
import Button from "@mui/material/Button/Button";
import makeStyles from "@mui/styles/makeStyles/makeStyles";

import { Controller } from "react-hook-form";
import { ChromePicker } from "react-color";
import InputFilterComponent from "./input-filter.component";

const useStyles = makeStyles({
  label: {
    fontWeight: "700",
    fontSize: "1.46vh",
    color: "#5C5C5C",
  },
  formControl: {
    width: "100%",
  },
  colorBotao: {
    backgroundColor: "#774E98 !important",
    color: "#fff",
  },
});

const ColorPickerComponent = ({
  name,
  label,
  control,
  disabled,
  isExterna = true,
  onChange,
  setValue,
}) => {
  const classes = useStyles();
  const [showColorPicker, setShowColorPicker] = useState(false);
  const [selectedColor, setSelectedColor] = useState("#ffffff");

  const handleToggleColorPicker = () => {
    setShowColorPicker(!showColorPicker);
  };

  const handleChangeColor = (color) => {
    setSelectedColor(color.hex);
  };

  const handleConfirmColor = () => {
    handleToggleColorPicker();
    setValue(name, selectedColor);
  };

  return (
    <Controller
      name={name}
      control={control}
      defaultValue=""
      render={({ field, formState }) => (
        <>
          <FormControl
            error={!!formState.errors[name]}
            className={classes.formControl}
          >
            <InputFilterComponent
              name={name}
              control={control}
              label={label}
              placeholder={label}
              disabled={disabled}
              value={field ? field.value : selectedColor}
              onChange={field ? field.onChange : onChange}
              onClick={handleToggleColorPicker}
            />
            <FormHelperText>{formState.errors[name]?.message}</FormHelperText>
            {showColorPicker && (
              <ChromePicker
                color={selectedColor}
                onChange={handleChangeColor}
              />
            )}
          </FormControl>
          {showColorPicker && (
            <Button className={classes.colorBotao} onClick={handleConfirmColor}>
              Confirmar
            </Button>
          )}
        </>
      )}
    />
  );
};

export default ColorPickerComponent;
