import Grid from "@mui/material/Grid/Grid";

import NavBarComponent from "./navbar.component";
import DescribleComponent from "./describle.component";
import { useResponsive } from "../../../../../providers";
import { useGlobalStyles } from "../../../../../hooks";
import FormComponent from "./form.component";

const DefaultSectionComponent = ({
  handleSectionClick,
  aboutRef,
  helpRef,
  campaignsRef,
}) => {
  const { isDesktop } = useResponsive();
  const classesGlobal = useGlobalStyles();

  return (
    <div className={classesGlobal.gradient}>
      <NavBarComponent
        handleSectionClick={handleSectionClick}
        aboutRef={aboutRef}
        helpRef={helpRef}
        campaignsRef={campaignsRef}
      />
      <Grid container>
        <Grid item xs={isDesktop ? 6 : 12}>
          <DescribleComponent />
        </Grid>
        {isDesktop && (
          <Grid item xs={6} style={{ textAlign: "end" }}>
            <FormComponent />
          </Grid>
        )}
      </Grid>
    </div>
  );
};

export default DefaultSectionComponent;
