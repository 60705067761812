import React from "react";
import moment from "moment";

import {
  campaignService,
  productionService,
  userService,
} from "../../../../infrastructure/services";
import { useAlert, useAuth, useDialog } from "../../../providers";
import { useIsMounted } from "../../../hooks";
import { Constants, Format } from "../../../utils";
import { useLoadingContext } from "../../../context/loading.context";
import dayjs from "dayjs";

const useReportsController = () => {
  const { showError } = useAlert();
  const { user } = useAuth();
  const { openDialog } = useDialog();
  const isMounted = useIsMounted();
  const [campaigns, setCampaigns] = React.useState([Constants.defaultSelect]);
  const [users, setUsers] = React.useState([Constants.defaultSelect]);
  const { setLoadingState } = useLoadingContext();

  const rowsPerPage = 10;
  const [productions, setProductions] = React.useState([]);
  const [count, setCount] = React.useState(0);
  const [page, setPage] = React.useState(0);
  const [lastQuery, setLastQuery] = React.useState(null);
  const [counters, setCounters] = React.useState({
    pending: 0,
    approved: 0,
    disapproved: 0,
  });

  React.useEffect(() => {
    if (user != null) {
      setLoadingState(true);
      campaignService
        .getUserCampaigns()
        .then((response) => {
          if (isMounted.current) {
            setCampaigns([
              Constants.defaultSelect,
              ...response.map((item) => ({ id: item.id, label: item.name })),
            ]);
          }
        })
        .catch(showError)
        .finally(() => {
          setLoadingState(false);
        });

      setLoadingState(true);
      userService
        .getAffilietdToReports({ id: user.id })
        .then((response) => {
          if (isMounted.current) {
            setUsers([
              Constants.defaultSelect,
              ...response.map((item) => ({
                id: item.id,
                label: item.displayName,
              })),
            ]);
          }
        })
        .catch(showError)
        .finally(() => {
          setLoadingState(false);
        });
    }
  }, [user]);

  const onSearch = ({
    newPage,
    startDate,
    endDate,
    user,
    campaign,
    situation,
    step,
    isDownload,
  }) => {
    const query = {
      startDate: dayjs(startDate).format().substring(0, 10),
      endDate: dayjs(endDate).format().substring(0, 10),
      situacao:
        situation.label == "APROVADAS"
          ? "CONCLUÍDO"
          : situation.label == "PENDENTES"
          ? "AGUARDANDO"
          : situation.label == "REPROVADAS"
          ? "REPROVADAS"
          : "TODOS",
      step: step != null ? step.id : null,
      page: newPage ?? page,
      rowsPerPage: rowsPerPage,
    };

    if (!!campaign && campaign.id != 0) {
      query.offerId = campaign.id;
    }

    if (!!user && user.id !== 0) {
      query.usuarioId = user.id;
    }

    if (newPage == 0) {
      setPage(0);
    }

    if (isDownload == true) {
      query.baixar = 1;
    }

    setLastQuery({
      startDate,
      endDate,
      user,
      campaign,
      situation,
      step,
    });
    setLoadingState(true);
    productionService
      .getReport(query)
      .then((response) => {
        if (isDownload == true) {
          makeCsvData(response.data);
        } else {
          setProductions(response.data);
          setCount(response.count);
          setCounters(response.counters);
        }
      })
      .catch(showError)
      .finally(() => {
        setLoadingState(false);
      });
  };

  const makeCsvData = (data) => {
    if (data.length == 0) {
      return;
    }

    var csvRows = [];

    csvRows.push(["PRODUTO", "ETAPA", "USUÁRIO", "DATA", "CONCLUÍDO"]);

    data.map((row) => {
      csvRows.push([
        row.campaign,
        Constants.getProductionStep(row.step),
        row.affiliate,
        row.eventDate,
        row.payment.toLocaleString("pt-BR", {
          style: "currency",
          currency: "BRL",
        }),
      ]);
    });

    const csvString = csvRows.join("\n");

    Format.downloadCsv(csvString, "relatorio");
  };

  const handleChangePage = (newPage) => {
    setPage(newPage);
    onSearch({ newPage, ...lastQuery });
  };

  const onShowProduction = (production) => {
    openDialog("production-detail-in-reports-dialog", production);
  };

  return {
    productions,
    campaigns,
    users,
    onSearch,
    handleChangePage,
    page,
    count,
    rowsPerPage,
    onShowProduction,
    counters,
  };
};

export default useReportsController;
