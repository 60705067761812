import dayjs from "dayjs";
import { Constants, Format } from "../../presentation/utils";
import httpClient from "./axios.config";

class UserService {
  getAffilietdToReports = ({ id }) => {
    return new Promise((resolve, reject) => {
      httpClient
        .get(`/usuario/usuariosSecundarios?idUsuario=${id}&modo=relatorio`)
        .then((response) => {
          const usersResponse = response.data.response;

          const users = usersResponse.map((user) => ({
            id: parseInt(user.idUsuario),
            displayName: user.nomeCompleto,
          }));

          resolve(users);
        })
        .catch((error) =>
          reject(
            error.response && error.response.data && error.response.data.message
              ? error.response.data.message
              : "Erro inesperado, tente novamente mais tarde."
          )
        );
    });
  };

  getAffilietdToInterReports = ({ id }) => {
    return new Promise((resolve, reject) => {
      httpClient
        .get(`/usuario/usuariosDoAnunciante`)
        .then((response) => {
          const usersResponse = response.data.response;

          const users = usersResponse.map((user) => ({
            id: parseInt(user.idUsuario),
            displayName: user.nomeCompleto,
          }));

          resolve(users);
        })
        .catch((error) =>
          reject(
            error.response && error.response.data && error.response.data.message
              ? error.response.data.message
              : "Erro inesperado, tente novamente mais tarde."
          )
        );
    });
  };

  getAffilietd = ({ id }) => {
    return new Promise((resolve, reject) => {
      httpClient
        .get("/usuario/produtoComissaoAReceber")
        .then((response) => {
          const comissoesAReceber = response.data.response;

          httpClient
            .get("/usuario/produtoComissaoAPagar")
            .then((response) => {
              const comissoesAPagarPorUsuarios =
                response.data.response.porUsuarios;
              const comissoesAPagarPadrao = response.data.response.padrao;
              const comissoesAPagarNovosProdutos =
                response.data.response.novosProdutos;

              httpClient
                .get(
                  `/usuario/usuariosSecundarios?idUsuario=${id}&modo=secundarios`
                )
                .then((response) => {
                  const usersResponse = response.data.response;

                  const newRows = comissoesAPagarPadrao.map((aPagar) => {
                    const comissaoAReceber = comissoesAReceber.filter(
                      (aReceber) => aReceber.idProduto === aPagar.idProduto
                    )[0];

                    if (!!comissaoAReceber) {
                      return {
                        ...aPagar,
                        tipoComissaoAReceber: comissaoAReceber.tipoComissao,
                        comissaoAReceber: comissaoAReceber.comissao,
                      };
                    } else {
                      return null;
                    }
                  });

                  const users = usersResponse.map((user) => {
                    const newRows2 = comissoesAReceber.map((aReceber) => {
                      var porUsuario = false;
                      var aPagar = null;
                      comissoesAPagarPorUsuarios.forEach((aPagarUsuario) => {
                        if (
                          aReceber.idProduto == aPagarUsuario.idProduto &&
                          aPagarUsuario.id == user.idUsuario
                        ) {
                          porUsuario = true;
                          aPagar = { ...aPagarUsuario, tipo: "user" };
                        }
                      });

                      if (porUsuario == false) {
                        comissoesAPagarPadrao.forEach((aPagarPadrao) => {
                          if (aReceber.idProduto == aPagarPadrao.idProduto) {
                            aPagar = { ...aPagarPadrao, tipo: "default" };
                          }
                        });
                      }

                      return {
                        ...aPagar,
                        tipoComissaoAReceber: aReceber.tipoComissao,
                        comissaoAReceber: aReceber.comissao,
                      };
                    });

                    return {
                      id: parseInt(user.idUsuario),
                      displayName: user.nomeCompleto,
                      commissions: newRows2
                        .filter((row) => !!row)
                        .map((campanha) => ({
                          campaign: {
                            id: parseInt(campanha.idProduto),
                            name: campanha.produtoNome,
                          },
                          commissionType: campanha.tipoComissaoAReceber,
                          commissionReceivable: campanha.comissaoAReceber,
                          commissionPayable:
                            campanha.tipoComissaoAReceber == "R$"
                              ? Format.percentToCurrency(
                                  campanha.comissao,
                                  campanha.comissaoAReceber
                                )
                              : campanha.comissao,
                          commissionPayablePercent: campanha.comissao,
                          getType: campanha.tipo,
                        })),
                    };
                  });
                  resolve({
                    users: users,
                    commissionToNewCampaign: comissoesAPagarNovosProdutos,
                    defaultCommissions: newRows
                      .filter((row) => !!row)
                      .map((aPagar) => ({
                        campaign: {
                          id: parseInt(aPagar.idProduto),
                          name: aPagar.produtoNome,
                        },
                        commissionType: aPagar.tipoComissaoAReceber,
                        commissionReceivable: aPagar.comissaoAReceber,
                        commissionPayable:
                          aPagar.tipoComissaoAReceber == "R$"
                            ? Format.percentToCurrency(
                                aPagar.comissao,
                                aPagar.comissaoAReceber
                              )
                            : aPagar.comissao,
                        commissionPayablePercent: aPagar.comissao,
                      })),
                  });
                })
                .catch((error) =>
                  reject(
                    error.response &&
                      error.response.data &&
                      error.response.data.message
                      ? error.response.data.message
                      : "Erro inesperado, tente novamente mais tarde."
                  )
                );
            })
            .catch((error) =>
              reject(
                error.response &&
                  error.response.data &&
                  error.response.data.message
                  ? error.response.data.message
                  : "Erro inesperado, tente novamente mais tarde."
              )
            );
        })
        .catch((error) =>
          reject(
            error.response && error.response.data && error.response.data.message
              ? error.response.data.message
              : "Erro inesperado, tente novamente mais tarde."
          )
        );
    });
  };

  sendInvite = ({ email }) => {
    return new Promise((resolve, reject) => {
      httpClient
        .post("/usuario/enviarLink", { enviar: { email } })
        .then((response) => {
          const json = response.data.response;
          resolve(json);
        })
        .catch((error) =>
          reject(
            error.response && error.response.data && error.response.data.message
              ? error.response.data.message
              : "Erro inesperado, tente novamente mais tarde."
          )
        );
    });
  };

  changeAffiliateCommission = ({ id, affiliate, campaign, commission }) => {
    return new Promise((resolve, reject) => {
      httpClient
        .put("/usuario/produtoPorUsuario", {
          idUsuarioPrimario: id,
          idUsuarioSecundario: affiliate,
          idProduto: campaign,
          comissaoAPagar: commission,
        })
        .then((response) => {
          const json = response.data.response;
          resolve(json);
        })
        .catch((error) =>
          reject(
            error.response && error.response.data && error.response.data.message
              ? error.response.data.message
              : "Erro inesperado, tente novamente mais tarde."
          )
        );
    });
  };

  changeDefaultCommissionToNewCampaign = ({ commission }) => {
    return new Promise((resolve, reject) => {
      httpClient
        .put("/usuario/comissaoNovoProduto", {
          valor: commission,
        })
        .then((response) => {
          const json = response.data.response;
          resolve(json);
        })
        .catch((error) =>
          reject(
            error.response && error.response.data && error.response.data.message
              ? error.response.data.message
              : "Erro inesperado, tente novamente mais tarde."
          )
        );
    });
  };

  register = (form) => {
    return new Promise((resolve, reject) => {
      httpClient
        .post("/usuario/cadastroConvite", { usuario: form })
        .then((response) => {
          const json = response.data.response;
          resolve(json);
        })
        .catch((error) =>
          reject(
            error.response && error.response.data && error.response.data.message
              ? error.response.data.message
              : "Erro inesperado, tente novamente mais tarde."
          )
        );
    });
  };

  forgot = (data) => {
    return new Promise((resolve, reject) => {
      httpClient
        .post("/security/forgot", data)
        .then((response) => {
          const json = response.data.response;
          resolve(json);
        })
        .catch((error) =>
          reject(
            error.response && error.response.data && error.response.data.message
              ? error.response.data.message
              : "Erro inesperado, tente novamente mais tarde."
          )
        );
    });
  };

  resetPassword = (data) => {
    return new Promise((resolve, reject) => {
      httpClient
        .post("/security/newPassword", data)
        .then((response) => {
          const json = response.data.response;
          resolve(json);
        })
        .catch((error) =>
          reject(
            error.response && error.response.data && error.response.data.message
              ? error.response.data.message
              : "Erro inesperado, tente novamente mais tarde."
          )
        );
    });
  };

  searchUsers = (query) => {
    return new Promise((resolve, reject) => {
      const estados = [
        { uf: "AC", nome: "Acre" },
        { uf: "AL", nome: "Alagoas" },
        { uf: "AP", nome: "Amapá" },
        { uf: "AM", nome: "Amazonas" },
        { uf: "BA", nome: "Bahia" },
        { uf: "CE", nome: "Ceará" },
        { uf: "DF", nome: "Distrito Federal" },
        { uf: "ES", nome: "Espírito Santo" },
        { uf: "GO", nome: "Goiás" },
        { uf: "MA", nome: "Maranhão" },
        { uf: "MT", nome: "Mato Grosso" },
        { uf: "MS", nome: "Mato Grosso do Sul" },
        { uf: "MG", nome: "Minas Gerais" },
        { uf: "PA", nome: "Pará" },
        { uf: "PB", nome: "Paraíba" },
        { uf: "PR", nome: "Paraná" },
        { uf: "PE", nome: "Pernambuco" },
        { uf: "PI", nome: "Piauí" },
        { uf: "RJ", nome: "Rio de Janeiro" },
        { uf: "RN", nome: "Rio Grande do Norte" },
        { uf: "RS", nome: "Rio Grande do Sul" },
        { uf: "RO", nome: "Rondônia" },
        { uf: "RR", nome: "Roraima" },
        { uf: "SC", nome: "Santa Catarina" },
        { uf: "SP", nome: "São Paulo" },
        { uf: "SE", nome: "Sergipe" },
        { uf: "TO", nome: "Tocantins" },
      ];

      httpClient
        .get("/usuario/cadastro", {
          params: query,
          responseType: query.baixar == true ? "blob" : undefined,
        })
        .then((response) => {
          if (query.baixar == true) {
            const href = URL.createObjectURL(response.data);

            const link = document.createElement("a");
            link.href = href;
            link.setAttribute("download", "relatorio_de_usuarios.csv");
            document.body.appendChild(link);
            link.click();

            document.body.removeChild(link);
            URL.revokeObjectURL(href);

            resolve();
          } else {
            const result = response.data.response;

            resolve({
              data: result.data.map((user) => ({
                userType: user.tipoUsuario,
                situation: user.situacao,
                lastLogin: user.ultimoLogin,
                profileType: user.perfilSubUsuario,
                dtCadastro: user.dtCadastro,
                displayName: user.nomeCompleto,
                phone: user.celular,
                email: user.email,
                idade: user.idade,
                sexo: user.sexo,
                estado:
                  user.estado != null
                    ? estados.filter((estado) => estado.uf == user.estado)[0]
                        .nome
                    : null,
                dataDeNascimento: !!user.dtNasc
                  ? dayjs(user.dtNasc).format("DD/MM/YYYY")
                  : null,
                id: parseInt(user.idUsuario),
              })),
              count: result.count,
            });
          }
        })
        .catch((error) => {
          console.log(error);
          return reject(
            error.response && error.response.data && error.response.data.message
              ? error.response.data.message
              : "Erro inesperado, tente novamente mais tarde."
          );
        });
    });
  };

  getUser = ({ id }) => {
    return new Promise((resolve, reject) => {
      httpClient
        .get("/usuario/cadastro?id=" + id)
        .then((response) => {
          const user = response.data.response;

          resolve({
            id: parseInt(user.idUsuario),

            userType: parseInt(user.idTipoUsuario),
            groupType: parseInt(user.idGrupoUsuario),
            profileType: parseInt(user.perfilSubUsuarioId),
            userLevel: user.secundario ?? 0,
            displayName: user.nomeCompleto ?? "",
            email: user.email ?? "",
            cpf: user.cpf ?? "",
            rg: user.rgNumero ?? "",
            phone: user.celular ?? "",
            branch: user.ramal ?? "",
            birthDate: user.dtNasc ?? "",
            gender: user.sexo ?? null,
            cnh: user.cnhNumero ?? "",
            bank: !!user.banco ? parseInt(user.banco.bancoId) : null,
            bankAccountType: user.tipoConta ?? "",
            bankAgency: user.agenciaBanco ?? "",
            bankAccount: user.contaBanco ?? "",
            pix: user.chavePix ?? "",
            address: user.logradouro ?? "",
            addressNumber: user.numero ?? "",
            addressCep: user.cep ?? "",
            addressDistrict: user.bairro ?? "",
            addressState: !!user.cidade ? user.cidade.uf : null,
            addressCity: !!user.cidade ? parseInt(user.cidade.cidadeId) : null,
            primaryUser: !!user.usuarioPrimarioId
              ? parseInt(user.usuarioPrimarioId)
              : null,

            situation: user.situacao,
          });
        })
        .catch((error) =>
          reject(
            error.response && error.response.data && error.response.data.message
              ? error.response.data.message
              : "Erro inesperado, tente novamente mais tarde."
          )
        );
    });
  };

  cadastroEndereco = (form) => {
    return new Promise((resolve, reject) => {
      httpClient
        .post("/usuario/cadastroEndereco", form)
        .then(() => resolve())
        .catch((error) =>
          reject(
            error.response && error.response.data && error.response.data.message
              ? error.response.data.message
              : "Erro inesperado, tente novamente mais tarde."
          )
        );
    });
  };

  editUser = ({ user }) => {
    return new Promise((resolve, reject) => {
      httpClient
        .put("/usuario/cadastro", { usuario: user })
        .then(() => resolve())
        .catch((error) =>
          reject(
            error.response && error.response.data && error.response.data.message
              ? error.response.data.message
              : "Erro inesperado, tente novamente mais tarde."
          )
        );
    });
  };

  createUser = ({ user }) => {
    return new Promise((resolve, reject) => {
      httpClient
        .post("/usuario/cadastro", { usuario: user })
        .then(() => resolve())
        .catch((error) =>
          reject(
            error.response && error.response.data && error.response.data.message
              ? error.response.data.message
              : "Erro inesperado, tente novamente mais tarde."
          )
        );
    });
  };

  getActiveUsers = ({ mode }) => {
    return new Promise((resolve, reject) => {
      httpClient
        .get("/usuario/ativos?modo=" + mode)
        .then((response) => {
          const usersResponse = response.data.response;

          resolve(
            usersResponse.map((user) => ({
              id: parseInt(user.id),
              displayName: user.displayName,
            }))
          );
        })
        .catch((error) =>
          reject(
            error.response && error.response.data && error.response.data.message
              ? error.response.data.message
              : "Erro inesperado, tente novamente mais tarde."
          )
        );
    });
  };

  toggleUserSituation = ({ id }) => {
    return new Promise((resolve, reject) => {
      httpClient
        .put("/usuario/suspenderAtivar", { usuarioId: id })
        .then(() => resolve())
        .catch((error) =>
          reject(
            error.response && error.response.data && error.response.data.message
              ? error.response.data.message
              : "Erro inesperado, tente novamente mais tarde."
          )
        );
    });
  };

  deactivateAccount = ({ id }) => {
    return new Promise((resolve, reject) => {
      httpClient
        .put("/usuario/suspenderConta", { usuarioId: id })
        .then(() => resolve())
        .catch((error) =>
          reject(
            error.response && error.response.data && error.response.data.message
              ? error.response.data.message
              : "Erro inesperado, tente novamente mais tarde."
          )
        );
    });
  };

  changeDefaultCommission = ({ idProduto, value }) => {
    return new Promise((resolve, reject) => {
      httpClient
        .put("/usuario/comissaoPadrao", { idProduto: idProduto, valor: value })
        .then(() => resolve())
        .catch((error) =>
          reject(
            error.response && error.response.data && error.response.data.message
              ? error.response.data.message
              : "Erro inesperado, tente novamente mais tarde."
          )
        );
    });
  };

  getTypeUsers = () => {
    return new Promise((resolve, reject) => {
      httpClient
        .get("/usuario/listaTipoUsuario")
        .then((response) => {
          const typeUsersResponse = response.data.response;

          resolve(
            typeUsersResponse.map((user) => ({
              id: parseInt(user.id),
              name: user.nome,
            }))
          );
        })
        .catch((error) =>
          reject(
            error.response && error.response.data && error.response.data.message
              ? error.response.data.message
              : "Erro inesperado, tente novamente mais tarde."
          )
        );
    });
  };

  getInviteToken = ({ token }) => {
    return new Promise((resolve, reject) => {
      httpClient
        .get("/usuario/tokenConvite?token=" + token)
        .then((response) => {
          const json = response.data.response;
          resolve(json);
        })
        .catch((error) =>
          reject(
            error.response && error.response.data && error.response.data.message
              ? error.response.data.message
              : "Erro inesperado, tente novamente mais tarde."
          )
        );
    });
  };

  selfRegistrationInCampaign = ({ campaignGuid, affiliateKey, form }) => {
    return new Promise((resolve, reject) => {
      httpClient
        .post("/producao/salvarProducaoInterna", {
          chaveAfiliado: affiliateKey,
          formulario: form,
          guidProduto: campaignGuid,
          objetivo: "cadastro_preenchido",
        })
        .then((response) => {
          const json = response.data.response;
          resolve(json);
        })
        .catch((error) =>
          reject(
            error.response && error.response.data && error.response.data.message
              ? error.response.data.message
              : "Erro inesperado, tente novamente mais tarde."
          )
        );
    });
  };

  openLinkInCampaign = ({ campaignGuid, affiliateKey }) => {
    return new Promise((resolve, reject) => {
      httpClient
        .post("/producao/salvarProducaoInterna", {
          chaveAfiliado: affiliateKey,
          guidProduto: campaignGuid,
          objetivo: "link_aberto",
        })
        .then((response) => {
          const json = response.data.response;
          resolve(json);
        })
        .catch((error) =>
          reject(
            error.response && error.response.data && error.response.data.message
              ? error.response.data.message
              : "Erro inesperado, tente novamente mais tarde."
          )
        );
    });
  };
}

export default new UserService();
