import ReactECharts from "echarts-for-react";
import { useEffect, useState } from "react";

const BarChartV2Component = ({ data, direction, hasZoom, formatter }) => {
  const [options, setOptions] = useState({});

  useEffect(() => {
    if (!!data) {
      makeOptions(data);
    }
  }, [data]);

  const makeOptions = (dataVariables) => {
    setOptions({
      tooltip: {
        trigger: "axis",
        formatter: formatter,
      },
      legend: {
        data: Object.keys(dataVariables),
      },
      yAxis:
        direction == "yAxis"
          ? {
              type: "category",
              data: Object.values(dataVariables)[0].map((item) => item.key),
            }
          : [
              {
                type: "value",
              },
            ],
      xAxis:
        direction == "xAxis"
          ? {
              type: "category",
              data: Object.values(dataVariables)[0].map((item) => item.key),
            }
          : {
              type: "value",
            },
      dataZoom:
        hasZoom == true
          ? [
              {
                type: "inside",
                start: 0,
                end: 100,
              },
              {
                start: 0,
                end: 100,
              },
            ]
          : null,
      series: Object.entries(dataVariables).map(([key, variable]) => {
        return {
          name: key,
          type: "bar",
          data: variable.map((item) => item.value),
        };
      }),
    });
  };

  return (
    <ReactECharts option={options} style={{ width: "100%", height: "34vh" }} />
  );
};

export default BarChartV2Component;
