import Grid from "@mui/material/Grid/Grid";

import { BodyComponent, ButtonComponent } from "../../../../components";
import useAdminCampaignFormController from "./admin-campaign-form.controller";
import BasicSectionComponent from "./components/basic-section.component";
import InternalSectionComponent from "./components/internal-section.component";
import ExternalSectionComponent from "./components/external-section.component";
import CPVSectionComponent from "./components/cpv-section.component";
import CPLSectionComponent from "./components/cpl-section.component";
import CPRSectionComponent from "./components/cpr-section.component";
import ImagesSectionComponent from "./components/images-section.component";
import CPLASectionComponent from "./components/cpla-section.component";
import { useParams } from "react-router-dom";

const AdminCampaignFormPage = () => {
  const { id: campaignId } = useParams();
  const {
    control,
    onSubmit,
    isToEdit,
    categoryOptions,
    isInternOptions,
    withLinkOptions,
    usersOptions,
    goalsOptions,
    goalsSelecteds,
    isIntern,
    formCPLOptions,
    formCPVOptions,
    productsCPV,
    isActiveOptions,
    onAddProduct,
    isValidPreview,
    onPreview,
    onAddCPVQuestion,
    onAddCPLQuestion,
    onRemoveProduct,
    onEditProduct,
    onAddProductToEdit,
    editProductMode,
    editImage,
    images,
    commissions,
    onChangeCommissionToProfile,
    addSupportImage,
    removeSupportImage,
    commissionTypeOptions,
    commissionType,
    productCommissionType,
    watch,
    setValue,
    onGoalsSelecteds,
    formCPROptions,
    onDeleteCampaign,
    isLoading,
    productImages,
    addProductImage,
    removeProductImage,
  } = useAdminCampaignFormController({
    campaignId,
  });

  return (
    <BodyComponent>
      <Grid component="form" onSubmit={onSubmit} container>
        <Grid item xs={12} style={{ marginBottom: "4vh" }}>
          <BasicSectionComponent
            control={control}
            categoryOptions={categoryOptions}
            isIntern={isIntern}
            isInternOptions={isInternOptions}
            isActiveOptions={isActiveOptions}
            commissionTypeOptions={commissionTypeOptions}
            editImage={editImage}
            isToEdit={isToEdit}
            commissions={commissions}
            onChangeCommissionToProfile={onChangeCommissionToProfile}
            commissionType={commissionType}
            watch={watch}
            isLoading={isLoading}
          />
        </Grid>

        {!!isToEdit && (
          <Grid item xs={12} style={{ marginBottom: "4vh" }}>
            <ImagesSectionComponent
              editImage={editImage}
              images={images}
              addSupportImage={addSupportImage}
              removeSupportImage={removeSupportImage}
              isLoading={isLoading}
            />
          </Grid>
        )}

        <Grid item xs={12} style={{ marginBottom: "4vh" }}>
          {isIntern == true ? (
            <InternalSectionComponent
              control={control}
              usersOptions={usersOptions}
              goalsOptions={goalsOptions}
              goalsSelecteds={goalsSelecteds}
              onGoalsSelecteds={onGoalsSelecteds}
              editImage={editImage}
              watch={watch}
              setValue={setValue}
              isLoading={isLoading}
            />
          ) : (
            <ExternalSectionComponent
              control={control}
              withLinkOptions={withLinkOptions}
              isLoading={isLoading}
            />
          )}
        </Grid>

        {(goalsSelecteds ?? []).filter((goal) => goal.id == 1).length > 0 && (
          <Grid item xs={12} style={{ marginBottom: "4vh" }}>
            <CPLSectionComponent
              control={control}
              formCPLOptions={formCPLOptions}
              onAddCPLQuestion={onAddCPLQuestion}
              isLoading={isLoading}
            />
          </Grid>
        )}

        {(goalsSelecteds ?? []).filter((goal) => goal.id == 2).length > 0 && (
          <Grid item xs={12} style={{ marginBottom: "4vh" }}>
            <CPVSectionComponent
              control={control}
              formCPVOptions={formCPVOptions}
              productsCPV={productsCPV}
              onAddProduct={onAddProduct}
              onRemoveProduct={onRemoveProduct}
              onAddCPVQuestion={onAddCPVQuestion}
              onEditProduct={onEditProduct}
              onAddProductToEdit={onAddProductToEdit}
              editProductMode={editProductMode}
              editImage={editImage}
              commissionTypeOptions={commissionTypeOptions}
              productCommissionType={productCommissionType}
              isLoading={isLoading}
              productImages={productImages}
              addProductImage={addProductImage}
              removeProductImage={removeProductImage}
            />
          </Grid>
        )}

        {(goalsSelecteds ?? []).filter((goal) => goal.id == 3).length > 0 && (
          <Grid item xs={12} style={{ marginBottom: "4vh" }}>
            <CPRSectionComponent
              control={control}
              formCPROptions={formCPROptions}
              isLoading={isLoading}
            />
          </Grid>
        )}

        {(goalsSelecteds ?? []).filter((goal) => goal.id == 4).length > 0 && (
          <Grid item xs={12} style={{ marginBottom: "4vh" }}>
            <CPLASectionComponent control={control} isLoading={isLoading} />
          </Grid>
        )}

        <Grid item xs={12} style={{ marginBottom: "4vh" }}>
          <div style={{ display: "flex", justifyContent: "end" }}>
            {isIntern && isValidPreview && (
              <ButtonComponent
                label={isLoading == false ? "PRÉ-VISUALIZAR" : "CARREGANDO..."}
                backgroundColor="#40215F"
                color="white"
                size="small"
                height="5vh"
                onClick={onPreview}
                disabled={isLoading}
              />
            )}
            <div style={{ marginLeft: "1vw" }}>
              <ButtonComponent
                label={
                  isLoading == false
                    ? isToEdit
                      ? "EDITAR CAMPANHA"
                      : "CRIAR CAMPANHA"
                    : "CARREGANDO..."
                }
                backgroundColor="#40215F"
                color="white"
                size="small"
                height="5vh"
                type="submit"
                disabled={isLoading}
              />
            </div>
            {isToEdit && (
              <div style={{ marginLeft: "1vw" }}>
                <ButtonComponent
                  label={
                    isLoading == false ? "EXCLUIR CAMPANHA" : "CARREGANDO..."
                  }
                  backgroundColor="#858585"
                  color="white"
                  size="small"
                  height="5vh"
                  onClick={onDeleteCampaign}
                  disabled={isLoading}
                />
              </div>
            )}
          </div>
        </Grid>
      </Grid>
    </BodyComponent>
  );
};

export default AdminCampaignFormPage;
