import FormControl from "@mui/material/FormControl/FormControl";
import FormLabel from "@mui/material/FormLabel/FormLabel";
import TextField from "@mui/material/TextField/TextField";
import styled from "@mui/material/styles/styled";
import { Controller } from "react-hook-form";

const StyledTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    height: "7vh",
    fontWeight: "600",
    marginTop: "1vh",
    "& fieldset": {
      borderRadius: "35px",
      borderColor: "#F0F4F8",
    },
    "&:hover fieldset": {
      borderRadius: "35px",
      borderColor: "#F0F4F8",
    },
    "&.Mui-focused fieldset": {
      borderRadius: "35px",
      borderColor: "#D8DBDF",
    },
    "&.Mui-error fieldset": {
      borderRadius: "35px",
      borderColor: "red",
    },
    "&.Mui-disabled fieldset": {
      borderRadius: "35px",
      borderColor: "#F0F4F8",
    },
  },
});

const InputComponent = ({
  name,
  label,
  placeholder,
  control,
  disabled,
  type,
}) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, formState }) => (
        <FormControl fullWidth>
          {!!label && (
            <FormLabel
              style={{
                fontWeight: "600",
                fontSize: "1.75vh",
                color: "#5C5C5C",
                textAlign: "start",
                marginTop: "3vh",
              }}
            >
              {label}
            </FormLabel>
          )}
          <StyledTextField
            {...field}
            variant="outlined"
            type={type}
            placeholder={placeholder}
            disabled={disabled || formState.isSubmitting}
            error={!!formState.errors[name]}
            helperText={formState.errors[name]?.message}
            style={{
              backgroundColor: "inherit",
              color: "#5C5C5C",
            }}
            InputLabelProps={{
              style: {
                color: !!formState.errors[name] ? "red" : "inherit",
              },
            }}
          />
        </FormControl>
      )}
    />
  );
};

export default InputComponent;
