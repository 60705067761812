import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import { useAlert, useAuth } from "../../../../providers";
import {
  bankService,
  campaignService,
  proposalService,
  userService,
} from "../../../../../infrastructure/services";
import { Constants, Format, Validate } from "../../../../utils";
import moment from "moment";
import { useLoadingContext } from "../../../../context/loading.context";
import dayjs from "dayjs";

const useAdminProposalsController = () => {
  const situations = [
    Constants.defaultSelect,
    { id: 1, label: "PENDENTE" },
    { id: 2, label: "APROVADO" },
    { id: 3, label: "REPROVADO" },
  ];
  const { user } = useAuth();
  const { showError } = useAlert();

  const rowsPerPage = 10;
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [proposals, setProposals] = useState([]);
  const [campaigns, setCampaigns] = useState([Constants.defaultSelect]);
  const [users, setUsers] = useState([Constants.defaultSelect]);
  const [banks, setBanks] = useState([Constants.defaultSelect]);
  const [filterIsLoaded, setFilterIsLoaded] = useState(false);
  const [lastQuery, setLastQuery] = useState(null);
  const { setLoadingState } = useLoadingContext();
  const [counters, setCounters] = useState({
    pending: 0,
    approved: 0,
    disapproved: 0,
  });

  const schema = yup.object().shape(
    {
      clientName: yup
        .string()
        .optional()
        .when("clientName", {
          is: (value) => value?.length,
          then: (rule) => rule.min(3, "O nome deve ter no mínimo 3 caracteres"),
        }),
      clientCpf: yup
        .string()
        .optional()
        .when("clientCpf", {
          is: (value) => value?.length,
          then: (rule) => Validate.cpf,
        }),
      affiliated: yup.object().optional(),
      campaign: yup.object().optional(),
      bank: yup.object().optional(),
      situation: yup.object().optional(),
      proposalType: yup
        .string()
        .optional()
        .when("proposalType", {
          is: (value) => value?.length,
          then: (rule) =>
            rule.min(3, "O tipo de proposta deve ter no mínimo 3 caracteres"),
        }),
    },
    [
      ["clientName", "clientName"],
      ["clientCpf", "clientCpf"],
      ["proposalType", "proposalType"],
    ]
  );

  const { control, handleSubmit, getValues } = useForm({
    defaultValues: {
      clientName: "",
      clientCpf: "",
      affiliated: Constants.defaultSelect,
      campaign: Constants.defaultSelect,
      bank: Constants.defaultSelect,
      situation: Constants.defaultSelect,
      proposalType: "",
      startDate: "",
      endDate: "",
    },
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (!!user) {
      setLoadingState(true);
      campaignService
        .getUserCampaigns()
        .then((response) => {
          setCampaigns([
            Constants.defaultSelect,
            ...response.map((item) => ({ id: item.id, label: item.name })),
          ]);
        })
        .catch(showError)
        .finally(() => {
          userService
            .getAffilietdToReports({ id: user.id })
            .then((response) => {
              setUsers([
                Constants.defaultSelect,
                ...response.map((item) => ({
                  id: item.id,
                  label: item.displayName,
                })),
              ]);
            })
            .catch(showError)
            .finally(() => {
              bankService
                .getBanks()
                .then((response) => {
                  setBanks([
                    Constants.defaultSelect,
                    ...response.map((item) => ({
                      id: item.id,
                      label: item.name,
                    })),
                  ]);
                })
                .catch(showError)
                .finally(() => {
                  setLoadingState(false);
                  setFilterIsLoaded(true);
                });
            });
        });
    }
  }, [user]);

  useEffect(() => {
    if (
      users.length > 1 &&
      campaigns.length > 1 &&
      banks.length > 1 &&
      filterIsLoaded == true
    ) {
      onSearch({ page: page, ...getValues() });
    }
  }, [filterIsLoaded]);

  const onSubmit = handleSubmit((data) => {
    onSearch({ newPage: 0, ...data, isDownload: false });
  });

  const onDownloadSubmit = () => {
    onSearch({
      newPage: 0,
      ...getValues(),
      isDownload: true,
    });
  };

  const onSearch = ({
    newPage,
    clientName,
    clientCpf,
    campaign,
    affiliated,
    bank,
    situation,
    proposalType,
    startDate,
    endDate,
    isDownload,
  }) => {
    var query = {
      page: newPage ?? page,
      rowsPerPage: rowsPerPage,
    };

    if (!!clientName) {
      query.clienteNome = clientName;
    }

    if (!!clientCpf) {
      query.clienteCpf = clientCpf;
    }

    if (!!campaign && campaign.id != 0) {
      query.idProduto = campaign.id;
    }

    if (!!affiliated && affiliated.id != 0) {
      query.idAfiliado = affiliated.id;
    }

    if (!!bank && bank.id != 0) {
      query.idBanco = bank.id;
    }

    if (!!situation && situation.id != 0) {
      query.situacao = situation.label;
    }

    if (!!proposalType) {
      query.tipoProposta = proposalType;
    }

    if (!!startDate) {
      query.startDate = dayjs(startDate).format().substring(0, 10);
    }

    if (!!endDate) {
      query.endDate = dayjs(endDate).format().substring(0, 10);
    }

    if (isDownload == true) {
      query.baixar = 1;
    }

    if (newPage == 0) {
      setPage(0);
    }

    setLastQuery({
      clientName,
      clientCpf,
      campaign,
      affiliated,
      bank,
      situation,
      proposalType,
      startDate,
      endDate,
    });

    setLoadingState(true);
    proposalService
      .getProposals(query)
      .then((response) => {
        if (isDownload == true) {
          makeCsvData(response.data);
        } else {
          setProposals(response.data);
          setCount(response.count);
          setCounters(response.counters);
        }
      })
      .catch(showError)
      .finally(() => {
        setLoadingState(false);
      });
  };

  const makeCsvData = (data) => {
    if (data.length == 0) {
      return;
    }

    var csvRows = [];

    csvRows.push([
      "TIPO DE PROPOSTA",
      "CLIENTE",
      "AFILIADO",
      "CAMPANHA",
      "CATEGORIA",
      "SITUAÇÃO",
    ]);

    data.map((row) => {
      csvRows.push([
        row.proposalType,
        row.clientName,
        row.affiliatedName,
        row.campaignName,
        row.campaignCategory,
        row.situation,
      ]);
    });

    const csvString = csvRows.join("\n");

    Format.downloadCsv(csvString, "propostas");
  };

  const handleChangePage = (newPage) => {
    setPage(newPage);
    onSearch({ newPage, ...lastQuery });
  };

  return {
    proposals,
    count,
    page,
    rowsPerPage,
    handleChangePage,
    control,
    onSubmit,
    userOptions: users,
    campaignOptions: campaigns,
    situationOptions: situations,
    bankOptions: banks,
    onDownloadSubmit,
    counters,
  };
};

export default useAdminProposalsController;
