import httpClient from "./axios.config";

class BankService {
  getBanks = () => {
    return new Promise((resolve, reject) => {
      httpClient
        .get("/utilidades/bancos")
        .then((response) => {
          const banksResponse = response.data.response;

          resolve(
            banksResponse.map((banco) => ({
              id: parseInt(banco.idBanco),
              name: banco.nome.substring(4, banco.nome.length),
              describle: banco.nome,
              number: parseInt(banco.numero),
            }))
          );
        })
        .catch((error) =>
          reject(
            error.response && error.response.data && error.response.data.message
              ? error.response.data.message
              : "Erro inesperado, tente novamente mais tarde."
          )
        );
    });
  };
  addBank = (form) => {
    return new Promise((resolve, reject) => {
      httpClient
        .post("/utilidades/bancos", form)
        .then(() => resolve())
        .catch((error) =>
          reject(
            error.response && error.response.data && error.response.data.message
              ? error.response.data.message
              : "Erro inesperado, tente novamente mais tarde."
          )
        );
    });
  };
  editBank = (form) => {
    return new Promise((resolve, reject) => {
      httpClient
        .put("/utilidades/bancos", form)
        .then(() => resolve())
        .catch((error) =>
          reject(
            error.response && error.response.data && error.response.data.message
              ? error.response.data.message
              : "Erro inesperado, tente novamente mais tarde."
          )
        );
    });
  };
}

export default new BankService();
