import Divider from "@mui/material/Divider/Divider";
import Grid from "@mui/material/Grid/Grid";
import Typography from "@mui/material/Typography/Typography";

import {
  InputFilterComponent,
  SelectComponent,
} from "../../../../../components";

const ExternalSectionComponent = ({ control, withLinkOptions, isLoading }) => {
  return (
    <div>
      <Typography
        style={{
          fontWeight: "600",
          fontSize: "1.75vh",
          color: "#5C5C5C",
        }}
      >
        INFORMAÇÕES ADICIONAIS
      </Typography>
      <Divider style={{ marginBottom: "2vh" }} />
      <Grid container spacing={2}></Grid>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid container spacing={2} style={{ alignItems: "center" }}>
            <Grid item xs={4}>
              <InputFilterComponent
                name="externalLink"
                control={control}
                label="LINK EXTERNO"
                placeholder="LINK EXTERNO"
                disabled={isLoading}
              />
            </Grid>
            <Grid item xs={4}>
              <InputFilterComponent
                name="shareId"
                control={control}
                label="ID DE COMPARTILHAMENTO"
                placeholder="ID DE COMPARTILHAMENTO"
                disabled={isLoading}
              />
            </Grid>
            <Grid item xs={4}>
              <SelectComponent
                name="withLink"
                control={control}
                label="COM LINK?"
                acessor="label"
                options={withLinkOptions}
                disabled={isLoading}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default ExternalSectionComponent;
