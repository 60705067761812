import { useNavigate } from "react-router-dom";
import {
  BodyComponent,
  ButtonComponent,
  TableComponent,
} from "../../../../components";
import useAdminCampaignsController from "./admin-campaigns.controller";

const AdminCampaignsPage = () => {
  const navigate = useNavigate();
  const { campaigns, categories } = useAdminCampaignsController();

  return (
    <BodyComponent>
      <div style={{ marginBottom: "2vh", width: "71vw", textAlign: "end" }}>
        <ButtonComponent
          label="CRIAR NOVA CAMPANHA"
          backgroundColor="#40215F"
          color="white"
          size="small"
          height="4vh"
          onClick={() => navigate("/admin/campanhas/formulario")}
        />
      </div>
      {campaigns.length > 0 ? (
        <TableComponent
          columns={[
            {
              label: "PRODUTO",
              acessor: "name",
              filter: true,
            },
            {
              label: "CATEGORIA",
              acessor: "category",
              formatText: (row) => row["category"]["name"],
              selectFilter: true,
              selectData: categories,
            },
            {
              label: "INTERNO",
              acessor: "isIntern",
              formatText: (row) => (row["isIntern"] ? "SIM" : "NÃO"),
            },
            {
              label: "SITUAÇÃO",
              acessor: "situation",
              selectFilter: true,
              selectData: [
                { label: "TODAS", id: 0 },
                { label: "ATIVA", id: true },
                { label: "INATIVA", id: false },
              ],
              formatText: (row) => (row["situation"] ? "ATIVA" : "INATIVA"),
            },
            {
              label: "COM LINK",
              acessor: "withLink",
              formatText: (row) => (row["withLink"] ? "SIM" : "NÃO"),
            },
            { label: "ID EXTERNO", acessor: "shareId" },
          ]}
          rows={campaigns}
          onClick={(row) => navigate("/admin/campanhas/formulario/" + row.id)}
        />
      ) : (
        <div style={{ height: "60vh" }}></div>
      )}
    </BodyComponent>
  );
};

export default AdminCampaignsPage;
