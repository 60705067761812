import { useState } from "react";
import Divider from "@mui/material/Divider/Divider";
import Grid from "@mui/material/Grid/Grid";
import Typography from "@mui/material/Typography/Typography";

import {
  BannerPickerComponent,
  ButtonComponent,
  InputFilterComponent,
  InputFormatComponent,
  SelectComponent,
  TableComponent,
} from "../../../../../components";
import ChangeCommissionDialogComponent from "./change-commission-dialog.component";
import { useDialog } from "../../../../../providers";
import { Format } from "../../../../../utils";

const BasicSectionComponent = ({
  control,
  categoryOptions,
  isInternOptions,
  isActiveOptions,
  editImage,
  isToEdit,
  commissions,
  onChangeCommissionToProfile,
  commissionTypeOptions,
  commissionType,
  isIntern,
  isLoading,
}) => {
  const { openDialog, closeDialog } = useDialog();
  const [changeCommissions, setChangeCommissions] = useState(false);
  const [commissionToChange, setCommissionToChange] = useState(null);

  const openChangeCommissionForm = (row) => {
    setCommissionToChange(row);
    openDialog("change-commission-dialog");
  };

  const handleChangeCommission = () => {
    var form = {
      id: commissionToChange.id,
      valor: Format.currencyToFloat(commissionToChange.commission),
      tipoComissao: commissionToChange.commissionType,
      tipo: "perfil",
    };

    if (commissionToChange.commissionType == 2) {
      form["valor"] = commissionToChange.commission.replace(" %", "");
    }

    onChangeCommissionToProfile({
      form: form,
      callback: () => {
        closeDialog();
      },
    });
  };

  return (
    <div>
      <ChangeCommissionDialogComponent
        form={commissionToChange}
        onChangeCommission={(value) =>
          setCommissionToChange({
            ...commissionToChange,
            commission: value,
          })
        }
        onChangeCommissionType={(value) =>
          setCommissionToChange({
            ...commissionToChange,
            commissionType: value,
          })
        }
        onSubmit={handleChangeCommission}
        commissionType={commissionType}
        commissionTypeOptions={commissionTypeOptions}
      />
      <Typography
        style={{
          fontWeight: "600",
          fontSize: "1.75vh",
          color: "#5C5C5C",
        }}
      >
        INFORMAÇÕES BÁSICAS
      </Typography>
      <Divider style={{ marginBottom: "2vh" }} />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <InputFilterComponent
                name="name"
                control={control}
                label="NOME DA CAMPANHA"
                placeholder="NOME DA CAMPANHA"
                disabled={isLoading}
              />
            </Grid>
            <Grid item xs={4}>
              <SelectComponent
                name="category"
                control={control}
                label="CATEGORIA"
                acessor="label"
                options={categoryOptions}
                disabled={isLoading}
              />
            </Grid>
            <Grid item xs={4}>
              <SelectComponent
                name="isIntern"
                control={control}
                label="CAMPANHA INTERNA?"
                acessor="label"
                options={isInternOptions}
                disabled={isToEdit || isLoading}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <SelectComponent
                name="commissionType"
                control={control}
                label="TIPO DE COMISSÃO"
                acessor="label"
                options={commissionTypeOptions}
                disabled={isLoading}
              />
            </Grid>
            <Grid item xs={4}>
              <InputFormatComponent
                name="commission"
                control={control}
                label="COMISSÃO DA CASA"
                placeholder="COMISSÃO DA CASA"
                format={commissionType == 1 ? "R$" : "%"}
                disabled={isLoading}
              />
            </Grid>
            <Grid item xs={4}>
              <SelectComponent
                name="isActive"
                control={control}
                label="CAMPANHA ATIVA?"
                acessor="label"
                options={isActiveOptions}
                disabled={isLoading}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <BannerPickerComponent
                name="logo"
                control={control}
                label="LOGO"
                onUpload={editImage}
                disabled={isLoading}
              />
            </Grid>
            <Grid item xs={9}>
              <InputFilterComponent
                name="describle"
                control={control}
                label="DESCRIÇÃO DA CAMPANHA"
                placeholder="DESCRIÇÃO DA CAMPANHA"
                multiline={true}
                minRows={7}
                disabled={isLoading}
              />
            </Grid>
          </Grid>
        </Grid>
        {isToEdit && (
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={9}>
                {changeCommissions && (
                  <TableComponent
                    width="100%"
                    onClick={
                      isLoading == false
                        ? (row) => openChangeCommissionForm(row)
                        : null
                    }
                    columns={[
                      { label: "PERFIL", acessor: "profile" },
                      {
                        label: "REMUNERAÇÃO",
                        acessor: "commission",
                        formatText: (row) => {
                          if (row.commissionType == 1) {
                            return row.commission.toLocaleString("pt-BR", {
                              style: "currency",
                              currency: "BRL",
                            });
                          } else {
                            return row.commission + "%";
                          }
                        },
                      },
                    ]}
                    rows={commissions}
                  />
                )}
              </Grid>
              <Grid item xs={3}>
                <ButtonComponent
                  fullWidth
                  label={"ALTERAR REMUNERAÇÃO POR PERFIL"}
                  backgroundColor="#40215F"
                  color="white"
                  size="small"
                  height="5vh"
                  disabled={isLoading}
                  onClick={() => setChangeCommissions(!changeCommissions)}
                />
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    </div>
  );
};

export default BasicSectionComponent;
