import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { useAlert, useAuth, useDialog } from "../../../providers";
import { cityService, userService } from "../../../../infrastructure/services";
import { Constants } from "../../../utils";
import { useLoadingContext } from "../../../context/loading.context";

const useSignInController = () => {
  const { login, redirectToRolePage } = useAuth();
  const { showError, showInfo, showSuccess } = useAlert();
  const { openDialog, closeDialog } = useDialog();
  const { setLoadingState } = useLoadingContext();
  const [cities, setCities] = useState([Constants.defaultSelectPlaceholder]);
  const [redirect, setRedirect] = useState([]);
  const location = useLocation();

  const schemaForgot = yup.object().shape({
    username: yup.string().required("Campo obrigatório"),
  });

  const schemaAddress = yup.object().shape({
    cidade: yup
      .number()
      .required("Campo obrigatório")
      .test("invalid", "Selecione uma das opções", (value) => {
        return value > 0;
      }),
    estado: yup.string().required("Campo obrigatório"),
  });

  const schema = yup.object().shape({
    username: yup.string().required("Campo obrigatório"),
    password: yup.string().required("Campo obrigatório"),
  });

  const {
    control: forgotForm,
    handleSubmit: handleForgotSubmit,
    setValue: setValueForgot,
    reset: resetForgot,
  } = useForm({
    defaultValues: {
      username: "",
    },
    resolver: yupResolver(schemaForgot),
  });

  const {
    control: addressForm,
    handleSubmit: handleAddressSubmit,
    setValue: setValueAddress,
    watch: watchAddress,
    reset: resetAddress,
  } = useForm({
    defaultValues: {
      estado: Constants.states[0].id,
      cidade: Constants.defaultSelectPlaceholder.id,
    },
    resolver: yupResolver(schemaAddress),
  });

  const { control, handleSubmit, setValue } = useForm({
    defaultValues: {
      username: "",
      password: "",
      reCaptcha: "",
    },
    resolver: yupResolver(schema),
  });

  const onSubmit = handleSubmit(async (data) => {
    if (data.reCaptcha === "") {
      showInfo(
        "O uso do reCAPTCHA é obrigatório. Por favor, complete o desafio para continuar."
      );
    } else {
      var usuario = await login(data);

      if (usuario && usuario.enderecoPendente) {
        openDialog("address-dialog");
      }
    }
  });

  useEffect(() => {
    getCities({ state: Constants.states[0].label });
  }, [location]);

  useEffect(() => {
    const subscription = watchAddress((value, { name, type }) => {
      if (name == "estado") {
        if (value.estado != null) {
          getCities({
            state: Constants.states.find((state) => state.id == value.estado)
              .label,
          });
        }
      }
    });

    return () => subscription.unsubscribe();
  }, [watchAddress]);

  const getCities = ({ state }) => {
    setLoadingState(true);
    cityService
      .getCities({ state: state })
      .then((response) => {
        setCities([
          Constants.defaultSelectPlaceholder,
          ...response.map((item) => ({
            id: item.id,
            label: item.name,
          })),
        ]);
        setValue("cidade", Constants.defaultSelectPlaceholder.id);
      })
      .catch(showError)
      .finally(() => {
        setLoadingState(false);
      });
  };

  const openDialogForgot = () => {
    openDialog("forgot-dialog");
  };

  const onForgot = () => {
    handleForgotSubmit((data) => {
      userService
        .forgot(data)
        .then((res) => {
          resetForgot();
          closeDialog("forgot-dialog");
          showSuccess(
            "Caso o E-MAIL ou CPF esteja cadastrado, você receberá uma mensagem no seu e-mail com as instruções para definir uma nova senha."
          );
        })
        .catch(showError);
    })();
  };

  const onSubmitAddress = () => {
    handleAddressSubmit((data) => {
      setLoadingState(true);
      userService
        .cadastroEndereco({
          userid: redirect.userid,
          cidade: data.cidade,
        })
        .then(() => {
          showSuccess("Endereço cadastrado!");
          setLoadingState(false);
          redirectToRolePage(
            !!redirect.produtosInternos ? "ANUNCIANTE" : redirect.tipoUsuario
          );
        })
        .catch(showError)
        .finally(() => {
          setLoadingState(false);
        });
    })();
  };

  return {
    control,
    onSubmit,
    setValue,
    openDialogForgot,
    forgotForm,
    onForgot,
    addressForm,
    onSubmitAddress,
    estados: Constants.states,
    cidades: cities,
  };
};

export default useSignInController;
