import React from "react";
import moment from "moment";
import { pdf } from "@react-pdf/renderer";

import {
  campaignService,
  financialService,
  userService,
} from "../../../../../infrastructure/services";
import { Constants, Format } from "../../../../utils";
import { useIsMounted } from "../../../../hooks";
import { useAlert, useAuth, useDialog } from "../../../../providers";
import { ReceiptForPaymentsComponent } from "../../../../components";
import { useLoadingContext } from "../../../../context/loading.context";
import { useLocation, useSearchParams } from "react-router-dom";
import dayjs from "dayjs";

const useAdminPaymentsController = () => {
  const situationOptions = [
    { id: 0, label: "TODOS" },
    { id: 1, label: "PAGOS" },
    { id: 2, label: "NAO-PAGOS" },
  ];

  const { showError, showSuccess } = useAlert();
  const { user } = useAuth();
  const location = useLocation();
  let [searchParams, setSearchParams] = useSearchParams();
  const isMounted = useIsMounted();
  const { openDialog, closeDialog } = useDialog();
  const [campaigns, setCampaigns] = React.useState([Constants.defaultSelect]);
  const [users, setUsers] = React.useState([Constants.defaultSelect]);
  const [payments, setPayments] = React.useState([]);
  const [selectedPayments, setSelectedPayments] = React.useState([]);
  const [previousSearchQuery, setPreviousSearchQuery] = React.useState(null);
  const [isToAntecipate, setIsToAntecipate] = React.useState(false);
  const rowsPerPage = 10;
  const [count, setCount] = React.useState(0);
  const [page, setPage] = React.useState(0);
  const { setLoadingState } = useLoadingContext();

  React.useEffect(() => {
    if (user != null) {
      setLoadingState(true);
      campaignService
        .getUserCampaigns()
        .then((response) => {
          if (isMounted.current) {
            setCampaigns([
              Constants.defaultSelect,
              ...response.map((item) => ({ id: item.id, label: item.name })),
            ]);
          }
        })
        .catch(showError)
        .finally(() => {
          setLoadingState(false);
        });
      setLoadingState(true);
      userService
        .getAffilietdToReports({ id: user.id })
        .then((response) => {
          if (isMounted.current) {
            const newUsers = [
              Constants.defaultSelect,
              ...response.map((item) => ({
                id: item.id,
                label: item.displayName,
              })),
            ];

            setUsers(newUsers);

            if (
              !!searchParams.get("startDate") &&
              !!searchParams.get("endDate") &&
              !!searchParams.get("situacao") &&
              !!searchParams.get("usuarioId")
            ) {
              setIsToAntecipate(true);
              onSearch({
                startDate: searchParams.get("startDate"),
                endDate: searchParams.get("endDate"),
                user: { id: searchParams.get("usuarioId") },
                campaign: null,
                situation: searchParams.get("situacao"),
              });
            }
          }
        })
        .catch(showError)
        .finally(() => {
          setLoadingState(false);
        });
    }
  }, [user, location]);

  const onSearch = ({
    newPage,
    startDate,
    endDate,
    user,
    campaign,
    situation,
    isDownload,
  }) => {
    const query = {
      startDate: dayjs(startDate).format().substring(0, 10),
      endDate: dayjs(endDate).format().substring(0, 10),
      situacao: situation.label,
      page: newPage ?? page,
      rowsPerPage: rowsPerPage,
    };

    if (!!campaign && campaign.id != 0) {
      query.offerId = campaign.id;
    }

    if (!!user && user.id !== 0) {
      query.usuarioId = user.id;
    }

    if (newPage == 0) {
      setPage(0);
    }

    if (isDownload == true) {
      query.baixar = 1;
    }

    setPreviousSearchQuery({ startDate, endDate, user, campaign, situation });
    setLoadingState(true);
    financialService
      .getPayments(query)
      .then((response) => {
        if (isDownload == true) {
          makeCsvData(response.data);
        } else {
          setPayments(response.data);
          setCount(response.count);
          setSelectedPayments([]);
        }
      })
      .catch(showError)
      .finally(() => {
        setLoadingState(false);
      });
  };

  const makeCsvData = (payments) => {
    if (payments.length == 0) {
      return;
    }

    var csvRows = [];

    csvRows.push([
      "PRODUTO",
      "USUÁRIO",
      "DATA",
      "GANHO DA RIZOMA",
      "GANHO DO USUÁRIO",
      "PAGO",
    ]);

    payments.map((row) => {
      csvRows.push([
        row.campaign,
        row.user.name,
        row.eventDate,
        Format.toCurrency(row.commission),
        Format.toCurrency(row.payment),
        row.paidOut == true ? "Sim" : "Não",
      ]);
    });

    const csvString = csvRows.join("\n");

    Format.downloadCsv(csvString, "pagamentos");
  };

  const onSelectedPayments = (payments) => {
    setSelectedPayments(payments);
  };

  const isValidSelectedPaymentsToPay = () => {
    if (selectedPayments.length == 0) return false;

    const withPaidOut =
      selectedPayments.filter(
        (selectedPayment) => selectedPayment.paidOut == true
      ).length > 0;

    if (withPaidOut) return false;

    return true;
  };

  const onPayUser = () => {
    makeReceipts().then((urls) => {
      openDialog("receipt-for-payments-dialog", urls);
    });
  };

  const onConfirmPayUser = () => {
    const paymentsId = selectedPayments.map(
      (selectedPayment) => selectedPayment.id
    );
    setLoadingState(true);
    financialService
      .confirmPayments({ paymentsId: paymentsId.join(",") })
      .then(() => {
        closeDialog();
        setSelectedPayments([]);
        onSearch(previousSearchQuery);
        showSuccess("Os pagamentos foram confirmados!");
      })
      .catch(showError)
      .finally(() => {
        setLoadingState(false);
      });
  };

  const makeReceipts = async () => {
    let users = {};

    selectedPayments.forEach((payment) => {
      users[payment.user.id] = payment.user;
    });

    return await Promise.all(
      Object.values(users).map((user) => {
        return makeReceiptForPaymentsByUser(user);
      })
    );
  };

  const makeReceiptForPaymentsByUser = async (user) => {
    var payments = [];

    var paymentSubTotal = 0;
    var commissionSubTotal = 0;

    selectedPayments.forEach((selectedPayment) => {
      if (selectedPayment.user.id == user.id) {
        payments.push({
          campaign: selectedPayment.campaign,
          eventDate: selectedPayment.eventDate,
          commission: Format.toCurrency(selectedPayment.commission),
          payment: Format.toCurrency(selectedPayment.payment),
        });

        paymentSubTotal += selectedPayment.payment;
        commissionSubTotal += selectedPayment.commission;
      }
    });

    const discounts = [
      {
        describle: "Antecipação da comissão",
        eventDate: dayjs(new Date()).format("D/MM/YYYY").substring(0, 10),
        reference: Format.toCurrency(20),
        value: Format.toCurrency(20),
      },
    ];

    const discountsSubTotal = discounts
      .map((e) => Format.currencyToFloat(e.value))
      .reduce((a, b) => a + b, 0);

    const blob = await pdf(
      <ReceiptForPaymentsComponent
        title={
          "Recibo de pagamento de - " +
          user.name +
          ", " +
          dayjs(new Date()).format("D/MM/YYYY").substring(0, 10)
        }
        user={{
          name: user.name,
          pix: user.paymentData.pix,
          cpf: user.paymentData.cpf,
          email: user.paymentData.email,
          eventDate: dayjs(new Date()).format("D/MM/YYYY").substring(0, 10),
          bank: user.paymentData.bank,
          bankAgency: user.paymentData.bankAgency,
          bankAccount: user.paymentData.bankAccount,
          bankAccountType: user.paymentData.bankAccountType,
        }}
        payments={payments}
        values={{
          paymentSubTotal: Format.toCurrency(paymentSubTotal),
          commissionSubTotal: Format.toCurrency(commissionSubTotal),
          discountsSubTotal: Format.toCurrency(discountsSubTotal),
          paymentTotal: isToAntecipate
            ? Format.toCurrency(paymentSubTotal - discountsSubTotal)
            : Format.toCurrency(paymentSubTotal),
        }}
        hasDiscounts={isToAntecipate}
        discounts={[
          {
            describle: "Antecipação da comissão",
            eventDate: dayjs(new Date()).format("D/MM/YYYY").substring(0, 10),
            reference: Format.toCurrency(20),
            value: Format.toCurrency(20),
          },
        ]}
      />
    ).toBlob();
    const url = URL.createObjectURL(blob);
    return url;
  };

  const handleChangePage = (newPage) => {
    setPage(newPage);
    onSearch({ newPage, ...previousSearchQuery });
  };

  return {
    payments,
    campaigns,
    users,
    onSearch,
    onPayUser,
    isValidSelectedPaymentsToPay,
    situationOptions,
    onSelectedPayments,
    onConfirmPayUser,
    handleChangePage,
    page,
    count,
    rowsPerPage,
  };
};

export default useAdminPaymentsController;
