import Divider from "@mui/material/Divider/Divider";
import Grid from "@mui/material/Grid/Grid";
import Typography from "@mui/material/Typography/Typography";

import { InputFilterComponent } from "../../../../../components";

const SocialSectionComponent = ({ advertiser }) => {
  return (
    <div>
      <Typography
        style={{
          fontWeight: "600",
          fontSize: "1.75vh",
          color: "#5C5C5C",
        }}
      >
        SOCIAL
      </Typography>
      <Divider style={{ marginBottom: "2vh" }} />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <InputFilterComponent
                value={advertiser?.siteUrl ?? ""}
                disabled={true}
                label="SITE DA EMPRESA"
                placeholder="SITE DA EMPRESA"
              />
            </Grid>
            <Grid item xs={4}>
              <InputFilterComponent
                value={advertiser?.facebookUrl ?? ""}
                disabled={true}
                label="FACEBOOK DA EMPRESA"
                placeholder="FACEBOOK DA EMPRESA"
              />
            </Grid>
            <Grid item xs={4}>
              <InputFilterComponent
                value={advertiser?.instagramUrl ?? ""}
                disabled={true}
                label="INSTAGRAM DA EMPRESA"
                placeholder="INSTAGRAM DA EMPRESA"
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default SocialSectionComponent;
