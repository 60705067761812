import Divider from "@mui/material/Divider/Divider";
import Grid from "@mui/material/Grid/Grid";
import Typography from "@mui/material/Typography/Typography";

import {
  BannerPickerComponent,
  InputFilterComponent,
} from "../../../../../components";

const BasicSectionComponent = ({ advertiser }) => {
  return (
    <div>
      <Typography
        style={{
          fontWeight: "600",
          fontSize: "1.75vh",
          color: "#5C5C5C",
        }}
      >
        DADOS BÁSICOS
      </Typography>
      <Divider style={{ marginBottom: "2vh" }} />
      <Grid container>
        <Grid item xs={3}>
          <BannerPickerComponent
            label="SEM DOCUMENTO"
            disabled={true}
            value={advertiser?.documentUrl ?? null}
          />
        </Grid>
        <Grid item xs={9}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <InputFilterComponent
                    value={advertiser?.userName ?? ""}
                    disabled={true}
                    label="NOME DA PESSOA"
                    placeholder="NOME DA PESSOA"
                  />
                </Grid>
                <Grid item xs={4}>
                  <InputFilterComponent
                    value={advertiser?.email ?? ""}
                    disabled={true}
                    label="EMAIL PARA CONTATO"
                    placeholder="EMAIL PARA CONTATO"
                  />
                </Grid>
                <Grid item xs={4}>
                  <InputFilterComponent
                    value={advertiser?.phone ?? ""}
                    disabled={true}
                    label="TELEFONE PARA CONTATO"
                    placeholder="TELEFONE PARA CONTATO"
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <InputFilterComponent
                    value={advertiser?.companyName ?? ""}
                    disabled={true}
                    label="NOME DA EMPRESA"
                    placeholder="NOME DA EMPRESA"
                  />
                </Grid>
                <Grid item xs={8}>
                  <InputFilterComponent
                    value={advertiser?.address ?? ""}
                    disabled={true}
                    label="ENDEREÇO DA EMPRESA"
                    placeholder="ENDEREÇO DA EMPRESA"
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default BasicSectionComponent;
