import React from "react";
import Slider from "@mui/material/Slider/Slider";
import TextField from "@mui/material/TextField/TextField";
import Typography from "@mui/material/Typography/Typography";
import makeStyles from "@mui/styles/makeStyles/makeStyles";
import withStyles from "@mui/styles/withStyles/withStyles";

import { GenericDialogComponent } from "../../../../components";
import { Format } from "../../../../utils";
import AlterarComissaoImage from "../../../../../assets/imagens/alterar-comissao.png";

const PrettoSlider = withStyles({
  root: {
    color:
      "linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), linear-gradient(96.51deg, #40215F , #774E98)",
    height: "1.56vh",
  },
  thumb: {
    height: "3vh",
    width: "3vh",
    background: "linear-gradient(178.18deg, #FD749B -13.56%, #281AC8 158.3%)",
    opacity: "0.2",
    marginTop: -7,
    marginLeft: -14,
    "&:focus, &:hover, &$active": {
      boxShadow:
        "1.3vh 1.3vh 1.3vh 1.3vh linear-gradient(178.18deg, #FD749B -13.56%, #281AC8 158.3%)",
      opacity: "0.2",
    },
  },
  active: {},
  track: {
    height: "1.56vh",
    borderRadius: "10px",
  },
  rail: {
    height: "1.56vh",
    borderRadius: "10px",
  },
})(Slider);

const useStyles = makeStyles(() => ({
  content: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: "1vh",
    marginBottom: "2vh",
  },
  title: {
    marginBottom: "6vh",
    fontWeight: "700",
    fontSize: "1.95vh",
    color: "#40215F",
  },
  currentValue: {
    marginBottom: "2vh",
    fontWeight: "700",
    fontSize: "1.95vh",
    color: "#40215F",
  },
}));

const CommissionDialogComponent = ({
  commissionForm,
  onChangeCommission,
  percent,
  setPercent,
}) => {
  const classes = useStyles();

  return (
    <GenericDialogComponent name="commission-dialog">
      <div className={classes.content}>
        {!!commissionForm && !!commissionForm.affiliate ? (
          <img
            src={AlterarComissaoImage}
            style={{ marginBottom: "6vh", width: "100%" }}
          />
        ) : (
          <Typography className={classes.title} style={{ width: "100%" }}>
            ALTERAR COMISSÃO PADRÃO PARA ESTE PRODUTO
          </Typography>
        )}
        {!!commissionForm && !!commissionForm.affiliate && (
          <TextField
            variant="outlined"
            value={commissionForm?.affiliate.displayName ?? ""}
            disabled
            label="USUÁRIO"
            style={{
              width: "100%",
            }}
            InputProps={{
              style: {
                borderRadius: "10px",
                color: "#5C5C5C",
                fontWeight: "700",
                fontSize: "1.46vh",
              },
            }}
            InputLabelProps={{
              style: {
                color: "#5C5C5C",
                fontWeight: "700",
                fontSize: "1.46vh",
              },
            }}
          />
        )}

        <Typography
          className={classes.currentValue}
          style={{
            marginTop:
              !!commissionForm && !!commissionForm.affiliate ? "4vh" : 0,
          }}
        >
          {commissionForm?.commission?.type == "R$"
            ? Format.percentToCurrency(
                percent,
                commissionForm?.commission?.receivable ?? 0
              ).toLocaleString("pt-BR", {
                style: "currency",
                currency: "BRL",
              })
            : percent + "%"}
        </Typography>

        <div style={{ width: "100%" }}>
          <PrettoSlider
            valueLabelDisplay="off"
            defaultValue={commissionForm?.commission?.payablePercent ?? 0}
            onChange={(e, v) => setPercent(v)}
            onChangeCommitted={(e, v) => onChangeCommission(v)}
          />
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Typography
              style={{
                color: "#5C5C5C",
                fontWeight: "700",
                fontSize: "1.17vh",
              }}
            >
              {commissionForm?.commission?.type == "R$" ? "R$ 0,00" : "0%"}
            </Typography>
            <Typography
              style={{
                color: "#5C5C5C",
                fontWeight: "700",
                fontSize: "1.17vh",
              }}
            >
              {commissionForm?.commission?.type == "R$"
                ? Format.toCurrency(commissionForm?.commission?.receivable ?? 0)
                : "100%"}
            </Typography>
          </div>
        </div>
      </div>
    </GenericDialogComponent>
  );
};

export default CommissionDialogComponent;
