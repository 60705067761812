import Grid from "@mui/material/Grid/Grid";
import Typography from "@mui/material/Typography/Typography";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

import { ButtonGradientComponent } from "../../../../../components";
import { useResponsive } from "../../../../../providers";

const SectionComponent = ({
  bannerUrl,
  buttonLabel,
  buttonOnClick,
  describle,
  points,
  isReverse,
}) => {
  const { isDesktop } = useResponsive();

  const Banner = () => {
    return (
      <Grid
        item
        xs={isDesktop ? 6 : 12}
        style={{ textAlign: "start", marginTop: "5vh" }}
      >
        <img
          src={bannerUrl}
          style={{
            maxWidth: isDesktop ? "25vw" : "90vw",
            width: "100%",
            height: "auto",
          }}
        />
      </Grid>
    );
  };

  const Content = () => {
    return (
      <Grid item xs={isDesktop ? 6 : 12}>
        <Grid container direction="column">
          <Grid item style={{ marginTop: "5vh", marginBottom: "5vh" }}>
            {describle}
          </Grid>
          {points.map((point) => (
            <Grid
              item
              key={point}
              style={{ display: "flex", alignItems: "center" }}
            >
              <CheckCircleIcon
                fontSize="large"
                style={{ color: "#858585", marginRight: "1vw" }}
              />
              <Typography
                style={{
                  fontFamily: "sans-serif",
                  fontSize: "1.85vh",
                  color: "#858585",
                }}
              >
                {point}
              </Typography>
            </Grid>
          ))}
          <Grid item style={{ marginTop: "5vh" }}>
            <ButtonGradientComponent
              height="6vh"
              label={buttonLabel}
              onClick={buttonOnClick}
            />
          </Grid>
        </Grid>
      </Grid>
    );
  };
  return (
    <Grid
      container
      spacing={isDesktop ? 8 : 4}
      style={{ alignItems: "center" }}
    >
      {isReverse ? <Content /> : <Banner />}
      {isReverse ? <Banner /> : <Content />}
    </Grid>
  );
};

export default SectionComponent;
