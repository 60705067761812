import Divider from "@mui/material/Divider/Divider";
import Grid from "@mui/material/Grid/Grid";
import Typography from "@mui/material/Typography/Typography";

import {
  InputFormatComponent,
  SelectComponent,
} from "../../../../../components";

const CommissionSectionComponent = ({
  control,
  isLoading,
  commissionTypeOptions,
  affiliatedCommissionType,
  houseCommissionType,
}) => {
  return (
    <div>
      <Typography
        style={{
          fontWeight: "600",
          fontSize: "1.75vh",
          color: "#5C5C5C",
        }}
      >
        COMISSÕES DA PROPOSTA
      </Typography>
      <Divider style={{ marginBottom: "2vh" }} />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <SelectComponent
                name="houseCommissionType"
                control={control}
                label="TIPO DE COMISSÃO DA CASA"
                acessor="label"
                options={commissionTypeOptions}
                disabled={isLoading}
              />
            </Grid>
            <Grid item xs={6}>
              <InputFormatComponent
                name="houseCommission"
                control={control}
                label="COMISSÃO DA CASA"
                placeholder="COMISSÃO DA CASA"
                format={houseCommissionType == 1 ? "R$" : "%"}
                disabled={isLoading}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <SelectComponent
                name="affiliatedCommissionType"
                control={control}
                label="TIPO DE COMISSÃO DO AFILIADO"
                acessor="label"
                options={commissionTypeOptions}
                disabled={isLoading}
              />
            </Grid>
            <Grid item xs={6}>
              <InputFormatComponent
                name="affiliatedCommission"
                control={control}
                label="COMISSÃO DO AFILIADO"
                placeholder="COMISSÃO DO AFILIADO"
                format={affiliatedCommissionType == 1 ? "R$" : "%"}
                disabled={isLoading}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <InputFormatComponent
                name="value"
                control={control}
                label="VALOR DA PROPOSTA"
                placeholder="VALOR DA PROPOSTA"
                format="R$"
                disabled={isLoading}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default CommissionSectionComponent;
