import Divider from "@mui/material/Divider/Divider";
import Grid from "@mui/material/Grid/Grid";
import Typography from "@mui/material/Typography/Typography";

import {
  AutocompleteComponent,
  InputFilterComponent,
} from "../../../../../components";

const AddressSectionComponent = ({
  control,
  addressStateOptions,
  addressCityOptions,
  isLoading,
}) => {
  return (
    <div>
      <Typography
        style={{
          fontWeight: "600",
          fontSize: "1.75vh",
          color: "#5C5C5C",
        }}
      >
        ENDEREÇO
      </Typography>
      <Divider style={{ marginBottom: "2vh" }} />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <InputFilterComponent
                name="address"
                control={control}
                label="LOGRADOURO"
                placeholder="LOGRADOURO"
                disabled={isLoading}
              />
            </Grid>
            <Grid item xs={3}>
              <InputFilterComponent
                name="addressNumber"
                control={control}
                label="NÚMERO"
                placeholder="NÚMERO"
                disabled={isLoading}
              />
            </Grid>
            <Grid item xs={3}>
              <InputFilterComponent
                name="addressCep"
                control={control}
                label="CEP"
                placeholder="CEP"
                disabled={isLoading}
              />
            </Grid>
            <Grid item xs={3}>
              <InputFilterComponent
                name="addressDistrict"
                control={control}
                label="BAIRRO"
                placeholder="BAIRRO"
                disabled={isLoading}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <AutocompleteComponent
                name="addressState"
                control={control}
                label="UF"
                acessorLabel="name"
                acessorValue="id"
                options={addressStateOptions}
                disabled={isLoading}
              />
            </Grid>
            <Grid item xs={3}>
              <AutocompleteComponent
                name="addressCity"
                control={control}
                label="CIDADE"
                acessorLabel="name"
                acessorValue="id"
                options={addressCityOptions}
                disabled={isLoading}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default AddressSectionComponent;
