import { BodyComponent } from "../../../components";
import useHomeController from "./home.controller";
import FeaturedCampaignsComponent from "./components/featured-campaigns.component";
import HelpBannerComponent from "./components/help-banner.component";
import HighlightCampaignsComponent from "./components/highlight-campaigns.component";
import CampaignsComponent from "./components/campaigns.component";
import ChangeHighlightCampaignsDialogComponent from "./components/change-highlight-campaigns-dialog.component";
import ChangeFeaturedCampaignDialogComponent from "./components/change-featured-campaign-dialog.component";

const HomePage = () => {
  const {
    featuredCampaigns,
    highlightCampaigns,
    campaigns,
    onSelectHighlightCampaigns,
    isValidToSelectHighlightCampaigns,
    onChangeHightlightCampaigns,
    allCampaigns,
    campaignOptions,
    onChangeFeatureCampaign,
  } = useHomeController();

  return (
    <BodyComponent>
      <ChangeFeaturedCampaignDialogComponent
        campaignOptions={campaignOptions}
        campaigns={allCampaigns}
        onChangeFeatureCampaign={onChangeFeatureCampaign}
      />

      <ChangeHighlightCampaignsDialogComponent
        campaigns={allCampaigns}
        onSelectHighlightCampaigns={onSelectHighlightCampaigns}
        isValidToSelectHighlightCampaigns={isValidToSelectHighlightCampaigns}
        onChangeHightlightCampaigns={onChangeHightlightCampaigns}
      />

      <HelpBannerComponent />

      {featuredCampaigns.length > 0 && (
        <FeaturedCampaignsComponent featuredCampaigns={featuredCampaigns} />
      )}

      {highlightCampaigns.length > 0 && (
        <HighlightCampaignsComponent highlightCampaigns={highlightCampaigns} />
      )}

      {campaigns.length > 0 && <CampaignsComponent campaigns={campaigns} />}
    </BodyComponent>
  );
};

export default HomePage;
