import httpClient from "./axios.config";

class MenuService {
  getMenu = () => {
    return new Promise((resolve, reject) => {
      httpClient
        .get("/menu/menu")
        .then((response) => {
          const chartResponse = response.data.response;
          resolve(chartResponse);
        })
        .catch((error) =>
          reject(
            error.response && error.response.data && error.response.data.message
              ? error.response.data.message
              : "Erro inesperado, tente novamente mais tarde."
          )
        );
    });
  };

  setMenu = (query) => {
    return new Promise((resolve, reject) => {
      httpClient
        .post("/menu/menuAlterar", query)
        .then((response) => {
          const chartResponse = response.data.response;
          resolve(chartResponse);
        })
        .catch((error) =>
          reject(
            error.response && error.response.data && error.response.data.message
              ? error.response.data.message
              : "Erro inesperado, tente novamente mais tarde."
          )
        );
    });
  };
}

export default new MenuService();
