import React, { useState } from "react";
import Drawer from "@mui/material/Drawer/Drawer";
import List from "@mui/material/List/List";
import Toolbar from "@mui/material/Toolbar/Toolbar";
import Typography from "@mui/material/Typography/Typography";
import Box from "@mui/material/Box/Box";
import IconButton from "@mui/material/IconButton/IconButton";
import makeStyles from "@mui/styles/makeStyles/makeStyles";

import SidebarItemComponent from "./sidebar-item.component";
import { useAuth, useResponsive } from "../../../../providers";
import BeAnAdvertiserDialogComponent from "./be-an-advertiser-dialog.component";
import useSidebarController from "./sidebar.controller";

import WhatsAppIcon from "../../../../../assets/icones/whatsapp.svg";
import ArrowToRightIcon from "../../../../../assets/icones/arrow-to-right.svg";
import HomeIcon from "../../../../../assets/icones/home.svg";
import CampaignsIcon from "../../../../../assets/icones/campaigns.svg";
import AffiliatedIcon from "../../../../../assets/icones/affiliated.svg";
import DashboardIcon from "../../../../../assets/icones/dashboard.svg";
import ReportsIcon from "../../../../../assets/icones/reports.svg";
import FinancialIcon from "../../../../../assets/icones/financial.svg";
import HelpIcon from "../../../../../assets/icones/help.svg";

const useStyles = makeStyles(() => ({
  drawer: {
    width: "20.14vw",
    flexShrink: 0,
    zIndex: 1,
  },
  drawerPaper: {
    width: "20.14vw",
    zIndex: 1,
  },
  mobileDrawer: {
    width: "40.5vw",
    flexShrink: 0,
    zIndex: 1,
  },
  onlyIcon: {
    width: "4vw",
    flexShrink: 0,
    zIndex: 1,
  },
  onlyIconMobile: {
    width: "10vw",
    flexShrink: 0,
    zIndex: 1,
  },
  mobileDrawerPaper: {
    width: "14.5vw",
  },
  button: {
    position: "fixed",
    top: "30%",
    transform: "translateY(-50%)",
    zIndex: 2,
    width: "0",
    height: "150px",
    borderTop: "15px solid transparent",
    borderBottom: "15px solid transparent",
    borderLeft: "25px solid white",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  icon: {
    color: "white",
    fontSize: "24px",
    marginRight: "20px",
  },
}));

const SidebarComponent = () => {
  const classes = useStyles();
  const { hasPermission } = useAuth();
  const { isDesktop } = useResponsive();
  const { menu } = useSidebarController();

  const [isOnlyIcon, setOnlyIcon] = useState(!isDesktop);

  const mudarDrawer = () => {
    setOnlyIcon(!isOnlyIcon);
  };

  const paddingLeft = isDesktop
    ? isOnlyIcon
      ? "1.6vw"
      : "6vw"
    : isOnlyIcon
    ? "2.5vw"
    : "6vw";

  const drawerStyle = isDesktop
    ? isOnlyIcon
      ? classes.onlyIcon
      : classes.drawer
    : isOnlyIcon
    ? classes.onlyIconMobile
    : classes.mobileDrawer;

  return (
    <>
      <BeAnAdvertiserDialogComponent />
      <Drawer
        variant="permanent"
        className={drawerStyle}
        classes={{ paper: drawerStyle }}
      >
        <Toolbar />
        <List style={{ marginTop: "5.46vh" }}>
          {hasPermission("/home") && (
            <SidebarItemComponent
              label="HOME"
              iconAsset={HomeIcon}
              path="/home"
              isOnlyIcon={isOnlyIcon}
            />
          )}
          {hasPermission("/campanhas") && (
            <SidebarItemComponent
              label="CAMPANHAS"
              iconAsset={CampaignsIcon}
              path="/campanhas"
              isOnlyIcon={isOnlyIcon}
            />
          )}
          {hasPermission("/afiliados") && (
            <SidebarItemComponent
              label="AFILIADOS"
              iconAsset={AffiliatedIcon}
              path="/afiliados"
              isOnlyIcon={isOnlyIcon}
            />
          )}
          {hasPermission("/dashboard") && (
            <SidebarItemComponent
              label="DASHBOARD"
              iconAsset={DashboardIcon}
              path="/dashboard"
              isOnlyIcon={isOnlyIcon}
            />
          )}
          {hasPermission("/relatorios") && (
            <SidebarItemComponent
              label="RELATÓRIOS"
              iconAsset={ReportsIcon}
              path="/relatorios"
              isOnlyIcon={isOnlyIcon}
            />
          )}
          {hasPermission("/propostas") && (
            <SidebarItemComponent
              label="PROPOSTAS"
              iconAsset={ReportsIcon}
              path="/propostas"
              isOnlyIcon={isOnlyIcon}
            />
          )}
          {hasPermission("/financeiro") && (
            <SidebarItemComponent
              label="FINANCEIRO"
              iconAsset={FinancialIcon}
              paddingLeft={
                isDesktop ? (isOnlyIcon ? "0.9vw" : "3.61vw") : "1.16vw"
              }
              height={"5vh"}
              path="/financeiro"
              isOnlyIcon={isOnlyIcon}
            />
          )}
          {hasPermission("/financeiro") && (
            <SidebarItemComponent
              label="SEJA UM ANUNCIANTE"
              iconAsset={isDesktop ? CampaignsIcon : null}
              dialogPath="be-an-advertiser-dialog"
              paddingRight="0vw"
              isOnlyIcon={isOnlyIcon}
            />
          )}
          {hasPermission("/duvidas") && (
            <SidebarItemComponent
              label="DÚVIDAS"
              iconAsset={HelpIcon}
              path="/duvidas"
              isOnlyIcon={isOnlyIcon}
            />
          )}

          {hasPermission("/admin/usuarios") &&
            hasPermission("/admin/campanhas") &&
            hasPermission("/admin/perfis") &&
            hasPermission("/admin/perfis") &&
            hasPermission("/admin/anunciantes") &&
            hasPermission("/admin/categorias") &&
            hasPermission("/admin/notificacoes") &&
            hasPermission("/admin/bancos") &&
            hasPermission("/admin/propostas") && (
              <SidebarItemComponent
                label="CADASTROS"
                iconAsset={AffiliatedIcon}
                isOnlyIcon={isOnlyIcon}
                children={[
                  <SidebarItemComponent
                    label="USUÁRIOS"
                    iconAsset={AffiliatedIcon}
                    paddingLeft={paddingLeft}
                    path="/admin/usuarios"
                    key="/admin/usuarios"
                    isOnlyIcon={isOnlyIcon}
                  />,
                  <SidebarItemComponent
                    label="PERFIS"
                    iconAsset={AffiliatedIcon}
                    paddingLeft={paddingLeft}
                    path="/admin/perfis"
                    key="/admin/perfis"
                    isOnlyIcon={isOnlyIcon}
                  />,
                  <SidebarItemComponent
                    label="CAMPANHAS"
                    iconAsset={CampaignsIcon}
                    paddingLeft={paddingLeft}
                    path="/admin/campanhas"
                    key="/admin/campanhas"
                    isOnlyIcon={isOnlyIcon}
                  />,
                  <SidebarItemComponent
                    label="ANUNCIANTES"
                    iconAsset={CampaignsIcon}
                    paddingLeft={paddingLeft}
                    path="/admin/anunciantes"
                    key="/admin/anunciantes"
                    isOnlyIcon={isOnlyIcon}
                  />,
                  <SidebarItemComponent
                    label="CATEGORIAS"
                    iconAsset={HelpIcon}
                    paddingLeft={paddingLeft}
                    path="/admin/categorias"
                    key="/admin/categorias"
                    isOnlyIcon={isOnlyIcon}
                  />,
                  <SidebarItemComponent
                    label="BANCOS"
                    iconAsset={HelpIcon}
                    paddingLeft={paddingLeft}
                    path="/admin/bancos"
                    key="/admin/bancos"
                    isOnlyIcon={isOnlyIcon}
                  />,
                  <SidebarItemComponent
                    label="NOTIFICAÇÕES"
                    iconAsset={HelpIcon}
                    paddingLeft={paddingLeft}
                    path="/admin/notificacoes"
                    key="/admin/notificacoes"
                    isOnlyIcon={isOnlyIcon}
                  />,
                  <SidebarItemComponent
                    label="PROPOSTAS"
                    iconAsset={HelpIcon}
                    paddingLeft={paddingLeft}
                    path="/admin/propostas"
                    key="/admin/propostas"
                    isOnlyIcon={isOnlyIcon}
                  />,
                ]}
              />
            )}
          {hasPermission("/admin/pagamentos") && (
            <SidebarItemComponent
              label="PAGAMENTOS"
              iconAsset={FinancialIcon}
              paddingLeft={isDesktop ? (isOnlyIcon ? "0.4vw" : "3vw") : "0vw"}
              path="/admin/pagamentos"
              isOnlyIcon={isOnlyIcon}
            />
          )}

          {hasPermission("/anunciante/relatorios") && (
            <SidebarItemComponent
              label="RELATÓRIOS"
              iconAsset={ReportsIcon}
              path="/relatorios"
              isOnlyIcon={isOnlyIcon}
            />
          )}

          <Box
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              paddingLeft: isOnlyIcon ? "0.6vw" : "4.16vw",
              paddingRight: "3.33vw",
              marginTop: "2vh",
            }}
          >
            {!isOnlyIcon && (
              <Typography
                style={{
                  fontWeight: "700",
                  fontSize: "1.36vh",
                  color: "#5C5C5C",
                }}
              >
                NOSSA COMUNIDADE
              </Typography>
            )}
            <IconButton
              onClick={() => window.open(menu.link, "_blank")}
              style={{ color: "#f6f6f6", padding: 0, margin: 0 }}
            >
              <img
                src={WhatsAppIcon}
                alt="Whatsapp"
                style={{
                  width: isDesktop ? "2vw" : "8vw",
                  height: "auto",
                }}
              />
            </IconButton>
          </Box>
        </List>
      </Drawer>

      <Box
        className={classes.button}
        style={{
          left: isOnlyIcon
            ? isDesktop
              ? "3.9vw"
              : "9vw"
            : isDesktop
            ? "20vw"
            : "39vw",
        }}
      >
        <IconButton
          edge="start"
          className={classes.icon}
          onClick={() => mudarDrawer()}
        >
          <img
            style={{
              width: "15px",
              transform: isOnlyIcon ? "" : "rotate(180deg)",
            }}
            src={ArrowToRightIcon}
          />
        </IconButton>
      </Box>
    </>
  );
};

export default SidebarComponent;
