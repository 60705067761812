import {
  BodyComponent,
  ButtonComponent,
  TableComponent,
} from "../../../../components";
import useAdminAffiliatedProfileController from "./admin-affiliated-profile.controller";
import AdminAffiliatedProfileFormDialogComponent from "./components/admin-affiliated-profile-form-dialog.component";

const AdminAffiliatedProfilePage = () => {
  const {
    profiles,
    onOpenCreateForm,
    onOpenEditForm,
    onDelete,
    onCreate,
    onEdit,
  } = useAdminAffiliatedProfileController();

  return (
    <BodyComponent>
      <AdminAffiliatedProfileFormDialogComponent
        onDelete={onDelete}
        onCreate={onCreate}
        onEdit={onEdit}
      />

      <div style={{ marginBottom: "2vh", width: "71vw", textAlign: "end" }}>
        <ButtonComponent
          label="CRIAR NOVO PERFIL"
          backgroundColor="#40215F"
          color="white"
          size="small"
          height="4vh"
          onClick={onOpenCreateForm}
        />
      </div>

      <TableComponent
        onClick={(row) => onOpenEditForm(row)}
        columns={[
          { label: "ID", acessor: "id", width: "10vw" },
          { label: "NOME", acessor: "name" },
        ]}
        rows={profiles}
      />
      <div style={{ height: "60vh" }}></div>
    </BodyComponent>
  );
};

export default AdminAffiliatedProfilePage;
