import React from "react";
import Grid from "@mui/material/Grid/Grid";

import DescribleComponent from "./components/describle.component";
import FormComponent from "./components/form.component";
import { useResponsive } from "../../../providers";
import { useGlobalStyles } from "../../../hooks";

const RegisterAdvertiserPage = () => {
  const { isDesktop } = useResponsive();
  const classesGlobal = useGlobalStyles();

  return (
    <Grid
      container
      className={classesGlobal.gradient}
      style={{ justifyContent: isDesktop ? "space-between" : "center" }}
    >
      {isDesktop && (
        <Grid item xs={6}>
          <DescribleComponent />
        </Grid>
      )}
      {!isDesktop && (
        <iframe
          xs={12}
          width={"90%"}
          height={"50vw"}
          src="https://www.youtube.com/embed/2oq7Zkwj4sw"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
          style={{ marginTop: "10vw", height: "50vw" }}
        ></iframe>
      )}
      <Grid item xs={isDesktop ? 6 : 12}>
        <FormComponent />
      </Grid>
    </Grid>
  );
};

export default RegisterAdvertiserPage;
