import React from "react";

import { useIsDesktop } from "../hooks";

const ResponsiveContext = React.createContext({
  isDesktop: null,
});

export const useResponsive = () => React.useContext(ResponsiveContext);

export const ResponsiveProvider = ({ children }) => {
  const { isDesktop } = useIsDesktop();

  if (isDesktop == null) {
    if (
      [
        "/",
        "/entrar",
        "/nova-senha",
        "/criar-conta",
        "/anunciar",
        "/sucesso",
      ].includes(window.location.pathname)
    ) {
      return (
        <div
          style={{
            background: "linear-gradient(292.46deg, #85539D, #6B2D87, #40215F)",
            minHeight: "100vh",
          }}
        ></div>
      );
    } else {
      return <div style={{ backgroundColor: "#F9F6F8" }}></div>;
    }
  }

  return (
    <ResponsiveContext.Provider value={{ isDesktop }}>
      {children}
    </ResponsiveContext.Provider>
  );
};
