import React from "react";
import Typography from "@mui/material/Typography/Typography";
import makeStyles from "@mui/styles/makeStyles/makeStyles";

import {
  ButtonComponent,
  GenericDialogComponent,
  TableComponent,
} from "../../../../components";
import { useResponsive } from "../../../../providers";

const useStyles = makeStyles(() => ({
  content: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: "1vh",
    marginBottom: "2vh",
  },
}));

const ChangeHighlightCampaignsDialogComponent = ({
  campaigns,
  onSelectHighlightCampaigns,
  isValidToSelectHighlightCampaigns,
  onChangeHightlightCampaigns,
}) => {
  const classes = useStyles();
  const { isDesktop } = useResponsive();

  return (
    <GenericDialogComponent
      name="change-highlight-campaigns-dialog"
      maxWidth={"lg"}
    >
      <div className={classes.content}>
        <Typography
          style={{
            marginBottom: "6vh",
            fontWeight: "700",
            fontSize: "1.95vh",
            color: "#40215F",
          }}
        >
          ESCOLHA AS CAMPANHAS EM DESTAQUE
        </Typography>
        <TableComponent
          isSelected={true}
          onSelected={onSelectHighlightCampaigns}
          width="50vw"
          columns={[
            {
              label: "MARCA",
              acessor: "logoUrl",
              width: isDesktop ? "14vh" : "8vh",
              formatWidget: (row) => {
                return (
                  <div
                    style={{
                      width: isDesktop ? "13vh" : "6vh",
                      height: isDesktop ? "6vh" : "3vh",
                      marginLeft: isDesktop ? null : "0.5vw",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <img
                      src={row["logoUrl"]}
                      style={{
                        maxHeight: isDesktop ? "6vh" : "3vh",
                        maxWidth: isDesktop ? "13vh" : "6vh",
                      }}
                    />
                  </div>
                );
              },
            },
            {
              label: "CAMPANHA",
              acessor: "name",
              width: isDesktop ? "36vw" : "28vw",

              formatWidget: (row) => {
                return (
                  <Typography
                    style={{
                      fontWeight: "700",
                      fontSize: isDesktop ? "1.86vh" : "0.77vh",
                      color: "#5C5C5C",
                      whiteSpace: "pre-line",
                    }}
                  >
                    {row["name"]}
                  </Typography>
                );
              },
            },
          ]}
          rows={campaigns}
        />
        <ButtonComponent
          disabled={!isValidToSelectHighlightCampaigns()}
          label="SALVAR"
          backgroundColor={
            !isValidToSelectHighlightCampaigns() ? "#858585" : "#774E98"
          }
          height="5vh"
          color="white"
          onClick={onChangeHightlightCampaigns}
        />
      </div>
    </GenericDialogComponent>
  );
};

export default ChangeHighlightCampaignsDialogComponent;
