import React from "react";
import Box from "@mui/material/Box/Box";
import Grid from "@mui/material/Grid/Grid";
import Typography from "@mui/material/Typography/Typography";
import makeStyles from "@mui/styles/makeStyles/makeStyles";

import FormComponent from "./components/form.component";
import DescribleComponent from "./components/describle.component";
import { useResponsive } from "../../../providers";
import { useGlobalStyles } from "../../../hooks";
import LucreMaisLightImage from "../../../../assets/imagens/lucre-mais-light.png";
import GrupoRizomaImage from "../../../../assets/imagens/grupo-rizoma.png";

const useStyles = makeStyles(() => ({
  container: {},
}));

const RegisterUserPage = () => {
  const classes = useStyles();
  const { isDesktop } = useResponsive();
  const classesGlobal = useGlobalStyles();

  return (
    <Grid
      container
      className={classesGlobal.gradient}
      style={{ justifyContent: isDesktop ? "space-between" : "center" }}
    >
      {isDesktop && (
        <Grid item xs={6}>
          <DescribleComponent />
        </Grid>
      )}
      {!isDesktop && (
        <Grid
          item
          xs={12}
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            paddingTop: "5%",
          }}
        >
          <img
            src={LucreMaisLightImage}
            style={{ width: "60%" }}
            className={classes.logo}
            onClick={() => (window.location.href = "/")}
          />
        </Grid>
      )}
      {!isDesktop && (
        <iframe
          xs={12}
          width={"90%"}
          height={"50vw"}
          src="https://www.youtube.com/embed/MknMjo_SrS0"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
          style={{ marginTop: "10vw", height: "50vw" }}
        ></iframe>
      )}
      <Grid item xs={isDesktop ? 6 : 12}>
        <FormComponent />
      </Grid>
      {!isDesktop && (
        <Grid item xs={12}>
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              paddingBottom: "5%",
            }}
          >
            <Typography
              style={{
                color: "white",
                fontWeight: "400",
                fontSize: "1.97vh",
                marginBottom: "0.5vh",
              }}
            >
              MAIS UMA EMPRESA DO
            </Typography>
            <img src={GrupoRizomaImage} style={{ width: "60%" }} />
          </Box>
        </Grid>
      )}
    </Grid>
  );
};

export default RegisterUserPage;
