import Grid from "@mui/material/Grid/Grid";

import FormComponent from "./components/form.component";

const NewPasswordPage = () => {
  return (
    <Grid
      container
      sx={{
        background: "linear-gradient(292.46deg, #85539D, #6B2D87, #40215F)",
        minHeight: "100vh",
        justifyContent: "space-between",
      }}
    >
      <Grid item xs={12}>
        <FormComponent />
      </Grid>
    </Grid>
  );
};

export default NewPasswordPage;
