import Box from "@mui/material/Box/Box";
import IconButton from "@mui/material/IconButton/IconButton";
import useTheme from "@mui/material/styles/useTheme";
import makeStyles from "@mui/styles/makeStyles/makeStyles";

import { useResponsive } from "../../providers";
import WhatsAppIcon from "../../../assets/icones/whatsapp.svg";

const useStyles = makeStyles((theme) => ({
  floatingButton: {
    position: "fixed",
    zIndex: theme.zIndex.drawer + 1,
  },
}));

const HelpButtonComponent = () => {
  const theme = useTheme();
  const classes = useStyles();
  const { isDesktop } = useResponsive();

  const onWhatsapp = () => {
    if (window.location.pathname == "/licenciamento") {
      window.open(
        "https://api.whatsapp.com/send?phone=554791670447&text=Olá, estou vindo da plataforma LUCRE MAIS. Preciso de ajuda!",
        "_blank"
      );
    } else {
      window.open(
        "https://api.whatsapp.com/send?phone=554792930983&text=Olá, estou vindo da plataforma LUCRE MAIS. Preciso de ajuda!",
        "_blank"
      );
    }
  };

  return (
    <Box
      className={classes.floatingButton}
      style={{
        bottom: theme.spacing(isDesktop ? 2 : 0),
        right: theme.spacing(isDesktop ? 2 : 0),
      }}
    >
      <IconButton onClick={onWhatsapp} style={{ color: "#f6f6f6" }}>
        <img
          src={WhatsAppIcon}
          alt="Whatsapp"
          style={{ width: isDesktop ? "5.5vw" : "15vw", height: "auto" }}
        />
      </IconButton>
    </Box>
  );
};

export default HelpButtonComponent;
