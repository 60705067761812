import React from "react";
import TextField from "@mui/material/TextField/TextField";
import Typography from "@mui/material/Typography/Typography";
import makeStyles from "@mui/styles/makeStyles/makeStyles";

import {
  GenericDialogComponent,
  InputWithButtonFormatComponent,
  SelectComponent,
} from "../../../../../components";

const useStyles = makeStyles(() => ({
  content: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: "1vh",
    marginBottom: "2vh",
  },
}));

const ChangeCommissionDialogComponent = ({
  form,
  onChangeCommission,
  onChangeCommissionType,
  onSubmit,
  commissionType,
  commissionTypeOptions,
}) => {
  const classes = useStyles();

  return (
    <GenericDialogComponent name="change-commission-dialog">
      <div className={classes.content}>
        <Typography
          style={{
            marginBottom: "6vh",
            fontWeight: "700",
            fontSize: "1.95vh",
            color: "#40215F",
          }}
        >
          ALTERAR COMISSÃO PARA ESTE PERFIL
        </Typography>
        <TextField
          variant="outlined"
          value={form?.profile ?? ""}
          disabled
          label="PERFIL"
          style={{ width: "100%", marginBottom: "4vh" }}
          InputProps={{
            style: {
              borderRadius: "10px",
              color: "#5C5C5C",
              fontWeight: "700",
              fontSize: "1.46vh",
            },
          }}
          InputLabelProps={{
            style: {
              color: "#5C5C5C",
              fontWeight: "700",
              fontSize: "1.46vh",
            },
          }}
        />

        <div style={{ width: "100%", marginBottom: "2vh" }}>
          <SelectComponent
            label="TIPO DE REMUNERAÇÃO"
            acessor="label"
            value={form?.commissionType ?? 1}
            onChange={(_, value) => onChangeCommissionType(value)}
            options={commissionTypeOptions}
          />
        </div>

        <InputWithButtonFormatComponent
          placeholder="REMUNERAÇÃO"
          color="#5C5C5C"
          isLight={true}
          value={form?.commission ?? 0}
          onChange={onChangeCommission}
          width="100%"
          onClick={onSubmit}
          buttonLabel="EDITAR"
          errorMessage={""}
          format={(form?.commissionType ?? 1) == 1 ? "R$" : "%"}
        />
      </div>
    </GenericDialogComponent>
  );
};

export default ChangeCommissionDialogComponent;
