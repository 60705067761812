import Grid from "@mui/material/Grid/Grid";
import makeStyles from "@mui/styles/makeStyles/makeStyles";

import FacebookIcon from "../../../../../assets/icones/facebook.svg";
import InstagramIcon from "../../../../../assets/icones/instagram.svg";
import LinksESitesImage from "../../../../../assets/imagens/links-e-sites.png";

const useStyles = makeStyles(() => ({
  social: {
    "&:hover": {
      cursor: "pointer",
    },
  },
}));

const DescribleComponent = () => {
  const classes = useStyles();
  return (
    <Grid container style={{ marginLeft: "10vw" }}>
      <Grid item xs={12}>
        <img
          src={LinksESitesImage}
          style={{ width: "100%", maxWidth: "17.64vw" }}
        />
      </Grid>
      <Grid item xs={12} style={{ marginTop: "1.5vh", display: "flex" }}>
        <div
          className={classes.social}
          onClick={() =>
            window.open(
              "https://www.facebook.com/profile.php?id=100090141008139&mibextid=LQQJ4d",
              "_blank"
            )
          }
        >
          <img
            src={FacebookIcon}
            style={{ height: "2.5vw", width: "2.5vw", marginRight: "1vw" }}
          />
        </div>
        <div
          className={classes.social}
          onClick={() =>
            window.open("https://www.instagram.com/lucremaisrzm/", "_blank")
          }
        >
          <img
            src={InstagramIcon}
            style={{ height: "2.5vw", width: "2.5vw" }}
          />
        </div>
      </Grid>
    </Grid>
  );
};

export default DescribleComponent;
