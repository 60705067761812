import React from "react";
import Avatar from "@mui/material/Avatar/Avatar";
import Typography from "@mui/material/Typography/Typography";
import Box from "@mui/material/Box/Box";
import makeStyles from "@mui/styles/makeStyles/makeStyles";

import { useAuth } from "../../../../providers";
import GenericMenuComponent from "../../../modals/generic-menu.component";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  box: {
    alignItems: "center",
    display: "flex",
    "&:hover": {
      cursor: "pointer",
    },
  },
  small: {
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
  profileMenu: {
    paddingInline: "1vw",
    fontWeight: "600",
    color: "#5C5C5C",
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "#f0f0f0",
    },
  },
}));

const ProfileButtonComponent = () => {
  const navigate = useNavigate();
  const classes = useStyles();
  const { user, logout } = useAuth();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <GenericMenuComponent
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
      >
        <Typography
          className={classes.profileMenu}
          onClick={() => navigate("/perfil")}
        >
          PERFIL
        </Typography>
        <Typography className={classes.profileMenu} onClick={logout}>
          SAIR
        </Typography>
      </GenericMenuComponent>

      <Box className={classes.box} onClick={handleOpenMenu}>
        <Avatar
          alt={user?.displayName ?? ""}
          src={user?.photoUrl ?? ""}
          className={classes.small}
        />
        <Typography
          style={{
            marginLeft: "0.69vw",
            fontSize: "1.17vh",
            fontWeight: "400",
          }}
        >
          {user?.displayName ?? ""}
        </Typography>
      </Box>
    </>
  );
};

export default ProfileButtonComponent;
