import React from "react";

import {
  campaignCategoryService,
  campaignService,
} from "../../../../infrastructure/services";
import { useAlert, useAuth } from "../../../providers";
import { useIsMounted } from "../../../hooks";
import { Constants } from "../../../utils";
import { useLoadingContext } from "../../../context/loading.context";

const useCampaignsController = () => {
  const { showError } = useAlert();
  const isMounted = useIsMounted();
  const { user } = useAuth();
  const [campaigns, setCampaigns] = React.useState([]);
  const [categoryToFilter, setCategoryToFilter] = React.useState(0);
  const [categoryNameFilter, setCategoryNameFilter] = React.useState("");
  const [categories, setCategories] = React.useState([Constants.defaultSelect]);
  const { setLoadingState } = useLoadingContext();

  React.useEffect(() => {
    if (user != null) {
      setLoadingState(true);
      campaignService
        .getUserCampaigns()
        .then((response) => {
          if (isMounted.current) {
            setCampaigns(response.sort((a, b) => b.createdAt - a.createdAt));
          }
        })
        .catch(showError)
        .finally(() => {
          setLoadingState(false);
        });
      setLoadingState(true);
      campaignCategoryService
        .getCategories()
        .then((response) => {
          if (isMounted.current) {
            setCategories([
              Constants.defaultSelect,
              ...response.map((item) => ({ id: item.id, label: item.name })),
            ]);
          }
        })
        .catch(showError)
        .finally(() => {
          setLoadingState(false);
        });
    }
  }, [user]);

  const filterByCategory = () => {
    if (categoryToFilter == 0) {
      return categoryNameFilter === ""
        ? campaigns
        : campaigns.filter((campaign) =>
            campaign.name
              .toUpperCase()
              .includes(categoryNameFilter.toUpperCase())
          );
    }

    return campaigns.filter(
      (campaign) =>
        campaign.category.id === categoryToFilter &&
        campaign.name.toUpperCase().includes(categoryNameFilter.toUpperCase())
    );
  };

  return {
    campaigns: filterByCategory(),
    categories,
    categoryToFilter,
    setCategoryToFilter,
    categoryNameFilter,
    setCategoryNameFilter,
  };
};

export default useCampaignsController;
