import httpClient from "./axios.config";

class CampaignCategoryService {
  getCategories = () => {
    return new Promise((resolve, reject) => {
      httpClient
        .get("/publico/listaCategoria")
        .then((response) => {
          const categoriesResponse = response.data.response;

          const categories = categoriesResponse.map((category) => ({
            id: parseInt(category.id),
            name: category.nome,
          }));

          resolve(categories);
        })
        .catch((error) =>
          reject(
            error.response && error.response.data && error.response.data.message
              ? error.response.data.message
              : "Erro inesperado, tente novamente mais tarde."
          )
        );
    });
  };

  createCategory = ({ name }) => {
    return new Promise((resolve, reject) => {
      httpClient
        .post("/categoria/criarCategoria", { categoria: { nome: name } })
        .then(() => resolve())
        .catch((error) =>
          reject(
            error.response && error.response.data && error.response.data.message
              ? error.response.data.message
              : "Erro inesperado, tente novamente mais tarde."
          )
        );
    });
  };

  editCategory = ({ id, name }) => {
    return new Promise((resolve, reject) => {
      httpClient
        .put("/categoria/editarCategoria", { categoria: { id, nome: name } })
        .then(() => resolve())
        .catch((error) =>
          reject(
            error.response && error.response.data && error.response.data.message
              ? error.response.data.message
              : "Erro inesperado, tente novamente mais tarde."
          )
        );
    });
  };

  deleteCategory = ({ id }) => {
    return new Promise((resolve, reject) => {
      httpClient
        .delete("/categoria/deletarCategoria?idCategoria=" + id)
        .then(() => resolve())
        .catch((error) =>
          reject(
            error.response && error.response.data && error.response.data.message
              ? error.response.data.message
              : "Erro inesperado, tente novamente mais tarde."
          )
        );
    });
  };
}

export default new CampaignCategoryService();
