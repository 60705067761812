import React from "react";
import Box from "@mui/material/Box/Box";
import Grid from "@mui/material/Grid/Grid";
import Typography from "@mui/material/Typography/Typography";
import Card from "@mui/material/Card/Card";
import makeStyles from "@mui/styles/makeStyles/makeStyles";
import { useNavigate } from "react-router-dom";

import { useAuth, useDialog, useResponsive } from "../../../../providers";
import { ButtonComponent } from "../../../../components";
import ArrowToLeftLargeIcon from "../../../../../assets/icones/arrow-to-left-large.svg";
import ArrowToRightLargeIcon from "../../../../../assets/icones/arrow-to-right-large.svg";

const useStyles = makeStyles({
  textoComOverflow: {
    display: "-webkit-box",
    "-webkit-line-clamp": 6,
    "-webkit-box-orient": "vertical",
    overflow: "hidden",
    textOverflow: "ellipsis",
    fontWeight: "700",

    textAlign: "center",
    color: "#5C5C5C",
    marginTop: "1vh",
    marginBottom: "1vh",
  },
  cardHover: {
    "&:hover": {
      cursor: "pointer",
    },
  },
});

const HighlightCampaignsComponent = ({ highlightCampaigns }) => {
  const { isDesktop } = useResponsive();
  const { user } = useAuth();
  const { openDialog } = useDialog();
  const [currentImageIndex, setCurrentImageIndex] = React.useState(0);

  const handleNext = () => {
    setCurrentImageIndex((currentImageIndex + 1) % highlightCampaigns.length);
  };

  const handlePrev = () => {
    setCurrentImageIndex(
      (currentImageIndex - 1 + highlightCampaigns.length) %
        highlightCampaigns.length
    );
  };

  const getVisibleImages = () => {
    const lastIndex = currentImageIndex + (isDesktop ? 2 : 0);
    if (lastIndex < highlightCampaigns.length) {
      return highlightCampaigns.slice(currentImageIndex, lastIndex + 1);
    } else {
      const overflow = lastIndex - (highlightCampaigns.length - 1);
      return highlightCampaigns
        .slice(currentImageIndex)
        .concat(highlightCampaigns.slice(0, overflow));
    }
  };

  return (
    <div style={{ width: isDesktop ? "71vw" : "76vw", marginTop: "8vh" }}>
      <Box
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Typography
          style={{
            fontWeight: "700",
            fontSize: "1.75vh",
            color: "#5C5C5C",
            marginBottom: "1vh",
            marginLeft: !isDesktop ? "2.5vw" : null,
          }}
        >
          CAMPANHAS EM DESTAQUE
        </Typography>
        {!!user && user.role == "ADMINISTRADOR" && (
          <ButtonComponent
            label="EDITAR"
            height="3vh"
            backgroundColor="#C4C4C4"
            color="white"
            borderRadius="10px"
            onClick={() => openDialog("change-highlight-campaigns-dialog")}
          />
        )}
      </Box>
      {isDesktop ? (
        <Grid container spacing={5}>
          {(highlightCampaigns ?? []).map((campaign, index) => {
            return (
              <Grid item key={index} xs={4}>
                <CampaignCard
                  id={campaign.id}
                  logoUrl={campaign.logoUrl}
                  describle={campaign.name}
                />
              </Grid>
            );
          })}
        </Grid>
      ) : (
        <Grid
          container
          style={{
            alignItems: "center",
            justifyContent: "space-between",
            height: isDesktop ? "14.64vh" : "10.64vh",
          }}
        >
          <Grid item>
            <div onClick={handlePrev}>
              <img
                src={ArrowToLeftLargeIcon}
                alt="Anterior"
                style={{
                  cursor: "pointer",
                  height: isDesktop ? null : "10vh",
                  marginTop: isDesktop ? null : "0.5vh",
                }}
              />
            </div>
          </Grid>
          <Grid item>
            <Grid container spacing={2}>
              {getVisibleImages().map((campaign, index) => {
                return (
                  <Grid item key={index} xs={4}>
                    <CampaignCard
                      id={campaign.id}
                      logoUrl={campaign.logoUrl}
                      describle={campaign.name}
                    />
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
          <Grid item>
            <div onClick={handleNext}>
              <img
                src={ArrowToRightLargeIcon}
                alt="Próximo"
                style={{
                  cursor: "pointer",
                  height: isDesktop ? null : "10vh",
                  marginTop: isDesktop ? null : "0.5vh",
                }}
              />
            </div>
          </Grid>
        </Grid>
      )}
    </div>
  );
};

const CampaignCard = ({ id, logoUrl, describle }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { isDesktop } = useResponsive();

  return (
    <div>
      <Card
        className={classes.cardHover}
        onClick={() => navigate("/campanhas/" + id)}
        elevation={0}
        style={{
          width: isDesktop ? "21.80vw" : "50vw",
          height: isDesktop ? "18vh" : "12vh",
          borderRadius: "10px",
        }}
      >
        <Grid
          container
          style={{
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            paddingInline: "1vw",
          }}
        >
          <Grid
            item
            xs={6}
            style={{
              height: isDesktop ? "17.5vh" : "11.5vh",
            }}
          >
            <img
              src={logoUrl}
              style={{
                width: "100%",
                height: "100%",
                objectFit: "contain",
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <Typography
              variant="body1"
              className={classes.textoComOverflow}
              style={{ fontSize: isDesktop ? "1.46vh" : "0.97vh" }}
              component="div"
            >
              {describle}
            </Typography>
          </Grid>
        </Grid>
      </Card>
    </div>
  );
};

export default HighlightCampaignsComponent;
