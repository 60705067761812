import Divider from "@mui/material/Divider/Divider";
import Grid from "@mui/material/Grid/Grid";
import Typography from "@mui/material/Typography/Typography";

import {
  BannerPickerComponent,
  InputFilterComponent,
  SelectComponent,
} from "../../../../../components";

const MessageSectionComponent = ({
  control,
  linkOptions,
  editImage,
  setErrorInMedia,
  onDeleteMedia,
  isLoading,
}) => {
  return (
    <div>
      <Typography
        style={{
          fontWeight: "600",
          fontSize: "1.75vh",
          color: "#5C5C5C",
        }}
      >
        MENSAGEM
      </Typography>
      <Divider style={{ marginBottom: "2vh" }} />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <InputFilterComponent
                name="title"
                control={control}
                label="TITULO"
                placeholder="TITULO"
                disabled={isLoading}
              />
            </Grid>
            <Grid item xs={6}>
              <SelectComponent
                name="link"
                control={control}
                label="LINK PARA"
                acessor="label"
                options={linkOptions}
                disabled={isLoading}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <BannerPickerComponent
                name="media"
                control={control}
                label="MÍDIA"
                onUpload={editImage}
                accept={"image/png, image/jpeg, audio/mp3, video/mp4"}
                maxSize={4000000}
                onError={(error) => setErrorInMedia(error, "media")}
                onDelete={() => onDeleteMedia("media")}
                disabled={isLoading}
              />
            </Grid>
            <Grid item xs={9}>
              <InputFilterComponent
                name="content"
                control={control}
                label="CONTEÚDO"
                placeholder="CONTEÚDO"
                multiline={true}
                minRows={7}
                disabled={isLoading}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <BannerPickerComponent
                name="header"
                control={control}
                label="BANNER DO CABEÇALHO"
                onUpload={editImage}
                accept={"image/png, image/jpeg"}
                maxSize={4000000}
                onError={(error) => setErrorInMedia(error, "header")}
                onDelete={() => onDeleteMedia("header")}
                disabled={true}
              />
            </Grid>
            <Grid item xs={3}>
              <BannerPickerComponent
                name="footer"
                control={control}
                label="BANNER DO RODAPÉ"
                onUpload={editImage}
                accept={"image/png, image/jpeg"}
                maxSize={4000000}
                onError={(error) => setErrorInMedia(error, "footer")}
                onDelete={() => onDeleteMedia("footer")}
                disabled={true}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default MessageSectionComponent;
