import Container from "@mui/material/Container/Container";
import Toolbar from "@mui/material/Toolbar/Toolbar";
import makeStyles from "@mui/styles/makeStyles/makeStyles";

import FooterComponent from "./components/footer/footer.component";
import NavbarComponent from "./components/navbar/navbar.component";
import SidebarComponent from "./components/sidebar/sidebar.component";
import { useResponsive } from "../../providers";

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  sidebarContainer: {
    //position: "absolute",
    //top: 0,
    //left: 0,
    //zIndex: 1,
  },
  appContainer: {
    width: "fit-content",
    paddingLeft: theme.spacing(2),
  },
}));

const PrivateLayoutComponent = ({ children }) => {
  const classes = useStyles();
  const { isDesktop } = useResponsive();

  return (
    <>
      <NavbarComponent />
      <main
        className={classes.container}
        style={{ paddingLeft: isDesktop ? "19.14vw" : "14.5vw" }}
      >
        <div className={classes.sidebarContainer}>
          <SidebarComponent />
        </div>
        <Toolbar />
        <Container className={classes.appContainer}>{children}</Container>
      </main>
      <FooterComponent />
    </>
  );
};

export default PrivateLayoutComponent;
