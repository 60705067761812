import { useState } from "react";
import Divider from "@mui/material/Divider/Divider";
import Grid from "@mui/material/Grid/Grid";
import Typography from "@mui/material/Typography/Typography";

import {
  AutocompleteMultipleComponent,
  InputWithButtonComponent,
} from "../../../../../components";

const CPLSectionComponent = ({
  control,
  formCPLOptions,
  onAddCPLQuestion,
  isLoading,
}) => {
  const [question, setQuestion] = useState("");
  const [questionError, setQuestionError] = useState(null);

  return (
    <div>
      <Typography
        style={{
          fontWeight: "600",
          fontSize: "1.75vh",
          color: "#5C5C5C",
        }}
      >
        CPL - POR LEAD VÁLIDO:
      </Typography>
      <Divider style={{ marginBottom: "2vh" }} />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={8}>
              <AutocompleteMultipleComponent
                name="formCPL"
                control={control}
                label="CAMPOS DO FORMULÁRIO"
                withoutAcessor={true}
                options={formCPLOptions}
                disabled={isLoading}
              />
            </Grid>
            <Grid item xs={4}>
              <InputWithButtonComponent
                placeholder="PERGUNTAS"
                color="#5C5C5C"
                isLight={true}
                value={question}
                onChange={setQuestion}
                disabled={isLoading}
                onClick={
                  isLoading == false
                    ? () => {
                        setQuestionError(null);
                        if (
                          !!question &&
                          question.length > 1 &&
                          !formCPLOptions.includes(question)
                        ) {
                          onAddCPLQuestion(question);
                          setQuestion("");
                        } else {
                          setQuestionError("Pergunta inválida!");
                        }
                      }
                    : null
                }
                buttonLabel="ADICIONAR"
                errorMessage={questionError}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default CPLSectionComponent;
