import React from "react";
import Box from "@mui/material/Box/Box";
import Grid from "@mui/material/Grid/Grid";
import Typography from "@mui/material/Typography/Typography";
import makeStyles from "@mui/styles/makeStyles/makeStyles";

import {
  ButtonGradientComponent,
  GenericDialogComponent,
} from "../../../../components";
import SelectGradientComponent from "../../../../components/form/select-gradient.component";

const useStyles = makeStyles(() => ({
  content: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: "1vh",
    marginBottom: "2vh",
    width: "100%",
    gap: "1vh",
  },
}));

const AddressDialogComponent = ({
  addressForm,
  onSubmitAddress,
  cidades,
  estados,
}) => {
  const classes = useStyles();

  return (
    <GenericDialogComponent
      name="address-dialog"
      isPublic={true}
      className={classes.content}
      isClosed={false}
    >
      <div className={classes.content}>
        <Grid item xs={12}>
          <Box>
            <Typography
              style={{
                color: "white",
                fontWeight: "400",
                fontSize: "2vh",
                marginBottom: "0.5vh",
              }}
            >
              CADASTRE SEU ESTADO E CIDADE
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} style={{ width: "80%" }}>
          <SelectGradientComponent
            name="estado"
            control={addressForm}
            label="ESTADO"
            options={estados}
            isValueObject={false}
          />
        </Grid>
        <Grid item xs={12} style={{ width: "80%" }}>
          <SelectGradientComponent
            name="cidade"
            control={addressForm}
            label="CIDADE"
            options={cidades}
            isValueObject={false}
          />
        </Grid>
        <Grid item xs={12} style={{ width: "80%" }}>
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <ButtonGradientComponent
              width="60%"
              label="SALVAR"
              onClick={() => onSubmitAddress()}
            />
          </Box>
        </Grid>
      </div>
    </GenericDialogComponent>
  );
};

export default AddressDialogComponent;
