import Button from "@mui/material/Button/Button";

const ButtonPickerComponent = ({
  label,
  name,
  acceptFile = "image/png, image/jpeg",
  onUpload,
}) => {
  const handleChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      const reader = new FileReader();
      reader.onload = (e) => {
        onUpload(e.target.result);
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  };
  return (
    <div>
      <input
        id={"image-picker-" + name}
        accept={acceptFile}
        style={{ display: "none" }}
        type="file"
        onChange={handleChange}
      />
      <label htmlFor={"image-picker-" + name}>
        <span>
          <Button
            variant="contained"
            component="span"
            color="primary"
            size="small"
            disableElevation
            style={{
              backgroundColor: "#774E98",
              color: "white",
              borderRadius: "30px",
              textTransform: "none",
              fontWeight: "700",
              height: "5vh",
              paddingInline: "1vw",
            }}
          >
            {label}
          </Button>
        </span>
      </label>
    </div>
  );
};

export default ButtonPickerComponent;
