import Button from "@mui/material/Button/Button";
import styled from "@mui/material/styles/styled";

const StyledButton = styled(Button)({
  textTransform: "none",
  borderRadius: "100px",
  background: "linear-gradient(45deg, #EA8578, #A64C9A)",
  fontWeight: "bold",
  color: "white",
});

const ButtonGradientComponent = ({
  label,
  type,
  onClick,
  width,
  height,
  padding,
  fullWidth,
  background,
  fontSize,
}) => {
  return (
    <StyledButton
      variant="contained"
      disableElevation
      disableRipple
      disableFocusRipple
      disableTouchRipple
      type={type}
      onClick={onClick}
      size="large"
      fullWidth={fullWidth}
      style={{
        width: width,
        height: height ?? "7vh",
        padding: padding ?? "1vh",
        background: background ?? "linear-gradient(45deg, #EA8578, #A64C9A)",
        fontSize: fontSize ?? "1.75vh",
      }}
    >
      {label}
    </StyledButton>
  );
};

export default ButtonGradientComponent;
