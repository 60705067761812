import axios from "axios";

const httpClient = axios.create({ baseURL: import.meta.env.VITE_URL_API });

httpClient.interceptors.request.use((config) => {
  if (!["/security/authenticate"].includes(config.url)) {
    if (typeof window !== "undefined") {
      const token = localStorage.getItem("token");

      if (!!token) {
        config.headers["Authorization"] = "Bearer " + token;
      }
    }
  }

  return config;
});

export default httpClient;
