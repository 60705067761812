import React, { useEffect } from "react";
import moment from "moment";

import {
  productionService,
  userService,
} from "../../../../infrastructure/services";
import { useAlert, useAuth, useDialog } from "../../../providers";
import { Constants, Format } from "../../../utils";
import { useIsMounted } from "../../../hooks";
import { useLoadingContext } from "../../../context/loading.context";
import dayjs from "dayjs";

const useAdvertiserReportsController = () => {
  const { showError, showSuccess } = useAlert();
  const { openDialog, closeDialog } = useDialog();

  const { user } = useAuth();
  const isMounted = useIsMounted();

  const rowsPerPage = 10;
  const [users, setUsers] = React.useState([Constants.defaultSelect]);
  const [productions, setProductions] = React.useState([]);
  const [count, setCount] = React.useState(0);
  const [page, setPage] = React.useState(0);
  const [lastQuery, setLastQuery] = React.useState(null);
  const [counters, setCounters] = React.useState({
    pending: 0,
    approved: 0,
    disapproved: 0,
    visualized: 0,
  });
  const [selectedProduction, setSelectedProduction] = React.useState(null);
  const { setLoadingState } = useLoadingContext();

  useEffect(() => {
    if (!!user) {
      setLoadingState(true);
      userService
        .getAffilietdToInterReports({ id: user.id })
        .then((response) => {
          if (isMounted.current) {
            setUsers([
              Constants.defaultSelect,
              ...response.map((item) => ({
                id: item.id,
                label: item.displayName,
              })),
            ]);
          }
        })
        .catch(showError)
        .finally(() => {
          setLoadingState(false);
        });
    }
  }, [user]);

  const onSearch = ({
    newPage,
    startDate,
    endDate,
    situation,
    isDownload,
    step,
    user,
  }) => {
    const query = {
      startDate: dayjs(startDate).format().substring(0, 10),
      endDate: dayjs(endDate).format().substring(0, 10),
      situacao: getSituation(situation.label),
      step: step != null ? step.id : null,
      page: newPage ?? page,
      rowsPerPage: rowsPerPage,
    };

    if (newPage == 0) {
      setPage(0);
    }

    if (!!user && user.id !== 0) {
      query.usuarioId = user.id;
    }

    if (isDownload == true) {
      query.baixar = 1;
    }

    setLastQuery({
      startDate,
      endDate,
      situation,
      user,
    });
    setLoadingState(true);
    productionService
      .getInterReport(query)
      .then((response) => {
        if (isDownload == true) {
          makeCsvData(response.data);
        } else {
          setProductions(response.data);
          setCount(response.count);
          setCounters(response.counters);
        }
      })
      .catch(showError)
      .finally(() => {
        setLoadingState(false);
      });
  };

  const getSituation = (situation) => {
    switch (situation) {
      case "PENDENTES":
        return "PENDENTE";
      case "VISUALIZADAS":
        return "VISUALIZADA";
      case "REPROVADAS":
        return "REPROVADA";
      case "APROVADAS":
        return "APROVADA";
      default:
        return "TODOS";
    }
  };

  const makeCsvData = (data) => {
    if (data.length == 0) {
      return;
    }

    var csvRows = [];

    csvRows.push([
      "PRODUTO",
      "ETAPA CONCLUÍDA",
      "USUÁRIO",
      "EMAIL",
      "TELEFONE",
      "DATA",
      "STATUS",
      "PAGAMENTO",
      "METADATA",
    ]);

    data.map((row) => {
      csvRows.push([
        row.campaign,
        Constants.getProductionStep(row.step),
        row.affiliate.name,
        row.affiliate.email,
        row.affiliate.phone,
        row.eventDate,
        row.situation,
        "R$ " + row.payment,
        row.metadata,
      ]);
    });

    const csvString = csvRows.join("\n");

    Format.downloadCsv(csvString, "relatorio");
  };

  const handleChangePage = (newPage) => {
    setPage(newPage);
    onSearch({ newPage, ...lastQuery });
  };

  const onSelectProduction = (production) => {
    if (production.situation == "PENDENTE") {
      onChangeProductionSituation({
        id: production.id,
        situation: "VISUALIZADA",
      });
    }

    setSelectedProduction(production);
    openDialog("production-detail-dialog");
  };

  const onChangeProductionSituation = ({ id, situation, payment }) => {
    var form = { idProducao: id ?? selectedProduction.id, status: situation };

    if (!!payment) {
      form["pagamento"] = payment;
    }
    setLoadingState(true);
    productionService
      .changeProductionSituation(form)
      .then(() => {
        setProductions(
          productions.map((production) => {
            if (production.id == form.idProducao) {
              return {
                ...production,
                situation: situation,
                payment: payment ?? 0,
              };
            }
            return production;
          })
        );
        if (situation == "APROVADA") {
          closeDialog();
          showSuccess("Produção aprovada!");
        } else if (situation == "REPROVADA") {
          closeDialog();
          showSuccess("Produção reprovada!");
        }
      })
      .catch(showError)
      .finally(() => {
        setLoadingState(false);
      });
  };

  const onSendLink = (link) => {
    var form = { idProducao: selectedProduction.id, link: link };
    setLoadingState(true);
    productionService
      .sendLinkCpr(form)
      .then(() => {
        closeDialog();
        showSuccess("Link enviado para o usuário");
        setProductions(
          productions.map((p) => {
            if (p.id == selectedProduction.id) {
              return { ...p, linkCpr: link };
            }
            return p;
          })
        );
      })
      .catch(showError)
      .finally(() => {
        setLoadingState(false);
      });
  };

  const onSetObservations = (observations) => {
    var form = { idProducao: selectedProduction.id, observacao: observations };
    setLoadingState(true);
    productionService
      .setObservations(form)
      .then(() => {
        closeDialog();
        showSuccess("Observações anexadas!");
        setProductions(
          productions.map((p) => {
            if (p.id == selectedProduction.id) {
              return { ...p, observation: observations };
            }
            return p;
          })
        );
      })
      .catch(showError)
      .finally(() => {
        setLoadingState(false);
      });
  };

  return {
    productions,
    onSearch,
    selectedProduction,
    onSelectProduction,
    onChangeProductionSituation,
    onSendLink,
    onSetObservations,
    handleChangePage,
    page,
    count,
    rowsPerPage,
    counters,
    userOptions: users,
  };
};

export default useAdvertiserReportsController;
