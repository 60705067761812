import makeStyles from "@mui/styles/makeStyles/makeStyles";
import TextField from "@mui/material/TextField/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { Controller } from "react-hook-form";

import ArrowToBottomIcon from "../../../assets/icones/arrow-to-bottom.svg";

const useStyles = makeStyles(() => ({
  option: {
    fontWeight: "700",
    fontSize: "1.46vh",
    color: "#5C5C5C",
  },
}));

const AutocompleteMultipleComponent = ({
  control,
  name,
  label,
  acessorValue,
  options,
  value,
  onChange,
  withoutAcessor,
  disabled,
}) => {
  const classes = useStyles();

  const ArrowIcon = () => {
    return <img src={ArrowToBottomIcon} />;
  };

  const Field = ({ field, formState }) => {
    return (
      <Autocomplete
        multiple
        filterSelectedOptions
        disabled={disabled}
        value={!!field ? field.value : value}
        onChange={(e, v) => {
          if (!!field) {
            field.onChange(v);
          } else {
            onChange(v);
          }
        }}
        getOptionLabel={(o) => {
          if (!!withoutAcessor) {
            return o;
          } else {
            return o.label;
          }
        }}
        isOptionEqualToValue={(o, v) => {
          if (!!withoutAcessor) {
            return !!v && o == v;
          } else {
            return !!v && o[acessorValue] == v[acessorValue];
          }
        }}
        options={options}
        popupIcon={<ArrowIcon />}
        renderOption={(props, option) => {
          const label = !!withoutAcessor ? option : option.label;
          return (
            <div className={classes.option} {...props}>
              {label}
            </div>
          );
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label}
            type="text"
            variant="outlined"
            autoComplete="false"
            disabled={disabled}
            error={!!field ? !!formState.errors[name] : false}
            helperText={!!field ? formState.errors[name]?.message : null}
            InputLabelProps={{
              style: {
                fontWeight: "700",
                fontSize: "1.46vh",
                color: "#5C5C5C",
              },
            }}
            InputProps={{
              ...params.InputProps,
              style: {
                borderRadius: "10px",
                fontWeight: "700",
                fontSize: "1.46vh",
                color: "#858585",
                height: "5.5vh",
              },
            }}
          />
        )}
      />
    );
  };

  if (!!control) {
    return (
      <Controller
        name={name}
        control={control}
        render={({ field, formState }) => (
          <Field field={field} formState={formState} />
        )}
      />
    );
  }

  return <Field field={null} formState={null} />;
};

export default AutocompleteMultipleComponent;
