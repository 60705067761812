import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { v4 as uuidv4 } from "uuid";
import React, { useEffect, useState } from "react";

import { useAlert, useAuth, useDialog } from "../../../../providers";
import {
  mediaService,
  notificationService,
  userService,
} from "../../../../../infrastructure/services";
import { Format } from "../../../../utils";
import { useLoadingContext } from "../../../../context/loading.context";

const useAdminModalFormController = ({ modalId }) => {
  const { setLoadingState } = useLoadingContext();

  const isActiveOptions = [
    { id: 1, label: "SIM", value: 1 },
    { id: 2, label: "NÃO", value: 0 },
  ];
  const bannerTypeOptions = [
    { id: 1, label: "IMAGEM", value: "imagem" },
    { id: 2, label: "VIDEO", value: "video" },
  ];

  const schema = yup.object().shape({
    active: yup
      .number()
      .required("Campo obrigatório")
      .test("invalid", "Selecione uma das opções", (value) => {
        return value > 0;
      }),
    bannerUrl: yup.string().required("Campo obrigatório"),
    link: yup
      .string()
      .optional()
      .when("bannerType", {
        is: (bannerType) => bannerType == 1,
        then: () => yup.string().required("Campo obrigatório"),
      }),
    bannerType: yup
      .number()
      .required("Campo obrigatório")
      .test("invalid", "Selecione uma das opções", (value) => {
        return value > 0;
      }),
    idTipoUsuario: yup
      .number()
      .required("Campo obrigatório")
      .test("invalid", "Selecione uma das opções", (value) => {
        return value > 0;
      }),
    fundoTransparente: yup.boolean(),
  });

  const { control, handleSubmit, reset, setValue, getValues, watch } = useForm({
    defaultValues: {
      id: "",
      active: isActiveOptions[0].id,
      bannerType: bannerTypeOptions[0].id,
      bannerUrl: null,
      link: "",
      width: 0,
      height: 0,
      idTipoUsuario: null,
      fundoTransparente: false,
    },
    resolver: yupResolver(schema),
  });

  const { showError, showSuccess } = useAlert();
  const { user } = useAuth();
  const { openDialog } = useDialog();
  const [isLoading, setIsLoading] = useState(false);
  const [isValidToPreview, setIsValidToPreview] = useState(false);
  const [listTypeUser, setListTypeUser] = React.useState([]);
  const [bannerType, setBannerType] = React.useState(bannerTypeOptions[0].id);

  useEffect(() => {
    if (!!user) {
      if (!!modalId) {
        fetchModalData();
      }
      setLoadingState(true);
      userService
        .getTypeUsers()
        .then((response) => {
          setListTypeUser(
            response.map((item) => ({
              id: parseInt(item.id),
              label: item.name,
            }))
          );
        })
        .catch(showError)
        .finally(() => {
          setLoadingState(false);
        });
    }
  }, [user]);

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      checkIsValidPreview(value);
      if (name == "bannerType") {
        setBannerType(value.bannerType);
        setValue("bannerUrl", null);
        setValue("link", "");
        setValue("width", 560);
        setValue("height", 315);
        setValue("fundoTransparente", false);
      }
    });

    return () => subscription.unsubscribe();
  }, [watch]);

  const fetchModalData = () => {
    setIsLoading(true);
    setLoadingState(true);
    notificationService
      .getDataOfModal({ modalId: modalId })
      .then((modalData) => {
        setIsLoading(false);
        const data = {
          ...modalData,
          active: isActiveOptions.filter(
            (option) => option.value == modalData.active
          )[0].id,
          bannerType: bannerTypeOptions.filter(
            (o) => o.value == modalData.bannerType
          )[0].id,
        };
        reset(data);
        checkIsValidPreview(data);
      })
      .catch(showRequestError)
      .finally(() => {
        setLoadingState(false);
      });
  };

  const showRequestError = (error) => {
    setIsLoading(false);
    showError(error);
  };

  const onSubmit = handleSubmit((data) => {
    setIsLoading(true);

    const form = {
      id: data.id,
      ativo: isActiveOptions.find((o) => o.id == data.active).value,
      bannerUrl: data.bannerUrl,
      bannerTipo: bannerTypeOptions.find((type) => type.id == data.bannerType)
        .value,
      idTipoUsuario: data.idTipoUsuario,
      width: data.width,
      height: data.height,
      link: data.link,
      fundoTransparente: data.fundoTransparente,
    };

    setLoadingState(true);

    if (!!modalId) {
      notificationService
        .changeDataOfInitialModal(form)
        .then(() => {
          setIsLoading(false);
          showSuccess("Modal inicial editado com sucesso!");
        })
        .catch(showRequestError)
        .finally(() => {
          setLoadingState(false);
        });
    } else {
      notificationService
        .createInitialModal(form)
        .then(() => {
          setIsLoading(false);
          showSuccess("Modal inicial criado com sucesso!");
        })
        .catch(showRequestError)
        .finally(() => {
          setLoadingState(false);
        });
    }
  });

  const editImage = ({ name, data, callback, width, height }) => {
    const formValue = getValues(name);

    var filename = uuidv4();
    if (!!formValue) {
      filename = Format.getFileNameFromURL(formValue);
    }

    setIsLoading(true);
    setLoadingState(true);
    mediaService
      .upload({ filename: filename, image: data })
      .then((imageUrl) => {
        setIsLoading(false);
        callback(imageUrl);
        setValue("width", width);
        setValue("height", height);
      })
      .catch(showRequestError)
      .finally(() => {
        setLoadingState(false);
      });
  };

  const setErrorInMedia = (error) => {
    setValue("media", null);
    setIsLoading(false);
    showError(error);
  };

  const onDeleteMedia = () => {
    setValue("media", null);
  };

  const onPreview = () => {
    openDialog("initial-modal-dialog", {
      modal: {
        ...getValues(),
        bannerType: bannerTypeOptions.filter((o) => o.id == bannerType)[0]
          .value,
      },
      nextModals: [],
    });
  };

  const checkIsValidPreview = (values) => {
    if (
      !!values.bannerUrl &&
      !!values.width &&
      !!values.idTipoUsuario &&
      !!values.height
    ) {
      if (values.bannerType == 1) {
        if (!values.link) {
          setIsValidToPreview(false);
        } else {
          setIsValidToPreview(true);
        }
      } else {
        setIsValidToPreview(true);
      }
    } else {
      setIsValidToPreview(false);
    }
  };

  return {
    control,
    onSubmit,
    editImage,
    setErrorInMedia,
    onDeleteMedia,
    isLoading,
    isActiveOptions,
    isValidToPreview,
    onPreview,
    listTypeUser,
    bannerType,
    bannerTypeOptions,
    watch,
  };
};

export default useAdminModalFormController;
