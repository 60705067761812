import ReCAPTCHA from "react-google-recaptcha";
import Box from "@mui/material/Box/Box";
import Grid from "@mui/material/Grid/Grid";

import {
  ButtonGradientComponent,
  InputGradientComponent,
} from "../../../../components";
import useRegisterAdvertiserController from "../register-advertiser.controller";
import SelectGradientComponent from "../../../../components/form/select-gradient.component";
import { useResponsive } from "../../../../providers";
import SelectFileGradientComponent from "../../../../components/form/select-file-gradient.component";

const FormComponent = () => {
  const { isDesktop } = useResponsive();
  const {
    control,
    onSubmit,
    setValue,
    _reCaptchaRef,
    categories,
    typePayments,
    handleBase64Change,
  } = useRegisterAdvertiserController();

  return (
    <Box
      component="form"
      onSubmit={onSubmit}
      style={{
        display: "flex",
        justifyContent: "center",
        paddingTop: "7vh",
        paddingBottom: "7vh",
      }}
    >
      <Grid
        container
        style={{
          maxWidth: isDesktop ? "22vw" : "100vw",
          paddingInline: isDesktop ? "0" : "4vw",
        }}
      >
        <Grid item xs={12}>
          <InputGradientComponent
            name="nome"
            control={control}
            placeholder="NOME COMPLETO"
          />
        </Grid>
        <Grid item xs={12}>
          <InputGradientComponent
            name="nomeEmpresa"
            control={control}
            placeholder="EMPRESA"
          />
        </Grid>
        <Grid item xs={12}>
          <InputGradientComponent
            name="site"
            control={control}
            placeholder="SITE"
          />
        </Grid>
        <Grid item xs={12}>
          <InputGradientComponent
            name="facebook"
            control={control}
            placeholder="FACEBOOK"
          />
        </Grid>
        <Grid item xs={12}>
          <InputGradientComponent
            name="instagram"
            control={control}
            placeholder="INSTAGRAM"
          />
        </Grid>
        <Grid item xs={12}>
          <InputGradientComponent
            name="email"
            control={control}
            placeholder="E-MAIL"
          />
        </Grid>
        <Grid item xs={12}>
          <SelectFileGradientComponent
            name="documento"
            control={control}
            onBase64Change={handleBase64Change}
            placeholder="RG OU CNH"
          />
        </Grid>
        <Grid item xs={12}>
          <InputGradientComponent
            name="telefone"
            control={control}
            placeholder="TELEFONE"
          />
        </Grid>
        <Grid item xs={12}>
          <InputGradientComponent
            name="endereco"
            control={control}
            placeholder="ENDEREÇO"
          />
        </Grid>
        <Grid item xs={12}>
          <SelectGradientComponent
            name="idTipoProduto"
            control={control}
            label="TIPO PRODUTO"
            options={categories}
          />
        </Grid>
        <Grid item xs={12}>
          <SelectGradientComponent
            name="idTipoPagamento"
            control={control}
            label="TIPO PAGAMENTO"
            options={typePayments}
          />
        </Grid>
        <Grid item xs={12}>
          <InputGradientComponent
            name="valor"
            control={control}
            placeholder="VALOR"
          />
        </Grid>
        <Grid item xs={12} style={{ marginTop: "2vh" }}>
          <div style={{ textAlign: "center" }}>
            <ReCAPTCHA
              style={{ display: "inline-block" }}
              ref={_reCaptchaRef}
              sitekey={import.meta.env.VITE_RECAPTCHA_KEY}
              onChange={(token) => setValue("reCaptcha", token)}
            />
          </div>
        </Grid>
        <Grid item xs={12} style={{ marginTop: "3vh" }}>
          <ButtonGradientComponent
            label="ENVIAR CADASTRO"
            fullWidth
            type="submit"
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default FormComponent;
