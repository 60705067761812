import Divider from "@mui/material/Divider/Divider";
import Grid from "@mui/material/Grid/Grid";
import Typography from "@mui/material/Typography/Typography";

import {
  AutocompleteMultipleComponent,
  SelectComponent,
} from "../../../../../components";
import DatapickerComponent from "../../../../../components/form/datapicker.component";
import { useResponsive } from "../../../../../providers";
import Constants from "../../../../../utils/constants.util";
import { Button, TextField } from "@mui/material";
import React from "react";
import { Controller } from "react-hook-form";

const SendSectionComponent = ({
  control,
  sendTypeOptions,
  sendGroupOptions,
  sendUsersOptions,
  notificationTypeOptions,
  withSendGroup,
  isLoading,
  getValues,
}) => {
  const { isDesktop } = useResponsive();
  const [emailList, setEmailList] = React.useState("");

  const handleInputChange = (event) => {
    setEmailList(event.target.value);
  };

  const handleButtonClick = () => {
    const emails = emailList.split(",").map((email) => email.trim());
    console.log(emails);
    // Faça algo com a lista de e-mails (por exemplo, enviar para o servidor)
  };

  const EmailListComponent = () => {
    return (
      <div>
        <Controller
          name="emailsExternos"
          control={control}
          render={({ field, formState }) => {
            return (
              <TextField
                {...field}
                label="Lista de E-mails"
                variant="outlined"
                fullWidth
                multiline
                rows={10}
                error={!!formState.errors["emailsExternos"]}
                helperText={formState.errors["emailsExternos"]?.message}
              />
            );
          }}
        />
      </div>
    );
  };

  return (
    <div>
      <Typography
        style={{
          fontWeight: "600",
          fontSize: "1.75vh",
          color: "#5C5C5C",
        }}
      >
        ENVIO
      </Typography>
      <Divider style={{ marginBottom: "2vh" }} />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <SelectComponent
                name="sendType"
                control={control}
                label="TIPO DE ENVIO"
                acessor="label"
                options={sendTypeOptions}
                disabled={isLoading}
              />
            </Grid>
            <Grid item xs={8}>
              {getValues("sendType") === Constants.DATA_CADASTRO ? (
                <Grid container spacing={isDesktop ? 4 : 2}>
                  <Grid item xs={6}>
                    <DatapickerComponent
                      name="startDate"
                      control={control}
                      label="DATA INICIAL"
                      readOnly={true}
                      required={false}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <DatapickerComponent
                      name="endDate"
                      control={control}
                      label="DATA FINAL"
                      required={false}
                      readOnly={true}
                    />
                  </Grid>
                </Grid>
              ) : getValues("sendType") === Constants.POR_GRUPO ? (
                <AutocompleteMultipleComponent
                  name="sendGroup"
                  control={control}
                  label="GRUPO DE ENVIO"
                  withoutAcessor={true}
                  options={sendGroupOptions}
                  disabled={isLoading}
                />
              ) : getValues("sendType") === Constants.DIRECIONADO ? (
                <AutocompleteMultipleComponent
                  name="sendUsers"
                  control={control}
                  label="USUÁRIOS PARA ENVIO"
                  acessorValue="id"
                  options={sendUsersOptions}
                  disabled={isLoading}
                />
              ) : (
                <AutocompleteMultipleComponent
                  name="notificationType"
                  control={control}
                  label="TIPO DE NOTIFICAÇÃO"
                  withoutAcessor={true}
                  options={notificationTypeOptions}
                  disabled={isLoading}
                />
              )}
            </Grid>

            <Grid item xs={12}>
              {getValues("sendType") === Constants.EXTERNO ? (
                <EmailListComponent />
              ) : (
                <AutocompleteMultipleComponent
                  name="notificationType"
                  control={control}
                  label="TIPO DE NOTIFICAÇÃO"
                  withoutAcessor={true}
                  options={notificationTypeOptions}
                  disabled={isLoading}
                />
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default SendSectionComponent;
