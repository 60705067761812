import React from "react";
import AppBar from "@mui/material/AppBar/AppBar";
import Divider from "@mui/material/Divider/Divider";
import Grid from "@mui/material/Grid/Grid";
import Toolbar from "@mui/material/Toolbar/Toolbar";
import Typography from "@mui/material/Typography/Typography";
import useTheme from "@mui/material/styles/useTheme";

import SectionOneComponent from "./section-one.component";
import SectionTwoComponent from "./section-two.component";
import SectionThreeComponent from "./section-three.component";
import SectionFourComponent from "./section-four.component";
import { useResponsive } from "../../../../providers";
import { useWhiteLabel } from "../../../../providers/white-label.provider";

const FooterComponent = () => {
  const tema = useTheme();
  const { isDesktop } = useResponsive();
  const { hasCustomTheme, theme } = useWhiteLabel();

  const DesktionSections = () => {
    return (
      <Grid
        container
        style={{
          justifyContent: "center",
          height: "100%",
          paddingTop: "7vh",
          marginBottom: "4vh",
        }}
      >
        <Grid item>
          <SectionOneComponent />
        </Grid>
        <Grid item>
          <CustomDivider />
        </Grid>
        <Grid item>
          <SectionTwoComponent />
        </Grid>
        <Grid item>
          <CustomDivider />
        </Grid>
        <Grid item>
          <SectionThreeComponent />
        </Grid>
        <Grid item>
          <SectionFourComponent />
        </Grid>
      </Grid>
    );
  };

  const MobileSections = () => {
    return (
      <div style={{ width: "85.5vw", marginTop: "6vh" }}>
        <SectionOneComponent />
        <CustomDivider />
        <SectionTwoComponent />
        <CustomDivider />
        <SectionThreeComponent />
        <SectionFourComponent />
        <CustomDivider />
      </div>
    );
  };

  return (
    <AppBar
      position="relative"
      elevation={hasCustomTheme ? 1 : 0}
      style={{
        height: isDesktop ? "45vh" : "120vh",
        background: hasCustomTheme
          ? theme.palette.primary.main
          : "linear-gradient(135deg, #3e2465, #5f2f85, #71459b, #7f59a6)",
      }}
      sx={{
        zIndex: tema.zIndex.drawer + 1,
      }}
    >
      <Toolbar>
        <Grid container direction={isDesktop ? "column" : "row"}>
          <Grid item xs={isDesktop ? 10 : 12} style={{ maxWidth: "100vw" }}>
            {isDesktop ? <DesktionSections /> : <MobileSections />}
          </Grid>
          <Grid
            item
            xs={isDesktop ? 2 : 12}
            style={{ maxWidth: "100vw", marginTop: isDesktop ? 0 : "2vh" }}
          >
            <Typography
              style={{
                color: hasCustomTheme ? null : "white",
                textAlign: "center",
                fontSize: "1.46vh",
                fontWeight: "700",
              }}
            >
              29.230.251/0001-44 - RIZOMA SOLUCOES EM SERVICOS CADASTRAIS EIRELI
              | 27.352.693/0001-38 - RC COMERCIO DE ALIMENTOS EIRELI |
              46.041.774/0001-61 - SALARI APOIO ADMINISTRATIVO LTDA |
              34.877.926/0001-81 - RICHARD FREITA S SALARI SEGUROS
            </Typography>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
};

const CustomDivider = () => {
  const { isDesktop } = useResponsive();

  return (
    <Divider
      orientation={isDesktop ? "vertical" : "horizontal"}
      style={{
        backgroundColor: "white",
        width: isDesktop ? 2 : "90vw",
        height: isDesktop ? "100%" : 2,
        marginTop: isDesktop ? 0 : "1vh",
        marginBottom: isDesktop ? 0 : "1vh",
      }}
    />
  );
};

export default FooterComponent;
