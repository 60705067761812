import React from "react";
import Box from "@mui/material/Box/Box";
import Grid from "@mui/material/Grid/Grid";
import Typography from "@mui/material/Typography/Typography";
import makeStyles from "@mui/styles/makeStyles/makeStyles";

import {
  ButtonGradientComponent,
  GenericDialogComponent,
  InputGradientComponent,
} from "../../../../../components";

const useStyles = makeStyles(() => ({
  content: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: "1vh",
    marginBottom: "2vh",
    width: "100%",
    gap: "1vh",
  },
}));

const LinkDialogComponent = ({ linkForm, onSubmitLink }) => {
  const classes = useStyles();

  return (
    <GenericDialogComponent
      name="link-dialog"
      isPublic={true}
      className={classes.content}
    >
      <div className={classes.content}>
        <Grid item xs={12}>
          <Box>
            <Typography
              style={{
                color: "white",
                fontWeight: "400",
                fontSize: "2vh",
                marginBottom: "0.5vh",
              }}
            >
              ALTERAR LINK NOSSA COMUNIDADE
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} style={{ width: "80%" }}>
          <InputGradientComponent
            control={linkForm}
            name="link"
            placeholder="Link"
          />
        </Grid>
        <Grid item xs={12} style={{ width: "80%" }}>
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <ButtonGradientComponent
              width="60%"
              label="ALTERAR"
              onClick={() => onSubmitLink()}
            />
          </Box>
        </Grid>
      </div>
    </GenericDialogComponent>
  );
};

export default LinkDialogComponent;
