import React from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import moment from "moment";

import { Constants, Format } from "../../../utils";
import {
  campaignService,
  financialService,
} from "../../../../infrastructure/services";
import { useAlert, useAuth, useDialog } from "../../../providers";
import { useIsMounted } from "../../../hooks";
import { useLoadingContext } from "../../../context/loading.context";
import dayjs from "dayjs";

const useFinancialController = () => {
  const schema = yup.object().shape({
    startDate: yup
      .date()
      .typeError("Data inválida")
      .required("Campo obrigatório"),
    endDate: yup
      .date()
      .typeError("Data inválida")
      .required("Campo obrigatório")
      .when("startDate", (startDate, schema) =>
        schema.min(startDate, "A data final deve ser maior que a data inicial")
      ),
    campaign: yup.object().required("Campo obrigatório"),
  });

  const { control, handleSubmit, watch, getValues } = useForm({
    defaultValues: {
      startDate: new Date(new Date().setDate(new Date().getDate() - 5)),
      endDate: new Date(),
      campaign: Constants.defaultSelect,
    },
    resolver: yupResolver(schema),
  });

  const { showError, showSuccess } = useAlert();
  const { user } = useAuth();
  const isMounted = useIsMounted();
  const { openDialog } = useDialog();
  const [campaigns, setCampaigns] = React.useState([Constants.defaultSelect]);
  const [extract, setExtract] = React.useState([]);
  const [amountReceivable, setAmountReceivable] = React.useState(0);
  const [balance, setBalance] = React.useState(0);
  const [pendingInvoice, setPendingInvoice] = React.useState(false);
  const [hasValueToAnticipate, setHasValueToAnticipate] = React.useState(false);
  const rowsPerPage = 10;
  const [count, setCount] = React.useState(0);
  const [page, setPage] = React.useState(0);
  const [lastQuery, setLastQuery] = React.useState(null);
  const { setLoadingState } = useLoadingContext();

  React.useEffect(() => {
    if (user != null) {
      setLoadingState(true);
      campaignService
        .getUserCampaigns()
        .then((response) => {
          if (isMounted.current) {
            setCampaigns([
              Constants.defaultSelect,
              ...response.map((item) => ({ id: item.id, label: item.name })),
            ]);
            onSubmit();
          }
        })
        .catch(showError)
        .finally(() => {
          setLoadingState(false);
        });
    }
  }, [user]);

  React.useEffect(() => {
    const subscription = watch((value, { name, type }) => onSubmit());

    return () => subscription.unsubscribe();
  }, [watch]);

  const onSendInvoice = (event) => {
    if (event.target.files && event.target.files[0]) {
      const reader = new FileReader();

      reader.onload = (e) => {
        setLoadingState(true);
        financialService
          .sendInvoice({ invoice: e.target.result })
          .then(() => {
            setPendingInvoice(false);
            showSuccess("Nota fiscal incluida!");
          })
          .catch(showError)
          .finally(() => {
            setLoadingState(false);
          });
      };

      reader.readAsDataURL(event.target.files[0]);
    }
  };

  const onSubmit = handleSubmit((data) => {
    onSearch({ newPage: 0, ...data, isDownload: false });
  });

  const onDownloadSubmit = () => {
    onSearch({
      newPage: 0,
      ...getValues(),
      isDownload: true,
    });
  };

  const onSearch = ({ newPage, startDate, endDate, campaign, isDownload }) => {
    var query = {
      startDate: dayjs(startDate).format().substring(0, 10),
      endDate: dayjs(endDate).format().substring(0, 10),
      page: newPage ?? page,
      rowsPerPage: rowsPerPage,
    };

    if (campaign.id != 0) {
      query.offerId = campaign.id;
    }

    if (newPage == 0) {
      setPage(0);
    }

    if (isDownload == true) {
      query.baixar = 1;
    }

    setLastQuery({
      startDate,
      endDate,
      campaign,
    });
    setLoadingState(true);
    financialService
      .getExtract(query)
      .then((response) => {
        if (isDownload == true) {
          makeCsvData(response.extract);
        } else {
          setExtract(response.extract);
          setPendingInvoice(response.pendingInvoice);
          setAmountReceivable(response.amountReceivable);
          setHasValueToAnticipate(response.valueToAnticipate);
          setBalance(response.balance);
          setCount(response.count);
        }
      })
      .catch(showError)
      .finally(() => {
        setLoadingState(false);
      });
  };

  const makeCsvData = (extract) => {
    if (extract.length == 0) {
      return;
    }

    var csvRows = [];

    csvRows.push(["PRODUTO", "USUÁRIO", "DATA", "SEU GANHO", "RECEBIDO"]);

    extract.map((row) => {
      csvRows.push([
        row.campaign,
        row.affiliate,
        row.eventDate,
        row.payment.toLocaleString("pt-BR", {
          style: "currency",
          currency: "BRL",
        }),
        row.received == true ? "Sim" : "Não",
      ]);
    });

    const csvString = csvRows.join("\n");

    Format.downloadCsv(csvString, "financeiro");
  };

  const onAdvancePayment = () => {
    openDialog("advance-payment-dialog");
  };

  const onConfirmAdvancePayment = () => {
    setLoadingState(true);
    financialService
      .advancePayment()
      .then(() => {
        setHasValueToAnticipate(false);
        showSuccess(
          "Em breve você será notificado do adiantamento das suas comissões!"
        );
      })
      .catch(showError)
      .finally(() => {
        setLoadingState(false);
      });
  };

  const handleChangePage = (newPage) => {
    setPage(newPage);
    onSearch({ newPage, ...lastQuery });
  };

  return {
    control,
    campaigns,
    extract,
    amountReceivable,
    balance,
    pendingInvoice,
    onSendInvoice,
    onAdvancePayment,
    onConfirmAdvancePayment,
    hasValueToAnticipate,
    onDownloadSubmit,
    handleChangePage,
    page,
    count,
    rowsPerPage,
  };
};

export default useFinancialController;
