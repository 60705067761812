import { Format } from "../../presentation/utils";
import httpClient from "./axios.config";

class ProposalService {
  getProposals = (query) => {
    return new Promise((resolve, reject) => {
      httpClient
        .get("/propostaNova/propostas", { params: query })
        .then((response) => {
          const proposals = response.data.response.data;
          const count = response.data.response.count;
          const counter = response.data.response.contadores;

          resolve({
            data: proposals.map((proposal) => ({
              id: parseInt(proposal.id),
              affiliatedName: proposal.afiliadoNome,
              clientName: proposal.clienteNome,
              clientCpf: proposal.clienteCpf,
              houseCommission: proposal.comissaoCasa,
              affiliatedCommission: proposal.comissaoAfiliado,
              value: proposal.valor,
              parcelValue: proposal.parcelaValor,
              parcelQuantity: proposal.parcelaQuantidade,
              campaignName: proposal.campanhaNome,
              campaignCategory: proposal.campanhaCategoria,
              bankName: proposal.bancoNome,
              bankNumber: proposal.bancoNumero,
              proposalType: proposal.tipoProposta,
              eventDate: proposal.dtEvento,
              situation: proposal.situacao,
              observation: proposal.observacao,
            })),
            count: count,
            counters: {
              pending: counter.pendentes,
              approved: counter.aprovadas,
              disapproved: counter.reprovadas,
            },
          });
        })
        .catch((error) =>
          reject(
            error.response && error.response.data && error.response.data.message
              ? error.response.data.message
              : "Erro inesperado, tente novamente mais tarde."
          )
        );
    });
  };

  createProposal = ({ query }) => {
    return new Promise((resolve, reject) => {
      httpClient
        .post("/propostaNova/proposta", { proposta: query })
        .then(() => resolve())
        .catch((error) =>
          reject(
            error.response && error.response.data && error.response.data.message
              ? error.response.data.message
              : "Erro inesperado, tente novamente mais tarde."
          )
        );
    });
  };

  editProposal = ({ id, query }) => {
    return new Promise((resolve, reject) => {
      httpClient
        .put("/propostaNova/proposta?idProposta=" + id, { proposta: query })
        .then(() => resolve())
        .catch((error) =>
          reject(
            error.response && error.response.data && error.response.data.message
              ? error.response.data.message
              : "Erro inesperado, tente novamente mais tarde."
          )
        );
    });
  };

  deleteProposal = ({ id }) => {
    return new Promise((resolve, reject) => {
      httpClient
        .delete("/propostaNova/proposta?idProposta=" + id)
        .then(() => resolve())
        .catch((error) =>
          reject(
            error.response && error.response.data && error.response.data.message
              ? error.response.data.message
              : "Erro inesperado, tente novamente mais tarde."
          )
        );
    });
  };

  getProposalById = ({ id, withCommission }) => {
    return new Promise((resolve, reject) => {
      httpClient
        .get(
          "/propostaNova/proposta?idProposta=" +
            id +
            "&comComissao=" +
            withCommission
        )
        .then((response) => {
          const proposal = response.data.response;

          resolve({
            id: parseInt(proposal.id),
            affiliatedName: proposal.afiliadoNome,
            clientName: proposal.clienteNome,
            clientCpf: proposal.clienteCpf,
            houseCommission:
              proposal.tipoComissaoCasa == 1
                ? Format.toCurrency(parseFloat(proposal.comissaoCasa))
                : parseFloat(proposal.comissaoCasa),
            houseCommissionType: proposal.tipoComissaoCasa,
            affiliatedCommission:
              proposal.tipoComissaoAfiliado == 1
                ? Format.toCurrency(parseFloat(proposal.comissaoAfiliado))
                : parseFloat(proposal.comissaoAfiliado),
            affiliatedCommissionType: proposal.tipoComissaoAfiliado,
            value: Format.toCurrency(parseFloat(proposal.valor)),
            parcelValue: !!proposal.parcelaValor
              ? Format.toCurrency(parseFloat(proposal.parcelaValor))
              : "",
            parcelQuantity: proposal.parcelaQuantidade ?? 0,
            campaignName: proposal.campanhaNome,
            campaignCategory: proposal.campanhaCategoria,
            bankName: proposal.bancoNome,
            bankNumber: proposal.bancoNumero,
            proposalType: proposal.tipoProposta,
            eventDate: proposal.dtEvento,
            situation: proposal.situacao,
            observation: proposal.observacao,
            yourCommission: proposal.suaComissao,
          });
        })
        .catch((error) =>
          reject(
            error.response && error.response.data && error.response.data.message
              ? error.response.data.message
              : "Erro inesperado, tente novamente mais tarde."
          )
        );
    });
  };

  changeSituationOfProposal = ({ id, query }) => {
    return new Promise((resolve, reject) => {
      httpClient
        .post("/propostaNova/situacao?idProposta=" + id, query)
        .then(() => resolve())
        .catch((error) =>
          reject(
            error.response && error.response.data && error.response.data.message
              ? error.response.data.message
              : "Erro inesperado, tente novamente mais tarde."
          )
        );
    });
  };
}

export default new ProposalService();
