import Grid from "@mui/material/Grid/Grid";
import Typography from "@mui/material/Typography/Typography";
import Card from "@mui/material/Card/Card";
import makeStyles from "@mui/styles/makeStyles/makeStyles";

import { useResponsive } from "../../../../providers";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles({
  textoComOverflow: {
    display: "-webkit-box",
    "-webkit-line-clamp": 6,
    "-webkit-box-orient": "vertical",
    overflow: "hidden",
    textOverflow: "ellipsis",
    fontWeight: "700",

    textAlign: "center",
    color: "#5C5C5C",
    marginTop: "1vh",
    marginBottom: "1vh",
  },
  cardHover: {
    "&:hover": {
      cursor: "pointer",
    },
  },
});

const CampaignsComponent = ({ campaigns }) => {
  const { isDesktop } = useResponsive();

  return (
    <div
      style={{
        width: isDesktop ? "71vw" : "76vw",
        marginTop: "8vh",
        marginBottom: "8vh",
      }}
    >
      <Typography
        style={{
          fontWeight: "700",
          fontSize: "1.75vh",
          color: "#5C5C5C",
          marginBottom: "1vh",
        }}
      >
        CAMPANHAS
      </Typography>
      <Grid container spacing={2}>
        {campaigns.map((campaign, index) => {
          return (
            <Grid item key={index} xs={isDesktop ? 4 : 6}>
              <CampaignCard
                id={campaign.id}
                logoUrl={campaign.logoUrl}
                describle={campaign.name}
              />
            </Grid>
          );
        })}
      </Grid>
    </div>
  );
};

const CampaignCard = ({ id, logoUrl, describle }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { isDesktop } = useResponsive();

  return (
    <div>
      <Card
        className={classes.cardHover}
        onClick={() => navigate("/campanhas/" + id)}
        elevation={0}
        style={{
          width: isDesktop ? "21.80vw" : "35vw",
          height: isDesktop ? "18vh" : "10vh",
          borderRadius: "10px",
        }}
      >
        <Grid
          container
          style={{
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            paddingInline: "1vw",
          }}
        >
          <Grid
            item
            xs={6}
            style={{
              height: isDesktop ? "17.5vh" : "9.5vh",
            }}
          >
            <img
              src={logoUrl}
              style={{
                width: "100%",
                height: "100%",
                objectFit: "contain",
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <Typography
              variant="body1"
              className={classes.textoComOverflow}
              style={{ fontSize: isDesktop ? "1.46vh" : "0.97vh" }}
              component="div"
            >
              {describle}
            </Typography>
          </Grid>
        </Grid>
      </Card>
    </div>
  );
};

export default CampaignsComponent;
