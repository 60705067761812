import React from "react";
import Paper from "@mui/material/Paper/Paper";
import Divider from "@mui/material/Divider/Divider";
import Grid from "@mui/material/Grid/Grid";
import Typography from "@mui/material/Typography/Typography";
import Card from "@mui/material/Card/Card";
import CardMedia from "@mui/material/CardMedia/CardMedia";
import makeStyles from "@mui/styles/makeStyles/makeStyles";

import {
  BodyComponent,
  ButtonComponent,
  InputCopyComponent,
} from "../../../components";
import useCampaignDetailController from "./campaigns-details.controller";
import { useAuth, useDialog, useResponsive } from "../../../providers";
import LinkQrCodeDialogComponent from "./components/link-qr-code-dialog.component";
import ImageVideoPdfListCardComponent from "../../../components/form/image-video-pdf-list-card.component";
import YoutubeDialogComponent from "./components/youtube-dialog.component";
import PixelSettingsDialogComponent from "./components/pixel-settings-dialog.component";
import TumbnailImage from "../../../../assets/imagens/tumbnail.jpg";

const useStyles = makeStyles(() => ({
  logoCard: {
    borderRadius: "10px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    paddingInline: "1vw",
  },
  logoMedia: {
    width: "100%",
    height: "100%",
    objectFit: "contain",
  },
  campaignName: {
    fontWeight: "700",
    fontSize: "1.95vh",
    color: "#858585",
    marginTop: "3vh",
    marginBottom: "5vh",
  },
  statusGrid: {
    justifyContent: "center",
    marginBottom: "4vh",
  },
  statusCard: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    borderRadius: "10px",
  },
  statusCardLabel: {
    fontWeight: "700",
    fontSize: "1.46vh",
    color: "white",
    marginTop: "2vh",
    textAlign: "center",
  },
  statusCardValue: {
    fontWeight: "300",

    color: "white",
    textAlign: "center",
    lineHeight: "95%",
  },
  card: {
    width: "71vw",
    borderRadius: "10px",
  },
  cardTitle: {
    marginInline: "4vh",
    marginTop: "4vh",
    marginBottom: "4vh",
    fontWeight: "700",
    fontSize: "1.75vh",
    color: "#5C5C5C",
  },
  describle: {
    marginInline: "4vh",
    marginTop: "4vh",
    marginBottom: "4vh",
    fontWeight: "400",
    fontSize: "1.75vh",
    color: "#5C5C5C",
  },
}));

const CampaignDetailsPage = () => {
  const classes = useStyles();
  const { openDialog } = useDialog();
  const { user } = useAuth();
  const { isDesktop } = useResponsive();
  const { campaign, onCopyShareLink, onClickInCanpaignButton, pixel } =
    useCampaignDetailController();

  return (
    <BodyComponent>
      <LinkQrCodeDialogComponent />
      <YoutubeDialogComponent />
      <PixelSettingsDialogComponent />

      <Card
        elevation={0}
        className={classes.logoCard}
        style={{
          width: isDesktop ? "14.16vw" : "28.32vw",
          height: isDesktop ? "15.03vh" : "12vh",
        }}
      >
        {!!campaign && (
          <CardMedia
            component="img"
            src={campaign.logoUrl}
            className={classes.logoMedia}
          />
        )}
      </Card>

      <Typography className={classes.campaignName}>
        {campaign?.name.toUpperCase() ?? ""}
      </Typography>

      <Grid
        container
        spacing={isDesktop ? 5 : 1}
        sx={{
          alignItems: "center",
          justifyContent: "center",
          marginBottom: "4vh",
        }}
      >
        <Grid item xs={isDesktop ? 3 : 6}>
          <CustomCardComponent
            backgroundColor="#40215F"
            label="STATUS"
            value="ATIVO"
          />
        </Grid>
        <Grid item xs={isDesktop ? 3 : 6}>
          <CustomCardComponent
            backgroundColor="#774E98"
            label="CAMPANHA"
            value={campaign?.category?.name ?? ""}
          />
        </Grid>
        <Grid item xs={isDesktop ? 3 : 6}>
          <CustomCardComponent
            backgroundColor="#61C3BD"
            label={
              !!user && user.role == "ADMINISTRADOR"
                ? "COMISSÃO DA CASA"
                : "COMISSÃO"
            }
            value={
              !!campaign
                ? campaign.commissionType == "R$"
                  ? campaign.commission.toLocaleString("pt-BR", {
                      style: "currency",
                      currency: "BRL",
                    })
                  : campaign.commission + "%"
                : 0
            }
          />
        </Grid>
        <Grid item xs={isDesktop ? 3 : 6}>
          <Card
            elevation={0}
            className={classes.logoCard}
            sx={{
              width: isDesktop ? "max-content" : "31vw",
              height: isDesktop ? "15vh" : "12vh",
              "&:hover": {
                cursor: "pointer",
              },
            }}
          >
            <CardMedia
              component="img"
              src={TumbnailImage}
              className={classes.logoMedia}
              onClick={() =>
                openDialog("youtube-dialog", {
                  link: "https://www.youtube.com/embed/2SQl08USI4U",
                })
              }
            />
          </Card>
        </Grid>
      </Grid>

      {!!campaign && !!campaign.shareLink && (
        <Grid
          container
          style={{ marginBottom: "4vh", width: "71vw" }}
          spacing={1}
        >
          <Grid item xs={isDesktop ? 8 : 12}>
            <InputCopyComponent
              width={isDesktop ? null : "69vw"}
              label="LINK AUTORIZADO PARA INDICAR O PRODUTO"
              value={campaign.shareLink}
              onClick={(onCopy) => onCopyShareLink(onCopy)}
              disabled={!!user && user.role == "ADMINISTRADOR"}
            />
          </Grid>
          <Grid item xs={isDesktop ? 2 : 12}>
            <ButtonComponent
              label="GERAR QR-CODE"
              backgroundColor="#858585"
              color="white"
              fullWidth
              height="5vh"
              onClick={() =>
                openDialog("link-qr-code-dialog", {
                  link: campaign.shareLink,
                  nome: campaign.name.toUpperCase(),
                })
              }
            />
          </Grid>
          <Grid item xs={isDesktop ? 2 : 12}>
            <ButtonComponent
              label="PIXEL (MÉTRICAS)"
              backgroundColor="#858585"
              color="white"
              fullWidth
              height="5vh"
              onClick={() =>
                openDialog("pixel-settings-dialog", {
                  campaign: campaign,
                  pixel: pixel,
                })
              }
            />
          </Grid>
        </Grid>
      )}

      <Paper
        variant="outlined"
        className={classes.card}
        style={{ marginBottom: "4vh" }}
      >
        <Typography className={classes.cardTitle}>DESCRIÇÃO</Typography>
        <Divider />
        {!!campaign &&
        (campaign.hasSelfRegistration == true ||
          campaign.hasOpenLink == true) ? (
          <Grid container style={{ alignItems: "center" }}>
            <Grid item xs={isDesktop ? 6 : 12}>
              <Typography className={classes.describle}>
                {(campaign?.describleLP ?? ".")
                  .split("\n")
                  .map((line, index) => (
                    <React.Fragment key={index}>
                      {line}
                      <br />
                    </React.Fragment>
                  ))}
              </Typography>

              <div
                style={{
                  marginInline: "4vh",
                  marginTop: "4vh",
                  marginBottom: "4vh",
                }}
              >
                <ButtonComponent
                  label={campaign?.textoBotao ?? "SOLICITAR SEU LINK"}
                  backgroundColor={campaign?.corBotao ?? "#774E98"}
                  color="white"
                  height="5vh"
                  onClick={onClickInCanpaignButton}
                />
              </div>
            </Grid>
            <Grid
              item
              xs={isDesktop ? 6 : 12}
              style={isDesktop ? null : { textAlign: "center" }}
            >
              {!!campaign && campaign?.isYoutube == true ? (
                <iframe
                  width="98%"
                  height={isDesktop ? "315" : "215"}
                  src={campaign?.linkYoutube ?? ""}
                  title={campaign?.textoBotao ?? "SOLICITAR SEU LINK"}
                  allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowFullScreen
                ></iframe>
              ) : (
                <img
                  src={campaign?.bannerLP ?? null}
                  style={{ width: "100%" }}
                />
              )}
            </Grid>
          </Grid>
        ) : (
          <Typography className={classes.describle}>
            {(campaign?.describle ?? "").split("\n").map((line, index) => (
              <React.Fragment key={index}>
                {line}
                <br />
              </React.Fragment>
            ))}
          </Typography>
        )}
      </Paper>
      {!!campaign && !!campaign.mediaList && campaign.mediaList.length > 0 && (
        <ImageVideoPdfListCardComponent
          title="MATERIAIS PARA VENDA"
          mediaList={campaign?.mediaList ?? []}
        />
      )}

      {/*!!campaign &&
        !!campaign.productsCPV &&
        campaign.productsCPV.length > 0 && (
          <ProductListComponent productsCPV={campaign.productsCPV} />
        )*/}
    </BodyComponent>
  );
};

const CustomCardComponent = ({ label, value, backgroundColor }) => {
  const classes = useStyles();
  const { isDesktop } = useResponsive();

  return (
    <Card
      elevation={0}
      className={classes.statusCard}
      style={{
        backgroundColor: backgroundColor,
        width: isDesktop ? "14.16vw" : "28.32vw",
        height: isDesktop ? "15.03vh" : "12vh",
      }}
    >
      <Typography className={classes.statusCardLabel}>{label}</Typography>
      <Typography
        className={classes.statusCardValue}
        style={{
          marginTop: isDesktop ? "3vh" : "1.5vh",
          fontSize: isDesktop ? "3.12vh" : "2vh",
        }}
      >
        {value}
      </Typography>
    </Card>
  );
};

export default CampaignDetailsPage;
