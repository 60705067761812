import { createRef } from "react";
import Box from "@mui/material/Box/Box";
import Grid from "@mui/material/Grid/Grid";
import ReCAPTCHA from "react-google-recaptcha";

import {
  InputGradientComponent,
  ButtonGradientComponent,
  CheckBoxComponent,
  LinkComponent,
} from "../../../../components";
import useRegisterUserController from "../register-user.controller";
import { useResponsive } from "../../../../providers";
import SelectGradientComponent from "../../../../components/form/select-gradient.component";

const FormComponent = () => {
  const _reCaptchaRef = createRef();
  const { isDesktop } = useResponsive();
  const {
    control,
    onSubmit,
    setValue,
    messagePasswordValidation,
    cidades,
    estados,
  } = useRegisterUserController();

  return (
    <Box
      component="form"
      onSubmit={onSubmit}
      style={{
        display: "flex",
        justifyContent: "center",
        paddingTop: "7vh",
        paddingBottom: "7vh",
      }}
    >
      <Grid
        container
        style={{
          maxWidth: isDesktop ? "22vw" : "100vw",
          paddingInline: isDesktop ? "0" : "4vw",
        }}
      >
        <Grid item xs={12}>
          <InputGradientComponent
            name="fullName"
            control={control}
            placeholder="NOME COMPLETO"
          />
        </Grid>
        <Grid item xs={12}>
          <InputGradientComponent
            name="email"
            control={control}
            placeholder="EMAIL"
          />
        </Grid>
        <Grid item xs={12}>
          <InputGradientComponent
            name="cpf"
            control={control}
            placeholder="CPF"
          />
        </Grid>
        <Grid item xs={12}>
          <InputGradientComponent
            name="phone"
            control={control}
            placeholder="CELULAR"
          />
        </Grid>
        <Grid item xs={12}>
          <SelectGradientComponent
            name="estado"
            control={control}
            label="ESTADO"
            options={estados}
            isValueObject={false}
          />
        </Grid>
        <Grid item xs={12}>
          <SelectGradientComponent
            name="cidade"
            control={control}
            label="CIDADE"
            options={cidades}
            isValueObject={false}
          />
        </Grid>
        <Grid item xs={12}>
          <InputGradientComponent
            name="pix"
            control={control}
            placeholder="CHAVE DO PIX"
          />
        </Grid>
        <Grid item xs={12}>
          <InputGradientComponent
            name="facebook"
            control={control}
            placeholder="FACEBOOK"
          />
        </Grid>
        <Grid item xs={12}>
          <InputGradientComponent
            name="instagram"
            control={control}
            placeholder="INSTAGRAM"
          />
        </Grid>
        <Grid item xs={12}>
          <InputGradientComponent
            name="password"
            control={control}
            placeholder="SENHA"
            type="password"
            textHelp={messagePasswordValidation}
          />
        </Grid>
        <Grid item xs={12}>
          <InputGradientComponent
            name="confirmPassword"
            control={control}
            placeholder="CONFIRMAR SENHA"
            type="password"
            textHelp={messagePasswordValidation}
          />
        </Grid>
        <Grid item xs={12} style={{ marginTop: "1vh" }}>
          <Box display="flex" flexDirection="column" alignItems="center">
            <LinkComponent
              onClick={() =>
                window.open(
                  "https://contratosrizoma.us-southeast-1.linodeobjects.com/CONTRATO_DE_ADESAO_AFILIADOS.pdf",
                  "_blank"
                )
              }
              label="TERMO DE ADESÃO E CONFIABILIDADE"
            />
            <CheckBoxComponent
              name="acceptedTerm"
              control={control}
              label="ACEITAR TERMO"
            />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <div style={{ textAlign: "center" }}>
            <ReCAPTCHA
              style={{ display: "inline-block" }}
              theme="light"
              ref={_reCaptchaRef}
              sitekey={import.meta.env.VITE_RECAPTCHA_KEY}
              onChange={(token) => setValue("reCaptcha", token)}
            />
          </div>
        </Grid>
        <Grid item xs={12} style={{ marginTop: "3vh" }}>
          <ButtonGradientComponent
            label="CRIAR CONTA"
            fullWidth
            type="submit"
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default FormComponent;
