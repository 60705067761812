import Grid from "@mui/material/Grid/Grid";
import Typography from "@mui/material/Typography/Typography";
import Divider from "@mui/material/Divider/Divider";

import { InputFilterComponent } from "../../../../components";

const ClientSectionComponent = ({ control, withObservation }) => {
  return (
    <div>
      <Typography
        style={{
          fontWeight: "600",
          fontSize: "1.75vh",
          color: "#5C5C5C",
        }}
      >
        INFORMAÇÕES DO CLIENTE
      </Typography>
      <Divider style={{ marginBottom: "2vh" }} />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <InputFilterComponent
                name="clientName"
                control={control}
                label="NOME DO CLIENTE"
                placeholder="NOME DO CLIENTE"
                disabled={true}
              />
            </Grid>
            <Grid item xs={4}>
              <InputFilterComponent
                name="clientCpf"
                control={control}
                label="CPF DO CLIENTE"
                placeholder="CPF DO CLIENTE"
                disabled={true}
              />
            </Grid>
            <Grid item xs={4}>
              <InputFilterComponent
                name="situation"
                control={control}
                label="SITUAÇÃO"
                placeholder="SITUAÇÃO"
                disabled={true}
              />
            </Grid>
          </Grid>
        </Grid>
        {withObservation == true && (
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <InputFilterComponent
                  name="observation"
                  control={control}
                  label="OBSERVAÇÕES"
                  placeholder="OBSERVAÇÕES"
                  multiline={true}
                  minRows={7}
                  disabled={true}
                />
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    </div>
  );
};

export default ClientSectionComponent;
