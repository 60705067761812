import httpClient from "./axios.config";

class CampaignGoalService {
  getGoals = () => {
    return new Promise((resolve, reject) => {
      httpClient
        .get("/produto/metas")
        .then((response) => {
          const goalsResponse = response.data.response;

          const goals = goalsResponse.map((meta) => ({
            id: parseInt(meta.id),
            name: meta.nome,
          }));

          resolve(goals);
        })
        .catch((error) =>
          reject(
            error.response && error.response.data && error.response.data.message
              ? error.response.data.message
              : "Erro inesperado, tente novamente mais tarde."
          )
        );
    });
  };
}

export default new CampaignGoalService();
