import Slider from "@mui/material/Slider/Slider";
import Grid from "@mui/material/Grid/Grid";
import Typography from "@mui/material/Typography/Typography";
import Card from "@mui/material/Card/Card";
import makeStyles from "@mui/styles/makeStyles/makeStyles";
import withStyles from "@mui/styles/withStyles/withStyles";

import { useResponsive } from "../../../../providers";

const PrettoSlider = withStyles({
  root: {
    color:
      "linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), linear-gradient(96.51deg, #40215F , #774E98)",
    height: "1.56vh",
  },
  thumb: {
    height: "3vh",
    width: "3vh",
    background: "linear-gradient(178.18deg, #FD749B -13.56%, #281AC8 158.3%)",
    opacity: "0.2",
    marginTop: -7,
    marginLeft: -14,
    "&:focus, &:hover, &$active": {
      boxShadow:
        "1.3vh 1.3vh 1.3vh 1.3vh linear-gradient(178.18deg, #FD749B -13.56%, #281AC8 158.3%)",
      opacity: "0.2",
    },
  },
  active: {},
  track: {
    height: "1.56vh",
    borderRadius: "10px",
  },
  rail: {
    height: "1.56vh",
    borderRadius: "10px",
  },
})(Slider);

const useStyles = makeStyles(() => ({
  cardTitle: {
    padding: "4vh",
    fontSize: "1.75vh",
    color: "#5C5C5C",
    fontWeight: "700",
    marginLeft: "1.5vw",
  },
  cardContent: {
    paddingTop: "4vh",
    paddingBottom: "4vh",
    paddingInline: "3vw",
  },
}));

const DefaultNewCampaignCommissionComponent = ({
  percent,
  setPercent,
  onChangeCommissionToNewCampaign,
}) => {
  const classes = useStyles();
  const { isDesktop } = useResponsive();

  return (
    <Card
      variant="outlined"
      style={{ width: "71vw", borderRadius: "10px", marginTop: "6vh" }}
    >
      <Grid container>
        <Grid item xs={6}>
          <Typography className={classes.cardTitle}>
            COMISSÃO PADRÃO PARA NOVAS CAMPANHAS
          </Typography>
        </Grid>
        <Grid item xs={6} style={{ marginTop: "3vh", paddingRight: "2vw" }}>
          <div style={{ width: "100%" }}>
            <PrettoSlider
              valueLabelDisplay="off"
              value={percent ?? 0}
              onChange={(e, v) => setPercent(v)}
              onChangeCommitted={(e, v) => onChangeCommissionToNewCampaign(v)}
            />
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Typography
                style={{
                  color: "#5C5C5C",
                  fontWeight: "700",
                  fontSize: "1.17vh",
                }}
              >
                0%
              </Typography>
              <Typography
                style={{
                  color: "#5C5C5C",
                  fontWeight: "700",
                  fontSize: "1.17vh",
                }}
              >
                {percent + "%"}
              </Typography>
              <Typography
                style={{
                  color: "#5C5C5C",
                  fontWeight: "700",
                  fontSize: "1.17vh",
                }}
              >
                100%
              </Typography>
            </div>
          </div>
        </Grid>
      </Grid>
    </Card>
  );
};

export default DefaultNewCampaignCommissionComponent;
