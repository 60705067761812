import { createRef } from "react";
import Box from "@mui/material/Box/Box";
import Grid from "@mui/material/Grid/Grid";
import Typography from "@mui/material/Typography/Typography";
import ReCAPTCHA from "react-google-recaptcha";

import {
  InputGradientComponent,
  ButtonGradientComponent,
} from "../../../../components";
import { useResponsive } from "../../../../providers";
import useNewPasswordController from "../new-password.controller";
import LucreMaisLightImage from "../../../../../assets/imagens/lucre-mais-light.png";
import GrupoRizomaImage from "../../../../../assets/imagens/grupo-rizoma.png";

const FormComponent = () => {
  const _reCaptchaRef = createRef();
  const { isDesktop } = useResponsive();
  const { control, onSubmit, setValue, messagePasswordValidation } =
    useNewPasswordController();

  return (
    <Box
      component="form"
      onSubmit={onSubmit}
      style={{
        display: "flex",
        justifyContent: "center",
        paddingTop: "7vh",
        paddingBottom: "7vh",
      }}
    >
      <Grid
        container
        style={{
          maxWidth: isDesktop ? "25vw" : "100vw",
          paddingInline: isDesktop ? "0" : "4vw",
        }}
      >
        <Grid item xs={12} style={{ textAlign: "center", marginBottom: "5vh" }}>
          <img
            src={LucreMaisLightImage}
            style={{
              width: "100%",
              maxWidth: isDesktop ? "12.01vw" : "36.94vw",
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <InputGradientComponent
            name="password"
            control={control}
            placeholder="SENHA"
            type="password"
            textHelp={messagePasswordValidation}
          />
        </Grid>
        <Grid item xs={12}>
          <InputGradientComponent
            name="confirmPassword"
            fullWidth
            control={control}
            placeholder="CONFIRMAR SENHA"
            type="password"
            textHelp={messagePasswordValidation}
          />
        </Grid>
        <Grid item xs={12}>
          <div style={{ textAlign: "center" }}>
            <ReCAPTCHA
              style={{ display: "inline-block" }}
              theme="light"
              ref={_reCaptchaRef}
              sitekey={import.meta.env.VITE_RECAPTCHA_KEY}
              onChange={(token) => setValue("reCaptcha", token)}
            />
          </div>
        </Grid>
        <Grid item xs={12} style={{ marginTop: "3vh", marginBottom: "5vh" }}>
          <ButtonGradientComponent
            label="SALVAR NOVA SENHA"
            fullWidth
            type="submit"
          />
        </Grid>
        <Grid item xs={12}>
          <Box textAlign="center">
            <Typography
              style={{
                color: "white",
                fontWeight: "400",
                fontSize: "0.97vh",
                marginBottom: "0.5vh",
              }}
            >
              MAIS UMA EMPRESA DO
            </Typography>
            <img
              src={GrupoRizomaImage}
              style={{
                width: "100%",
                maxWidth: isDesktop ? "9.3vw" : "28.61vw",
              }}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default FormComponent;
