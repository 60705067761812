import Slider from "@mui/material/Slider/Slider";
import Typography from "@mui/material/Typography/Typography";
import withStyles from "@mui/styles/withStyles/withStyles";
import { Controller } from "react-hook-form";

const PrettoSlider = withStyles({
  root: {
    color:
      "linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), linear-gradient(96.51deg, #40215F , #774E98)",
    height: "1.56vh",
    width: "23vw",
  },
  thumb: {
    height: "3vh",
    width: "3vh",
    background: "linear-gradient(178.18deg, #FD749B -13.56%, #281AC8 158.3%)",
    opacity: "0.2",
    marginTop: -7,
    marginLeft: -14,
    "&:focus, &:hover, &$active": {
      boxShadow:
        "1.3vh 1.3vh 1.3vh 1.3vh linear-gradient(178.18deg, #FD749B -13.56%, #281AC8 158.3%)",
      opacity: "0.2",
    },
  },
  active: {},
  track: {
    height: "1.56vh",
    borderRadius: "10px",
  },
  rail: {
    height: "1.56vh",
    borderRadius: "10px",
  },
})(Slider);

const SliderComponent = ({ control, name, label, value, onChange }) => {
  const Field = ({ field, formState }) => {
    return (
      <div>
        <Typography
          style={{
            fontWeight: "700",
            fontSize: "1.46vh",
            color: "#5C5C5C",
          }}
        >
          {label + `: ${field.value}%`}
        </Typography>
        <PrettoSlider
          id="slider"
          valueLabelDisplay="off"
          value={!!field ? field.value : value}
          onChange={(e, v) => {
            if (!!field) {
              field.onChange(v);
            } else {
              onChange(v);
            }
          }}
        />
      </div>
    );
  };

  if (!!control) {
    return (
      <Controller
        name={name}
        control={control}
        render={({ field, formState }) => {
          return <Field field={field} formState={formState} />;
        }}
      />
    );
  }

  return <Field field={null} formState={null} />;
};

export default SliderComponent;
