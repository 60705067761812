import Grid from "@mui/material/Grid/Grid";
import Typography from "@mui/material/Typography/Typography";

import {
  ButtonComponent,
  DatapickerComponent,
  InputFilterComponent,
} from "../../../../../components";
import AutocompleteComponent from "../../../../../components/form/autocomplete.component";
import { useResponsive } from "../../../../../providers";
import { useNavigate } from "react-router-dom";

const AdminUsersFilterComponent = ({
  control,
  onSubmit,
  userTypes,
  profileTypes,
  count,
  baixarRelatorio,
}) => {
  const navigate = useNavigate();
  const { isDesktop } = useResponsive();

  return (
    <div style={{ marginBottom: "8vh", width: "71vw" }}>
      <Grid component="form" onSubmit={onSubmit} container spacing={2}>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={isDesktop ? 3 : 6}>
              <InputFilterComponent
                name="name"
                control={control}
                label="PESQUISAR POR USUÁRIO"
                placeholder="PESQUISAR POR USUÁRIO"
              />
            </Grid>
            <Grid item xs={isDesktop ? 3 : 6}>
              <InputFilterComponent
                name="cpf"
                control={control}
                label="PESQUISAR POR CPF"
                placeholder="PESQUISAR POR CPF"
              />
            </Grid>
            <Grid item xs={isDesktop ? 3 : 6}>
              <AutocompleteComponent
                name="userType"
                control={control}
                label="TIPO DE USUÁRIO"
                acessorLabel="name"
                acessorValue="id"
                options={userTypes}
              />
            </Grid>
            <Grid item xs={isDesktop ? 3 : 6}>
              <AutocompleteComponent
                name="profileType"
                control={control}
                label="PERFIL DO USUÁRIO"
                acessorLabel="name"
                acessorValue="id"
                options={profileTypes}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item md={isDesktop ? 3 : 6}>
              <DatapickerComponent
                name="dtCadastroInicio"
                control={control}
                label="DATA INICIAL"
                readOnly={true}
              />
            </Grid>
            <Grid item md={isDesktop ? 3 : 6}>
              <DatapickerComponent
                name="dtCadastroFim"
                control={control}
                label="DATA FINAL"
                readOnly={true}
              />
            </Grid>
            <Grid item md={isDesktop ? 3 : 6}>
              <Typography
                style={{
                  display: "flex",
                  borderRadius: "10px",
                  borderColor: "#858585",
                  border: "1px solid",
                  fontWeight: "700",
                  fontSize: "1.46vh",
                  color: "#5C5C5C",
                  height: "5.5vh",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                USUÁRIOS: {count}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sx={{ marginTop: "2vh" }}>
          <Grid container spacing={2}>
            <Grid item xs={isDesktop ? 2 : 5}>
              <ButtonComponent
                label="PESQUISAR"
                backgroundColor="#858585"
                color="white"
                size="small"
                fullWidth
                height="5vh"
                type="submit"
              />
            </Grid>
            <Grid item xs={isDesktop ? 3 : 5}>
              <ButtonComponent
                label="BAIXAR RELATÓRIO"
                backgroundColor="#40215F"
                color="white"
                size="small"
                fullWidth
                height="5vh"
                onClick={baixarRelatorio}
              />
            </Grid>
            <Grid item xs={isDesktop ? 3 : 7}>
              <ButtonComponent
                label="CADASTRAR NOVO USUÁRIO"
                backgroundColor="#40215F"
                color="white"
                size="small"
                fullWidth
                height="5vh"
                onClick={() => navigate("/admin/usuarios/formulario")}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default AdminUsersFilterComponent;
