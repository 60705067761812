import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Controller } from "react-hook-form";
import dayjs from "dayjs";
import "dayjs/locale/pt-br";

import EventIcon from "../../../assets/icones/event.svg";

const DatapickerComponent = ({
  control,
  name,
  label,
  value,
  onChange,
  readOnly,
  disabled,
  required = true,
}) => {
  const Field = ({ field, formState }) => {
    return (
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={"pt-br"}>
        <DatePicker
          value={!!field ? dayjs(field.value) || null : dayjs(value) || null}
          onChange={(date) => {
            if (!!field) {
              field.onChange(date);
            } else {
              onChange(date);
            }
          }}
          disabled={disabled}
          slots={{
            openPickerIcon: () => {
              return (
                <img
                  src={EventIcon}
                  style={{ width: "2.5vh", height: "2.5vh" }}
                />
              );
            },
          }}
          slotProps={{
            textField: {
              disabled: disabled,
              fullWidth: true,
              variant: "outlined",
              label: label,
              error: !!field ? !!formState.errors[name] : false,
              helperText: !!field ? formState.errors[name]?.message : null,
              InputLabelProps: {
                style: {
                  fontWeight: "700",
                  fontSize: "1.46vh",
                  color: "#5C5C5C",
                },
              },
              InputProps: {
                readOnly: readOnly ?? false,
                required: required,
                inputProps: {
                  style: {
                    borderRadius: "10px",
                    fontWeight: "700",
                    fontSize: "1.46vh",
                    color: "#858585",
                    paddingRight: "0.2vw",
                    height: "5.5vh",
                  },
                },
                style: {
                  height: "5.5vh",
                  borderRadius: "10px",
                },
              },
            },
            openPickerButton: {
              disableFocusRipple: true,
              disableRipple: true,
              disableTouchRipple: true,
            },
          }}
        />
      </LocalizationProvider>
    );
  };

  if (!!control) {
    return (
      <Controller
        name={name}
        control={control}
        render={({ field, formState }) => (
          <Field field={field} formState={formState} />
        )}
      />
    );
  }

  return <Field field={null} formState={null} />;
};

export default DatapickerComponent;
